import React, { useEffect, useState } from "react";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import menu_11 from "../../images/icon31.svg";
import menu_12 from "../../images/icon32.svg";
import menu_13 from "../../images/icon33.svg";
import menu_14 from "../../images/icon34.svg";
import Button from "react-bootstrap/Button";
import { BsChevronRight } from "react-icons/bs";
import { useNavigate, useLocation } from "react-router-dom";

function SendDoc() {
  // const [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();
  const location = useLocation();
  const dataParams = location.state || {};

  const [title, setTitle] = useState("");

  const policyNo = dataParams.policyNo || "";
  // const age = dataParams.age || "";
  const [age, setAge] = useState(localStorage.getItem("age"));

  const goback = () => {
    // window.location.href = "/refundPolicy?age=" + age + "&policyNo=" + policyNo;
    navigate(-1, {
      state: {
        policyNo: policyNo,
      },
    });
  };

  const goToBrowseFile = () => {
    // window.location.href = "/browseFile?age=" + age + "&policyNo=" + policyNo;
    navigate(`/browseFile`, {
      replace: true,
      state: {
        policyNo: policyNo,
        age: age,
      },
    });
  };

  useEffect(() => {
    console.log(age);
    if (age < 7) {
      setTitle("ผู้เยาว์ (อายุต่ำกว่า 7 ปี)");
    } else if (age >= 7 && age < 20) {
      setTitle("ผู้เยาว์ (อายุตั้งแต่ 7 ปีขึ้นไป แต่น้อยกว่า 20 ปี)");
    } else {
      setTitle("ผู้เอาประกัน (อายุตั้งแต่ 20 ปีขึ้นไป)");
    }
  }, [age]);

  return (
    <>
      <Header_Home />
      <Header title="ส่งเอกสารแสดงตัวตน" />
      <div className="container vh-100 px-4 pt-5">
        <div className="frame-custom h-auto">
          <div className="d-flex px-3 pt-3 pb-3">
            <h2 className="font-size-title">ส่งเอกสารแสดงตัวตน</h2>
          </div>
          {/* <hr style={{ margin: "0px 0px 15px 0px", border: "solid 1px" }} /> */}
          <div className="p-2">
            <div className="card-bg-detail p-3">
              <p className="font-size-sub-title px-3">
                ทำไมต้องส่งเอกสารแสดงตัวตน ?
              </p>
              <div className="d-flex flex-column px-3">
                <div className="d-flex flex-row align-items-center">
                  <img
                    className="icon-image-content mx-1"
                    src={menu_11}
                    alt="menu_11"
                  />
                  <div className="font-size-content">
                    เพื่อยืนยันตัวตนสำหรับการสมัครทำประกัน
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <img
                    className="icon-image-content mx-1"
                    src={menu_12}
                    alt="menu_12"
                  />
                  <div className="font-size-content">
                    เพื่อรับเงินตามเงื่อนไขกรมธรรม์
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-center px-2 align-items-center">
              <Button
                onClick={goToBrowseFile}
                className="col-12 d-flex justify-content-between align-items-center"
                style={{
                  backgroundColor: "#F1F1F1",
                  color: "black",
                  borderColor: "#d4d4d4",
                  marginTop: 10,
                  borderRadius: 0,
                  boxShadow: "0px 4px 2px 0px rgba(187, 186, 185, 0.5)",
                }}
                size="mb"
              >
                <span className="bold font-size-content">{title}</span>
                <BsChevronRight />
              </Button>

              <br />
            </div>
          </div>
        </div>
        <button onClick={goback} className="btn-checkout-back w-100 p-2 bold">
          ย้อนกลับ
        </button>
      </div>
    </>
  );
}

export default SendDoc;
