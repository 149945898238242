/* eslint-disable no-unused-vars */
import moment from "moment";
import MomentUtils from "@date-io/moment"; // choose your lib
import "moment/locale/th";

export default class OverwriteMomentBE extends MomentUtils {
  constructor({ locale, formats, instance }) {
    console.log(locale);
    console.log(formats);
    console.log(instance);
    super({ locale, formats, instance });
    
  }
  
  // date = (value = null) => {
  //   if (value === null) {
  //     return null;
  //   }
    
  //   if (this.locale != null) {
  //     const momentDate = this.moment(value);
  //     momentDate.locale(this.locale);
  //   return momentDate;

  //   }
    
  // };

  // toBuddhistYear = (momentDate, format) => {
  //   if (!momentDate || !momentDate.isValid()) {
  //     return null;
  //   }
  //   const christianYear = momentDate.format('YYYY');
  //   const buddhishYear = (parseInt(christianYear) + 543).toString();
  //   return momentDate
  //     .format(format.replace('YYYY', buddhishYear).replace('YY', buddhishYear.substring(2, 4)))
  //     .replace(christianYear, buddhishYear);
  // };

  // format = (date, formatKey) => {
  //   if (date === null) {
  //     return '';
  //   }
  //   return this.toBuddhistYear(date, formatKey);
  // };
}
