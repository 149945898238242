import React, { createContext, useState } from "react";

const TransferContext = createContext();

export const TransferProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [titleScreen, setTitleScreen] = useState("");
  const [headerTitle, setHeaderTitle] = useState(
    "บริการเพื่อรับเงินผลประโยชน์"
  );
  const [citizenId, setCitizenId] = useState("");
  const [docRange, setDocRange] = useState(0);
  const [uuid, setUuid] = useState("");
  const [mobileTel, setMobileTel] = useState("");

  const value = {
    activeStep,
    setActiveStep,
    skipped,
    setSkipped,
    titleScreen,
    setTitleScreen,
    headerTitle,
    setHeaderTitle,
    citizenId,
    setCitizenId,
    docRange,
    setDocRange,
    uuid,
    setUuid,
    mobileTel,
    setMobileTel
  };

  return (
    <TransferContext.Provider value={value}>
      {children}
    </TransferContext.Provider>
  );
};

export default TransferContext;
