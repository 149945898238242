import React, { useState, useEffect } from "react";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import "../Payment/payment-styles.css";
import { Box, Typography, Grid, Paper, Button, Divider } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import QrCodeIcon from "@mui/icons-material/QrCode";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { payment } from "./PaymentService/paymentService";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { Col } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { getParameterAPI } from "../PolicyDetail/PolicyDetailService/policyDetailService";
import {
  getPolicyDetailAPI,
  getDataByReferenceID,
  getDataPlanCode,
} from "../Payment/PaymentService/paymentService";
import CryptoJS from "crypto-js";
import moment from "moment";

const Payment = (props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  // const paymentData = location.state || {};
  const [paymentData, setPaymentData] = useState(location.state || {});

  const navigate = useNavigate();
  const [policyNoText, setPolicyNoText] = useState(paymentData.policyNo);
  const [paymentDueDateText, setPaymentDueDateText] = useState(
    paymentData.policyDueDate
  );
  const [policyPaymentAllowDate, setPaymentEffectiveDateText] = useState(
    paymentData.policyPaymentAllowDate
  );
  const [premiumTotalAmount, setPremiumTotalAmount] = useState(
    paymentData.policyTotalPremiumAmount
  );
  const [planCode, setPlanCode] = useState(paymentData.baseCode);
  const [configPlan, setConfigPlan] = useState();

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    reference_number: paymentData.policyNumber || "",
    payment_method: "",
    amount: paymentData.premiumAmount || "",
    client_code: "CLIENT_CODE", // ปรับตามที่ต้องการ
    signature: "",
  });
  const [cusTel, setCusTel] = useState("");
  const [policyData, setPolicyData] = useState("");
  const paymentDueDateTextFormat = moment(
    paymentDueDateText,
    ["DD/MM/YYYY", "YYYY-MM-DD"],
    true
  );

  const paymentDueDateTextFormatPlus30 = paymentDueDateTextFormat.isValid()
    ? paymentDueDateTextFormat.add(30, "days").format("DD/MM/YYYY")
    : "-";

  useEffect(() => {
    console.log(planCode);
    if (paymentData.policyNo == undefined) {
      getDataBackPayment();
    }
    getTel();
    getDataPlanCodeApi();
    console.log(policyPaymentAllowDate);
  }, []);

  const createAndSubmitForm = (formData) => {
    const form = document.createElement("form");
    form.action = "https://apiproxyuat.rabbitlife.co.th/pay";
    form.method = "POST";
    form.style.display = "none";

    Object.keys(formData).forEach((key) => {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = formData[key];
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
  };

  const handleLoading = () => {
    setLoading(!loading);
  };

  const clickPayment = async (methodType) => {
    console.log(paymentData);
    var json = {};
    if (paymentData.length != undefined) {
      json = {
        policyNo: paymentData.policyNo,
        paymentMethod: methodType,
        paymentInsuredName: `${localStorage.getItem(
          "first_name"
        )} ${localStorage.getItem("last_name")}`,
        amount: paymentData.policyTotalPremiumAmount,
        paymentDueDate: paymentData.policyDueDate,
      };
    } else {
      json = {
        policyNo: paymentData.policyNo,
        paymentMethod: methodType,
        paymentInsuredName: `${localStorage.getItem(
          "first_name"
        )} ${localStorage.getItem("last_name")}`,
        amount: paymentData.policyTotalPremiumAmount,
        paymentDueDate: paymentData.policyDueDate,
      };
    }

    handleLoading();
    // const json = {
    //   policyNo: paymentData.policyNo,
    //   paymentMethod: methodType,
    //   paymentInsuredName: `${localStorage.getItem(
    //     "first_name"
    //   )} ${localStorage.getItem("last_name")}`,
    //   amount: paymentData.policyTotalPremiumAmount,
    //   paymentDueDate: paymentData.policyDueDate,
    // };

    console.log(json);
    const result = await payment(json);
    console.log(result);
    if (result.success) {
      let amount = parseFloat(result.payload.amount);
      let formattedAmount = amount.toFixed(2);
      const newFormData = {
        reference_number: result.payload.reference_number,
        payment_method: result.payload.paymentMethod,
        amount: formattedAmount,
        client_code: result.payload.client_code,
        signature: result.payload.signature,
      };
      setFormData(newFormData);
      // console.log(newFormData)
      handleLoading();
      createAndSubmitForm(newFormData);
    }
  };

  const formatCurrency = (num) => {
    console.log(num);
    if (num != undefined) {
      return num
        .toLocaleString("en-US", { style: "currency", currency: "USD" })
        .replace("$", "");
    }
  };

  const goToPolicyList = () => {
    navigate("/policylist");
  };

  const getTel = async () => {
    const result = await getParameterAPI(12);
    const payload = result.payload;
    const cusTel = payload.find((item) => item.code === "CUS_SERV_TEL");
    const variable1 = cusTel.variable1;
    setCusTel(variable1);
  };

  const decryptCardNumber = (encryptedCardNumber, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedCardNumber, secretKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  };

  const getDataBackPayment = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const referenceNumber = urlParams.get("referenceNumber");
    console.log(referenceNumber);
    const resultRef = await getDataByReferenceID(referenceNumber);
    // console.log(resultRef.payload.policyNo);
    const resultPolicy = await getPolicyDetailAPI(
      resultRef.payload.policyNo,
      decryptCardNumber(
        localStorage.getItem("customer_id"),
        process.env.REACT_APP_ENCRYPT_DATA
      )
    );
    console.log(resultPolicy);
    setPolicyNoText(resultPolicy.data.policyNo);
    setPaymentDueDateText(resultPolicy.data.policyDueDate);
    setPaymentEffectiveDateText(resultPolicy.data.policyPaymentAllowDate);
    setPremiumTotalAmount(resultPolicy.data.policyTotalPremiumAmount);
    setPaymentData(resultPolicy.data);
    console.log(resultPolicy.data);
  };

  const getDataPlanCodeApi = async () => {
    const result = await getDataPlanCode(planCode);
    console.log(result.data.length);
    setConfigPlan(result.data.length);
  };

  return (
    <>
      <Header_Home />
      <Header title="ชำระเบี้ยประกัน" />
      {loading && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress
            sx={{
              animationDuration: "550ms",
            }}
            color="inherit"
          />
        </Backdrop>
      )}
      <div className="container" style={{ paddingBottom: 95 }}>
        <Paper
          elevation={3}
          className="paper"
          style={{ marginTop: "20px", marginLeft: "20px", marginRight: "20px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                style={{ fontSize: 13 }}
                className={`label ${isSmallScreen ? "small" : ""}`}
              >
                กรมธรรม์เลขที่
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{ fontSize: 13 }}
                className={`value ${isSmallScreen ? "small" : ""}`}
              >
                {policyNoText}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{ fontSize: 13 }}
                className={`label ${isSmallScreen ? "small" : ""}`}
              >
                วันที่ครบกำหนดชำระ
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{ fontSize: 13 }}
                className={`value ${isSmallScreen ? "small" : ""}`}
              >
                {paymentDueDateText}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{ fontSize: 13 }}
                className={`label ${isSmallScreen ? "small" : ""}`}
              >
                ชำระได้ถึงวันที่
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{ fontSize: 13 }}
                className={`value ${isSmallScreen ? "small" : ""}`}
              >
                {policyPaymentAllowDate}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{ fontSize: 13 }}
                className={`label ${isSmallScreen ? "small" : ""}`}
              >
                ค่าเบี้ยประกัน
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{ fontSize: 13 }}
                className={`value ${isSmallScreen ? "small" : ""}`}
              >
                {formatCurrency(premiumTotalAmount)}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <p
          style={{
            marginLeft: "20px",
            marginRight: "20px",
            marginTop: "25px",
            color: "#f44336",
            fontSize: 18,
          }}
        >
          ช่องทางการชำระ
        </p>
        <Box
          className="payment-options"
          style={{ marginLeft: "20px", marginRight: "20px" }}
        >
          <Box className="d-flex flex-row">
            <Box className="d-flex flex-column w-100">
              <Typography variant="h6" className="label" color="textPrimary">
                ออนไลน์
              </Typography>
              <Box
                style={{marginLeft:"40px"}}
                className={`d-flex flex-row ${
                  configPlan > 0
                    ? "justify-content-around"
                    : "justify-content-start"
                }`}
              >
                {configPlan > 0 && (
                  <Box
                    className="payment-option"
                    onClick={() => clickPayment("card")}
                  >
                    <CreditCardIcon />
                    <Typography
                      className="set-font text-black"
                      style={{ fontSize: 13 }}
                    >
                      บัตรเครดิต
                    </Typography>
                  </Box>
                )}
                <Box
                  className="payment-option"
                  onClick={() => clickPayment("qr")}
                >
                  <QrCodeIcon />
                  <Typography
                    className="set-font text-black"
                    style={{ fontSize: 13 }}
                  >
                    QR PromptPay
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* <Box className="d-flex flex-column align-items-center w-50">
              <Typography variant="h6" className="label" color="textPrimary">
                อื่นๆ
              </Typography>
              <Box className="payment-option">
                <ReceiptIcon />
                <Typography
                  className="set-font text-black"
                  style={{ fontSize: 13 }}
                >
                  Bill Payment
                </Typography>
              </Box>
            </Box> */}
          </Box>
          <Typography className="payment-notice" style={{ paddingTop: "30px" }}>
            **หากเกินวันที่ {policyPaymentAllowDate}{" "}
            กรุณาติดต่อตัวแทนประกันชีวิตที่ดูแลท่าน หรือติดต่อฝ่ายลูกค้าสัมพันธ์{" "}
            {cusTel}
          </Typography>
        </Box>

        {/* <Box
          className="payment-options"
          style={{ marginLeft: "20px", marginRight: "20px" }}
        >
          <Box className="d-flex flex-row justify-content-around">
            <Typography variant="h6" className="label" color="textPrimary">
              ออนไลน์
            </Typography>
            <Typography variant="h6" className="label" color="textPrimary">
              อื่นๆ
            </Typography>
          </Box>
          <Box className="payment-option-container">
            <Box
              className="payment-option"
              onClick={() => clickPayment("card")}
            >
              <CreditCardIcon />
              <Typography
                className="set-font text-black"
                style={{ fontSize: 13 }}
              >
                บัตรเครดิต
              </Typography>
            </Box>
            <Box className="payment-option" onClick={() => clickPayment("qr")}>
              <QrCodeIcon />
              <Typography
                className="set-font text-black"
                style={{ fontSize: 13 }}
              >
                QR Promptpay
              </Typography>
            </Box>
            <Box className="payment-option">
              <ReceiptIcon />
              <Typography
                className="set-font text-black"
                style={{ fontSize: 13 }}
              >
                bill payment
              </Typography>
            </Box>
          </Box>
          <Typography className="payment-notice" style={{ paddingTop: "30px" }}>
            * หากเกินวันที่ {paymentDueDateText}{" "}
            กรุณาติดต่อสาขาประกันชีวิตที่ดูแลท่าน
            หรือแจ้งต่อที่ศูนย์ลูกค้าสัมพันธ์ 021500523
          </Typography>
        </Box> */}
        <Row className="d-flex flex-lg-row flex-column mt-2 mb-5">
          <Col className="d-flex justify-content-start px-5">
            <button
              onClick={goToPolicyList}
              className="btn-checkout-back w-100 p-2 bold"
            >
              ย้อนกลับ
            </button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Payment;
