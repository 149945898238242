import React, { useContext, useState } from "react";
import { TextField } from "@mui/material";
import TransferContext, {
  TransferProvider,
} from "../TranferContextProvider.js";
import { sendOtp } from "../quickTransferServices/QuickTransferServices.js";
import { notifySuccess, notifyWarning } from "../../../util/Notification.js";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

const EnterCitizenId = () => {
  const {
    activeStep,
    setActiveStep,
    skipped,
    setSkipped,
    citizenId,
    setCitizenId,
    mobileTel,
    setMobileTel
  } = useContext(TransferContext);

  const [loading, setLoading] = useState(false);

  const verifyOtp = async () => {
    if (citizenId == "") {
      notifyWarning("กรุณาระบุเลขประจำตัวประชาชน หรือ เลขที่หนังสือเดินทาง");
    } else {
      setLoading(true);
      const res = await sendOtp(citizenId);

      if (res.success == false) {
        notifyWarning(res.payload.error);
        setLoading(false);
      } else {
        setMobileTel(res.payload.profile)
        notifySuccess("ส่งรหัส OTP สำเร็จ");
        handleNext();
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  return (
    <>
      <div className="border-content">
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress
            sx={{
              animationDuration: "550ms",
            }}
            color="inherit"
          />
        </Backdrop>
        <div
          style={{
            flex: 1,
            flexDirection: "column",
            justifyContent: "start",
            width: "100%",
          }}
        >
          เลขประจำตัวประชาชน หรือ เลขที่หนังสือเดินทาง
        </div>
        <TextField
          InputProps={{ sx: { marginTop: 2, borderRadius: 16 } }}
          id="bank_account_name"
          type="search"
          placeholder="โปรดระบุ"
          variant="outlined"
          size="small"
          fullWidth
          value={citizenId}
          onChange={(e) => setCitizenId(e.target.value)}
        />
      </div>
      <button
        style={{ marginTop: 10, fontSize: 15 }}
        onClick={verifyOtp}
        className="btn-checkout-premium w-100 p-2 bold"
      >
        ตกลง
      </button>
      <button
        className="btn-checkout-back w-100 p-2 bold"
        onClick={handleBack}
      >
        กลับ
      </button>
    </>
  );
};

export default EnterCitizenId;
