import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component } from "react";
import img_2 from "../../images/logonew1.png";
import img_logo from "../../images/rabbitlife-logo.png";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { TextField } from "@mui/material";
import CryptoJS from "crypto-js";

class OTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      error_flag: false,
      loading: false,
      error_meg: "เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้ง",
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  componentWillUnmount() {
    console.log("componentWillUnmount");
  }

  resend_otp = () => {
    let username = this.props.username;
    let password = this.props.password;
    this.setState({ loading: true });
    this.setState({ username: username });
    this.setState({ password: password });
    console.log("username: " + username);
    console.log("password: " + password);
    document.getElementById("otp").value = "";
    fetch(process.env.REACT_APP_API_BASEURL + "/api/user/activate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        dob: password,
      }),
    })
      .then((res) => res.json())

      .then((res) => {
        //console.log(res)
        if (res.success === true) {
          //   alert("ระบบทำการส่ง OTP เรียบร้อยแล้วรบกวนทำการตรวจสอบอีกครั้ง");
          this.setState({ loading: false });
          this.setState({
            error_flag: true,
            error_meg:
              "ระบบทำการส่ง OTP เรียบร้อยแล้วโปรดทำการตรวจสอบอีกครั้ง",
          });
        } else {
          //   alert(res.payload.error);
          this.setState({ error_flag: true, error_meg: res.payload.error });
          this.setState({ loading: false });
        }
      })
      .catch(function (res) {
        console.log(res);
      });
  };

  encryptCardNumber = (cardNumber, secretKey) => {
    const encrypted = CryptoJS.AES.encrypt(cardNumber, secretKey).toString();
    return encrypted;
  };

  submit = () => {
    let username = this.props.username;
    let password = this.props.password;
    let otp = document.getElementById("otp").value;
    this.setState({ loading: true });
    console.log("username: " + username);
    console.log("password: " + password);
    console.log("otp: " + otp);
    fetch(process.env.REACT_APP_API_BASEURL + "/api/user/confirmactivate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
        act_code: otp,
      }),
    })
      .then((res) => res.json())

      .then((res) => {
        console.log("confirmactivate");

        console.log(res);
        if (res.success === true) {
          //   alert("ท่านได้ลงทะเบียนเรียบร้อย");
          this.setState({
            error_flag: true,
            error_meg: "ท่านได้ลงทะเบียนเรียบร้อย",
          });
          fetch(process.env.REACT_APP_API_BASEURL + "/token", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username: username,
              otp: res.payload.error,
            }),
          })
            .then((res) => res.json())

            .then((res) => {
              console.log("retrun", res);

              this.setState({ token: res.token });
              console.log(res.token);
              console.log("sss");

              console.log("token", this.state.token);
              

              if (res.success !== false) {
                const encryptedCardNumber = this.encryptCardNumber(
                  res.customer_id,
                  process.env.REACT_APP_ENCRYPT_DATA
                );
                localStorage.setItem("customer_id", encryptedCardNumber);
                localStorage.setItem("profile", res.profile);
                localStorage.setItem("first_name", res.first_name);
                localStorage.setItem("last_name", res.last_name);
                localStorage.setItem("token_login", res.token);
                // console.log("localStorage", localStorage.getItem('username'))
                console.log(
                  "localStorage",
                  localStorage.getItem("token_login")
                );

                window.location.href = "/home";
              } else {
                // alert(res.payload.error);
                this.setState({ loading: false });
                this.setState({
                  error_flag: true,
                  error_meg: res.payload.error,
                });
                // window.location.href = "/";
              }
            })
            .catch(function (res) {
              console.log(res);
            });
        } else {
          //   alert(res.payload.error);
          this.setState({ loading: false });
          this.setState({
            error_flag: true,
            error_meg: res.payload.error,
          });
        }
      })
      .catch(function (res) {
        console.log(res);
      });
  };

  render() {
    const { step, loading } = this.state;
    const { values, handleChange, username } = this.props;
    switch (step) {
      case 1:
        return (
          <div className="page-contanner">
            {/* <Header/> */}
            {loading && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
              >
                <CircularProgress
                  sx={{
                    animationDuration: "550ms",
                  }}
                  color="inherit"
                />
              </Backdrop>
            )}
            <section className="sec-form" id="">
              <div className="container-fluid">
                <div className="row">
                  {/* <!-- <div className="col-md-4 col-sm-4 col-xs-12"></div> --> */}
                  <div className="col-lg-4 col-md- col-xs-12 p-5 mx-auto">
                    <div className="logo text-center">
                      <img className="img-fluid" src={img_2} alt="" />
                      <h4>iService</h4>
                    </div>

                    <form className="login-form mt-5">
                      <div className="form-group">
                        <label for="exampleInputEmail1" id="lbl_email">
                          กรุณาใส่หมายเลข OTP 6 หลัก
                          ที่ส่งไปยังเบอร์โทรศัพท์ของท่าน
                          (รหัสนี้มีอายุการใช้งาน 5 นาที)
                        </label>
                        <TextField
                          type="tel"
                          defaultValue=""
                          style={{ borderWidth: 1, marginBottom: "10px" }}
                          className="form-control form-control-lg"
                          id="otp"
                          placeholder="OTP"
                          inputProps={{
                            maxLength: 6,
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                            onInput: (e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            },
                          }}
                        />
                        {this.state.error_flag && (
                          <p className="text-danger">{this.state.error_meg}</p>
                        )}
                        <a onClick={this.resend_otp}>
                          <small
                            id="emailHelp"
                            className="form-text text-primary pointer"
                            style={{ cursor: "pointer" }}
                          >
                            ส่งรหัส OTP อีกครั้ง?
                          </small>
                        </a>
                      </div>
                      <a
                        onClick={this.submit}
                        type="submit"
                        className="btn btn-primary btn-block btn-lg btn mt-3 cust-btn"
                      >
                        เข้าสู่ระบบ
                      </a>
                    </form>
                  </div>
                </div>
              </div>
            </section>

            {/* <!-- Bootstrap JS Link --> */}
            {/* <script src="https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossOrigin="anonymous"></script>
            <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js" integrity="sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN" crossOrigin="anonymous"></script>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.min.js" integrity="sha384-VHvPCCyXqtD5DqJeNxl2dtTyhF78xXNXdkwX1CZeRusQfRKp+tA7hAShOK/B/fQ2" crossOrigin="anonymous"></script> */}
          </div>
        );
      case 2:
        return <div></div>;
    }
  }
}

export default OTP;
