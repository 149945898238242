import { Axios } from "../../../util";
import axios from "axios";

const searchPaymentAPI = async (policyNo, paymentDate) => {
  try {
    const res = await Axios.post('/api/payment/search',{policyNo: policyNo,paymentDate: paymentDate});
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const inquiryAPI = async (referenceNumber) => {
  try {
    const res = await Axios.post(`/api/payment/inquiry?referenceNumber=${referenceNumber}`);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};



export { searchPaymentAPI,
         inquiryAPI };
