import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import Header_Home from '../Component/Header_Home';

class Request_freelook_comp extends Component {
    constructor(props) {
        super(props);
        this.state = { }
    }

    componentDidMount() {

        console.log('componentDidMount')
    }


    componentDidUpdate() {
       
        console.log('componentDidUpdate')
    }

    componentWillUnmount() {
        console.log('componentWillUnmount')
       
    }

    


 
    render() {
        return (
            <div >
                <Header_Home/>
                <section className="d-flex justify-content-center align-items-center h-100 my-5">
                    <div className="container-fluid  container-margin">
                        <div className='row'>
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>ส่งคำร้องขอใช้สิทธิ Freelook เรียบร้อย</strong></label>
                                            </div>
                                        </div>      
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 ">
                                            <div className="col-2 ">
                                                    {/* <label htmlFor="subject" className='text-righ mt-2'><strong>Email</strong></label> */}
                                            </div>
                                            <div className="col-10">
                                               

                                                <div className="card-body list-group">
                                                    <div className="row col-10 ">
                                                        <div className="card p-0">  
                                                            {/* <div className="card-body header-card">
                                                            <label className='text-righ mt-2'><strong>เอกสารแนบ </strong></label>
                                                            </div>  */}
                                                            <ul className="list-group list-group-flush">
                                                            <li className="list-group-item">
                                          
                                                            <span className="bold">กรณีผู้เอาประกันชำระเบี้ยด้วยการโอนเงินเข้าบัญชีบริษัท :</span>  บริษัทฯ จะโอนเงินคืนเข้าบัญชีธนาคารตามที่ท่านแจ้งมา <br/><br/>
                                                            <span className="bold">กรณีที่ผู้เอาประกันชำระเบี้ยงวดแรกด้วยบัตรเครดิต :</span> บริษัทฯ จะคืนเงินเข้าบัตรเครดิตใบเดิม<br/><br/>
                                                            <span className="bold">ที่อยู่ในการส่งเล่มกรมธรรม์</span><br/>
                                                            บริษัท แรบบิทประกันชีวิต จำกัด (มหาชน)<br/>
                                                            เลขที่ 175 อาคารสาธรซิตี้ ทาวเวอร์ ชั้น 1/1และ 2/1 ถนนสาทรใต้<br/>
                                                            แขวงทุ่งมหาเมฆ เขตสาทร กรุงเทพฯ 10120<br/>
                                                         
                                                            </li>
                                                                           
                                                        </ul>
                                                        
                                                            
                                                        </div>   
                                                    </div>              
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body App">

                                        <a href="/request"  className="btn btn-lg btn-blue">เรียบร้อย</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    

                        {/* <div className='App'>
                            <a  href="/home" type="button" className="btn btn-lg btn mt-3 cust-btn"  >ย้อนกลับ</a>
                        </div> */}
                        </div> 
                </section>
            </div> 
        )
    }
        
    
}

export default Request_freelook_comp;