// import "../../App.css";
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import menu_1 from "../../images/1.svg";
import gmail from "../../images/gmail.png";
import location from "../../images/location.png";
import credit from "../../images/credit.png";
import document from "../../images/document.png";
import phone from "../../images/phone.png";
import bank from "../../images/bank.png";
import menu_7 from "../../images/7.svg";
import menu_8 from "../../images/8.svg";
import Header_Home from "../Component/Header_Home";
import { useNavigate } from "react-router-dom";
import "./personal.css";
import Card from "react-bootstrap/Card";
import { getPersonalMenu } from "./PersonalService/personalService";

const Personal = () => {
  let navigate = useNavigate();
  const [menuList, setMenuList] = useState([]);

  const handleRoute = (url) => {
    navigate(url);
  };

  useEffect(() => {
    initialPersonalMenu();
  }, []);

  const initialPersonalMenu = async () => {
    const res = await getPersonalMenu();
    setMenuList(res.data);
  };

  const img = (req) => {
    switch (req) {
      case "MST012_1":
      return location;
      case "MST012_2":
      return phone;
      case "MST012_3":
      return gmail;
      case "MST012_4":
      return document;
      case "MST012_5":
      return credit;
      case "MST012_6":
      return bank;
      default:
        return location;
    }
  };

  return (
    <>
      <Header_Home />
      {/* <section className="d-flex justify-content-center align-items-center">
        <div className="my-4 px-4">
          <div className="row justify-content-center">
            <div className="col-12 text-start px-lg-2">
              <h2>
                <span className="bold">การใช้สิทธิ</span>
              </h2>
            </div>
            <div className="card-menu-grid">
              {menuList.map((item) => (
                <div
                  className="card-menu"
                  onClick={() => {
                    handleRoute(item.variable3);
                  }}
                >
                  <img
                    className="image align-self-center"
                    // style={{maxWidth:'100px'}}
                    src={`${process.env.PUBLIC_URL}/personalIcons/${item.variable2}`}
                    alt="icon"
                  />
                  <p>{item.variable1}</p>
                  
                </div>
              ))}
            </div>
          </div>
        </div>
      </section> */}
      <div className="item-container">
        <div className="row">
          <div className="col-12">
            <h2>
              <b>แจ้งเปลี่ยนแปลง</b>
            </h2>
          </div>
          {menuList &&
            // menuList.length &&
            menuList.map(
              (post, index) => (
                <div key={index} className="col-4 mar">
                  <a onClick={() => handleRoute(post.variable3)}>
                    <div className="item-menu flex-column text-center">
                      <img
                        className="image align-self-center mt-2"
                        src={img(post.code)}
                        alt="icon"
                        />
                      <p className="flex-shrink-1" style={{color:" #F2654E"}}>{post.variable1}</p> 
                    </div>
                  </a>
                </div>
              )
            )}
        </div>
      </div>
      {/* <section className="d-flex justify-content-center align-items-center">
        <div className="my-4 px-4">
          <div className="row justify-content-center">
            <div className="col-12 text-start px-lg-2">
              <h2>
                <span className="bold">แจ้งเปลี่ยนแปลง</span>
              </h2>
            </div>
            <div className="card-menu-grid">
              {menuList.map((item) => (
                <div
                  className="card-menu"
                  onClick={() => {
                    handleRoute(item.variable3);
                  }}
                >
                  <img
                    className="icon"
                    src={`${process.env.PUBLIC_URL}/personalIcons/${item.variable2}`}
                    alt="icon"
                  />
                  <p>{item.variable1}</p>
                </div>
              ))}

            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="d-flex justify-content-center align-items-center">
        <div className="my-4 px-4">
          <div className="row justify-content-center">
            <div className="col-12 text-start px-lg-2">
              <h2>
                <span className="bold">แจ้งเปลี่ยนแปลง</span>
              </h2>
            </div>
            <div className="card-menu-grid">
              {menuList.map((item) => (
                <div
                  className="card-menu d-flex flex-column align-items-center"
                  onClick={() => {
                    handleRoute(item.variable3);
                  }}
                >
                  <img className="image align-self-center"
                    style={{ maxWidth: '100px' }}
                    src={`${process.env.PUBLIC_URL}/personalIcons/${item.variable2}`}
                    alt=""
                  />
                  <p style={{ textAlign: 'center', width: '170px' }}>{item.variable1}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Personal;
