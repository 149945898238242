import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import menu_1 from '../../images/d1.png'
import menu_2 from '../../images/d2.png'
import Header_Home from '../Component/Header_Home';

class Download extends Component {
    constructor(props) {
        super(props);
        this.state = {date: new Date() ,
        token: '',
        username:'',
        step:1}
    }

    tick() {
        this.setState({date: new Date()});
    }
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };
    
    prevStep = () => {
        this.props.prevStep();
    };

    componentDidMount() {
        console.log('componentDidMount')
    }

    componentDidUpdate() {
        console.log('componentDidUpdate')
    }

    componentWillUnmount() {
        console.log('componentWillUnmount')
       
    }


 
    render() {
        return (
                
            <div>
                <Header_Home/>

                <section className="d-flex justify-content-center align-items-center h-100 my-5">
                    <div className="container-fluid  container-margin">
                        <div className="row">
                            <div className="col-12">
                                <h1><b>ดาวน์โหลดเอกสาร</b></h1>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-4">
                                <a href='/download/document'>
                                    <div className="bx h-100 d-flex px-3 py-4 flex-column text-center">
                                        <img className="image align-self-center" src={menu_2}  alt=""/>
                                        <h3 className="flex-shrink-1">ดาวน์โหลดเอกสาร</h3>
                                    </div>
                                </a>
                            </div> 

                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-4">
                                <a href='/download/form'>
                                    <div className="bx h-100 d-flex px-3 py-4 flex-column text-center">
                                        <img className="image align-self-center" src={menu_1} alt=""/>
                                        <h3 className="flex-shrink-1">ดาวน์โหลดแบบฟอร์ม</h3>
                                    </div>
                                </a>
                                
                            </div>    
                            
                           

                            
                        </div>
                        <div className='App'>
                            <a  href="/home" type="button" className="btn btn-lg mt-3 btn-blue"  >ย้อนกลับ</a>
                        </div>
                    </div>
                </section>

                {/* <Footer/> */}

            {/* <!-- Bootstrap JS Link --> */}
            {/* <script src="https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossOrigin="anonymous"></script>
            <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js" integrity="sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN" crossOrigin="anonymous"></script>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.min.js" integrity="sha384-VHvPCCyXqtD5DqJeNxl2dtTyhF78xXNXdkwX1CZeRusQfRKp+tA7hAShOK/B/fQ2" crossOrigin="anonymous"></script> */}

            </div>
            )
    }
        
    
}

export default Download;