import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import "../../App.css";
import "react-datepicker/dist/react-datepicker.css";
import { TextField, Grid, InputLabel, TableFooter } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "../LetterDigiCom/letterDigiCom-styles.css";
import { getParameterAPI } from "../PolicyDetail/PolicyDetailService/policyDetailService";
import {
  searchDigitalCom,
  deleteDigitalCom,
  downloadLetterDigiCom,
} from "../LetterDigiCom/LetterDigiComService/letterDigiComService";
import Skeleton from "react-loading-skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TablePagination } from "@material-ui/core";
import "moment/locale/th";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { Select, OutlinedInput, MenuItem } from "@mui/material";
import imgTrash from "../../images/Trash-Icon.png";
import imgEdit from "../../images/mode-edit.png";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../../../src/context/store";
import "./letterDigiCom-styles.css";
import { Modal, Col, Button } from "react-bootstrap";
import { CustomInputText } from "../Component/CustomInputText";
import { CustomInputSelect } from "../Component/CustomInputSelect";
import { CustomInputDropdown } from "../Component/CustomInputDropdown";
import { Padding } from "@mui/icons-material";

const LetterDigiCom = () => {
  const theme = createTheme({
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            // backgroundColor: "#FFA500",
          },
        },
      },
    },
  });

  useEffect((date) => {
    // getConvertFormat(date)
  }, []);

  const style = {
    py: 0,
    width: "100%",
    maxWidth: 360,
    borderRadius: 2,
    border: "1px solid",
    borderColor: "divider",
    backgroundColor: "background.paper",
  };

  const [payDate, setPayDate] = useState(null);
  const [policyNo, setPolicyNo] = useState("");
  const [rowDigiCom, setRowDigiCom] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [refNo, setRefNo] = useState("");
  const [LoadingContent, setLoadingContent] = useState(false);
  const [spinner, setSpinner] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentDate, setCurrentDate] = useState(moment());
  const [openTooltip, setOpenTooltip] = useState(false);
  const [dataSearchBy, setDataSearchBy] = useState([]);
  const [selectedSearchBy, setSelectedSearchBy] = useState("");
  const [paramSearchBy, setParamSearchBy] = useState("");
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showModalNotFoundFile, setShowModalNotFoundFile] = useState(false);
  const [itemId, setItemId] = useState(null);

  const handleChangeSearchBy = (e) => {
    const selectedValue = e.target.value;
    setSelectedSearchBy(selectedValue);
    const selectedItem = dataSearchBy.find(
      (item) => item.code === selectedValue
    );
    if (selectedItem) {
      setParamSearchBy(selectedItem.code);
      console.log(selectedItem.code);
    } else {
      setParamSearchBy("");
    }
    console.log(selectedValue);
  };

  const handleChangeParam = (event) => {
    setInputValue(event.target.value);
    console.log(event.target.value);
  };

  const clear = () => {
    setSelectedSearchBy("");
    setInputValue("");
    setRowDigiCom([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getDataSearchBy = async () => {
    const result = await getParameterAPI(15);
    if (result.success) {
      const payload = result.payload;
      setDataSearchBy(payload);
      console.log(payload);
    }
  };

  const fetchDigitalCom = async (searchBy, param) => {
    setLoadingContent(true);
    const validSearchBy = searchBy || "";
    const validParam = param || "";

    const result = await searchDigitalCom(validSearchBy, validParam);
    console.log(result);

    setTimeout(() => {
      setPage(0);
      setRowDigiCom(result.data);
      setLoadingContent(false);
    }, 1000);
  };

  const deleteDigitalComById = async (id) => {
    const result = await deleteDigitalCom(id);
    console.log(result);
    setShowModal(false);
    fetchDigitalCom(paramSearchBy, inputValue);
    clear();
  };

  const handleEditClick = (rowData) => {
    navigate("/letterDigiComDetail", { state: { rowData } });
  };

  const handleSearchClick = () => {
    fetchDigitalCom(paramSearchBy, inputValue);
  };

  const confirmDelete = (item) => {
    setShowModal(true);
    setItemId(item);
  };

  const downloadLetterDigiComAPI = async (fileName) => {
    if (!fileName.toLowerCase().endsWith(".pdf")) {
      fileName += ".pdf";
    }
    console.log(fileName);
    const result = await downloadLetterDigiCom(fileName);
    if (result.status) {
      console.log(result.downloadUrl);
      window.open(result.downloadUrl, "_blank");
    } else {
      setShowModalNotFoundFile(true);
    }
  };

  useEffect(() => {
    getDataSearchBy();
  }, []);

  return (
    <div>
      <Header_Home />
      <Header title="จดหมาย" />
      <div className="table-page-container footer-space">
        <div className="rounded-border bg-white p-2">
          <Grid container spacing={1} justifyContent="center" style={{ marginTop: '5px', paddingInline: '32px' }}>
            <Grid item xs={12} md={12}>
              <InputLabel className="label">
                <label style={{ color: "black" }} htmlFor="policyNo">
                  เรื่องที่ค้นหา
                </label>
              </InputLabel>
              <CustomInputDropdown
                id="bank_name"
                variant="outlined"
                size="small"
                fullWidth
                displayEmpty
                className="select-input mb-2 label"
                value={selectedSearchBy}
                onChange={handleChangeSearchBy}
                options={dataSearchBy}
                InputProps={{
                  style: {
                    borderRadius: "16px",
                    height: "42px",
                  },
                }}
              />

            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="center" style={{ marginTop: '5px', paddingInline: '32px' }}>
            <Grid item xs={12} md={12}>
              <CustomInputText
                id="input-name"
                value={inputValue}
                onChange={handleChangeParam}
                InputProps={{
                  style: {
                    borderRadius: "16px",
                    height: "42px",
                  },
                }}
              />
            </Grid>
          </Grid>
          <div className="App">
            <br />
            <section className="filter-action-section">
              <button
                style={{ fontSize: 18 }}
                onClick={() => handleSearchClick()}
                className="btn-fill-orange  p-2 bold"
              >
                ค้นหา
              </button>
              <button
                onClick={() => clear()}
                className="btn-fill-gray p-2 bold ml-2">
                ล้าง
              </button>
            </section>
            <br />
            <hr
              style={{
                margin: "10px 10px 15px 5px",
                border: "solid 1px",
                borderTop: 0.1,
              }}
            />

            <br />
            <div style={{ position: 'relative' }}>
              {LoadingContent && (
                <div className="loading-content">
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                </div>
              )}
              <ThemeProvider theme={theme}>
                <TableContainer
                  style={{ height: "auto" }}
                  component={Paper}
                >
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow style={{ backgroundColor: "#e7e7e786" }}>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 300, minWidth: 8 }}
                        >
                          หมายเลขประจำตัวประชาชน
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 300, minWidth: 8 }}
                        >
                          กรมธรรม์เลขที่
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 200, minWidth: 70 }}
                        >
                          ชื่อจดหมาย
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 200, minWidth: 30 }}
                        >
                          ชื่อเจ้าของจดหมาย
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 300, minWidth: 50 }}
                        >
                          ชื่อไฟล์จดหมาย
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 200, minWidth: 50 }}
                        >
                          เบอร์ติดต่อ
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 100, minWidth: 50 }}
                        >
                          แก้ไข
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 100, minWidth: 50 }}
                        >
                          ลบ
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!(rowDigiCom.length === 0) ? (
                        rowDigiCom
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item, idx) => {
                            const seq = idx + 1 + page * rowsPerPage;
                            return (
                              <TableRow key={idx}>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 200, minWidth: 8 }}
                                >
                                  {item.citizen_id}
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 300, minWidth: 70 }}
                                >
                                  {item.policy_no}
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="left"
                                  style={{ width: 200, minWidth: 30 }}
                                >
                                  {item.plan_name}
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 200, minWidth: 50 }}
                                >
                                  {item.letter_owner_name}
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{
                                    width: 200,
                                    minWidth: 50,
                                    cursor: "pointer",
                                    color: "#007bff",
                                    textDecoration: "underline",
                                  }}
                                  onClick={() =>
                                    downloadLetterDigiComAPI(
                                      item.letter_file_name
                                    )
                                  }
                                  onMouseEnter={(e) =>
                                    (e.target.style.color = "#0056b3")
                                  }
                                  onMouseLeave={(e) =>
                                    (e.target.style.color = "#007bff")
                                  }
                                >
                                  {item.letter_file_name}
                                </TableCell>

                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 200, minWidth: 50 }}
                                >
                                  {item.mobile}
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 100, minWidth: 50 }}
                                >
                                  <img
                                    src={imgEdit}
                                    alt="แก้ไข"
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleEditClick(item)}
                                  />
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 100, minWidth: 50 }}
                                >
                                  <img
                                    src={imgTrash}
                                    alt="ลบ"
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => confirmDelete(item.id)}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })
                      ) : (
                        <TableCell align="center" colSpan={8}>
                          <span className="font-new">ไม่พบข้อมูล</span>
                        </TableCell>
                      )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rowDigiCom.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </ThemeProvider>
            </div>
          </div>
          <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header></Modal.Header>
            <Modal.Body
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                fontSize: "25px",
              }}
            >
              <img
                src={imgTrash}
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />
              <p style={{ marginTop: "20px" }}>ยืนยันการลบ</p>
            </Modal.Body>
            <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
              <a
                onClick={() => deleteDigitalComById(itemId)}
                type="button"
                className="mt-3 btn-confirm"
                style={{ minWidth: "100px", textAlign: "center" }}
              >
                ตกลง
              </a>
              <a
                onClick={() => setShowModal(false)}
                type="button"
                className="mt-3 btn-back"
                style={{ minWidth: "100px", textAlign: "center" }}
              >
                ยกเลิก
              </a>
            </Modal.Footer>
          </Modal>
          <Modal
            show={showModalNotFoundFile}
            onHide={() => setShowModalNotFoundFile(false)}
            centered
          >
            <Modal.Header></Modal.Header>
            <Modal.Body
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                fontSize: "25px",
              }}
            >
              <p style={{ marginTop: "20px" }}>ไม่พบไฟล์ที่ต้องการดาวน์โหลด</p>
            </Modal.Body>
            <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
              <a
                onClick={() => setShowModalNotFoundFile(false)}
                type="button"
                className="mt-3 btn-back"
                style={{ minWidth: "100px", textAlign: "center" }}
              >
                ปิด
              </a>
            </Modal.Footer>
          </Modal>
        </div>

      </div>
    </div>

  );
};

export default LetterDigiCom;
