// import "../../App.css";
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import menu_1 from "../../images/1.svg";
import gmail from "../../images/gmail.png";
import location from "../../images/location.png";
import credit from "../../images/credit.png";
import document from "../../images/document.png";
import phone from "../../images/phone.png";
import menu_7 from "../../images/7.svg";
import menu_8 from "../../images/8.svg";
import Header_Home from "../Component/Header_Home";
import { useNavigate } from "react-router-dom";
import "./requestV2-styles.css";
import Card from "react-bootstrap/Card";
import { getMenuRequest } from "./RequestV2Service/RequestV2Service";
import freelook from "../../images/7.การใช้สิทธิ์ Freelook.png";
import rpu from "../../images/8.กรมธรรม์ใช้เงินสำเร็จ.png";
import eti from "../../images/9.การประกันภัยแบบขยายเวลา.png";
import tax from "../../images/10.การขอใช้สิทธิ์ยกเว้นภาษี.png";


const Personal = () => {
  let navigate = useNavigate();
  const [menuList, setMenuList] = useState([]);

  const handleRoute = (url) => {
    navigate(url);
  };

  useEffect(() => {
    initialRequestMenu();
  }, []);

  const initialRequestMenu = async () => {
    const res = await getMenuRequest();
    setMenuList(res.data);
    console.log(res.data);
  }

  const img = (req) => {
    switch (req) {
      case "ISV03_1":
        return freelook;
      case "ISV03_2":
        return rpu;
      case "ISV03_3":
        return eti;
      case "ISV03_4":
        return tax;
      default:
        return eti;
    }
  };

  return (
    <>
      <Header_Home />
      {/* <section className="d-flex justify-content-center align-items-center">
        <div className="my-4 px-4">
          <div className="row justify-content-center">
            <div className="col-12 text-start px-lg-2">
              <h2>
                <span className="bold">การใช้สิทธิ</span>
              </h2>
            </div>
            <div className="card-menu-grid">
              {menuList.map((item) => (
                <div
                  className="card-menu"
                  onClick={() => {
                    handleRoute(item.variable3);
                  }}
                >
                  <img
                    className="image align-self-center"
                    style={{maxWidth:'120px'}}
                    src={img(item.code)}
                    alt=""
                  />
                  <p>{item.variable1}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section> */}
      <div className="item-container">
        <div className="row">
          <div className="col-12">
            <h2>
              <b>การใช้สิทธิ</b>
            </h2>
          </div>
          {
             menuList &&
            //  menuList.length &&
            menuList.map(
              (post, index) => (
                <div key={index} className="col-4 mar">
                  <a onClick={() => handleRoute(post.variable3)}>
                    <div className="item-menu flex-column text-center">
                      <img
                        className="image align-self-center mt-2"
                        src={img(post.code)}
                        alt=""
                      />
                      <p className="flex-shrink-1" style={{color:" #F2654E"}}>{post.variable1}</p>
                    </div>
                  </a>
                </div>
              )
            )
          }
        </div>
      </div>
    </>
  );
};

export default Personal;
