import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { Form } from "react-bootstrap";
import axios from 'axios';
import Header_Home from '../Component/Header_Home';
class Download_form extends Component {
    constructor(props) {
        super(props);
        this.state = {date: new Date() ,
        token: '',
        username:'',
        step:1,
        offset: 0,
        data: [],
        perPage: 50,
        currentPage: 0,
        pageCount:0,
        showing_step:1,
        showing_all:false
    }
    }

    receivedData() {
         
        fetch(process.env.REACT_APP_API_BASEURL + "/api/personal/getform",
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { 
            //console.log(res)
            const data = res.payload;
            const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
            
            if(data.length > 1){
                this.setState({showing_all : true})
            }
            const postData = slice.map(item => 
                <div className="col-lg-4 col-md-6 col-sm-6" >
                    <a href={item.file_path}  download className="pointer">
                    {/* <a onClick={() => this.download(item.file_path, item.file_name)}  className="pointer"> */}
                    
                        <div className="card">  
                            <div className="card-body">
                                <div className="row col-12 ">
                        
                                    <div className="col-12 pointer">
                                        <label className='text-righ mt-2 pointer'> {item.file_name}</label>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    
                    </a>
                </div>
                
          
            )
            this.setState({payload : res.payload,
                pageCount: Math.ceil(data.length / this.state.perPage),
                postData})
            
        })
        .catch(function(res){ console.log(res) })
    }

    componentDidMount() {

        console.log('componentDidMount')
        this.receivedData()
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    handleselectedFile  = input => e => {
        this.setState({
            selectedFile: e.target.files[0],
            //**** added line below ****//
            file_ori: e.target.files[0].name,
            [input]: e.target.files[0].name
        });
    };

    download (file_path, file_name) {
        const FileDownload = require("js-file-download");
        fetch(process.env.REACT_APP_API_BASEURL + "/api/personal/downloadform?file_name="+file_name.trim(),
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(response => response.blob())
        
        .then(response => {
            FileDownload(response, file_name.trim());

        })
        .catch(function(res){ console.log(res) })
    }



    componentDidUpdate() {
       
        console.log('componentDidUpdate')
    }

    componentWillUnmount() {
        console.log('componentWillUnmount')
       
    }

    con () {
        const { payload} = this.state;
        const data = payload;
        const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        const postData2 = this.item_change()
        this.setState({postData2})
    }

    

    handleChange = input => e => {
        console.log("handleChange",e.target.value)

        this.setState({ [input]: e.target.value });
       
        console.log({ [input]: e.target.value })
    };

    back () {
        const {showing_step} = this.state;
        this.setState({
            showing_step: showing_step-1
        });
        this.con();
    }



 
    render() {
        const { showing_step, postData, postData2, showing_all} = this.state;
        return (
            <div >
                <Header_Home/>
                <section className="d-flex justify-content-center align-items-center h-100 my-5">
                    <div className="container-fluid  container-margin">
                        <div className='row'>
                            <div className="col-12">
                                <div className="card" style={{ display: (showing_step === 1 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>ดาวน์โหลดแบบฟอร์ม</strong></label>
                                            </div>
                                        </div>       
                                    </div>
                       
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 ">
                                            <div className="col-2">
                                                </div>
                                                <div className="row col-12 color-text-rbl">
                                                 
                                                {postData} 
                                                </div>
                                       
                                                
                                        </div>       
                                    </div>
                                    
                                    <div className="card-body App">
                                        <a  href="/download" type="button" className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a>
                                        {/* <a  onClick={() => this.download()} className="btn btn-lg btn-blue">ดาวน์โหลดเอกสาร</a> */}
                                    </div>
                                </div>


                            </div>
                        </div>
                    

                        {/* <div className='App'>
                            <a  href="/home" type="button" className="btn btn-lg btn mt-3 cust-btn"  >ย้อนกลับ</a>
                        </div> */}
                        </div> 
                </section>
            </div> 
        )
    }
        
    
}

export default Download_form;