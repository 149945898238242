import React, { useEffect, useState } from "react";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import OwlCarousel from "react-owl-carousel";
import tpa from "../../images/unnamed 1.svg";
import qr1 from "../../images/image 1.svg";
import qr2 from "../../images/image 2.svg";
import downloadApp from "../../images/download-app.png";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import styles from "./policyDetail-styles.module.css";
import "../PolicyDetail/policyDetail-styles.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  getPolicyDetailAPI,
  getParameterAPI,
} from "../PolicyDetail/PolicyDetailService/policyDetailService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import CryptoJS from "crypto-js";
import { CurrencyFormater, CurrencyFormaterNonDec } from "../../util";

const PolicyDetail = () => {
  // const searchParams = new URLSearchParams(window.location.search);
  // const channelType = searchParams.get("channelType");
  let [searchParams, setSearchParams] = useSearchParams();

  const policyNo = searchParams.get("policyNo") || "";
  const insureIdCard = localStorage.getItem("customer_id") || "";

  const [activeTab, setActiveTab] = useState("home");
  const [policy, setPolicy] = useState("");
  const [LoadingContent, setLoadingContent] = useState(true);
  const [InfomationPolicy, setInformationPolicy] = useState("");
  const [beneficiaries, setBeneficiaries] = useState("");
  const [riders, setRiders] = useState([""]);
  const [lnkHosp, setLnkHosp] = useState("");
  const [lnkForm, setLnkForm] = useState("");
  const [cusTel, setCusTel] = useState("");
  let navigate = useNavigate();
  const [slideRiders, setSlideRiders] = useState(
    riders.length > 0 ? riders[0] : ""
  );
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [ridersStatus, setRidersStatus] = useState("");
  const [ridersCode, setRidersCode] = useState("");
  const allowedCodes = ["HS", "HP", "HSM", "OPD", "PA", "MEMAX", "MEPRO","HABMAX"];
  const [ridersIndex, setRidersIndex] = useState("");

  useEffect(() => {
    fetchPolicyDetailAPI();
    getLnk();
    getTel();
  }, []);

  const handleSlideChangeInternal = async (e) => {
    if (riders.length > 0) {
      setSelectedIndex(e);
      setRidersIndex(riders[e]);
    }
  };

  const handleSelect = (key) => {
    setActiveTab(key);
  };

  const fetchPolicyDetailAPI = async () => {
    const result = await getPolicyDetailAPI(
      policyNo,
      decryptCardNumber(insureIdCard, process.env.REACT_APP_ENCRYPT_DATA)
    );
    console.log(result);
    if (result.status) {
      setLoadingContent(false);
      setInformationPolicy(result.data);
      setBeneficiaries(result.data.beneficiaries);
      setRiders(result.data.riders);
      setRidersIndex(result.data.riders[selectedIndex]);
      console.log(result.data.riders[selectedIndex]);
    }
  };

  const getLnk = async () => {
    const result = await getParameterAPI(11);
    if (result.success) {
      const payload = result.payload;
      const lnkHospital = payload.find((item) => item.code === "LINK_HOSPITAL");
      const lnkForm = payload.find((item) => item.code === "LINK_FORM");

      const lnkHosp = lnkHospital.variable1;
      const lnkF = lnkForm.variable1;
      setLnkHosp(lnkHosp);
      setLnkForm(lnkF);
    }
  };

  const getTel = async () => {
    const result = await getParameterAPI(12);
    const payload = result.payload;
    const cusTel = payload.find((item) => item.code === "CUS_SERV_TEL");
    const variable1 = cusTel.variable1;
    setCusTel(variable1);
  };

  const goToPolicyList = () => {
    navigate("/policylist");
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 5 },
    { width: 1750, itemsToShow: 6 },
  ];

  const decryptCardNumber = (encryptedCardNumber, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedCardNumber, secretKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  };

  return (
    <div>
      <Header_Home />
      {/* <Header title="รายละเอียดกรมธรรม์" /> */}
      <div className="top" style={{ marginBottom: "25px" }}>
        <section className="sec-navbar">
          <nav
            style={{
              backgroundColor: "#FF5E43",
              color: "white",
              height: "50px",
            }}
          >
            <div style={{ padding: "10px" }}>
              <h5 className="flex-shrink-1 text-center bold">
                รายละเอียดกรมธรรม์
              </h5>
            </div>
          </nav>
        </section>
      </div>
      <div className="container p-4">
        {LoadingContent && (
          <>
            <Row className="card-custom3 d-flex">
              <Col className="mt-3">
                <Skeleton style={{ height: "30px" }} />
                <div className="d-flex flex-row justify-content-between">
                  <Skeleton />
                </div>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Col>
            </Row>
            <br />
          </>
        )}
        <div>
          {!LoadingContent && InfomationPolicy && (
            <Tabs
              defaultActiveKey="home"
              id="fill-tab-example"
              fill
              activeKey={activeTab}
              onSelect={handleSelect}
            >
              <Tab eventKey="home" title="รายละเอียดกรมธรรม์">
                <div className="card-custom">
                  <ul className="list-group">
                    <h2
                      className="bold"
                      style={{
                        fontSize: "20px",
                        marginLeft: "20px",
                        marginTop: "20px",
                      }}
                    >
                      {InfomationPolicy && InfomationPolicy.baseName}
                    </h2>
                    <hr style={{ margin: "0 20px" }} />
                    <li
                      className={styles.bodyListGroup}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        fontSize: 15,
                      }}
                    >
                      <span className="light">กรมธรรม์เลขที่</span>
                      <span className="bold">{InfomationPolicy.policyNo}</span>
                    </li>
                    <li
                      className={styles.bodyListGroup}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        fontSize: 15,
                      }}
                    >
                      <span className="light">วันเริ่มสัญญา</span>
                      <span className="bold">
                        {InfomationPolicy.policyEffectiveDate}
                      </span>
                    </li>
                    <li
                      className={styles.bodyListGroup}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        fontSize: 15,
                      }}
                    >
                      <span className="light">วันครบกำหนดสัญญา</span>
                      <span className="bold">
                        {InfomationPolicy.policyMatureDate}
                      </span>
                    </li>
                    <li
                      className={styles.bodyListGroup}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        fontSize: 15,
                      }}
                    >
                      <span className="light">จำนวนเงินเอาประกันภัย</span>
                      <span className="bold">
                        {CurrencyFormaterNonDec(InfomationPolicy.sumAssure)} บาท
                      </span>
                    </li>
                    <li
                      className={styles.bodyListGroup}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        fontSize: 15,
                      }}
                    >
                      <span className="light">ระยะเวลาความคุ้มครอง</span>
                      <span className="bold">
                        {InfomationPolicy.coverageYear} ปี
                      </span>
                    </li>
                    <li
                      className={styles.bodyListGroup}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        fontSize: 15,
                      }}
                    >
                      <span className="light">ระยะเวลาชำระเบี้ย</span>
                      <span className="bold">
                        {InfomationPolicy.paymentYear} ปี
                      </span>
                    </li>
                    <li
                      className={styles.bodyListGroup}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        fontSize: 15,
                      }}
                    >
                      <span className="light">ช่องทางการขาย</span>
                      <span className="bold">
                        {InfomationPolicy.policySaleChannel}
                      </span>
                    </li>
                    <li
                      className={styles.bodyListGroup}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        fontSize: 15,
                      }}
                    >
                      <span className="light">ชื่อตัวแทน</span>
                      <span className="bold">
                        {InfomationPolicy.policyAgentName}
                      </span>
                    </li>
                  </ul>

                  <br />
                </div>

                <br />
              </Tab>

              <Tab eventKey="profile" title="ผู้รับผลประโยชน์">
                <div className="card-custom">
                  <br />
                  {beneficiaries.length > 0 && !LoadingContent && (
                    <ul className="list-group">
                      {beneficiaries
                        .sort((a, b) => b.percent - a.percent)
                        .map((item, idx) => (
                          <div key={idx}>
                            <li
                              className={styles.bodyListGroup}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginRight: "10px",
                                fontSize: 15,
                              }}
                            >
                              <span className="light">
                                ลำดับที่ {idx + 1} :
                              </span>
                              <span className="bold">
                                {item.titleName} {item.firstName} {item.surname}
                              </span>
                            </li>
                            <li
                              className={styles.bodyListGroup}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginRight: "10px",
                                fontSize: 15,
                              }}
                            >
                              <span className="light">ความสัมพันธ์ :</span>
                              <span className="bold">{item.relationName}</span>
                            </li>
                            <li
                              className={styles.bodyListGroup}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginRight: "10px",
                                fontSize: 15,
                              }}
                            >
                              <span className="light">สัดส่วนผลประโยชน์ :</span>
                              <span className="bold">{item.percent} %</span>
                            </li>
                            {idx < beneficiaries.length - 1 && (
                              <hr
                                style={{
                                  margin: "0 20px",
                                  height: "0.1px",
                                  border: "none",
                                  backgroundColor: "black",
                                }}
                              />
                            )}
                          </div>
                        ))}
                      <br />
                    </ul>
                  )}
                </div>
                <br />
              </Tab>
            </Tabs>
          )}
          {LoadingContent ? (
            <Row className="d-flex justify-content-around px-3">
              <Col>
                <Skeleton style={{ marginLeft: "-25px", width: "120px" }} />
              </Col>
            </Row>
          ) : (
            <h2
              className="bold"
              style={{
                fontSize: "20px",
                color: "#EE7214",
              }}
            >
              สัญญาเพิ่มเติม
            </h2>
          )}
          <br />
          {LoadingContent ? (
            <Row className="card-custom2 d-flex">
              <Col className="mt-3">
                <Skeleton />
                <div className="d-flex flex-row justify-content-between">
                  <Skeleton />
                </div>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Col>
            </Row>
          ) : riders.length === 0 ? (
            <div className="card-custom2">
              <h2
                className="bold"
                style={{
                  fontSize: "15px",
                  marginLeft: "20px",
                  marginTop: "20px",
                }}
              >
                ไม่มีข้อมูลสัญญาเพิ่มเติม
              </h2>
            </div>
          ) : (
            <Carousel
              emulateTouch={true}
              showThumbs={false}
              selectedItem={selectedIndex}
              showArrows={false}
              showStatus={false}
              breakPoints={breakPoints}
              items={1}
              loop={riders.length > 1}
              margin={10}
              responsiveClass
              dotClass={styles.customDots}
              responsive={{
                0: {
                  items: 1,
                  nav: riders.length > 1,
                },
                750: {
                  items: 1,
                  nav: riders.length > 1,
                },
                1000: {
                  items: riders.length,
                  nav: false,
                },
              }}
              onChange={handleSlideChangeInternal}
              onClickItem={handleSlideChangeInternal}
            >
              {riders.map((item, idx) => (
                <div className="card-custom2" key={idx}>
                  <h2
                    className="bold"
                    style={{
                      fontSize: "15px",
                      marginLeft: "20px",
                      marginTop: "20px",
                    }}
                  >
                    {item.name}
                  </h2>
                  <li
                    className={styles.bodyListGroup}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginRight: "10px",
                      fontSize: 15,
                    }}
                  >
                    <span className="light">
                      จำนวนเงินเอาประกันภัย/ผลประโยชน์
                    </span>
                    <span className="bold">
                      {/* {parseFloat(item.sumAssure).toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}{" "} */}
                      {CurrencyFormaterNonDec(item.sumAssure)} บาท
                    </span>
                  </li>
                  <li
                    className={styles.bodyListGroup}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginRight: "10px",
                      fontSize: 15,
                    }}
                  >
                    <span className="light">ระยะเวลาชำระเบี้ย</span>
                    <span className="bold">{item.paymentYear} ปี</span>
                  </li>
                  <li
                    className={styles.bodyListGroup}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginRight: "10px",
                      fontSize: 15,
                    }}
                  >
                    <span className="light">สถานะ</span>
                    <span className="bold">{item.status}</span>
                  </li>
                  <li
                    className={styles.bodyListGroup}
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      marginRight: "10px",
                      fontSize: 15,
                    }}
                  >
                    <span
                      style={{
                        marginLeft: "15px",
                        color: "red",
                        fontSize: "15px",
                      }}
                    >
                      ** รายละเอียดความคุ้มครองโปรดศึกษาในเล่มกรมธรรม์
                    </span>
                  </li>
                </div>
              ))}
            </Carousel>
          )}

          <br />
          {riders.length > 0 &&
          ridersIndex.status === "มีผลบังคับ" &&
          allowedCodes.includes(ridersIndex.code) ? (
            <>
              <li
                className={styles.bodyListGroup}
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginRight: "10px",
                }}
              >
                <span style={{ marginLeft: "15px", fontSize: "15px" }}>
                  กรณีรายละเอียดข้อมูลกรมธรรม์ไม่ถูกต้อง
                  กรุณาติดต่อฝ่ายลูกค้าสัมพันธ์ {cusTel}
                </span>
              </li>
              <li
                className={styles.bodyListGroup}
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "10px",
                }}
              >
                <span style={{ marginLeft: "15px", fontSize: "15px" }}>
                  โรงพยาบาลในเครือข่ายสามารถเช็คได้ที่เว็บไซต์
                </span>
                <div style={{ marginLeft: "15px" }}>
                  <a
                    className="link"
                    href={lnkHosp}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(lnkHosp, "_blank");
                    }}
                  >
                    {lnkHosp}
                  </a>
                </div>
              </li>
              <li
                className={styles.bodyListGroup}
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginRight: "10px",
                  fontSize: "15px",
                  flexWrap: "wrap",
                }}
              >
                <span
                  style={{
                    marginLeft: "15px",
                    fontSize: "15px",
                    width: "100%",
                  }}
                >
                  แบบฟอร์มสามารถดาวน์โหลดได้ที่
                </span>
                <div style={{ marginLeft: "15px", fontSize: "15px" }}>
                  <a
                    className="link"
                    href={lnkForm}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(lnkForm, "_blank");
                    }}
                  >
                    {lnkForm}
                  </a>
                </div>
              </li>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={tpa} alt="TPA Logo" style={{ marginLeft: "15px" }} />
                <span style={{ color: "#12466C", fontSize: "14px" }}>
                  TPA Care <br />
                  <strong className="small-font" style={{ color: "#EE7214" }}>
                    แอปพลิเคชั่นสำหรับลูกค้า
                  </strong>
                </span>
                <img
                  src={downloadApp}
                  alt="qr1"
                  style={{ marginLeft: "15px", width: "150px", height: "70px" }}
                />
              </div>
            </>
          ) : (
            <>
              <li
                className={styles.bodyListGroup}
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginRight: "10px",
                }}
              >
                <span style={{ marginLeft: "15px", fontSize: "15px" }}>
                  กรณีรายละเอียดข้อมูลกรมธรรม์ไม่ถูกต้อง
                  กรุณาติดต่อฝ่ายลูกค้าสัมพันธ์ {cusTel}
                </span>
              </li>
              <li
                className={styles.bodyListGroup}
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginRight: "10px",
                  fontSize: "15px",
                  flexWrap: "wrap",
                }}
              >
                <span
                  style={{
                    marginLeft: "15px",
                    fontSize: "15px",
                    width: "100%",
                  }}
                >
                  แบบฟอร์มสามารถดาวน์โหลดได้ที่
                </span>
                <div style={{ marginLeft: "15px", fontSize: "15px" }}>
                  <a
                    className="link"
                    href={lnkForm}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(lnkForm, "_blank");
                    }}
                  >
                    {lnkForm}
                  </a>
                </div>
              </li>
            </>
          )}

          {/* <br /> */}
          <div className="App" style={{ marginBottom: "70px" }}>
            <div className="d-flex justify-content-center flex-wrap">
              <button
                onClick={goToPolicyList}
                type="button"
                className="btn-checkout-back w-100 p-2 bold"
              >
                ย้อนกลับ
              </button>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default PolicyDetail;
