import React, { useContext } from "react";
import { CreditScore } from "@mui/icons-material";
import cashCheckIcon from "../../../images/cash-check.png";
import clockTime from "../../../images/clock-time-eight-outline.png";
import "./components.css";
import Button from "react-bootstrap/Button";
import { BsChevronRight } from "react-icons/bs";
import TransferContext, {
  TransferProvider,
} from "../TranferContextProvider.js";
import {
  Typography,
} from "@mui/material";

const SelectDocumentAge = () => {



  const { activeStep, setActiveStep, skipped, setSkipped, titleScreen, setTitleScreen, setDocRange } =
    useContext(TransferContext);



  const handleNext = (titlePrm, docRange) => {
    setDocRange(docRange)
    setTitleScreen(titlePrm)
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <>
      <div className="border-content">
        <div>ส่งเอกสารแสดงตัวตน</div>
        <div className="border-content-inside">
          <div style={{ fontSize: 13, marginTop: 10 }}>
            ทำไมต้องส่งเอกสารแสดงตัวตน ?
          </div>
          <div className="item-between">
            <img
              src={cashCheckIcon}
              alt="checkIcon"
              style={{ height: 25, width: 25 }}
            />
            <div className="text-label">
              เพื่อยืนยันตัวตนสำหรับการสมัครทำประกัน
            </div>
          </div>
          <div className="item-between">
            <img
              src={clockTime}
              alt="checkIcon"
              style={{ height: 25, width: 25 }}
            />
            <div className="text-label">เพื่อรับเงินตามเงื่อนไขกรมธรรม์</div>
          </div>
        </div>
        <Typography
          variant="body2"
          sx={{ color: "red", marginTop: "5px", marginLeft: "5px" }} // ข้อความสีแดงพร้อม margin ด้านบน
        >
          *กรุณาแนบเอกสารให้ตรงตามอายุปัจจุบันของผู้เอาประกัน
        </Typography>
        <Button
          className="col-12 d-flex justify-content-between align-items-center"
          style={{
            backgroundColor: "#F1F1F1",
            color: "black",
            borderColor: "#d4d4d4",
            marginTop: 10,
            borderRadius: 0,
            boxShadow: "0px 4px 2px 0px rgba(187, 186, 185, 0.5)",
            height: 40
          }}
          size="mb"
          onClick={() => handleNext('ผู้เยาว์ (อายุต่ำกว่า 7 ปี)', 1)}
        >
          <span className="bold font-size-content">
            ผู้เยาว์ (อายุต่ำกว่า 7 ปี)
          </span>
          <BsChevronRight />
        </Button>
        <Button
          className="col-12 d-flex justify-content-between align-items-center"
          style={{
            backgroundColor: "#F1F1F1",
            color: "black",
            borderColor: "#d4d4d4",
            marginTop: 10,
            borderRadius: 0,
            boxShadow: "0px 4px 2px 0px rgba(187, 186, 185, 0.5)",
            height: 40
          }}
          onClick={() => handleNext('ผู้เยาว์ (อายุตั้งแต่ 7 ปีขึ้นไป แต่น้อยกว่า 20 ปี)', 2)}
          size="mb"
        >
          <span className="bold font-size-content">
            ผู้เยาว์ (อายุตั้งแต่ 7 ปีขึ้นไป แต่น้อยกว่า 20 ปี)
          </span>
          <BsChevronRight />
        </Button>
        <Button
          className="col-12 d-flex justify-content-between align-items-center"
          style={{
            backgroundColor: "#F1F1F1",
            color: "black",
            borderColor: "#d4d4d4",
            marginTop: 10,
            borderRadius: 0,
            boxShadow: "0px 4px 2px 0px rgba(187, 186, 185, 0.5)",
            height: 40
          }}
          size="mb"
          onClick={() => handleNext('ผู้เอาประกัน (อายุตั้งแต่ 20 ปีขึ้นไป)', 3)}
        >
          <span className="bold font-size-content">
            ผู้เอาประกัน (อายุตั้งแต่ 20 ปีขึ้นไป)
          </span>
          <BsChevronRight />
        </Button>
      </div>
      <button className="btn-checkout-back w-100 p-2 bold" onClick={handleBack}>กลับ</button>
    </>
  );
};

export default SelectDocumentAge;
