const CurrencyFormater = (number) => {
  if (number == null || isNaN(number)) {
    return "NaN";
  }
  return number.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") ?? "NaN";
};

const CurrencyFormaterNonDec = (number) => {
  return Math.round(number).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") ?? "NaN";
};

export { CurrencyFormater,CurrencyFormaterNonDec };
