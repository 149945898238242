import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { Form } from "react-bootstrap";
import axios from 'axios';
import Header_Home from '../Component/Header_Home';
import PopUp from '../Component/Popup';
class Request_loan extends Component {
    constructor(props) {
        super(props);
        this.state = {date: new Date() ,
        token: '',
        username:'',
        step:1,
        offset: 0,
        data: [],
        perPage: 50,
        currentPage: 0,
        pageCount:0,
        showing_step:1,
        showing_all:false,
        loading:true
    }
    }

    receivedData() {
         
        fetch(process.env.REACT_APP_API_BASEURL + "/api/policy/list",
        // fetch(process.env.REACT_APP_API_BASEURL + "/api/policy/list?customer="+localStorage.getItem('customer_id'),
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { 
            //console.log(res)
            const data = res.payload;
            const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
            
            if(data.length > 1){
                this.setState({showing_all : true})
            }
            const postData = slice.map(item => 
                <div className="col-8">
                    <div className="card">  
                        <div className="card-body">
                            <div className="row col-12 ">
                                <div className="col-1 text-right pt-2">
                                    <input onChange={this.handleChange("policy_change_ans")} type="radio" id="policy_change" name="policy_change" value={item.policy_no} className="margin-left-px"  disabled={this.state.showing3} ></input>
                                </div>
                                <div className="col-9">
                                    <label className='text-righ mt-2'><strong>หมายเลขกรมธรรม์ : </strong> {item.policy_no}</label>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
          
            )
            this.setState({payload : res.payload,
                pageCount: Math.ceil(data.length / this.state.perPage),
                postData, loading:false})
            
        })
        .catch(function(res){ console.log(res) })
    }

    componentDidMount() {
        fetch(process.env.REACT_APP_API_BASEURL + "/api/user/getprofile",
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { 
            console.log("profile.check" , res)
            if(res.success == 'true'){
                if(res.payload.profile == 'POS2'){
                    window.location.href ='/home';
                }
            }
        })
        // if(localStorage.getItem('profile') == 'POS2'){
        //     window.location.href ='/home';
        // }

        console.log('componentDidMount')
        this.receivedData()
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    handleselectedFile  = input => e => {
        this.setState({
            selectedFile: e.target.files[0],
            //**** added line below ****//
            file_ori: e.target.files[0].name,
            [input]: e.target.files[0].name
        });
    };

    componentDidUpdate() {
       
        console.log('componentDidUpdate')
    }

    componentWillUnmount() {
        console.log('componentWillUnmount')
       
    }

    con () {
        const { payload} = this.state;
        const data = payload;
        const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        const postData2 = this.item_change()
        this.setState({postData2})
    }

    item_change () {
        const { policy_change_ans} = this.state;
        // if(document.getElementById(item.policy_no).checked) {
            return (
                <div className="card-body list-group">
                    <div className="row col-6 ">
                        <div className="card p-0">  
                            <div className="card-body header-card">
                            <label className='text-righ mt-2'><strong>หมายเลขกรมธรรม์ที่ใช้สิทธิยื่นกู้ </strong> </label>
                            </div> 
                            <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <div>
                                <label className='text-righ mt-2'><strong>หมายเลขกรมธรรม์ : </strong> {policy_change_ans}</label>
                                </div>
                            </li>                  
                        </ul>
                        
                        </div>   
                    </div>              
                </div>    
            )
        // }
    }

    handleChange = input => e => {
        console.log("handleChange",e.target.value)

        this.setState({ [input]: e.target.value });
        console.log({ [input]: e.target.value })
    };
    next () {
        const { showing_step,policy_change_ans} = this.state;
        let num = 1 ;
        var txt_error = 'กรุณากรอกข้อมูล';
        console.log("showing_step",showing_step)
        if(showing_step === 1) {
            // let laser_code = document.getElementById("laser_code").value
            console.log("policy_change",policy_change_ans)

            if(policy_change_ans === undefined){
                alert("กรุณาเลือกกรมธรรม์")
                num++;
            } 
            // if(!laser_code && laser_code !== undefined){
            //     document.getElementById("error_laser_code").innerHTML = `<span name="#laser_code" class="error">` + txt_error +  ` </span>`
            //     num++;
            // } else {
            //     document.getElementById("error_laser_code").innerHTML = ''
            // }
        } else if (showing_step === 2) {
            if(!document.getElementById('file1').files.length>0){
                alert('เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ')
                num++;
            }
            else if(!document.getElementById('file2').files.length>0){
                alert('เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ')
                num++;
            }
            else if(!document.getElementById('file3').files.length>0){
                alert('เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ')
                num++;
            }
            else if(!document.getElementById('file4').files.length>0){
                alert('เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ')
                num++;
            } else if(document.getElementById('file1').files.length>0) {
                let type =  document.getElementById('file1').files[0].type 
                
                if(!(type === 'image/jpeg' || type === 'image/jpg' ||type === 'image/png' ||type === 'application/pdf' )){
                    alert("กรุณาอัพโหลดเอกสารนามสกุล .jpeg, .png, .pdf เท่านั้น")
                    num++;
                }
            } else if(document.getElementById('file2').files.length>0) {
                let type =  document.getElementById('file2').files[0].type 
                
                if(!(type === 'image/jpeg' || type === 'image/jpg' ||type === 'image/png' ||type === 'application/pdf' )){
                    alert("กรุณาอัพโหลดเอกสารนามสกุล .jpeg, .png, .pdf เท่านั้น")
                    num++;
                }
            } else if(document.getElementById('file3').files.length>0) {
                let type =  document.getElementById('file3').files[0].type 
                
                if(!(type === 'image/jpeg' || type === 'image/jpg' ||type === 'image/png' ||type === 'application/pdf' )){
                    alert("กรุณาอัพโหลดเอกสารนามสกุล .jpeg, .png, .pdf เท่านั้น")
                    num++;
                }
            } else if(document.getElementById('file4').files.length>0) {
                let type =  document.getElementById('file4').files[0].type 
                
                if(!(type === 'image/jpeg' || type === 'image/jpg' ||type === 'image/png' ||type === 'application/pdf' )){
                    alert("กรุณาอัพโหลดเอกสารนามสกุล .jpeg, .png, .pdf เท่านั้น")
                    num++;
                }
            } else if(document.getElementById('file5').files.length>0) {
                let type =  document.getElementById('file5').files[0].type 
                
                if(!(type === 'image/jpeg' || type === 'image/jpg' ||type === 'image/png' ||type === 'application/pdf' )){
                    alert("กรุณาอัพโหลดเอกสารนามสกุล .jpeg, .png, .pdf เท่านั้น")
                    num++;
                }
            }
            
        }
        if(num === 1 ){
            this.setState({
                showing_step: showing_step+1
            });
            this.con();
        }
        
    }
    back () {
        const {showing_step} = this.state;
        this.setState({
            showing_step: showing_step-1
        });
        this.con();
    }

    select_all = input => e =>  {
        // const {payload} = this.state;
        const {payload} = this.state;
        let policies = [];
        let body = [];
        if(document.getElementById("all").checked){
            for (let item of payload) {

                document.getElementById(item.policy_no).checked = true
            }
        } else {
            for (let item of payload) {

                document.getElementById(item.policy_no).checked = false
            }
        }
    }
    save () {
        this.setState({loading:true})
        document.querySelector("input[type='submit']").click();
    }

    submitUpload = (event) => {
        const { policy_change_ans} = this.state;
        // const FormData = require('form-data');
        
        // event.preventDefault();
        let url = process.env.REACT_APP_API_BASEURL + '/api/personal/upload'
        event.preventDefault();

        
        let file_name1 = undefined;
        let file1;
        let file_name2 = undefined;
        let file2;
        let file_name3 = undefined;
        let file3;
        let file_name4 = undefined;
        let file4;
        let file_name5 = undefined;
        let file5;
        console.log(event.target[0].files[0])
        
        if(event.target[0].files[0]){
            file_name1 = event.target[0].files[0].name;
            file1 = event.target[0].files[0];
        }
        if(event.target[1].files[0]){
            file_name2 = event.target[1].files[0].name;
            file2 = event.target[1].files[0];
        }
        if(event.target[2].files[0]){
            file_name3 = event.target[2].files[0].name;
            file3 = event.target[2].files[0];
        }
        if(event.target[3].files[0]){
            file_name4 = event.target[3].files[0].name;
            file4 = event.target[3].files[0];
        }
        if(event.target[4].files[0]){
            file_name5 = event.target[4].files[0].name;
            file5 = event.target[4].files[0];
        }
        // let laser_code = document.getElementById("laser_code").value
        // laser_code = laser_code.replaceAll("-", "");
        const formData = new FormData();        
        formData.append('image1', file1, file_name1);
        formData.append('image2', file2, file_name2);
        formData.append('image3', file3, file_name3);
        formData.append('image4', file4, file_name4);

        if(file_name5 !== undefined){
            formData.append('image5', file5, file_name5);
        }
        // formData.append('laser_code', laser_code);
        // formData.append('customer', localStorage.getItem('customer_id'));
        formData.append('type', '6');
        formData.append('policy', policy_change_ans);
        for (var [key, value] of formData.entries()) { 
            console.log(key, value);
        }

        var self = this;
        let response =  axios.post(url, formData, {
            headers: {
                'authorization': localStorage.getItem('token_login'),
                'content-type': 'multipart/form-data'
            }
        
        }).then(function(result) {
            console.log("result",result) 
            self.setState({loading:false})
            if (!result.data.success) {
                console.log('error: ' + result.data.payload.error);
                alert( result.data.payload.error)
            } else {
                // if(res.success){
                    alert( "บริษัทได้รับทราบข้อมูลจากท่านแล้ว ท่านจะได้รับ SMS เพื่อดาวน์โหลดจดหมายแจ้งการใช้สิทธิภายใน 5 วันทำการ ขอบคุณค่ะ")
                    window.location.href="/home";
                // }
            }
         })
        
        
        
    };



 
    render() {
        const { showing_step, postData, postData2, loading} = this.state;
        return (
            <div >
                {loading ? <PopUp /> : null}
                <Header_Home/>
                <section className="d-flex justify-content-center align-items-center h-100 my-5">
                    <div className="container-fluid  container-margin">
                        <div className='row'>
                            <div className="col-12">
                                <div className="card" style={{ display: (showing_step === 1 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>การใช้สิทธิกู้เงิน</strong></label>
                                            </div>
                                        </div>       
                                    </div>
                                    {/* <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 pt-2">
                                            <div className="col-3 text-right">
                                                <label htmlFor="subject" className='text-righ mt-2'><strong>เบอร์หลังบัตรประชาชน</strong></label>
                                            </div>
                                            <div className="col-3">
                                                <input  type="text" id="laser_code" name="laser_code" autocomplete="off" className="margin-left-10-px form-control"></input>
                                                    <label id="error_laser_code"></label>
                                            </div>
                                        </div>   
                                    </div> */}
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 ">
                                            <div className="col-2">
                                                </div>
                                                <div className="col-10">
                                                
                                                {postData} 
                                                </div>
                                       
                                                
                                        </div>       
                                    </div>
                                    <div className="card-body App">
                                        <a  href="/request" type="button" className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a>
                                        <a  onClick={() => this.next()} className="btn btn-lg btn-blue">ต่อไป</a>
                                    </div>
                                </div>

                                <div className="card" style={{ display: (showing_step === 2 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>อัพโหลดไฟล์</strong></label>
                                            </div>
                                        </div>      
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 ">
                                            
                                            <Form onSubmit={this.submitUpload} id="form_id">
                                                <div className="padding-title">
                                                    <div className="row col-12 ">
                                                        <div className="col-lg-6 col-md-8 col-sm-8 col-xs-8 text-right mt-3">
                                                            <label htmlFor="filename" className='text-righ'><strong>รูปถ่ายหน้าตรงที่ถ่ายคู่กับสำเนาบัตรประชาชน</strong> <br/>รองรับไฟล์ .jpeg, .png, .pdf</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="col-12 text-left" align="right"  style={{ margin:'10px 0px 0px 0px' }}>
                                                                <label htmlFor="file1" className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i> อัพโหลด 
                                                                </label>
                                                                <label className='ml-2'>{this.state.fileName1}</label>
                                                                <input  type="file"  id="file1" accept="image/png, image/jpeg, image/jpg, application/pdf" style={{display:'none'}}  onChange={this.handleselectedFile("fileName1")}/>
                                                            </div>
                                                        </div>
                                                    </div>      
                                                </div>
                                                <div className="padding-title">
                                                    <div className="row col-12 ">
                                                        <div className="col-lg-6 col-md-8 col-sm-8 col-xs-8 text-right mt-3">
                                                            <label htmlFor="filename" className='text-righ'><strong>สำเนาบัตรประจำตัวประชาชนด้านหน้า เซ็นรับรองสำเนาถูกต้อง</strong> <br/>รองรับไฟล์ .jpeg, .png, .pdf</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="col-12 text-left" align="right"  style={{ margin:'10px 0px 0px 0px' }}>
                                                                <label htmlFor="file2" className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i> อัพโหลด 
                                                                </label>
                                                                <label className='ml-2'>{this.state.fileName2}</label>
                                                                <input  type="file"  id="file2" accept="image/png, image/jpeg, image/jpg, application/pdf" style={{display:'none'}}  onChange={this.handleselectedFile("fileName2")}/>
                                                            </div>
                                                        </div>
                                                    </div>      
                                                </div>
                                                <div className="padding-title">
                                                    <div className="row col-12 ">
                                                        <div className="col-lg-6 col-md-8 col-sm-8 col-xs-8 text-right mt-3">
                                                            <label htmlFor="filename" className='text-righ'><strong>สำเนาบัตรประจำตัวประชาชนด้านหลัง เซ็นรับรองสำเนาถูกต้อง</strong> <br/>รองรับไฟล์ .jpeg, .png, .pdf</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="col-12 text-left" align="right"  style={{ margin:'10px 0px 0px 0px' }}>
                                                                <label htmlFor="file3" className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i> อัพโหลด 
                                                                </label>
                                                                <label className='ml-2'>{this.state.fileName3}</label>
                                                                <input  type="file"  id="file3" accept="image/png, image/jpeg, image/jpg, application/pdf" style={{display:'none'}}  onChange={this.handleselectedFile("fileName3")}/>
                                                            </div>
                                                        </div>
                                                    </div>      
                                                </div>
                                                <div className="padding-title">
                                                    <div className="row col-12 ">
                                                        <div className="col-lg-6 col-md-8 col-sm-8 col-xs-8 text-right mt-3">
                                                            <label htmlFor="filename" className='text-righ'><strong>แบบฟอร์มสัญญากู้ยืมเงินตามกรมธรรม์ประกันภัย (1 ฉบับ ต่อ 1 กรมธรรม์)</strong> <br/>รองรับไฟล์ .jpeg, .png, .pdf</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="col-12 text-left" align="right"  style={{ margin:'10px 0px 0px 0px' }}>
                                                                <label htmlFor="file4" className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i> อัพโหลด 
                                                                </label>
                                                                <label className='ml-2'>{this.state.fileName4}</label>
                                                                <input  type="file"  id="file4" accept="image/png, image/jpeg, image/jpg, application/pdf" style={{display:'none'}}  onChange={this.handleselectedFile("fileName4")}/>
                                                            </div>
                                                        </div>
                                                    </div>      
                                                </div>
                                                <div className="padding-title">
                                                    <div className="row col-12 ">
                                                        <div className="col-lg-6 col-md-8 col-sm-8 col-xs-8 text-right mt-3">
                                                            <label htmlFor="filename" className='text-righ'><strong>สำเนาสมุดบัญชีธนาคาร (ถ้ามี) เซ็นรับรองสำเนาถูกต้อง</strong> <br/>รองรับไฟล์ .jpeg, .png, .pdf</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="col-12 text-left" align="right"  style={{ margin:'10px 0px 0px 0px' }}>
                                                                <label htmlFor="file5" className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i> อัพโหลด 
                                                                </label>
                                                                <label className='ml-2'>{this.state.fileName5}</label>
                                                                <input  type="file"  id="file5" accept="image/png, image/jpeg, image/jpg, application/pdf" style={{display:'none'}}  onChange={this.handleselectedFile("fileName5")}/>
                                                            </div>
                                                        </div>
                                                    </div>      
                                                </div>


                                            

                                                <div className="row dv-button">
                                                        
                                                    <input  type="submit" className="btn btn-primary button-right-page"  style={{display:'none'}}></input>                               
                                                </div>

                                            </Form>
                                                
                                        
                                        </div>               
                                    </div>
                                    <div className="card-body App">
                                        <a onClick={() => this.back()}  className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a> 
                                        <a onClick={() => this.next()} className="btn btn-lg btn-blue">ต่อไป</a>
                                    </div>
                                </div>

                                <div className="card" style={{ display: (showing_step === 3 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>ยืนยันข้อมูล</strong></label>
                                            </div>
                                        </div>      
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 ">
                                            <div className="col-2 ">
                                                    {/* <label htmlFor="subject" className='text-righ mt-2'><strong>Email</strong></label> */}
                                            </div>
                                            <div className="col-10">
                                                {postData2} 

                                                <div className="card-body list-group">
                                                    <div className="row col-10 ">
                                                        <div className="card p-0">  
                                                            <div className="card-body header-card">
                                                            <label className='text-righ mt-2'><strong>เอกสารแนบ </strong></label>
                                                            </div> 
                                                            <ul className="list-group list-group-flush">
                                                            <li className="list-group-item">
                                                            <span className="bold">รูปถ่ายหน้าตรงที่ถ่ายคู่กับสำเนาบัตรประชาชน &nbsp;&nbsp;:</span>
                                                            <span className="light">{this.state.fileName1}</span>
                                                            </li>
                                                                           
                                                            <li className="list-group-item">
                                                            <span className="bold">สำเนาบัตรประจำตัวประชาชนด้านหน้า เซ็นรับรองสำเนาถูกต้อง &nbsp;&nbsp;:</span>
                                                            <span className="light">{this.state.fileName2}</span>
                                                            </li>
                                                            <li className="list-group-item">
                                                            <span className="bold">สำเนาบัตรประจำตัวประชาชนด้านหลัง เซ็นรับรองสำเนาถูกต้อง &nbsp;&nbsp;:</span>
                                                            <span className="light">{this.state.fileName3}</span>
                                                            </li>
                                                            <li className="list-group-item">
                                                            <span className="bold">แบบฟอร์มสัญญากู้ยืมเงินตามกรมธรรม์ประกันภัย (1 ฉบับ ต่อ 1 กรมธรรม์) &nbsp;&nbsp;:</span>
                                                            <span className="light">{this.state.fileName4}</span>
                                                            </li>
                                                            <li className="list-group-item">
                                                            <span className="bold">สำเนาสมุดบัญชีธนาคาร (ถ้ามี) เซ็นรับรองสำเนาถูกต้อง &nbsp;&nbsp;:</span>
                                                            <span className="light">{this.state.fileName5}</span>
                                                            </li>
                                                        </ul>
                                                        
                                                            
                                                        </div>   
                                                    </div>              
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body App">
                                        <a onClick={() => this.back()} type="button" className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a>
                                        <a  onClick={() => this.save()} className="btn btn-lg btn-blue">บันทึก</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    

                        {/* <div className='App'>
                            <a  href="/home" type="button" className="btn btn-lg btn mt-3 cust-btn"  >ย้อนกลับ</a>
                        </div> */}
                        </div> 
                </section>
            </div> 
        )
    }
        
    
}

export default Request_loan;