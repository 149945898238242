import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../util/Notification";
import {
  uploadFileAPI,
  getPolicyInformationAPI,
  getPolicyCouponAPI,
} from "./verifyService/verifyService";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Form } from "react-bootstrap";
import uploadImg from "../../images/upload.svg";
import { StoreContext } from "../../context/store";

const Verify = () => {
  const [username, setUsername] = useState("");
  const [ageLessThan7Years, setAgeLessThan7Years] = useState(false);
  const [ageBetween7And20Years, setageBetween7And20Years] = useState(false);
  const [ageMoreThan20Years, setageMoreThan20Years] = useState(false);
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [filesInfo, setFilesInfo] = useState({});

  let navigate = useNavigate();

  const { infomationAccount, updateInformationAccount } =
    useContext(StoreContext);

  //   const handleDataInformation = async () => {
  //     let customerId = await localStorage.getItem("customer_id");
  //     const resultInformation = await getPolicyInformationAPI(customerId);
  //     console.log(resultInformation);
  //     if (resultInformation.status) {
  //       const policyNo =
  //         resultInformation.data.policies.length > 0
  //           ? resultInformation.data.policies[0].policyNo
  //           : "";
  //       const resultCoupon = await getPolicyCouponAPI(policyNo);
  //       if (resultCoupon.status) {
  //         let data = {
  //           username: customerId,
  //           age: resultCoupon.data.insureAge,
  //         };
  //         updateInformationAccount(data);
  //       }
  //     }
  //   };

  //   useEffect(() => {
  //     handleDataInformation();
  //   }, []);

  const handleFileUpload = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    let amountFile = 0;
    let flagAge = "";

    if (ageMoreThan20Years) {
      amountFile = 2;
      flagAge = "ageMoreThan20Years";
    } else if (ageLessThan7Years) {
      amountFile = 5;
      flagAge = "ageLessThan7Years";
    } else if (ageBetween7And20Years) {
      amountFile = 5;
      flagAge = "ageBetween7And20Years";
    }

    // for (let i = 0; i < amountFile; i++) {
    //   let file = event.target[i].files[0];
    //   console.log("loop check file : ", file);
    //   if (file == undefined) {
    //     console.log("undefined");
    //   }
    // }

    for (let i = 0; i < amountFile; i++) {
      let file = event.target[i].files[0];
      if (file !== undefined) {
        formData.append(
          "files",
          event.target[i].files[0],
          event.target[i].files[0].name
        );
      }
    }

    console.log("handleFile check ", formData.getAll("files").length);

    if (formData.getAll("files").length == amountFile) {
      formData.append("type", "11"); // ยืนยันตัวตน
      formData.append("flagAge", flagAge);

      setLoading(true);
      const result = await uploadFileAPI(formData);

      if (result.status) {
        notifySuccess("บริษัทได้รับทราบข้อมูลจากท่านแล้ว ขอบคุณค่ะ");
        setTimeout(() => {
          setLoading(false);
          goback();
        }, 1500);
      } else {
        notifyError("เกิดข้อผิดพลาดไม่สามารถทำรายการได้");
        console.log(result);
      }
    } else {
      notifyWarning(
        "เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ"
      );
    }
  };

  const handleFilesInfo = (fileKey, e) => {
    let fileName = e.target.files[0].name;

    let dataFileInfo = {
      [fileKey]: fileName,
    };

    setFilesInfo({ ...filesInfo, ...dataFileInfo });
  };

  const goback = () => {
    navigate(`/home`);
  };

  useEffect(() => {
    let age = infomationAccount.age ?? "";
    console.log("age : ", age);
    if (age !== "") {
      if (age < 7) {
        setTitle("ผู้เยาว์ (อายุต่ำกว่า 7 ปี)");
        setAgeLessThan7Years(true);
      } else if (age >= 7 && age < 20) {
        setTitle("ผู้เยาว์ (อายุตั้งแต่ 7 ปีขึ้นไป แต่น้อยกว่า 20 ปี)");
        setageBetween7And20Years(true);
      } else {
        setTitle("ผู้เอาประกัน (อายุตั้งแต่ 20 ปีขึ้นไป)");
        setageMoreThan20Years(true);
      }
    }
  }, [infomationAccount]);

  return (
    <>
      <Header_Home />
      <Header title="ส่งเอกสารแสดงตัวตน" />
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          // onClick={handleClose}
        >
          <CircularProgress
            sx={{
              animationDuration: "550ms",
            }}
            color="inherit"
          />
        </Backdrop>
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={infomationAccount.loadingInformation}
        // onClick={handleClose}
      >
        <CircularProgress
          sx={{
            animationDuration: "550ms",
          }}
          color="inherit"
        />
      </Backdrop>
      {!infomationAccount.loadingInformation && (
        <div className="container vh-100 px-lg-5 pt-lg-5 px-4 pt-5">
          <div className="card big-frame h-auto">
            <div className="d-flex px-lg-5 px-3 pt-3 pb-3">
              <h2 className="font-size-title">นำส่งเอกสารแสดงตัวตน {title}</h2>
            </div>
            <hr
              className="mx-lg-5 mx-2"
              style={{ margin: "0px 0px 15px 0px", border: "solid 1px" }}
            />
            {ageMoreThan20Years && (
              <>
                <Form onSubmit={handleFileUpload}>
                  <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-lg-5">
                    <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                      สำเนาบัตรประชาชน เซ็นสำเนาถูกต้อง
                      <p className="text-danger">*</p>
                    </div>
                    <label
                      htmlFor="file1"
                      style={{fontSize: 15}}
                      className="btn-upload-file d-flex flex-row justify-content-center align-items-center"
                    >
                      <i className="fa fa-cloud-upload"></i> อัพโหลด
                      <img
                        className="icon-image-content-upload"
                        src={uploadImg}
                        style={{ textAlign: "center" }}
                        alt=""
                      />
                    </label>
                    <input
                      type="file"
                      id="file1"
                      accept="image/png, image/jpeg, image/jpg, application/pdf"
                      style={{ display: "none" }}
                      onChange={(e) => handleFilesInfo("file1", e)}
                    />
                  </div>
                  {filesInfo.file1 && (
                    <p style={{fontSize: 10}} className="ml-lg-5 ml-2 text-danger">
                      {filesInfo.file1 || ""}
                    </p>
                  )}
                  <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-lg-5">
                    <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                      รูปถ่ายหน้าตรงคู่กับบัตรประชาชน
                      <p className="text-danger">*</p>
                    </div>
                    <label
                      htmlFor="file2"
                      style={{fontSize: 15}}
                      className="btn-upload-file d-flex flex-row justify-content-center align-items-center"
                    >
                      <i className="fa fa-cloud-upload"></i> อัพโหลด
                      <img
                        className="icon-image-content-upload"
                        src={uploadImg}
                        style={{ textAlign: "center" }}
                        alt=""
                      />
                    </label>
                    <input
                      type="file"
                      id="file2"
                      accept="image/png, image/jpeg, image/jpg, application/pdf"
                      style={{ display: "none" }}
                      onChange={(e) => handleFilesInfo("file2", e)}
                    />
                  </div>
                  {filesInfo.file2 && (
                    <p className="ml-lg-5 ml-2 text-danger" style={{fontSize: 10}}>
                      {filesInfo.file2 || ""}
                    </p>
                  )}
                  <Row className="d-flex flex-column mt-5 mb-5">
                    <Col className="d-flex justify-content-end px-5 gap-2">
                      <input
                        type="submit"
                        style={{ fontSize: 15 }}
                        value="ถัดไป"
                        className="btn-checkout-premium w-100 p-2 bold"
                      ></input>
                    </Col>
                    <Col className="d-flex justify-content-start px-5">
                      <button
                        onClick={goback}
                        type="button"
                        className="btn-checkout-back w-100 p-2 bold"
                      >
                        กลับ
                      </button>
                    </Col>
                  </Row>
                </Form>
              </>
            )}

            {ageLessThan7Years && (
              <Form onSubmit={handleFileUpload}>
                <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-lg-5">
                  <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                    สำเนาสูติบัตรของผู้เยาว์ เซ็นสำเนาถูกต้อง
                    <p className="text-danger">*</p>
                  </div>
                  <label
                    htmlFor="file1"
                    style={{fontSize: 15}}
                    className="btn-upload-file d-flex flex-row justify-content-center align-items-center"
                  >
                    <i className="fa fa-cloud-upload"></i> อัพโหลด
                    <img
                      className="icon-image-content-upload"
                      src={uploadImg}
                      style={{ textAlign: "center" }}
                      alt=""
                    />
                  </label>
                  <input
                    type="file"
                    id="file1"
                    accept="image/png, image/jpeg, image/jpg, application/pdf"
                    style={{ display: "none" }}
                    onChange={(e) => handleFilesInfo("file1", e)}
                  />
                  {filesInfo.file1 && (
                    <p className="ml-lg-5 ml-2 text-danger" style={{fontSize: 10}}>
                      {filesInfo.file1 || ""}
                    </p>
                  )}
                </div>
                <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-lg-5">
                  <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                    สำเนาบัตรประชาชนของผู้ปกครอง โดยชอบธรรม์ เซ็นสำเนาถูกต้อง
                    <p className="text-danger">*</p>
                  </div>
                  <label
                    htmlFor="file1"
                    style={{fontSize: 15}}
                    className="btn-upload-file d-flex flex-row justify-content-center align-items-center"
                  >
                    <i className="fa fa-cloud-upload"></i> อัพโหลด
                    <img
                      className="icon-image-content-upload"
                      src={uploadImg}
                      style={{ textAlign: "center" }}
                      alt=""
                    />
                  </label>
                  <input
                    type="file"
                    id="file2"
                    accept="image/png, image/jpeg, image/jpg, application/pdf"
                    style={{ display: "none" }}
                    onChange={(e) => handleFilesInfo("file2", e)}
                  />
                  {filesInfo.file2 && (
                    <p className="ml-lg-5 ml-2 text-danger" style={{fontSize: 10}}>
                      {filesInfo.file2 || ""}
                    </p>
                  )}
                </div>
                <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-lg-5">
                  <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                    รูปถ่ายหน้าตรงคู่กับบัตรประชาชนของผู้ปกครองโดยชอบธรรม์
                    <p className="text-danger">*</p>
                  </div>
                  <label
                    htmlFor="file3"
                    style={{fontSize: 15}}
                    className="btn-upload-file d-flex flex-row justify-content-center align-items-center"
                  >
                    <i className="fa fa-cloud-upload"></i> อัพโหลด
                    <img
                      className="icon-image-content-upload"
                      src={uploadImg}
                      style={{ textAlign: "center" }}
                      alt=""
                    />
                  </label>
                  <input
                    type="file"
                    id="file3"
                    accept="image/png, image/jpeg, image/jpg, application/pdf"
                    style={{ display: "none" }}
                    onChange={(e) => handleFilesInfo("file3", e)}
                  />
                  {filesInfo.file3 && (
                    <p className="ml-lg-5 ml-2 text-danger" style={{fontSize: 10}}>
                      {filesInfo.file3 || ""}
                    </p>
                  )}
                </div>
                <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-lg-5">
                  <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                    รูปถ่ายหน้าตรงที่เป็นปัจจุบันของผู้เยาว์
                    <p className="text-danger">*</p>
                  </div>
                  <label
                    htmlFor="file4"
                    style={{fontSize: 15}}
                    className="btn-upload-file d-flex flex-row justify-content-center align-items-center"
                  >
                    <i className="fa fa-cloud-upload"></i> อัพโหลด
                    <img
                      className="icon-image-content-upload"
                      src={uploadImg}
                      style={{ textAlign: "center" }}
                      alt=""
                    />
                  </label>
                  <input
                    type="file"
                    id="file4"
                    accept="image/png, image/jpeg, image/jpg, application/pdf"
                    style={{ display: "none" }}
                    onChange={(e) => handleFilesInfo("file4", e)}
                  />
                  {filesInfo.file4 && (
                    <p className="ml-lg-5 ml-2 text-danger" style={{fontSize: 10}}>
                      {filesInfo.file4 || ""}
                    </p>
                  )}
                </div>
                <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-lg-5">
                  <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                    สำเนาทะเบียนบ้านของผู้เยาว์ เซ็นสำเนาถูกต้อง
                    <p className="text-danger">*</p>
                  </div>
                  <label
                    htmlFor="file4"
                    style={{fontSize: 15}}
                    className="btn-upload-file d-flex flex-row justify-content-center align-items-center"
                  >
                    <i className="fa fa-cloud-upload"></i> อัพโหลด
                    <img
                      className="icon-image-content-upload"
                      src={uploadImg}
                      style={{ textAlign: "center" }}
                      alt=""
                    />
                  </label>
                  <input
                    type="file"
                    id="file5"
                    accept="image/png, image/jpeg, image/jpg, application/pdf"
                    style={{ display: "none" }}
                    onChange={(e) => handleFilesInfo("file5", e)}
                  />
                  {filesInfo.file5 && (
                    <p className="ml-lg-5 ml-2 text-danger" style={{fontSize: 10}}>
                      {filesInfo.file5 || ""}
                    </p>
                  )}
                </div>
                <Row className="d-flex flex-lg-row flex-column mt-5 mb-5">
                  <Col className="d-flex justify-content-end px-5 gap-2">
                    <input
                      type="submit"
                      style={{ fontSize: 15 }}
                      // onClick={goChangePayment}
                      value="ถัดไป"
                      className="btn-checkout-premium w-sm-100 w-lg-25 p-2 bold"
                    ></input>
                  </Col>
                  <Col className="d-flex justify-content-start px-5">
                    <button
                      onClick={goback}
                      type="button"
                      className="btn-checkout-back w-sm-100 w-lg-25 p-2 bold"
                    >
                      กลับ
                    </button>
                  </Col>
                </Row>
              </Form>
            )}

            {ageBetween7And20Years && (
              <Form onSubmit={handleFileUpload}>
                <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-lg-5">
                  <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                    สำเนาบัตรประชาชนของผู้เยาว์ เซ็นสำเนาถูกต้อง
                    <p className="text-danger">*</p>
                  </div>
                  <label
                    htmlFor="file1"
                    style={{fontSize: 15}}
                    className="btn-upload-file d-flex flex-row justify-content-center align-items-center"
                  >
                    <i className="fa fa-cloud-upload"></i> อัพโหลด
                    <img
                      className="icon-image-content-upload"
                      src={uploadImg}
                      style={{ textAlign: "center" }}
                      alt=""
                    />
                  </label>
                  <input
                    type="file"
                    id="file1"
                    accept="image/png, image/jpeg, image/jpg, application/pdf"
                    style={{ display: "none" }}
                    onChange={(e) => handleFilesInfo("file1", e)}
                  />
                  {filesInfo.file1 && (
                    <p className="ml-lg-5 ml-2 text-danger" style={{fontSize: 10}}>
                      {filesInfo.file1 || ""}
                    </p>
                  )}
                </div>
                <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-lg-5">
                  <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                    สำเนาบัตรประชาชนของ ผู้ปกครองโดยชอบธรรม์ เซ็นสำเนาถูกต้อง
                    <p className="text-danger">*</p>
                  </div>
                  <label
                    htmlFor="file2"
                    style={{fontSize: 15}}
                    className="btn-upload-file d-flex flex-row justify-content-center align-items-center"
                  >
                    <i className="fa fa-cloud-upload"></i> อัพโหลด
                    <img
                      className="icon-image-content-upload"
                      src={uploadImg}
                      style={{ textAlign: "center" }}
                      alt=""
                    />
                  </label>
                  <input
                    type="file"
                    id="file2"
                    accept="image/png, image/jpeg, image/jpg, application/pdf"
                    style={{ display: "none" }}
                    onChange={(e) => handleFilesInfo("file2", e)}
                  />
                  {filesInfo.file2 && (
                    <p className="ml-lg-5 ml-2 text-danger" style={{fontSize: 10}}>
                      {filesInfo.file2 || ""}
                    </p>
                  )}
                </div>
                <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-lg-5">
                  <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                    รูปถ่ายหน้าตรงคู่กับบัตรประชาชนของผู้เยาว์
                    <p className="text-danger">*</p>
                  </div>
                  <label
                    htmlFor="file3"
                    style={{fontSize: 15}}
                    className="btn-upload-file d-flex flex-row justify-content-center align-items-center"
                  >
                    <i className="fa fa-cloud-upload"></i> อัพโหลด
                    <img
                      className="icon-image-content-upload"
                      src={uploadImg}
                      style={{ textAlign: "center" }}
                      alt=""
                    />
                  </label>
                  <input
                    type="file"
                    id="file3"
                    accept="image/png, image/jpeg, image/jpg, application/pdf"
                    style={{ display: "none" }}
                    onChange={(e) => handleFilesInfo("file3", e)}
                  />
                  {filesInfo.file3 && (
                    <p className="ml-lg-5 ml-2 text-danger" style={{fontSize: 10}}>
                      {filesInfo.file3 || ""}
                    </p>
                  )}
                </div>
                <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-lg-5">
                  <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                    รูปถ่ายหน้าตรงคู่กับบัตรประชาชนของผู้ปกครอง โดยชอบธรรม์
                    <p className="text-danger">*</p>
                  </div>
                  <label
                    htmlFor="file3"
                    style={{fontSize: 15}}
                    className="btn-upload-file d-flex flex-row justify-content-center align-items-center"
                  >
                    <i className="fa fa-cloud-upload"></i> อัพโหลด
                    <img
                      className="icon-image-content-upload"
                      src={uploadImg}
                      style={{ textAlign: "center" }}
                      alt=""
                    />
                  </label>
                  <input
                    type="file"
                    id="file4"
                    accept="image/png, image/jpeg, image/jpg, application/pdf"
                    style={{ display: "none" }}
                    onChange={(e) => handleFilesInfo("file4", e)}
                  />
                  {filesInfo.file4 && (
                    <p className="ml-lg-5 ml-2 text-danger" style={{fontSize: 10}}>
                      {filesInfo.file4 || ""}
                    </p>
                  )}
                </div>
                <div className="d-flex flex-row justify-content-between align-content-center align-items-center p-2 px-lg-5">
                  <div className="font-size-content-upload d-flex flex-row justify-content-center align-items-center">
                    สำเนาทะเบียนบ้านของผู้เยาว์
                    <p className="text-danger">*</p>
                  </div>
                  <label
                    htmlFor="file3"
                    style={{fontSize: 15}}
                    className="btn-upload-file d-flex flex-row justify-content-center align-items-center"
                  >
                    <i className="fa fa-cloud-upload"></i> อัพโหลด
                    <img
                      className="icon-image-content-upload"
                      src={uploadImg}
                      style={{ textAlign: "center" }}
                      alt=""
                    />
                  </label>
                  <input
                    type="file"
                    id="file5"
                    accept="image/png, image/jpeg, image/jpg, application/pdf"
                    style={{ display: "none" }}
                    onChange={(e) => handleFilesInfo("file5", e)}
                  />
                  {filesInfo.file5 && (
                    <p className="ml-lg-5 ml-2 text-danger" style={{fontSize: 10}}>
                      {filesInfo.file5 || ""}
                    </p>
                  )}
                </div>
                <Row className="d-flex flex-lg-row flex-column mt-5 mb-5">
                  <Col className="d-flex justify-content-end px-5 gap-2">
                    <input
                      type="submit"
                      style={{ fontSize: 15 }}
                      // onClick={goChangePayment}
                      value="ถัดไป"
                      className="btn-checkout-premium w-sm-100 w-lg-25 p-2 bold"
                    ></input>
                  </Col>
                  <Col className="d-flex justify-content-start px-5">
                    <button
                      onClick={goback}
                      type="button"
                      className="btn-checkout-back w-sm-100 w-lg-25 p-2 bold"
                    >
                      กลับ
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Verify;
