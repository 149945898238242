import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component } from "react";
import img_2 from "../../images/logonew1.png";
import img_logo from "../../images/rabbitlife-logo.png";
import HeaderLogin from "../Component/HeaderLogin";
import OTP from "./otp";
import Main from "./Main";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment, TextField } from "@mui/material";

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      loading: false,
      error_flag: false,
      error_meg: "เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้ง",
      showPassword: false,
    };
  }

  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  prevStep = () => {
    this.props.prevStep();
  };

  componentDidMount() {
    console.log("componentDidMount");
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  componentWillUnmount() {
    console.log("componentWillUnmount");
  }

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  submit = () => {
    // this.props.nextStep();
    //fetch("/api/form", { method: "POST", body: JSON.stringify(data) });
    let username = document.getElementById("username").value;
    let password = document.getElementById("password").value;
    // var newPassword =  document.getElementById('password').value;
    // var confirm_password =   document.getElementById('confirm_password').value;
    // var minNumberofChars = 8;
    // var regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    // alert(newPassword);
    this.setState({ username: username });
    this.setState({ password: password, loading: true });
    console.log("username: " + username);
    console.log("password: " + password);

    fetch(process.env.REACT_APP_API_BASEURL + "/api/user/activate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        dob: password,
      }),
    })
      .then((res) => res.json())

      .then((res) => {
        console.log("activate : ", res);
        if (res.success === true) {
          const { step } = this.state;
          this.setState({
            step: 2,
          });
          this.setState({ loading: false });
        } else {
          this.setState({
            loading: false,
            error_flag: true,
            error_meg: res.payload.error,
          });
          //   alert(res.payload.error);
        }
      })
      .catch(function (res) {
        console.log(res);
      });
  };

  render() {
    const { step, loading } = this.state;
    switch (step) {
      case 1:
        return (
          <div>
            {loading && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
              >
                <CircularProgress
                  sx={{
                    animationDuration: "550ms",
                  }}
                  color="inherit"
                />
              </Backdrop>
            )}
            <HeaderLogin />
            <section className="sec-form" id="">
              <div className="container-fluid">
                <div className="row">
                  {/* <!-- <div className="col-md-4 col-sm-4 col-xs-12"></div> --> */}
                  <div className="col-lg-4 col-md- col-xs-12 p-5 mx-auto">
                    <div className="logo text-center">
                      <img className="img-fluid" src={img_2} alt="" />
                      <h4>iService</h4>
                    </div>

                    <form className="login-form mt-5">
                      <div className="form-group">
                        <label for="username" id="lbl_email">
                          ลงทะเบียนสมาชิกใหม่
                        </label>
                        <TextField
                          type="text"
                          className="form-control form-control-lg"
                          style={{ borderWidth: 1 }}
                          id="username"
                          placeholder="เลขประจำตัวประชาชน หรือ เลขที่หนังสือเดินทาง"
                        />

                        <TextField
                          // type={this.state.showPassword ? "text" : "password"}
                          type="password"
                          className="form-control form-control-lg mt-3"
                          id="password"
                          style={{ borderWidth: 1, marginBottom: "15px" }}
                          placeholder="ปีเกิด พ.ศ. เดือน วัน (ex. 25200131)"
                          InputProps={{
                            maxLength: 6,
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                            onInput: (e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            },
                            // endAdornment: (
                            //   <InputAdornment position="end">
                            //     <IconButton
                            //       onClick={this.togglePasswordVisibility}
                            //       edge="end"
                            //     >
                            //       {this.state.showPassword ? (
                            //         <VisibilityOff />
                            //       ) : (
                            //         <Visibility />
                            //       )}
                            //     </IconButton>
                            //   </InputAdornment>
                            // ),
                          }}
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          fullWidth
                        />

                        {this.state.error_flag && (
                          <p className="text-danger">{this.state.error_meg}</p>
                        )}

                        <small id="emailHelp" className="form-text text-muted">
                          หากท่านประสบปัญหาในการสมัครสมาชิก หรือ
                          ต้องการเปลี่ยนเบอร์ โทรศัพท์สามารถติดต่อ 026483600
                          ในวันและเวลาทำการ 8:00 - 17:00 น. หรือ facebook Rabbit
                          Life
                        </small>
                      </div>
                      <a
                        onClick={this.submit}
                        type="button"
                        className="btn btn-primary btn-block btn-lg btn mt-3 cust-btn"
                      >
                        ลงทะเบียน
                      </a>
                      <a
                        href="/"
                        type="button"
                        className="btn btn-back-regis btn-block btn-lg btn mt-3 cust-btn-back"
                      >
                        ย้อนกลับ
                      </a>
                    </form>
                    <br/>
                  </div>
                </div>
              </div>
            </section>
          </div>
        );

      case 2:
        return (
          <OTP username={this.state.username} password={this.state.password} />
        );
      case 3:
        return <Main />;
    }
  }
}

export default Registration;
