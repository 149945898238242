import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Col } from 'react-bootstrap';
import { Modal, Form } from 'react-bootstrap';

// import Header_Home from '../Component/Header_Home';
// import Left_bar from '../Component/Left_bar';
// import ReactPaginate from 'react-paginate';
// import img_profile from '../../images/profile.svg'
import Header_Home from '../Component/Header_Home';
import Header from '../Component/Header';
import Button from 'react-bootstrap/Button';
import { BsChevronRight } from "react-icons/bs";
import menu_11 from '../../images/icon31.svg'
import menu_12 from '../../images/icon32.svg'
import menu_13 from '../../images/icon33.svg'
import menu_14 from '../../images/icon34.svg'
import CollapseTable from '../Benefit/collapseTable.tsx';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import styles from '../Benefit/benefit-styles.css';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { OutlinedInput, InputLabel } from "@mui/material";
import { Checkbox, FormControlLabel } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import successGif from "../../images/successGiff.gif"

import {
    useForm,
    Controller,
    FormProvider,
    useFormContext,
    useWatch,
} from "react-hook-form";
function ListPolicy() {
    const [isChecked, setIsChecked] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
        setShowModal(e.target.checked);

    };
    const handleModalClose = () => {
        setShowModal(false);
    };



    return (
        <div>
            <Header_Home />
            <Header title="รายชื่อลูกค้าที่ลงทะเบียนรับเงินผลประโยชน์" />
            {/* <div className="card-frame margin-1"> */}
            <div className="content margin-1">
                <div className="card big-frame">
                    <ul className="list-group list-group-flush cus-frame2" style={{ display: 'flex', flexDirection: 'column' }}>
                        <h2 className="bold" style={{ fontSize: "25px", marginLeft: "5px", marginTop: "20px" }} >แก้ไขข้อมูล</h2>
                        <hr style={{ margin: "0px 0px 15px 0px", border: 'solid 1px' }} />
                        <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Col xl={3} md={3} xs={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <h2 className="bold font-field" style={{ textAlign: 'end' }} style={{ marginLeft: "20px", marginTop: "20px" }} >ชื่อ - นามสกุล</h2>
                            </Col>
                            <Col xl={8} md={8} xs={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <input type="text" className="form-control textFeild" id="username" />
                            </Col>
                        </li>
                        <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Col xl={3} md={3} xs={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <h2 className="bold font-field" style={{ textAlign: 'end' }} style={{ marginLeft: "20px", marginTop: "20px" }} >เลขประจำตัวประชาชน</h2>
                            </Col>
                            <Col xl={8} md={8} xs={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <input type="text" className="form-control textFeild" id="username" />
                            </Col>
                        </li>
                        <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Col xl={3} md={3} xs={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <h2 className="bold font-field" style={{ textAlign: 'end' }} style={{ marginLeft: "20px", marginTop: "20px" }} >เบอร์โทร</h2>
                            </Col>
                            <Col xl={8} md={8} xs={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <input type="text" className="form-control textFeild" id="username" />
                            </Col>
                        </li>
                        <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Col xl={3} md={3} xs={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <h2 className="bold font-field" style={{ textAlign: 'end' }} style={{ marginLeft: "20px", marginTop: "20px" }} >ธนาคาร</h2>
                            </Col>
                            <Col xl={8} md={8} xs={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <input type="text" className="form-control textFeild" id="username" />
                            </Col>
                        </li>
                        <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Col xl={3} md={3} xs={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <h2 className="bold font-field" style={{ textAlign: 'end' }} style={{ marginLeft: "20px", marginTop: "20px" }} >เลขบัญชี</h2>
                            </Col>
                            <Col xl={8} md={8} xs={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <input type="text" className="form-control textFeild" id="username" />
                            </Col>
                        </li>
                        <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Col xl={5} md={5} xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingLeft: '10px' }}>
                                <span className="bold font-field" style={{ textAlign: 'end' }}>KYC : </span>
                            </Col>
                            <Col xl={8} md={8} xs={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <span className="light">N</span>
                            </Col>
                        </li>
                        <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Col xl={5} md={5} xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <span className="bold font-field" style={{ textAlign: 'end' }}>ตรวจสอบ : </span>
                            </Col>
                            <Col xl={8} md={8} xs={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                {/* <span className="light"></span> */}
                                {/* <input type="checkbox" id="ch_mobile" name="ch_mobile" class="ml-2" /> */}
                                <Form.Check
                                    type="checkbox"
                                    className='ml-4 mb-4'
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />

                            </Col>
                        </li>
                        <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Col xl={5} md={5} xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <span className="bold font-field" style={{ textAlign: 'end' }} style={{ textAlign: 'end' }}>สำเนาสูติบัตรของผู้เยาว์เซ็นสำเนาถูกต้อง : </span>
                            </Col>
                            <Col xl={8} md={8} xs={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <span className="light" style={{ color: '#4CAF50' }}>filename.png</span>
                            </Col>
                        </li>
                        <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Col xl={5} md={5} xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <span className="bold font-field" style={{ textAlign: 'end' }}>สำเนาบัตรประชาชนของผู้ปกครอง โดยชอบธรรม เซ็นสำเนาถูกต้อง : </span>
                            </Col>
                            <Col xl={8} md={8} xs={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <span className="light" style={{ color: '#4CAF50' }}>filename.png</span>
                            </Col>
                        </li>
                        <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Col xl={5} md={5} xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <span className="bold font-field" style={{ textAlign: 'end' }}>รูปถ่ายหน้าตรงคู่กับบัตรประชาชนของผู้ปกครองโดยชอบธรรม : </span>
                            </Col>
                            <Col xl={8} md={8} xs={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <span className="light" style={{ color: '#4CAF50' }}>filename.png</span>
                            </Col>
                        </li>
                        <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Col xl={5} md={5} xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <span className="bold font-field" style={{ textAlign: 'end' }}>รูปถ่ายหน้าตรงที่เป็นปัจจุบันของผู้เยาว์ : </span>
                            </Col>
                            <Col xl={8} md={8} xs={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <span className="light" style={{ color: '#4CAF50' }}>filename.png</span>
                            </Col>
                        </li>
                        <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Col xl={5} md={5} xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <span className="bold font-field" style={{ textAlign: 'end' }}>สำเนาสมุดบัญชีเงินฝากธนาคาร : </span>
                            </Col>
                            <Col xl={8} md={8} xs={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <span className="light" style={{ color: '#4CAF50' }}>filename.png</span>
                            </Col>
                        </li>
                        <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Col xl={5} md={5} xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <span className="bold font-field" style={{ textAlign: 'end' }}>สำเนาทะเบียนบ้านของผู้เยาว์ เซ็นสำเนาถูกต้อง  : </span>
                            </Col>
                            <Col xl={8} md={8} xs={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <span className="light" style={{ color: '#4CAF50' }}>filename.png</span>
                            </Col>
                        </li>




                        <div className='App'>
                            <div className="d-flex justify-content-center flex-wrap">
                                <a href="/addChannelPay" type="button" className="mt-3 pay-btn col-xl-6 col-lg-6 col-md-6 col-sm-12">บันทึก</a>
                                <a href="/benefit" type="button" className="mt-3 btn-back col-xl-6 col-lg-6 col-md-6 col-sm-12">กลับ</a>
                            </div>
                        </div>
                        <br />




                    </ul>
                </div>

            </div>
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    {/* <Modal.Title>ตรวจสอบสำเร็จ</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <img
                        src={successGif}
                        alt={"confirmGif"}
                        style={{
                            height: "auto",
                            marginLeft: '35%',
                            width: '30%',
                        }}
                    />
                    <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Col xl={12} md={12} xs={12} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                            <h3 className="bold">ตรวจสอบสำเร็จ</h3>
                        </Col>
                    </li>
                    <div className='App'>
                        <div className="d-flex justify-content-center flex-wrap">
                            <a onClick={handleModalClose} type="button" className="mt-3 btn-back col-xl-6 col-lg-6 col-md-6 col-sm-12">ปิด</a>
                        </div>
                    </div>


                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        ปิด
                    </Button>
                </Modal.Footer> */}
            </Modal>



        </div>
    );
}

export default ListPolicy;
