import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { makeStyles } from "@mui/styles";
import { Col } from "react-bootstrap";
import { CurrencyFormater } from "../../util";
// import "./refundPolicy-styles.css";

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  <React.Fragment>
      <TableRow
        className={classes.tableRow}
        sx={{ "& > *": { borderBottom: "unset" } }}
      >
        <TableCell
          className="font-new"
          align="center"
          component="th"
          scope="row"
          width={100}
        >
          {row.loanDate}
        </TableCell>
        <TableCell className="font-new" align="center">
          {row.loanAmount}
        </TableCell>
        {/* <TableCell className="font-new" align="center" width={100}>
          {CurrencyFormater(row.couponAmount)}
        </TableCell> */}
      </TableRow>
  </React.Fragment>

}
const theme = createMuiTheme({
  overrides: {
    MuiTableHead: {
      root: {
        backgroundColor: "#FFA500", 
      },
    },
  },
});
const useStyles = makeStyles({
  tableCell: {
    padding: "5px",
  },
  tableRow: {
    "& > .MuiTableCell-root": {
      padding: "5px",
    },
  },
});

export default function CollapsibleTable(props) {
  const classes = useStyles();
  const { data } = props;

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow className={classes.tableRow} style={{ backgroundColor: "#e7e7e786" }}>
            <TableCell className="font-new" align="center">
              วันที่กู้เงิน
            </TableCell>
            <TableCell className="font-new" align="center">
              จำนวนเงินกู้
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {data.loanHistory
        .sort((a, b) => {
          const dateA = new Date(parseInt(a.loanDate.split("/")[2]) - 543, a.loanDate.split("/")[1] - 1, a.loanDate.split("/")[0]);
          const dateB = new Date(parseInt(b.loanDate.split("/")[2]) - 543, b.loanDate.split("/")[1] - 1, b.loanDate.split("/")[0]);
          return dateB - dateA;
        })
        .map((item, idx) => (
          <TableRow key={idx}>
            <TableCell align="center">
              {item.loanDate}
            </TableCell>
            <TableCell align="right">
              {item.loanAmount ? item.loanAmount.toLocaleString(undefined, { minimumFractionDigits: 2 }) : "-"}
            </TableCell>
          </TableRow>
        ))}
        </TableBody>
      </Table>

      </TableContainer>
    </ThemeProvider>
  );
}
