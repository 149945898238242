import React, { useEffect, useState } from "react";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import Row from "react-bootstrap/Row";
import { Modal, Col, Button } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  getPolicyInformationAPI,
  insertConsentAPI,
} from "./ExemptIncomeTaxService/exemptIncomeTaxService";
import SuccessPage from "../Component/Success_page";
import "../ExemptIncomeTax/exemptIncomeTax-styles.css";
import CircularProgress from "@mui/material/CircularProgress";
import CryptoJS from "crypto-js";
import { Typography } from "@material-ui/core";
import successGif from "../../images/successGiff.gif";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";

const ExemptIncomeTax = () => {
  const [LoadingContent, setLoadingContent] = useState(true);
  const [dataMaster, setDataMaster] = useState([]);
  const [dataPolicies, setDataPolicies] = useState([]);
  const [dataRdConsent, setDataRdConsent] = useState([]);
  const [rdConsent, setRdConsent] = useState([]);
  const [listPolicyNo, setListPolicyNo] = useState([]);
  const [insureIdCard, setInsureIdCard] = useState(
    localStorage.getItem("customer_id")
  );
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isAllUnChecked, setIsAllUnChecked] = useState(false);
  const [checkedPolicies, setCheckedPolicies] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const decryptCardNumber = (encryptedCardNumber, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedCardNumber, secretKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  };

  useEffect(() => {
    getPolicyInformation();
  }, []);

  const getPolicyInformation = async () => {
    const result = await getPolicyInformationAPI(
      decryptCardNumber(insureIdCard, process.env.REACT_APP_ENCRYPT_DATA)
    );
    if (result.status) {
      setDataMaster(result.data);
      setDataPolicies(result.data.policies);
      setDataRdConsent(result.data.rdConsent);
      setRdConsent(
        result.data.rdConsent.length > 0 ? result.data.rdConsent : []
      );
      setCheckedPolicies(result.data.policies.map(() => false));
      setLoadingContent(false);
      console.log(result.data.policies);
      console.log(result.data.rdConsent);
    }
  };

  const handleSelectAll = () => {
    const newCheckedState = !isAllChecked;
    setIsAllChecked(newCheckedState);
    // setCheckedPolicies(dataPolicies.map(() => newCheckedState));
    // setIsAllUnChecked(false);
    // if (newCheckedState) {
    //   clickAllPolicies();
    // } else {
    //   setListPolicyNo([]);
    // }
  };

  const handleSelectPolicy = (index) => {
    console.log(index);
    const newCheckedPolicies = [...checkedPolicies];
    newCheckedPolicies[index] = !newCheckedPolicies[index];
    setCheckedPolicies(newCheckedPolicies);
    // setIsAllChecked(newCheckedPolicies.every((checked) => checked));
    // setIsAllUnChecked(false);
    console.log(checkedPolicies);
  };

  const handleDeselectPolicy = () => {
    const newCheckedState = !isAllUnChecked;
    // setIsAllUnChecked(newCheckedState);
    // setIsAllChecked(false);
    if (newCheckedState) {
      setCheckedPolicies([]);
      setListPolicyNo([]);
      clickDeselectAllPolicies();
    } else if (!newCheckedState) {
      // clickDeselectAllPoliciesUncheck();
    }
  };

  const clickPolicy = (policyNo) => {
    var arr = [];
    var map = {};

    const filterData = listPolicyNo.find((po) => po.policyNo === policyNo);
    if (filterData) {
      console.log("aleady policy");
      const filterData = listPolicyNo.filter((po) => po.policyNo === policyNo);
      setListPolicyNo(filterData);
      arr.push(filterData);
    } else {
      rdConsent.forEach((value, index) => {
        if (policyNo === value.policyNo) {
          map["policyNo"] = policyNo;
          map["consentFlag"] = value.consentFlag;
          map["consentFlagNew"] = "Y";
        }
        //  console.log(value)
      });
      arr.push(map);
    }

    setListPolicyNo(arr);
    console.log(arr);
  };

  const clickAllPolicies = () => {
    const updatedPolicies = dataPolicies.map((policy) => {
      const consent =
        rdConsent.find((consent) => consent.policyNo === policy.policyNo) || {};
      return {
        policyNo: policy.policyNo,
        consentFlag: consent.consentFlag || "N",
        consentFlagNew: "Y",
      };
    });
    setListPolicyNo(updatedPolicies);
    console.log(updatedPolicies);
  };

  const clickDeselectAllPolicies = () => {
    const updatedPolicies = dataPolicies.map((policy) => {
      const consent =
        rdConsent.find((consent) => consent.policyNo === policy.policyNo) || {};
      return {
        policyNo: policy.policyNo,
        consentFlag: consent.consentFlag || "Y",
        consentFlagNew: "N",
      };
    });
    setListPolicyNo(updatedPolicies);
    console.log(updatedPolicies);
  };

  const clickDeselectAllPoliciesUncheck = () => {
    setListPolicyNo([]);
  };

  const saveData = async () => {
    console.log(listPolicyNo > 0);
    console.log(listPolicyNo);

    if (listPolicyNo.length > 0) {
      setLoading(true);
      try {
        const result = await insertConsentAPI(listPolicyNo);
        console.log("Consent data inserted successfully", result);
        setTimeout(() => {
          setShowSuccess(true);
          setLoading(false);
        }, 1500);
      } catch (error) {
        console.error("Failed to insert consent data", error);
        // alert('การบันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง');
      }
    } else {
      setShowModal(true);
    }
  };

  const goToHome = () => {
    navigate("/home");
  };

  return (
    <div>
      <Header_Home />
      <div className="top">
        <section className="sec-navbar">
          <nav
            style={{
              backgroundColor: "#FF5E43",
              color: "white",
              height: "50px",
            }}
          >
            <div style={{ padding: "10px" }}>
              <h3 className="flex-shrink-1 text-center bold">
                การขอใช้สิทธิยกเว้นภาษีเงินได้
              </h3>
            </div>
          </nav>
        </section>
      </div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
          }}
        >
          <CircularProgress
            sx={{
              animationDuration: "550ms",
            }}
            color="inherit"
          />
        </div>
      )}
      {!showSuccess ? (
        <div
          className="p-4 frame-other"
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "20px auto 0 auto",
            maxWidth: "1600px",
          }}
        >
          <h2
            className="bold"
            style={{ fontSize: "25px", marginLeft: "20px", marginTop: "20px" }}
          >
            เลือกกรมธรรม์ที่ต้องการลดหย่อนภาษี
          </h2>
          <br />
          <>
            <div
              className="body-layout-chkbox-head"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                type="checkbox"
                id="insurance_desire"
                name="insurance_desire"
                style={{ marginRight: "5px" }}
                checked={isAllChecked}
                onChange={handleSelectAll}
              />
              <label htmlFor="insurance_desire" style={{ marginTop: "11px" }}>
                มีความประสงค์ใช้สิทธิลดหย่อนภาษีกรมธรรม์ที่ทำไว้กับ บริษัท แรบบิท ประกันชีวิต จำกัด (มหาชน)
              </label>
            </div>

            <div className="body-layout-text">
              <span style={{ marginLeft: "15px", fontSize: "17px" }}>
                มีความประสงค์ใช้สิทธิ เฉพาะกรมธรรม์ประกันภัยเลขที่ต่อไปนี้
              </span>
            </div>

            {isAllChecked &&
              dataPolicies
                .filter((policy) =>
                  dataRdConsent.some(
                    (item) =>
                      item.policyNo === policy.policyNo &&
                      item.consentFlag === "N"
                  )
                )
                .map((item, idx) => (
                  <div key={idx} className="body-layout-chkbox">
                    <input
                      type="checkbox"
                      id={`insurance_desire_${idx}`}
                      name="insurance_desire"
                      checked={checkedPolicies[idx]}
                      onChange={() => handleSelectPolicy(idx)}
                      onClick={() => clickPolicy(item.policyNo)}
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <label
                        htmlFor={`insurance_desire_${idx}`}
                        style={{ marginLeft: "5px", marginTop: "20px" }}
                      >
                        กรมธรรม์เลขที่ :
                      </label>
                      <span
                        className="value-item"
                        style={{ marginLeft: "10px", marginTop: "11px" }}
                      >
                        {item.policyNo}
                      </span>
                    </div>
                  </div>
                ))}
          </>

          <>
            <div
              className="body-layout-chkbox-head"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                type="checkbox"
                id="insurance_desire"
                name="insurance_desire"
                style={{ marginRight: "5px" }}
                checked={isAllUnChecked}
                onChange={handleDeselectPolicy}
              />
              <label htmlFor="insurance_desire" style={{ marginTop: "11px" }}>
                ไม่มีความประสงค์
              </label>
            </div>
            {isAllUnChecked && dataPolicies
              .filter((policy) =>
                dataRdConsent.some(
                  (item) =>
                    item.policyNo === policy.policyNo &&
                    item.consentFlag === "Y"
                )
              )
              .map((item, idx) => (
                <div key={idx} className="body-layout-chkbox">
                  <input
                    type="checkbox"
                    id={`insurance_desire_${idx}`}
                    name="insurance_desire"
                    checked={checkedPolicies[idx]}
                    // onChange={() => handleSelectPolicy(idx)}
                    onChange={() => handleDeselectPolicy(idx)}
                    onClick={() => clickPolicy(item.policyNo)}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <label
                      htmlFor={`insurance_desire_${idx}`}
                      style={{ marginLeft: "5px", marginTop: "20px" }}
                    >
                      กรมธรรม์เลขที่ :
                    </label>
                    <span
                      className="value-item"
                      style={{ marginLeft: "10px", marginTop: "11px" }}
                    >
                      {item.policyNo}
                    </span>
                  </div>
                </div>
              ))
            }      
            
          </>

          <br />
          <div className="body-layout-text">
            <span>
              การไม่แจ้งความประสงค์ที่จะใช้สิทธิยกเว้นภาษีเงินได้
              ต่อบริษัทอาจทำให้ท่านไม่สามารถหักค่าลดหย่อนภาษี
              เงินได้บุคคลธรรมดาสำหรับเบี้ยประกันภัย ตามข้อกำหนดของกรมสรรพากรได้
            </span>
          </div>
          <br />
          {isAllChecked && (
            <div className="body-layout-text">
              <span className="text-desc">
                ข้าพเจ้ายินยอมให้บริษัท แรบบิท ประกันชีวิต จำกัด (มหาชน)
                นำส่งแลเปิดเผยข้อมูลเกี่ยวกับเบี้ยประกันภัยต่อกรมสรรพากร
                ตามหลักเกณฑ์วิธีการที่กรมสรรพากรกำหนดเพื่อใช้สิทธิ์ยกเว้นภาษีเงินได้
                ตามกฏหมายว่าด้วยภาษีอากร
              </span>
            </div>
          )}
          <br />
          <div style={{ display: "flex", marginLeft: "20px" }}>
            <span className="text-desc">หมายเหตุ</span>
            <div style={{ display: "flex", marginLeft: "23px" }}>
              <span className="text-desc">
                ตามประกาศอธิบดีกรมสรรพกรเกี่ยวกับภาษีเงินได้ ( ฉบับที่ 383 )
                กำหนดให้ผู้มีเงินได้ที่ต้องการนำเบี้ยประกันไปใช้สิทธิลดหย่อนภาษีเงินได้สำหรับปีภาษี
                2561 เป็นต้นไป ต้องแจ้งความประสงค์ที่จะใช้สิทธิ
                และจะยินยอมให้บริษัทฯ
                เปิดเผยข้อมูลเกี่ยวกับเบี้ยประกันภัยต่อกรมสรรพากร ภายในวันที่ 31
                ธันวาคม ของปีภาษีนั้น ๆ
              </span>
            </div>
          </div>
          <br />
          <div className="d-flex justify-content-center flex-wrap">
            <a
              onClick={
                dataRdConsent.some(
                  (item) =>
                    item.consentFlag === "Y" &&
                    dataRdConsent.length > 0 &&
                    dataPolicies.some(
                      (policy) => policy.policyNo === item.policyNo
                    )
                )
                  ? saveData
                  : (e) => e.preventDefault()
              }
              type="button"
              className={`mt-3 btn-confirm ${
                dataRdConsent.some(
                  (item) =>
                    item.consentFlag === "Y" &&
                    dataRdConsent.length > 0 &&
                    dataPolicies.some(
                      (policy) => policy.policyNo === item.policyNo
                    )
                )
                  ? "disabled"
                  : ""
              }`}
              style={{
                minWidth: "100px",
                textAlign: "center",
                cursor: dataRdConsent.some(
                  (item) =>
                    item.consentFlag === "Y" &&
                    dataRdConsent.length > 0 &&
                    dataPolicies.some(
                      (policy) => policy.policyNo === item.policyNo
                    )
                )
                  ? "pointer"
                  : "not-allowed",
                opacity: dataRdConsent.some(
                  (item) =>
                    item.consentFlag === "Y" &&
                    dataRdConsent.length > 0 &&
                    dataPolicies.some(
                      (policy) => policy.policyNo === item.policyNo
                    )
                )
                  ? 1
                  : 0.5,
              }}
            >
              ยืนยัน
            </a>

            <a
              // onClick={clear}
              type="button"
              className="mt-3 btn-back"
              style={{ minWidth: "100px", textAlign: "center" }}
              onClick={goToHome}
            >
              ย้อนกลับ
            </a>
          </div>
          <br />
          <br />
        </div>
      ) : (
        <>
          <div
            className="p-4 frame-other"
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "20px auto 0 auto",
              maxWidth: "1600px",
            }}
          >
            <Typography variant="h3" align="center">
              <img
                src={successGif}
                alt={"confirmGif"}
                style={{
                  height: "auto",
                  marginTop: "5%",
                  marginBottom: "5%",
                  width: "20%",
                }}
              />
              <li
                className="body-list-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Col
                  xl={12}
                  md={12}
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <h3 className="bold">บันทึกข้อมูลสำเร็จ</h3>
                </Col>
              </li>
            </Typography>
            <Row className="d-flex  flex-column mb-5">
              <Col className="d-flex justify-content-center px-5">
                <button
                  onClick={goToHome}
                  type="button"
                  className="btn-ex-back w-sm-100 w-lg-25 p-2 bold"
                >
                  กลับสู่หน้าหลัก
                </button>
              </Col>
            </Row>
          </div>
        </>
        // <SuccessPage />
      )}
      {LoadingContent && (
        <Row style={{ padding: "20px" }}>
          <Col lg={4} md={6} sm={12}>
            <Skeleton height={30} />
          </Col>
        </Row>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header></Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          กรุณาเลือกกรมธรรม์ที่ต้องการทำรายการหรือเลือกไม่มีความประสงค์ !!!
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          <a
            onClick={() => setShowModal(false)}
            type="button"
            className="mt-3 btn-back"
            style={{ minWidth: "100px", textAlign: "center" }}
          >
            Close
          </a>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ExemptIncomeTax;
