import React, { useEffect, useState, useRef } from "react";
import "../../App.css";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import { getParameterAPI } from "../PolicyDetail/PolicyDetailService/policyDetailService";
import {
  getDownloadDoc,
  getFileByApi,
  getFileFromOneDrive,
  downloadLetterDigiCom,
} from "../DownloadDoc/DownloadDocService/downloadDocService";
import imgDoc from "../../images/Arhive_load.png";
import CryptoJS from "crypto-js";
import "../DownloadDoc/downloadDoc-styles.css";
import { Modal, Col, Button } from "react-bootstrap";
import { data } from "jquery";
import CircularProgress from "@mui/material/CircularProgress";

const DownloadDoc = () => {
  const decryptCardNumber = (encryptedCardNumber, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedCardNumber, secretKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  };

  let navigate = useNavigate();
  const [cusTel, setCusTel] = useState("");
  const username = localStorage.getItem("customer_id");
  const [dataDoc, setDataDoc] = useState([]);
  const [checkedPolicies, setCheckedPolicies] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const radiosRef = useRef([]);
  const [showModal, setShowModal] = useState(false);
  const [spinner, setSpinner] = useState();

  useEffect(() => {
    getTel();
    getDataDownloadDoc();
  }, []);

  const getTel = async () => {
    const result = await getParameterAPI(12);
    const payload = result.payload;
    const cusTel = payload.find((item) => item.code === "CUS_SERV_TEL");
    setCusTel(cusTel.variable1);
  };

  const getDataDownloadDoc = async () => {
    setSpinner(true);
    const result = await getDownloadDoc(
      decryptCardNumber(username, process.env.REACT_APP_ENCRYPT_DATA)
    );
    if (result.success) {
      setTimeout(() => {
        setDataDoc(result.data.result);
        setSpinner(false);
      }, 1000);

      console.log(result.data.result);
    }
  };

  const handleSelectPolicy = (file, policyNo, idx) => {
    if (!file.selectedPolicies) {
      file.selectedPolicies = [];
    }

    const isChecked = file.selectedPolicies.includes(policyNo);

    if (isChecked) {
      // Remove the policy from selectedPolicies
      file.selectedPolicies = file.selectedPolicies.filter(
        (policy) => policy !== policyNo
      );
    } else {
      // Add the policy to selectedPolicies
      file.selectedPolicies.push(policyNo);
    }

    // Update checked policies
    setCheckedPolicies((prevPolicies) => {
      const updatedPolicies = isChecked
        ? prevPolicies.filter(
            (policy) => !(policy.policy_no === policyNo && policy.file === file)
          )
        : [...prevPolicies, { policy_no: policyNo, file }];

      // Check if all policies in the current file are selected
      const allPoliciesInFileChecked =
        dataDoc[idx]?.policies &&
        file.selectedPolicies.length === dataDoc[idx].policies.length;

      // Update the allChecked state based on whether all policies are selected
      // const allChecked = dataDoc.every(
      //   (doc) =>
      //     doc.policies &&
      //     doc.policies.every((policy) =>
      //       updatedPolicies.some(
      //         (checked) =>
      //           checked.policy_no === policy.policyNo && checked.file === doc
      //       )
      //     )
      // );

      const allChecked = dataDoc.every((doc) =>
        updatedPolicies.some((policy) => policy.policy_no === doc.policy_no)
      );

      setIsAllChecked(allChecked);

      // If all policies in the current file are checked, also check the overall checkbox
      if (allPoliciesInFileChecked) {
        setCheckedPolicies((prev) => [
          ...prev,
          ...dataDoc[idx].policies.map((policy) => ({
            policy_no: policy.policyNo,
            file,
          })),
        ]);
      }

      return updatedPolicies;
    });
  };

  const handleSelectAll = () => {
    const newCheckedState = !isAllChecked;
    setIsAllChecked(newCheckedState);

    if (newCheckedState) {
      const allPolicies = dataDoc.map((item) => ({
        policy_no: item.policy_no,
        file: item.file,
      }));
      setCheckedPolicies(allPolicies);

      // Update selectedPolicies for each item
      dataDoc.forEach((item) => {
        if (!item.file.selectedPolicies) {
          item.file.selectedPolicies = [];
        }
        item.file.selectedPolicies = [item.policy_no];
      });
    } else {
      setCheckedPolicies([]);

      // Clear selectedPolicies for each item
      dataDoc.forEach((item) => {
        item.file.selectedPolicies = [];
      });
    }
  };

  const getFileFromOneDriveAPI = async (fileName) => {
    // if (!fileName.toLowerCase().endsWith(".pdf")) {
    //   fileName += ".pdf";
    // }

    console.log(fileName);
    const result = await getFileFromOneDrive(fileName);
    if (result.status) {
      console.log(result.downloadUrl);
      window.open(result.downloadUrl, "_blank");
    } else {
      setShowModal(true);
    }
  };

  const downloadDocument = async (policyNo, receiptNo, fileName, flag) => {
    console.log(policyNo);
    console.log(receiptNo);
    console.log(fileName);
    console.log(flag);
    console.log(dataDoc);
    try {
      if (flag == "api") {
        await getFileByApi(policyNo, receiptNo, fileName);
      } else if (flag == "digital_com") {
        await downloadLetterDigiComAPI(fileName);
      } else {
        console.log(`Downloading from OneDrive: ${fileName}`);
        await getFileFromOneDriveAPI(fileName);
      }
    } catch (error) {
      console.error("Error during file download:", error);
    }
  };

  const findFileByPolicyNo = (policyNo, fileName) => {
    const foundPolicy = dataDoc.find((doc) => doc.policy_no === policyNo);
    if (foundPolicy) {
      const foundFile = foundPolicy.file.find(
        (file) => file.file_name === fileName
      );
      return foundFile ? foundFile : null;
    }
    return null;
  };

  const handleDownload = (policyNo, fileName, flag) => {
    setSpinner(true);

    const fileData = findFileByPolicyNo(policyNo, fileName);
    if (fileData) {
      console.log("พบไฟล์:", fileData.file_name, fileData.receipt_no);
      downloadDocument(policyNo, fileData.receipt_no, fileData.file_name, flag);
      setTimeout(() => {
        setSpinner(false);
      }, 1000);
    } else {
      console.log("ไม่พบไฟล์ที่ต้องการ");
    }
  };

  const downloadLetterDigiComAPI = async (fileName) => {
    // if (!fileName.toLowerCase().endsWith(".pdf")) {
    //   fileName += ".pdf";
    // }
    console.log(fileName);
    const result = await downloadLetterDigiCom(fileName);
    if (result.status) {
      console.log(result.downloadUrl);
      window.open(result.downloadUrl, "_blank");
    } else {
      setShowModalNotFoundFile(true);
    }
  };

  return (
    <div>
      <Header_Home />
      <Header title="ดาวน์โหลดเอกสาร" />
      {spinner && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
          }}
        >
          <CircularProgress
            sx={{
              animationDuration: "550ms",
            }}
            color="inherit"
          />
        </div>
      )}
      <div className="table-page-container">
        <div style={{ marginLeft: "20px" }}>
          <span>
            ท่านสามารถดาวน์โหลดหนังสือรับรองชำระเบี้ยประกัน ใบเสร็จรับเงิน
            และจดหมายต่างๆ โดยเลือกกรมธรรม์ที่ท่านต้องการ
          </span>
        </div>
        <div className="p-4 frame-other">
          <div
            className="body-layout-chkbox-head"
            style={{ display: "flex", alignItems: "center" }}
          >
            <input
              type="checkbox"
              id="insurance_desire"
              name="insurance_desire"
              style={{ marginRight: "5px" }}
              checked={isAllChecked}
              onChange={handleSelectAll}
            />
            <label htmlFor="insurance_desire" style={{ marginTop: "11px" }}>
              ทุกกรมธรรม์
            </label>
          </div>
          <div>
            {dataDoc
              .slice() // Make a shallow copy to avoid mutating the original data
              .sort((a, b) => a.policy_no.localeCompare(b.policy_no)) // Sort by policy_no
              .map((item, idx) => (
                <div key={idx} className="body-layout-chkbox-head">
                  <input
                    ref={(el) => (radiosRef.current[idx] = el)}
                    type="checkbox"
                    id={`insurance_desire_${idx}`}
                    name="insurance_desire"
                    checked={checkedPolicies.some(
                      (policy) => policy.policy_no === item.policy_no
                    )}
                    onChange={() =>
                      handleSelectPolicy(item.file, item.policy_no, idx)
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <label
                      htmlFor={`insurance_desire_${idx}`}
                      style={{ marginLeft: "5px", marginTop: "20px" }}
                    >
                      กรมธรรม์เลขที่ :
                    </label>
                    <span
                      className="value-item"
                      style={{ marginLeft: "10px", marginTop: "11px" }}
                    >
                      {item.policy_no}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="mt-3" style={{ marginLeft: "20px" }}>
          <span className="text-description">
            ท่านสามารถดาวน์โหลดหนังสือรับรองการชำระเบี้ยประกันย้อนหลังได้ 2 ปี
            หากท่านต้องการเอกสารก่อนช่วงเวลาดังกล่าว
            กรุณาติดต่อฝ่ายลูกค้าสัมพันธ์ {cusTel}
          </span>
        </div>
        <div style={{ padding: "10px", marginTop: "20px", marginLeft: "10px" }}>
          <h3 className="flex-shrink-1 bold">
            เอกสาร{" "}
            <img className="image align-self-center" src={imgDoc} alt="" />
          </h3>
        </div>

        <div
          className="p-4 frame-doc"
          style={{
            height: "auto",
            maxHeight: "auto",
            overflowY: "auto",
          }}
        >
          {checkedPolicies.length > 0
            ? checkedPolicies.map((item1, idx1) =>
                item1.file.length > 0
                  ? item1.file.map((item2, idx2) => (
                      <div key={idx2}>
                        <ul
                          className="list-group"
                          style={{ marginBottom: "10px" }}
                        >
                          <li
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginRight: "10px",
                              fontSize: 15,
                            }}
                          >
                            <span className="light">{item2.file_name}</span>
                            <span
                              className="bold download-reciept pointer"
                              onClick={() =>
                                handleDownload(
                                  item1.policy_no,
                                  item2.file_name,
                                  item2.flag
                                )
                              }
                            >
                              ดาวน์โหลด
                            </span>
                          </li>
                        </ul>
                      </div>
                    ))
                  : null
              )
            : "กรุณาเลือกกรมธรรม์"}
        </div>
        <br />
        <br />
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header></Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            fontSize: "25px",
          }}
        >
          {/* <img
              src={imgTrash}
              style={{
                width: "50px",
                height: "50px",
              }}
            /> */}
          <p style={{ marginTop: "20px" }}>ไม่พบไฟล์ที่ต้องการ</p>
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          <a
            onClick={() => setShowModal(false)}
            type="button"
            className="mt-3 btn-back"
            style={{ minWidth: "100px", textAlign: "center" }}
          >
            ปิด
          </a>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DownloadDoc;
