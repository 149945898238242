import React, { Component } from 'react';
import Login from './Login';
import Registration from './Registration';
import OTP from './otp';

export class Main extends Component {
  constructor(props) {
    super(props);
    this.state=  {
      step: 1,
      username: '',
      password: '',
      confirm_password: '',
      otp: '',
    };
}
    // state =
  // Proceed to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  };

  // Go back to login_page
  login_page = () => {
    const { step } = this.state;
    this.setState({
      step: 3
    });
  };
  // Go back to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  // Handle fields change
  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };


  render() {
    const { step } = this.state;
    const { username, password, otp, confirm_password} = this.state;
    const values = {username, password, confirm_password, otp};
    // alert(step)
    switch (step) {

      case 1:
        return (
          <Login
          nextStep={this.nextStep}
          handleChange={this.handleChange}
          prevStep={this.prevStep}/>
        );
      case 2: 
        return (
          <Registration
          nextStep={this.nextStep}
          handleChange={this.handleChange}
          values={values}
          prevStep={this.prevStep}/>
        );
      case 3:
        return (
          <OTP
          nextStep={this.nextStep}
          handleChange={this.handleChange}
          login_page={this.login_page}
          values={values}/>
        );
      default:
        (console.log('This is a multi-step form built with React.'))
    }
  }
}

export default Main;
