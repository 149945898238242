import React, { Component } from "react";
import '../../App.css';
import success_icon from '../../images/success.png'
import successGif from "../../images/successGiff.gif";
export default class SuccessPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agent: {},
            class_lv: 'col-6',
            showSuccess: true

        }
        // setInterval(() => this.tick() ,1000)
        // setInterval(() => console.log('con') ,1000)
        // console.log('con'+ props.username)
    }
    handleClick = () => {
        this.props.toggle();
    };

    handleBackClick = () => {
        this.props.onConfirm(this.props.showSuccess);
    };

    handleShowSuccess = (showSuccess) => {
        this.setState({ showSuccess: showSuccess });
    }

    async componentDidMount() {
        console.log('componentDidMount', this.props.showSuccess)
    }
    componentDidUpdate() {

        console.log('PopUp')
    }
    render() {

        return (
            <div className="card frame-other"
            style={{
                display: "flex",
                flexDirection: "column",
                margin: "20px auto 0 auto",
                maxWidth: "1600px",
                justifyContent: "space-around",
                marginTop: "50px"
              }} >
                <img src={successGif} alt="success" style={{ display: "block", marginTop: "20px", marginLeft: "auto", marginRight: "auto", width: "150px", height: "150px" }} />
                <br />
                <br />
                <div className="d-flex justify-content-center flex-wrap">
                    <h1 style={{ fontWeight: "bold" }}>บันทึกข้อมูลสำเร็จ</h1>
                </div>
                <br/>
                <div className="d-flex justify-content-center flex-wrap">
                    <a href="/home" type="button" className="mt-3 btn-back" style={{ textAlign: "center", display: "inline-block", width: "fit-content" }}>กลับสู่หน้าหลัก</a>
                </div>
                <br />
                <br />

            </div>


        );
    }
}
