import React, { Component } from "react";
import '../../App.css';
export default class PopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
        agent:{},
        class_lv: 'col-6',

    }
        // setInterval(() => this.tick() ,1000)
        // setInterval(() => console.log('con') ,1000)
        // console.log('con'+ props.username)
    }
    handleClick = () => {
        this.props.toggle();
    };

    async componentDidMount() {
        console.log('componentDidMount',this.props)
    }
    componentDidUpdate() {

        console.log('PopUp')
    }
    render() {

        return (
        <div className="md">
            <div className="md_content">
            
            <div className="row col-12 no-margin">
                <div>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/react/15.4.1/react.js"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/react/15.4.1/react-dom.js"></script>
                    <div id="app" class="loader" ></div>
                </div>
                
            </div>
            </div>
        </div>
        );
    }
}
