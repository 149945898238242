export const mockDataBankName = [
  "ธนาคารกสิกรไทย (KBank)",
  "ธนาคารกรุงเทพ (Bangkok Bank)",
  "ธนาคารกรุงไทย (KTB)",
  "ธนาคารไทยพาณิชย์ (SCB)",
  "ธนาคารกรุงศรี (SCB)",
  "ธนาคารกรุงศรีอยุธยา (BAY)",
  "ธนาคารซีไอเอ็มบี (CIMB)",
  "ธนาคารทหารไทยธนชาต (TMBThanachart)",
  "ธนาคารออมสิน (GSB)",
  "ธนาคารยูโอบี (UOB)",
  "ธนาคารทิสโก้ (TISCO)",
  "อื่นๆ"

  
];

export const mockDataHistory = [
  {
    ageType: "under 7",
    name: "สมชาย คายข้าว",
    citizenId: "8888888888888",
    phoneNumber: "0000000000",
    bankName: "ธนาคารกรุงเทพ (Bangkok Bank)",
    bankNumber: "1234567890123",
    statusKYC: "N",
    statusVerification: "false",
    copyIdentityDocument: "ใบเกิด เด็กชายสมชาย.png",
    imageOwner: "รูปเด็กชายสมชาย.png",
    copyParentIdCard: "บัตรประชาชน คุณแม่ของเด็กชายสมชาย.png",
    imageParent: "รูปคุณแม่เด็กชายสมชาย.png",
    copyHouseRegistration: "สำเนาทะเบียนบ้าน เด็กชายสมชาย.png",
    copyBookBank: "สมุดบัญชี เด็กชายสมชาย.png",
    status: "ไม่สำเร็จ",
  },
  {
    ageType: "under 20",
    name: "สมหญิง จริงหรือ",
    citizenId: "1560100333333",
    phoneNumber: "0987654321",
    bankName: "ธนาคารกรุงไทย (KTB)",
    bankNumber: "1234567890123",
    statusKYC: "N",
    statusVerification: "true",
    copyIdentityDocument: "บัตรประชาชน เด็กชายสมหญิง.png",
    imageOwner: "รูปเด็กชายสมหญิง.png",
    copyParentIdCard: "บัตรประชาชน คุณพ่อของเด็กชายสมหญิง.png",
    imageParent: "รูปคุณพ่อเด็กชายสมหญิง.png",
    copyHouseRegistration: "สำเนาทะเบียนบ้าน เด็กชายสมหญิง.png",
    copyBookBank: "สมุดบัญชี เด็กชายสมหญิง.png",
    status: "สำเร็จ",
  },
  {
    ageType: "20 or above",
    name: "สมบูรณ์ นูนต่ำ",
    citizenId: "1560100333333",
    phoneNumber: "0987654321",
    bankName: "ธนาคารทหารไทย (TMB)",
    bankNumber: "1234567890123",
    statusKYC: "N",
    statusVerification: "false",
    copyIdentityDocument: "บัตรประชาชน นายสมบูรณ์.png",
    imageOwner: "รูปนายสมบูรณ์.png",
    copyParentIdCard: undefined,
    imageParent: undefined,
    copyHouseRegistration: undefined,
    copyBookBank: "สมุดบัญชี นายสมบูรณ์.png",
    status: "สำเร็จ",
  },
];
export const mockDataTest = [
  {
    cus_uuid: "d11dc683-8ef5-4f5c-b7da-6d418aa47f4d",
    cus_psnid: "5555555555555",
    cus_nm: "สมหมาย",
    cus_ln: "ทดสอบ",
    cus_tel: "0655897340",
    cuscf_bank: {},
    cuscf_banknum: {},
    cus_dateexp: "28/05/2567",
    cus_check: {},
    status: "Y",
    age_range: "MST011",
    file: [
      {
        doc_code: "MST011_1",
        file_name: "Screenshot 2567-08-07 at 11.50.29.png",
        link_files: "https://rabbitlife-my.sharepoint.com/:i:/g/personal/a-sync_rabbitlife_co_th/Efhx79NKzEtMjFnKTHdg_QoBxXz7IRQeYGyToj6P2ru20w",
        file_id: "01RIRT3U7YOHX5GSWMJNGIYWOKJR3WB7IK"
      },
      {
        doc_code: "MST011_2",
        file_name: "jake-weirick-l2_h97Lpxtk-unsplash.jpg",
        link_files: "https://rabbitlife-my.sharepoint.com/:i:/g/personal/a-sync_rabbitlife_co_th/EYGajT9qrohFvjOMBFQ2zuoBhrLMCgWR-GAxsmgiz8sUxw",
        file_id: "01RIRT3U4BTKGT62VORBC34M4MARKDNTXK"
      },
      {
        doc_code: "MST011_3",
        file_name: "pexels-cliford-mervil-2469122.jpg",
        link_files: "https://rabbitlife-my.sharepoint.com/:i:/g/personal/a-sync_rabbitlife_co_th/EVN05Se42jxOqOtwpHFndNUBGeEpP1-jFX0-pxsZj6k2lg",
        file_id: "01RIRT3U2TOTSSPOG2HRHKR23QURYWO5GV"
      }
    ]
  },
  {
    cus_uuid: "3dcd7454-86e2-4777-9193-0547d2aea768",
    cus_psnid: "2222222222222",
    cus_nm: "สมฤดี",
    cus_ln: "ทดสอบ",
    cus_tel: "0812222222",
    cuscf_bank: {},
    cuscf_banknum: {},
    cus_dateexp: "28/02/2567",
    cus_check: {},
    status: "Y",
    age_range: {},
    file: [
      {
        doc_code: {},
        file_name: {},
        link_files: {},
        file_id: {}
      }
    ]
  },
  {
    cus_uuid: "314a7a14-759a-4517-9579-5190db55f43a",
    cus_psnid: "4444444444444",
    cus_nm: "สมคิด",
    cus_ln: "ทดสอบ",
    cus_tel: "0855555555",
    cuscf_bank: {},
    cuscf_banknum: {},
    cus_dateexp: "28/04/2567",
    cus_check: {},
    status: "Y",
    age_range: {},
    file: [
      {
        doc_code: {},
        file_name: {},
        link_files: {},
        file_id: {}
      }
    ]
  }
];
