import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { Form } from "react-bootstrap";
import axios from 'axios';
// import Header_Home from '../Component/Header_Home';
// import Left_bar from '../Component/Left_bar';
// import ReactPaginate from 'react-paginate';
// import img_profile from '../../images/profile.svg'
import Header_Home from '../Component/Header_Home';
class Request_RD_comp extends Component {
    constructor(props) {
        super(props);
        this.state = { }
    }

    componentDidMount() {

        console.log('componentDidMount')
    }


    componentDidUpdate() {
       
        console.log('componentDidUpdate')
    }

    componentWillUnmount() {
        console.log('componentWillUnmount')
       
    }

    


 
    render() {
        return (
            <div >
                <Header_Home/>
                <section className="d-flex justify-content-center align-items-center h-100 my-5">
                    <div className="container-fluid  container-margin">
                        <div className='row'>
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>ส่งคำร้องขอใช้สิทธิ ลดหย่อนภาษี เรียบร้อย</strong></label>
                                            </div>
                                        </div>      
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 ">
                                            <div className="col-2 ">
                                                    {/* <label htmlFor="subject" className='text-righ mt-2'><strong>Email</strong></label> */}
                                            </div>
                                            <div className="col-10">
                                               

                                                <div className="card-body list-group">
                                                    <div className="row col-10 ">
                                                        <div className="card p-0">  
                                                            {/* <div className="card-body header-card">
                                                            <label className='text-righ mt-2'><strong>เอกสารแนบ </strong></label>
                                                            </div>  */}
                                                            <ul className="list-group list-group-flush">
                                                            <li className="list-group-item">
                                          
                                                            <span className="bold">แจ้งความประสงค์ลดหย่อนภาษี</span><br/><br/>
                                                 
                                                            ตามประกาศอธิบดีกรมสรรพากรเกี่ยวกับภาษีเงินได้(ฉบับที่ 383) กำหนดให้ผู้มีเงินได้ที่ต้องการนำเบี้ยประกันไปใช้สิทธิลดหย่อนภาษีเงินได้สำหรับปีภาษี 2561 เป็นต้นไป 
                                                            ต้องแจ้งความประสงค์ที่จะใช้สิทธิ และยินยอมให้บริษัทฯ เปิดเผยข้อมูลเกี่ยวกับเบี้ยประกันต่อสรรพากร
                                                            ท่านสามารถแจ้งความประสงค์ที่จะใช้สิทธิลดหย่อยภาษีเงินได้ โดยคลิกเริ่มทำรายการ	
                                                         
                                                            </li>
                                                                           
                                                        </ul>
                                                        
                                                            
                                                        </div>   
                                                    </div>              
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body App">

                                        <a href="/request"  className="btn btn-lg btn-blue">เรียบร้อย</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    

                        {/* <div className='App'>
                            <a  href="/home" type="button" className="btn btn-lg btn mt-3 cust-btn"  >ย้อนกลับ</a>
                        </div> */}
                        </div> 
                </section>
            </div> 
        )
    }
        
    
}

export default Request_RD_comp;