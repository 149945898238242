import React, { useContext, useState } from "react";
import img_1 from "../../../images/logo_consent.png";

import "./components.css";
import Checkbox from "@mui/material/Checkbox";
import TransferContext, {
  TransferProvider,
} from "../TranferContextProvider.js";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";

const label1 = { inputProps: { "aria-label": "Checkbox Consent1" } };
const label2 = { inputProps: { "aria-label": "Checkbox Consent2" } };

const UserConsent = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [checked, setChecked] = useState(true);
  const [enableButton, setEnableButton] = useState(true);
  const [checkBox1Enable, setCheckbox1Enable] = useState(false);
  const [checkBox2Enable, setCheckbox2Enable] = useState(false);

  const { activeStep, setActiveStep, skipped, setSkipped } =
    useContext(TransferContext);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handlerConsent1 = (event) => {
    if (event.target.checked) {
      setEnableButton(false);
      setCheckbox2Enable(true);
    } else {
      setEnableButton(true);
      setCheckbox2Enable(false);
    }
  };

  const handlerConsent2 = (event) => {
    if (event.target.checked) {
      setEnableButton(true);
      setCheckbox1Enable(true);
    } else {
      setEnableButton(true);
      setCheckbox1Enable(false);
    }
  };
  return (
    <>
      <div className="middle-item">
        <img src={img_1} height={isSmallScreen ? "120" : "160"} alt="" />
        <div
          style={{
            flex: 1,
            flexDirection: "column",
            marginTop: 40,
            justifyContent: "start",
            width: "100%",
          }}
        >
          <div style={{ marginTop: 20, textAlign: "left" }}>
            <p><strong>Rabbit Pay</strong></p>
            <p>1. ชื่อเจ้าของบัญชีจะต้องเป็นบุคคลเดียวกับเจ้าของกรมธรรม์เท่านั้น</p>
            <p>2. ในกรณีที่บริษัทไม่สามารถนำเงินเข้าบัญชีธนาคารตามที่ระบุไว้ข้างต้นได้ไม่ว่าด้วยเหตุผลใดๆก็ตาม ข้าพเจ้ายินดีรับเงินผลประโยชน์ตามกรมธรรม์ประกันภัยเป็นเช็คตามวิธีปกติหลังจากบริษัทได้ทราบผลการโอนเงินเข้าบัญชีจากทางธนาคาร</p>
            <p>3. หากมีการเปลี่ยนแปลงใดๆที่เกี่ยวกับบัญชีธนาคารที่ระบุไว้ในวรรคต้น ข้าพเจ้าจะดำเนินการแจ้งแก่บริษัทเป็นลายลักษณ์อักษรล่วงหน้าอย่างน้อย 1 เดือนก่อนวันครบรอบปีกรมธรรม์</p>
            <p>4. ข้าพเจ้าทราบว่า บริษัทจะนำเงินผลประโยชน์ที่เกิดขึ้นตามกรมธรรม์เข้าบัญชีธนาคารของข้าพเจ้าเมื่อบริษัทได้ตรวจสอบและบันทึกข้อมูลเสร็จสิ้นแล้วเท่านั้น</p>
            <p>5. กรณีผู้เอาประกันภัยเป็นผู้เยาว์ ข้าพเจ้ารับทราบว่า เมื่อผู้เอาประกันภัยบรรลุนิติภาวะแล้ว แต่ยังไม่มีการแจ้งเปลี่ยนบัญชีธนาคารให้เป็นชื่อของผู้เอาประกันภัย บริษัทจะจ่ายเงินผลประโยชน์เป็นเช็คตามวิธีปกติให้แก่ผู้เอาประกันภัยดังกล่าว</p>
            <p>6. ข้าพเจ้ายินยอมให้บริษัท ยกเลิก หรือเปลี่ยนแปลงการนำเงินผลประโยชน์ตามกรมธรรม์ประกันภัยเข้าบัญชีธนาคารของข้าพเจ้าได้ทุกเมื่อ กรณียกเลิก บริษัทจะจ่ายเงินผลประโยชน์เป็นเช็คตามวิธีปกติแทน</p>
            <p>7. ข้าพเจ้ายินยอมให้บริษัท และ/หรือ ธนาคารที่ข้าพเจ้าได้แจ้งความจำนงขอรับบริการข้างต้น ใช้หรือเปิดเผยข้อมูลดังกล่าวของข้าพเจ้าเพื่อประโยชน์ในการยืนยันตัวตนของข้าพเจ้า และเพื่อ ตรวจสอบความถูกต้องของข้อมูล และยินยอมให้บริษัท บันทึก ปรับปรุง ข้อมูลเบอร์ติดต่อ เพื่อใช้ในการติดต่อสื่อสารครั้งต่อไป</p>
          </div>
          {/* <div style={{ flex: 1, flexDirection: "column" }}>
            <Checkbox
              {...label1}
              onChange={handlerConsent1}
              disabled={checkBox1Enable}
            />
            <span className="consent-font-size">ยินยอม</span>
          </div>
          <div style={{ flex: 1, flexDirection: "column" }}>
            <Checkbox
              {...label2}
              onChange={handlerConsent2}
              disabled={checkBox2Enable}
            />
            <span className="consent-font-size">ไม่ยินยอม</span>
          </div> */}
          <div style={{ display: "flex", justifyContent: "left", alignItems: "center", marginTop: 20 }}>
            <div style={{ display: "flex", alignItems: "center", marginRight: 20 }}>
              <Checkbox
                {...label1}
                onChange={handlerConsent1}
                disabled={checkBox1Enable}
              />
              <label className="consent-font-size" style={{ marginLeft: 8 ,marginTop:10}}>ยินยอม</label>
            </div>

            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                {...label2}
                onChange={handlerConsent2}
                disabled={checkBox2Enable}
              />
              <label className="consent-font-size" style={{ marginLeft: 8 ,marginTop:10}}>ไม่ยินยอม</label>
            </div> */}
          </div>

        </div>
        <button
          style={{ fontSize: 15 }}
          onClick={handleNext}
          className="btn-checkout-premium w-100 p-2 bold"
          disabled={enableButton}
        >
          ยอมรับ
        </button>
        <button
          className="btn-checkout-back w-100 p-2 bold"
          onClick={handleBack}
        >
          กลับ
        </button>
        <br />
        <br />
        <br />

      </div>
    </>
  );
};

export default UserConsent;
