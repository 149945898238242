import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Backdrop, CircularProgress, Typography, Card, CardContent } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Checkbox, FormControlLabel, Box } from '@mui/material';
import { styled } from '@mui/system';
import Badge from '@mui/material/Badge';
import { NoSsr } from '@mui/base/NoSsr';
import ScrollableCardPhone from './ScrollableCardPhone'
// CSS

import TaskRoundedIcon from '@mui/icons-material/TaskRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import { useSwipeable } from 'react-swipeable';
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./edit_phone_v2.css";
// component
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import PopUp from "../Component/Popup";
import { CustomCarousel } from "../Component/CustomCarousel";
import { CustomInputText } from "../Component/CustomInputText";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
// services
import {
  getPersonalPhoneAPI,
  getUserProfileAPI,
  updatePersonalPhoneAPI,
} from "./PersonalService/personalService";
// icon
import icon_smartphone_call from "../../Icon/smartphone-call.png";
import { Carousel } from "react-responsive-carousel";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Modal from "react-modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import iconSearch from "../../images/icon-search-2.png";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import Skeleton from "react-loading-skeleton";
import { Modal as BootstrapModal } from "react-bootstrap";
import greenCheck from "../../Icon/green-circle-check.png";
import remove from "../../images/remove.png";
import MultipleSelectPhone from "./MultipleSelectPhone";
import { CardHeader } from '@mui/material';
const EditPhone2 = (props) => {
  let navigate = useNavigate();

  const refCarousel = useRef(null);

  const [phoneStep, setPhoneStep] = useState(1);
  const [phoneData, setPhoneData] = useState([]);
  const [selectedPhone, setSelectedPhone] = useState([]);
  const [loading, setLoading] = useState(true); // for get profile, get phone, save edit phone
  const [option, setOption] = useState([]);

  const [mobilePhone, setMobilePhone] = useState();
  const [homePhone, setHomePhone] = useState();
  const [workPhone, setWorkPhone] = useState();
  const [homePhoneTo, setHomePhoneTo] = useState();
  const [workPhoneTo, setWorkPhoneTo] = useState();
  const [otherPhone, setOtherPhone] = useState();
  const [inputErrors, setInputErrors] = useState([]);
  const breakPoints = [{ width: 1, itemsToShow: 1 }];
  const [activeIndex, setActiveIndex] = useState(0);
  const [clickPre, setClickPre] = useState();
  const [clickForward, setClickForward] = useState();
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const [searchBasePolicy, setSearchBasePolicy] = useState("");
  const [activeDataModal, setActiveDataModal] = useState(0);
  const [completedModal, setCompletedModal] = useState(false);
  const [textModal, setTextModal] = useState("");
  const [toggleImg, setToggleImg] = useState(false);

  const [selectedIndexes, setSelectedIndexes] = useState([]); // เก็บ index ของ Box ที่ถูกเลือกเป็น array
  const [isChecked, setIsChecked] = useState(false); // สถานะของ CheckAll
  const [listData, setListData] = useState([]); // เก็บข้อมูลที่ถูกเลือก

  const [inputData, setInputData] = useState([{}]);

  const handleInputDataChange = (data) => {
    setInputData(data);
  };


  const handleCloseModal = () => {
    setOpenModal(false);
    setSearchBasePolicy("");
  };

  const handleSelectSearch = (idx) => {
    setActiveDataModal(idx);
  };
  // ----- function for Step 1 update phone -----
  const getPersonalPhone = () => {
    setLoading(true);
    // setErrorPhoneData(false);
    getPersonalPhoneAPI()
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let option = [];

          res.payload.map((item, idx) => {
            let dataTmp = {
              label: item.policy_no,
              id: idx,
            };
            option.push(dataTmp);
          });
          setOption(option);
          setPhoneData(res.payload);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setToggleImg(false);
        setTextModal("ไม่สามารถเข้าถึงข้อมูลได้");
        setCompletedModal(true);
      });
  };

  const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  // ----- function for Step 2 confirm -----
  // function isObjectEmpty(obj) {
  //   return Object.keys(obj).every(key => obj[key] !== '' && obj[key] !== null && obj[key] !== undefined);
  // }
  const validatePhone = () => {
    // console.log("-----------------");
    // console.log(inputData==null)
    // const errors = [];
    var error = false

    if (isEmptyObject(inputData)) {
      error = true
      setToggleImg(false);
      setTextModal("กรุณาเลือกประเภทโทรศัพท์ที่ท่านต้องการเปลี่ยน");
      setCompletedModal(true);
    } else {
      // console.log(inputData.mobile)



      if (listData.length == 0) {
        error = true
        setToggleImg(false);
        setTextModal("กรุณาเลือกกรมธรรม์อย่างน้อย 1 รายการ");
        setCompletedModal(true);
      } else {
        return error;
      }
    }

  };

  const removeInputError = (inputId) => {
    setInputErrors((errors) => errors.filter((err) => err !== inputId));
  };

  const savePhone = () => {
    setLoading(true);
    // setErrorSavePhoneData(false);
    console.log(listData)
    const errorsPhone = validatePhone();
    if (selectedPhone.length > 0 && !errorsPhone) {
      const payload = { policies: [] };
      listData.forEach((item) => {
        payload.policies.push({
          policy_no: item.policy_no,
          policy_number: item.id,
          mobile: item.mobile !== undefined ? item.mobile : "",
          phone_home: inputData.phone_home !== undefined ? inputData.phone_home[0] : "",
          phone_office: inputData.phone_office !== undefined ? inputData.phone_office[0] : "",
          phone_home_to: inputData.phone_home !== undefined ? inputData.phone_home[1] : "",
          phone_office_to: inputData.phone_office !== undefined ? inputData.phone_office[1] : "",
          phone_other: inputData.phone_other !== undefined ? inputData.phone_other : "",

          old_mobile: item.old_mobile,
          old_phone_office: item.old_phone_office,
          old_phone_home: item.old_phone_home,
          old_phone_office_to: item.old_phone_office_to,
          old_phone_home_to: item.old_phone_home_to,
          old_phone_other: item.old_phone_other,
        });
      });
      // console.log("payload to submit >>>", payload);
      updatePersonalPhoneAPI(payload)
        .then((res) => {
          setLoading(false);
          if (res.success) {
            setToggleImg(true);
            setTextModal("บริษัทได้รับทราบข้อมูลจากท่านแล้ว ขอบคุณค่ะ");
            setCompletedModal(true);
          } else {
            console.error("fail Save Phone Data", res);
            // setErrorSavePhoneData(true);
            // setOpenErrorSavePhone(true);
            setToggleImg(false);
            setTextModal("ไม่สามารถบันทึกข้อมูลได้");
            setCompletedModal(true);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          // setErrorSavePhoneData(true);
          // setOpenErrorSavePhone(true);
          setToggleImg(false);
          setTextModal("ไม่สามารถบันทึกข้อมูลได้");
          setCompletedModal(true);
        });
    }
  };

  const filteredOptions = option.filter((item) => {
    return searchBasePolicy.trim() === ""
      ? true
      : item.label.toLowerCase().includes(searchBasePolicy.toLowerCase());
  });

  const renderOptionRow = (val, idx) => {
    const isActive = activeDataModal === idx;

    return (
      <div key={idx}>
        <Row
          onClick={!isActive ? () => handleSelectSearch(idx) : undefined}
          style={isActive ? { backgroundColor: "#FF5E43" } : {}}
          className={`gap-1 border-top border-bottom border-1 rounded-1 ${isActive ? "text-white" : "text-danger pointer"
            }`}
        >
          <Col className="px-3 d-flex flex-row justify-content-between">
            <span>{val.label}</span>
            {isActive && (
              <ArrowCircleLeftOutlinedIcon style={{ color: "white" }} />
            )}
          </Col>
        </Row>
      </div>
    );
  };



  useEffect(() => {
    const updatedList = listData.map((item) => ({
      ...item,
      policy_no: item.policy_no,
      mobile: inputData.mobile ?? "",
      phone_home: inputData.phone_home?.[0] ?? "",
      phone_office: inputData.phone_office?.[0] ?? "",
      phone_home_to: inputData.phone_home?.[1] ?? "",
      phone_office_to: inputData.phone_office?.[1] ?? "",
      phone_other: inputData.phone_other ?? "",

    }));
    console.log("updatedList:", updatedList);
    setListData(updatedList);
  }, [inputData]);

  useEffect(() => {
    // check user profile





    setLoading(true);
    getUserProfileAPI()
      .then((res) => {
        setLoading(false);
        if (res.success == "true") {
          if (res.payload.profile == "POS2") {
            // window.location.href = "/home";
            navigate("/home");
          }
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        // setErrorProfileData(true);
        // setOpenErrorWarning(true);
        // alert("ไม่สามารถเข้าถึงข้อมูลได้");
        setToggleImg(false);
        setTextModal("ไม่สามารถเข้าถึงข้อมูลได้");
        setCompletedModal(true);
      });
    // get Personal Phone
    getPersonalPhone();

    return () => {
      console.log("useEffect return unmount >>>");
    };
  }, []);

  const handleConfrimSearch = () => {
    console.log("activeDataModal", activeDataModal);
    setActiveIndex(activeDataModal);
    // updateDataPolicyActive(activeDataModal);
    handleCloseModal();
    setUpSelectPhone(activeDataModal);
  };

  const handleSearchBasePolicy = (e) => {
    setSearchBasePolicy(e);
  };

  const setUpSelectPhone = (index) => {
    setSelectedPhone((prev) => {
      let findPhone = prev.find((i) => i.id === phoneData[index].id);
      if (findPhone) {
        return prev.filter((ii) => ii.id !== findPhone.id);
      } else {
        // return [...prev, phoneData[index]]; // multi
        return [phoneData[index]];
      }
    });
  };

  const handleOwlChange = async (e) => {
    setActiveIndex(e);
    setActiveDataModal(e);
    setUpSelectPhone(e);
    // updateDataPolicyActive(e);
  };

  useEffect(() => {
    console.log(phoneData)
    if (phoneData.length > 0) {
      setUpSelectPhone(0);
    }
  }, [phoneData]);


  const handleCheckAll = () => {
    if (isChecked) {
      removeAllItems(); // ลบข้อมูลทั้งหมด
    } else {
      const allIndexes = phoneData.map((_, index) => index); // สร้าง array ของทุก index
      setSelectedIndexes(allIndexes);
      const updatedList = phoneData.map((item) => ({
        policy_no: item.policy_no,
        policy_number: item.id,
        mobile: mobilePhone,
        phone_home: phoneData?.[0]?.phone_home?.[0] ?? "",
        phone_office: phoneData?.[0]?.phone_office?.[0] ?? "",
        phone_home_to: phoneData?.[0]?.phone_home?.[1] ?? "",
        phone_office_to: phoneData?.[0]?.phone_office?.[1] ?? "",

        old_mobile: item.mobile,
        old_phone_office: item.phone_office,
        old_phone_home: item.phone_home,
        old_phone_office_to: item.phone_office_to,
        old_phone_home_to: item.phone_home_to,
        old_phone_other: item.phone_other,
      }));



      setListData(updatedList);
      // setDataInformation(updatedList) // ตั้งค่า listData ให้มีข้อมูลทั้งหมด
    }
    setIsChecked(!isChecked); // เปลี่ยนสถานะ CheckAll
  };

  const removeAllItems = () => {
    setListData([]); // ตั้งค่า listData ให้เป็น array ว่าง
    setSelectedIndexes([]); // เคลียร์ selectedIndexes
  };


  const CustomCheckbox = styled(Checkbox)({
    color: 'rgba(255, 94, 67, 0.5)',
    '&.Mui-checked': {
      color: '#FF5E43',
    },
    marginRight: '15px'
  });

  const styleDrive = {
    py: 0,
    width: '100%',
    maxWidth: 'auto',
    // borderRadius: 2,
    // border: '1px solid',
    borderColor: 'divider',
    backgroundColor: 'background.paper',
  };

  const ScrollableCardInformation = () => {

    const cardRef = React.useRef(null);

    const handlers = useSwipeable({
      onSwiped: (eventData) => {
        if (cardRef.current) {
          cardRef.current.scrollTop += -eventData.deltaY; // เลื่อนการ์ดตามการสไวป์ (ขึ้นหรือลง)
        }
      },
      delta: 10, // กำหนดความไวต่อการสไวป์
      trackMouse: true, // ทำให้ใช้เมาส์ในการลากได้
    });

    return (

      <Card
        sx={{
          maxHeight: '510px',
          overflowY: 'hidden',
          border: '1px solid rgba(255, 94, 67, 0.3) ', // สีของขอบ #FF5E43 ที่โปร่งใส 30%
          borderRadius: '12px', // ความโค้งของขอบ
          borderWidth: '2px',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        }}
        ref={cardRef}
        {...handlers} // ผูกฟังก์ชันสไวป์ให้กับการ์ด
      >

        <CardContent
          sx={{
            cursor: 'pointer',
            overflowY: 'auto', // เปิดการเลื่อนในแนวตั้ง
            boxSizing: 'border-box',
          }}
        >

          {listData.map((item, index) => {
            // แสดงผลหมายเลขโทรศัพท์มือถือ
            const mobileDisplay = item.mobile || '-';

            // แสดงผลหมายเลขโทรศัพท์บ้าน
            const phoneHomeDisplay = item.phone_home && item.phone_home !== '-' ? item.phone_home : '';
            const phoneHomeToDisplay = item.phone_home_to && item.phone_home_to !== '-' ? `ต่อ ${item.phone_home_to}` : '';
            const phoneHomeFinal = phoneHomeDisplay || phoneHomeToDisplay ? `${phoneHomeDisplay} ${phoneHomeToDisplay}` : '-';

            // แสดงผลหมายเลขโทรศัพท์ที่ทำงาน
            const phoneOfficeDisplay = item.phone_office || '';
            const phoneOfficeToDisplay = item.phone_office_to ? `ต่อ ${item.phone_office_to}` : '';
            const phoneOfficeFinal = phoneOfficeDisplay || phoneOfficeToDisplay ? `${phoneOfficeDisplay} ${phoneOfficeToDisplay}` : '-';

            // แสดงผลหมายเลขโทรศัพท์อื่นๆ
            const phoneOtherDisplay = item.phone_other || '-';

            // แสดงผลหมายเลขโทรศัพท์เก่า
            const oldMobileDisplay = item.old_mobile || '-';
            const oldPhoneHomeDisplay = item.old_phone_home && item.old_phone_home !== '-' ? item.old_phone_home : '';
            const oldPhoneHomeToDisplay = item.old_phone_home_to ? `ต่อ ${item.old_phone_home_to}` : '-';
            const oldPhoneHomeFinal = oldPhoneHomeDisplay || oldPhoneHomeToDisplay ? `${oldPhoneHomeDisplay} ${oldPhoneHomeToDisplay}` : '-';

            const oldPhoneOfficeDisplay = item.old_phone_office && item.old_phone_office !== '-' ? item.old_phone_office : '';
            const oldPhoneOfficeToDisplay = item.old_phone_office_to ? `ต่อ ${item.old_phone_office_to}` : '-';
            const oldPhoneOfficeFinal = oldPhoneOfficeDisplay || oldPhoneOfficeToDisplay ? `${oldPhoneOfficeDisplay} ${oldPhoneOfficeToDisplay}` : '-';

            const oldPhoneOtherDisplay = item.old_phone_other || '-';

            return (
              <Box
                key={index}
                sx={{
                  marginBottom: '15px',
                  padding: '10px',
                  transition: 'background-color 0.3s ease, border 0.3s ease',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Card>
                  <CardHeader
                    title={`กรมธรรม์เลขที่ : ${item.policy_no}`}
                    sx={{ backgroundColor: 'rgba(255, 94, 67, 0.3)' }}
                    titleTypographyProps={{
                      sx: {
                        fontFamily: '"Prompt-Thin", sans-serif !important',
                        fontSize: '15px',
                        color: 'black',
                        marginLeft: '15px',
                      },
                    }}
                  />

                  {/* ข้อมูลใหม่ */}
                  <CardContent>
                    <List sx={styleDrive} style={{ marginBottom: '10px' }}>
                      <ListItem>
                        <ListItemText primary="ข้อมูลใหม่" />
                      </ListItem>
                      <Divider variant="middle" component="li" />
                    </List>

                    <Typography>
                      <Grid container spacing={1}>
                        <Grid item xs={7} sm={4} md={2} lg={3} xl={3}>
                          <span className="font-size-label-pol">เบอร์โทรศัพท์มือถือ :</span>
                        </Grid>
                        <Grid item xs={5} sm={5} md={2} lg={2} xl={2}>
                          <span className="font-size-label-pol">{mobileDisplay}</span>
                        </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                        <Grid item xs={7} sm={7} md={2} lg={3} xl={3}>
                          <span className="font-size-label-pol">เบอร์โทรศัพท์บ้าน :</span>
                        </Grid>
                        <Grid item xs={5} sm={5} md={2} lg={8} xl={8}>
                          <span className="font-size-label-pol">{phoneHomeFinal}</span>
                        </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                        <Grid item xs={7} sm={7} md={4} lg={3} xl={3}>
                          <span className="font-size-label-pol">เบอร์โทรศัพท์ที่ทำงาน :</span>
                        </Grid>
                        <Grid item xs={5} sm={5} md={2} lg={7} xl={9}>
                          <span className="font-size-label-pol">{phoneOfficeFinal}</span>
                        </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                        <Grid item xs={7} sm={4} md={2} lg={3} xl={3}>
                          <span className="font-size-label-pol">เบอร์โทรศัพท์อื่นๆ :</span>
                        </Grid>
                        <Grid item xs={5} sm={5} md={2} lg={2} xl={2}>
                          <span className="font-size-label-pol">{phoneOtherDisplay}</span>
                        </Grid>
                      </Grid>
                    </Typography>
                  </CardContent>

                  {/* ข้อมูลเก่า */}
                  <CardContent>
                    <List sx={styleDrive} style={{ marginBottom: '10px' }}>
                      <ListItem>
                        <ListItemText primary="ข้อมูลเก่า" />
                      </ListItem>
                      <Divider variant="middle" component="li" />
                    </List>

                    <Typography>
                      <Grid container spacing={1}>
                        <Grid item xs={7} sm={4} md={2} lg={3} xl={3}>
                          <span className="font-size-label-pol">เบอร์โทรศัพท์มือถือ :</span>
                        </Grid>
                        <Grid item xs={5} sm={5} md={2} lg={2} xl={2}>
                          <span className="font-size-label-pol">{oldMobileDisplay}</span>
                        </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                        <Grid item xs={7} sm={7} md={2} lg={3} xl={3}>
                          <span className="font-size-label-pol">เบอร์โทรศัพท์บ้าน :</span>
                        </Grid>
                        <Grid item xs={5} sm={5} md={2} lg={8} xl={8}>
                          <span className="font-size-label-pol">{oldPhoneHomeFinal}</span>
                        </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                        <Grid item xs={7} sm={7} md={4} lg={3} xl={3}>
                          <span className="font-size-label-pol">เบอร์โทรศัพท์ที่ทำงาน :</span>
                        </Grid>
                        <Grid item xs={5} sm={5} md={2} lg={2} xl={2}>
                          <span className="font-size-label-pol">{oldPhoneOfficeFinal}</span>
                        </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                        <Grid item xs={7} sm={4} md={2} lg={3} xl={3}>
                          <span className="font-size-label-pol">เบอร์โทรศัพท์อื่นๆ :</span>
                        </Grid>
                        <Grid item xs={5} sm={5} md={2} lg={2} xl={2}>
                          <span className="font-size-label-pol">{oldPhoneOtherDisplay}</span>
                        </Grid>
                      </Grid>
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            );
          })}

        </CardContent>
      </Card>
    );
  };

  return (
    <div className="edit-phone">
      {loading ? (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress
            sx={{
              animationDuration: "550ms",
            }}
            color="inherit"
          />
        </Backdrop>
      ) : null}
      <Header_Home />
      <Header title="เปลี่ยนเบอร์โทรศัพท์" />
      <div className="container-footer-space">

        <section
          className="update-receiving-address"
          style={{ display: phoneStep === 1 ? "block" : "none" }}
        >


          <div className="pb-2">
            <img
              className="address-icon"
              src={icon_smartphone_call}
              alt="phone-icon"
            />
            <span className="bold">{"เบอร์โทรศัพท์"}</span>
          </div>
          <div className="px-4">
            <MultipleSelectPhone onInputChange={handleInputDataChange} />
          </div>

        </section>
        <br />

        <Box sx={{ display: phoneStep === 2 ? "flex" : "none", alignItems: 'center' }} style={{ marginBottom: '10px' }}>
          <AssignmentTurnedInRoundedIcon fontSize={'large'} style={{ color: 'rgb(255, 94, 67)' }} />
          <Typography style={{ fontSize: '20px', marginLeft: '10px' }}>ยืนยันข้อมูล</Typography>
        </Box>
        {phoneStep === 1 ? <ScrollableCardPhone phoneData={phoneData} setPhoneData={setPhoneData} listData={listData}
          inputData={inputData} setListData={setListData} mobilePhone={mobilePhone} /> : <ScrollableCardInformation />}

        <br />
        <section className="action-address"

        >
          <Grid container
            spacing={2}
            justifyContent="center" // จัดตำแหน่งตรงกลางแนวนอน
            alignItems="center"     // จัดตรงกลางแนวตั้ง
            sx={{ minHeight: '10vh' }} // ให้ Grid เต็มความสูงหน้าจอ
          >
            
            <Grid item xs={12} sm={6} md={2}>
              <button
                onClick={() => {
                  if (phoneStep === 1) {
                    // exit page or back step
                    navigate(-1);
                  } else {
                    setPhoneStep(1);
                  }
                }}
                className="btn-address-back w-100 p-2 bold"
              >
                ย้อนกลับ
              </button>

            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <button
                style={{ fontSize: 18 }}
                onClick={() => {
                  if (phoneStep === 1) {
                    let errorsPhone = validatePhone();
                    if (errorsPhone === false) {
                      if (listData === undefined) {
                        // setOpenErrorMissingPhone(true);

                        setToggleImg(false);
                        setTextModal(
                          "กรุณาเลือกกรมธรรม์ที่ต้องการแจ้งเปลี่ยนเบอร์โทรศัพท์"
                        );
                        setCompletedModal(true);
                        refCarousel.current.scrollIntoView();
                      } else {
                        console.log("select phoine data", selectedPhone);
                        setPhoneStep(2);
                      }
                    }
                  } else {
                    savePhone();
                  }
                }}
                className="btn-address-next w-100 p-2 bold"
              >
                {phoneStep === 1 ? "ต่อไป" : "บันทึก"}
              </button>
            </Grid>
          </Grid>
        </section>
      </div>
      <Modal
        isOpen={openModal}
        onRequestClose={handleCloseModal}
        contentLabel="ModalPolicy"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="p-lg-2 p-3">
          <h3 style={{ color: "#FA0E0E", fontSize: "18px" }}>กรมธรรม์เลขที่</h3>
          <div className="d-grid mb-4">
            <TextField
              type="text"
              size="small"
              onChange={(e) => handleSearchBasePolicy(e.target.value)}
              className=""
              value={searchBasePolicy}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={iconSearch} height={20} width={20} alt="" />
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "#FFF",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "6px",
                  height: "35px",
                  "& fieldset": {
                    borderColor: "#FB5E3F",
                  },
                  "&:hover fieldset": {
                    borderColor: "#FB5E3F",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#FB5E3F",
                  },
                },
              }}
            />
          </div>
          <div
            style={{
              maxHeight: "150px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {!filteredOptions.length == 0 ? (
              filteredOptions.map(renderOptionRow)
            ) : (
              <div className="text-center text-muted">
                ไม่มีข้อมูลที่ตรงกับการค้นหา
              </div>
            )}
          </div>
          <Row className="d-flex flex-row justify-content-evenly mt-3">
            {/* <Col className="bg-danger"> */}
            <button
              style={{ fontSize: 15 }}
              onClick={handleConfrimSearch}
              className="btn-checkout-premium w-25 p-2 bold"
            >
              ตกลง
            </button>
            <button
              style={{ fontSize: 15 }}
              onClick={handleCloseModal}
              className="btn-checkout-back w-25 p-2 bold"
            >
              ยกเลิก
            </button>
          </Row>
        </div>
      </Modal>
      <BootstrapModal
        show={completedModal}
        backdrop="static"
        // size="md"
        // style={{}}
        className="backdrop-alert"
        contentClassName="modal-alert"
      >
        <BootstrapModal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={toggleImg ? greenCheck : remove}
              alt="check-icon"
              className="modal-image-size"
            />
          </div>

          <li
            className="body-list-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Col
              xl={12}
              md={12}
              xs={12}
              style={{
                display: "flex",
                marginTop: 5,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div className="font-modal">{textModal}</div>
            </Col>
          </li>
          <div className="d-flex justify-content-center flex-wrap">
            <button
              className="btn-checkout-modal w-20 p-2 bold"
              onClick={() => {
                if (toggleImg) {
                  navigate("/personal");
                } else {
                  setCompletedModal(false);
                }
              }}
            >
              กลับ
            </button>
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>
    </div>
  );
};

export default EditPhone2;
