import React, { useContext } from "react";
import img_1 from "../../../images/logo_consent.png";
import img_2 from "../../../images/logo_consentMobile.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";

import "./components.css";
import TransferContext, { TransferProvider } from "../TranferContextProvider.js";

const RegisterQuickTransfet = () => {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const { activeStep, setActiveStep, skipped, setSkipped } =
    useContext(TransferContext);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };


  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  return (
    <>
      <div className="middle-item">
        {/* <img src={img_1} height="120" alt="" /> */}
        <div>
          <img src={img_1} height={isSmallScreen ? "120" : "160"} alt="" />
        </div>
        {/* <div
          style={{
            display: isSmallScreen ? "block" : "none",
          }}
        >
          <img src={img_2} height="120" alt="" />
        </div> */}
        <div
          style={{
            flex: 1,
            flexDirection: "column",
            marginTop: 40,
            justifyContent: "start",
            width: "100%",
          }}
        >
          <span>บริการโอนเงินตามกรมธรรม์ผ่านบัญชีธนาคาร</span>
          <div>
            <span style={{ marginLeft: 10 }}>- สะดวก รวดเร็ว ปลอดภัย</span>
          </div>
          <div>
            <span style={{ marginLeft: 10 }}>
              - ไม่ต้องรอเช็ค ไม่ต้องเดินทางนำเช็คไปขึ้น
            </span>
          </div>
          <div>
            <span style={{ marginLeft: 10 }}>- ไม่มีค่าธรรมเนียมการโอน</span>
          </div>
        </div>
        <button
          style={{ fontSize: 15 }}
          onClick={handleNext}
          className="btn-checkout-premium w-100 p-2 bold"
        >
          สมัครเลย
        </button>
      </div>
    </>
  );
};

export default RegisterQuickTransfet;
