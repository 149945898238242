import React, { useContext, useState, useEffect } from "react";
import {
  TextField,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Select,
} from "@mui/material";
import TransferContext, {
  TransferProvider,
} from "../TranferContextProvider.js";
import { verifyOtp } from "../quickTransferServices/QuickTransferServices.js";
import { notifySuccess, notifyWarning } from "../../../util/Notification.js";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { Modal, Form } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import InputAdornment from "@mui/material/InputAdornment";
import iconSearch from "../../../images/icon-search-2.png";
import remove from "../../../images/remove.png";
import { getParameterAPI } from "../../PolicyDetail/PolicyDetailService/policyDetailService";
import { updateBenefit } from "../quickTransferServices/QuickTransferServices";




const UpdateBank = () => {
  const {
    activeStep,
    setActiveStep,
    skipped,
    setSkipped,
    citizenId,
    setUuid,
    setCitizenId,
    mobileTel,
    setMobileTel
  } = useContext(TransferContext);

  const [otpValue, setOtpValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertText, setAlertText] = useState("false");
  const [open, setOpen] = useState(false);
  const [textOtpAlertField, setTextOtpAlertField] = useState("");
  const [textAlertStatus, setTextAlertStatus] = useState(false);
  const [cusTel, setCusTel] = useState("");
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState('');
  const [bankAccountName, setBankAccountName] = useState("");
  const [bankAccount, setBankAccount] = useState("");



  useEffect(() => {
    getBankName();
  }, []);

  // const getTel = async () => {
  //   const result = await getParameterAPI(12);
  //   const payload = result.payload;
  //   const cusTel = payload.find((item) => item.code === "CUS_SERV_TEL");
  //   const variable1 = cusTel.variable1;
  //   setCusTel(variable1);
  // };

  const getBankName = async () => {
    const result = await getParameterAPI(14);
    if (result.success) {
      const payload = result.payload;
      setBanks(result.payload);
      console.log(payload);
    }
  };
  const handleBankChange = (event) => {
    // updateInformationEditBank({bank_name: event.target.value})
    setSelectedBank(event.target.value);
  };


  const enterOtp = async () => {
    if (bankAccountName != '' && selectedBank != '' && bankAccount != '') {
      const result = await updateBenefit(citizenId, bankAccountName, bankAccount, selectedBank);
      console.log("updateBenefit DATA ID >>>", result);
      setUuid(result.message)
      handleNext();
    } else {
      notifyWarning("กรุณากรอกข้อมูลให้ครบถ้วน");
    }




  };
  const maskMobileTel = (mobileTel) => {
    if (mobileTel.length === 10) {
      return mobileTel.replace(/(\d{2})(\d{4})(\d{4})/, '$1xxxx$3');
    }
    return mobileTel; // ในกรณีที่หมายเลขไม่ใช่ 10 หลัก จะคืนค่าเดิม
  };
  const maskedMobileTel = maskMobileTel(mobileTel);


  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleConfrim = () => {
    setOpen(false);
    setCitizenId("");
    setActiveStep(0);
  };

  return (
    <>
      <div className="border-content">
        <div className="d-flex px-3 pt-3 pb-3">
          {/* <h2 className="font-size-title"> */}
          กรอกข้อมูล
          {/* </h2> */}
        </div>
        <hr
          className="mx-2"
          style={{ margin: "0px 0px 15px 0px", border: "solid 1px" }}
        />
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress
            sx={{
              animationDuration: "550ms",
            }}
            color="inherit"
          />
        </Backdrop>
        <>
          <InputLabel
            htmlFor="bank_account_name"
            className="label"
            style={{ fontWeight: "bold" }}
          >
            ชื่อบัญชี<span className="text-danger mx-1">*</span>
          </InputLabel>
          <TextField
            InputProps={{ sx: { borderRadius: 16 } }}
            id="bank_account_name"
            type="search"
            className="mb-2 label"
            placeholder="ชื่อจริง - นามสกุล"
            variant="outlined"
            value={bankAccountName}
            size="small"
            onChange={(e) =>
              setBankAccountName(e.target.value)
            }
            fullWidth
          />
          <InputLabel
            htmlFor="bank_name"
            className="label"
            style={{ fontWeight: "bold" }}
          >
            ธนาคาร<span className="text-danger mx-1">*</span>
          </InputLabel>
          <Select
            input={<OutlinedInput />}
            style={{ borderRadius: "16px", height: "42px" }}
            id="bank_name"
            variant="outlined"
            fullWidth
            displayEmpty
            className="select-input mb-2 label"
            value={selectedBank}
            onChange={handleBankChange}
          >
            <MenuItem value="" disabled>
              เลือกธนาคาร
            </MenuItem>
            {banks.map((bank) => (
              <MenuItem key={bank.pmdId} value={bank.name}>
                {bank.name}
              </MenuItem>
            ))}
            {/* <MenuItem value="อื่นๆ">อื่นๆ</MenuItem> */}
          </Select>
          <InputLabel
            htmlFor="bank_account"
            className="label"
            style={{ fontWeight: "bold" }}
          >
            เลขที่บัญชี<span className="text-danger mx-1">*</span>
          </InputLabel>
          <TextField
            InputProps={{ sx: { borderRadius: 16 } }}
            id="bank_account"
            type="search"
            className="mb-2 label"
            placeholder="เลขที่บัญชี"
            variant="outlined"
            value={bankAccount}
            // onChange={(e) => handleDataEdit("bank_account", e.target.value)}
            onChange={(e) =>
              setBankAccount(e.target.value)
            }
            size="small"
            fullWidth
          />


        </>

        {textAlertStatus ? (
          <div
            className="font-size-content-otp"
            style={{
              marginLeft: 10,
              marginTop: 5,
              color: "red",
            }}
          >
            {textOtpAlertField}
          </div>
        ) : (
          ""
        )}

        <div
          style={{
            flex: 1,
            flexDirection: "column",
            justifyContent: "start",
            width: "100%",
            marginTop: 10,
          }}
        >
          {/* <div className="font-size-content-otp">
            ส่งรหัสผ่านไปที่หมายเลข {maskedMobileTel} เพื่อเข้าสู่ระบบ
          </div>
          <div className="font-size-content-otp">
            {" "}
            กรณีไม่ได้รับหรือเปลี่ยนหมายเลขโทรศัพท์มือถือ
          </div>
          <div className="font-size-content-otp">
            กรุณาติดต่อฝ่ายลูกค้าสัมพันธ์ {cusTel}
          </div> */}
        </div>
      </div>
      <button
        style={{ fontSize: 15 }}
        onClick={enterOtp}
        className="btn-checkout-premium w-100 p-2 bold"
      >
        ตกลง
      </button>
      <button
        className="btn-checkout-back w-100 p-2 bold"
        onClick={handleBack}
      >
        กลับ
      </button>
      <Modal show={open} backdrop="static" size="md" style={{}}>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={remove}
              alt={"confirmGif"}
              style={{
                height: "auto",
                width: "20%",
              }}
            />
          </div>

          <li
            className="body-list-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Col
              xl={12}
              md={12}
              xs={12}
              style={{
                display: "flex",
                marginTop: 20,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <h3 className="font-size-content-alert-modal">{alertText}</h3>
            </Col>
          </li>
          <div className="App">
            <div className="d-flex justify-content-center flex-wrap">
              <button
                className="btn-checkout-back-modal w-30 p-2 bold"
                onClick={handleConfrim}
              >
                กลับ
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateBank;
