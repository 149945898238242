import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { Form } from "react-bootstrap";
import axios from 'axios';
import Header_Home from '../Component/Header_Home';
import PopUp from '../Component/Popup';
class EditEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {date: new Date() ,
        token: '',
        username:'',
        step:1,
        offset: 0,
        data: [],
        perPage: 50,
        currentPage: 0,
        pageCount:0,
        showing_step:1,
        showing_card2:false,
        showing_card3:false,
        showing_all:false,
        loading:true
    }
    }

    receivedData() {
         
        fetch(process.env.REACT_APP_API_BASEURL + "/api/personal/getemail",
        // fetch(process.env.REACT_APP_API_BASEURL + "/api/personal/getemail?customer="+localStorage.getItem('customer_id'),
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { 
            //console.log(res)
            if(res.success) {
                const data = res.payload;
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                if(data.length > 1){
                    this.setState({showing_all : true})
                }
                const postData = slice.map(item => 
                    <div className="col-lg-8 col-md-12 col-sm-12 margin-auto">
                        <div className="card">  
                            <div className="card-body">
                                <div className="row col-12 p-0">
                                    <div className="col-1 text-right">
                                    <input  onChange={this.handleChange("policy_change")}  type="checkbox" id= {item.policy_no} name={item.policy_no} value={item.policy_no} className="margin-left-px mt-3" disabled={this.state.showing2}></input>
                                    </div>
                                    <div className="col-9 pr-0">
                                        <label className='text-righ mt-2'><strong>หมายเลขกรมธรรม์ : </strong> {item.policy_no}</label>
                                        <div>
                                            <label className='text-righ mt-2'><strong>อีเมล : </strong> {item.email}</label>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                    
                        
                )
                this.setState({payload : res.payload,
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    postData, loading:false})
                
            } else {
                this.setState({
                    showing_step: 10, 
                    loading:false
                });
            }
            
        })
        .catch(function(res){ console.log(res) })
    }


    componentDidMount() {
        fetch(process.env.REACT_APP_API_BASEURL + "/api/user/getprofile",
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { 
            console.log("profile.check" , res)
            if(res.success == 'true'){
                if(res.payload.profile == 'POS2'){
                    window.location.href ='/home';
                }
            }
        })
        
        // if(localStorage.getItem('profile') == 'POS2'){
        //     window.location.href ='/home';
        // }

        console.log('componentDidMount')
        this.receivedData()
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    handleselectedFile  = input => e => {
        this.setState({
            selectedFile: e.target.files[0],
            //**** added line below ****//
            file_ori: e.target.files[0].name,
            [input]: e.target.files[0].name
        });
    };
    componentDidUpdate() {
       
        console.log('componentDidUpdate')
    }

    componentWillUnmount() {
        console.log('componentWillUnmount')
       
    }

    con () {
        const { payload} = this.state;
        const data = payload;
        const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        const postData2 = slice.map(item => 
            this.item_change(item)
        )
        this.setState({postData2})
    }

    item_change (item) {
        if(document.getElementById(item.policy_no).checked) {
            return (
                <div className="card-body list-group p-0">
                <div className="row col-lg-9 col-md-12 p-0 margin-auto">
                    <div className="card p-0">  
                        <div className="card-body header-card">
                            <label className='text-righ mt-2'><strong>หมายเลขกรมธรรม์ : </strong> {item.policy_no}</label>
                            </div> 
                            <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                            <span className="bold">อีเมลเดิม &nbsp;&nbsp;:</span>
                            <span className="light">{item.email}</span>
                            </li>
                            <li className="list-group-item">
                            <span className="bold">อีเมลใหม่ :</span>
                            <span className="light">{document.getElementById(item.policy_no).checked?document.getElementById("new_email").value:item.email} </span>
                            </li>                     
                        </ul>
                        
                            
                        </div>   
                    </div>              
                </div>      
            )
        }
    }

    handleChange = input => e => {
        console.log(e.target.value)
        if(document.getElementById(e.target.value).checked== false){
            document.getElementById("all").checked =false
        }
        this.setState({ [input]: e.target.value });
        console.log({ [input]: e.target.value })
    };

    select_all = input => e =>  {
        // const {payload} = this.state;
        const {payload} = this.state;
        let policies = [];
        let body = [];
        console.log('payload',payload)
        if(document.getElementById("all").checked){
            for (let item of payload) {
                document.getElementById(item.policy_no).checked = true
            }
        } else {
            for (let item of payload) {

                document.getElementById(item.policy_no).checked = false
            }
        }
    }

    next () {
        const { showing_step} = this.state;
        let num = 1 ;
        var txt_error = 'กรุณากรอกข้อมูล';
        console.log("showing_step",showing_step)
        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(showing_step === 1) {
            // let laser_code = document.getElementById("laser_code").value
            let new_email = document.getElementById("new_email").value
            if(!new_email && new_email !== undefined){
                document.getElementById("error_new_email").innerHTML = `<span name="#new_email" class="error">` + txt_error +  ` </span>`
                num++;
            } else if (!new_email.match(mailformat)) {
                document.getElementById("error_new_email").innerHTML = `<span name="#new_email" class="error"> กรุณากรอกอีเมลให้ถูกต้อง </span>`
                num++;
            } else {
                document.getElementById("error_new_email").innerHTML = ` `
            }
            // if(!laser_code && laser_code !== undefined){
            //     document.getElementById("error_laser_code").innerHTML = `<span name="#laser_code" class="error">` + txt_error +  ` </span>`
            //     num++;
            // } else {
            //     document.getElementById("error_laser_code").innerHTML = ''
            // }

            if(num == 1) {
                const { payload} = this.state;
                let data = payload;
                let slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                let count_policy = 0;
                let loop = slice.map(item => {
                    if(document.getElementById(item.policy_no).checked){
                        count_policy++;
                    }
                })
                if(count_policy == 0) {
                    alert("กรุณาเลือกกรมธรรม์ที่ต้องการแจ้งเปลี่ยน");
                    num++;
                }
            }
        }
        //  else if (showing_step === 2) {
        //     if(!document.getElementById('file1').files.length>0){
        //         alert('เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ')
        //         num++;
        //     }
           
        // }
        if(num === 1 ){
            this.setState({
                showing_step: showing_step+1
            });
            this.con();
        }
        
    }

    back () {
        const {showing_step} = this.state;
        this.setState({
            showing_step: showing_step-1
        });
        this.con();
    }

    save () {
        const {payload} = this.state;
        let policies = [];
        let body = [];
        this.setState({loading:true})
        for (let item of payload) {
            if(document.getElementById(item.policy_no).checked){
                let policy = {};
                policy.policy_no = item.policy_no;
                policy.email = document.getElementById(item.policy_no).checked?document.getElementById("new_email").value:item.email;
    
                policy.old_email = item.email;

                //console.log('relation.agent_code: ' + relation.agent_code);
                console.log('policy_no: ' + item.policy_no);
                console.log('email: ' + item.email);
                policies.push(policy);
            }
            
            
        }
        // let laser_code = document.getElementById("laser_code").value
        // laser_code = laser_code.replaceAll("-", "");
        let save = {};
        save.policies = policies;
        // save.laser_code = laser_code;
        console.log("body",save)
        fetch(process.env.REACT_APP_API_BASEURL + '/api/personal/email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization':  localStorage.getItem('token_login')
                
            },
            body: JSON.stringify(save),
        })
        .then(res => res.json())
        
        .then( res => { 
            //console.log(res)
           console.log("body_save",res)
           this.setState({loading:false})
           if(res.success) {
                alert( "บริษัทได้รับทราบข้อมูลจากท่านแล้ว ขอบคุณค่ะ")
                window.location.href="/personal";
            } else {
                alert( res.payload.error)
            }
        //    alert( "บริษัทได้รับทราบข้อมูลจากท่านแล้ว ขอบคุณค่ะ")
                // window.location.href="/personal";
        //    document.querySelector("input[type='submit']").click();
        })
        .catch(function(res){ console.log(res) })
    }

    submitUpload = (event) => {

        // const FormData = require('form-data');
        
        // event.preventDefault();
        let url = process.env.REACT_APP_API_BASEURL + '/api/personal/upload'
        event.preventDefault();

        
        let file_name1 = undefined;
        let file1;
        // let file_name2 = undefined;
        // let file2;
        if(event.target[0].files[0]){
            file_name1 = event.target[0].files[0].name;
            file1 = event.target[0].files[0];
        }
        const formData = new FormData();        
        formData.append('image1', file1, file_name1);
        // formData.append('image2', file2, file_name2);
        // formData.append('customer', localStorage.getItem('customer_id'));
        formData.append('type', '3');
        for (var [key, value] of formData.entries()) { 
            console.log(key, value);
        }
        let response =  axios.post(url, formData, {
            headers: {
                'authorization': localStorage.getItem('token_login'),
                'content-type': 'multipart/form-data'
            }
        
        }).then(function(result) {
            console.log("result",result) 
            if (!result.data.success) {
                console.log('error: ' + result.data.payload.error);
                alert( result.data.payload.error)
            } else {
                if(result.success) {
                    alert( "บริษัทได้รับทราบข้อมูลจากท่านแล้ว ขอบคุณค่ะ")
                    window.location.href="/personal";
                } else {
                    alert( result.payload.error)
                }
               
            }
         })
        
        //  this.reload()
        
        
    };

 
    render() {
        const { showing_step, postData, postData2, showing_all, loading} = this.state;
        return (
            <div >
                {loading ? <PopUp /> : null}
                <Header_Home/>
                <section className="d-flex justify-content-center align-items-center h-100 my-5">
                    <div className="container-fluid  container-margin">
                        <div className='row'>
                            <div className="col-12">
                                <div className="card" style={{ display: (showing_step === 1 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>เปลี่ยนอีเมล</strong></label>
                                            </div>
                                        </div>       
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 ">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="subject" className='text-righ mt-2'><strong>อีเมล</strong></label>
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                                <input style={{borderWidth: 1}}  type="email" id="new_email" name="new_email" maxLength="50" className="margin-left-10-px form-control"></input>
                                                <label id="error_new_email"></label>
                                            </div>
                                        </div> 
                                        {/* <div className="row col-12 pt-2">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="subject" className='text-righ mt-2'><strong>เบอร์หลังบัตรประชาชน</strong></label>
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                                <input  type="text" id="laser_code" name="laser_code" autocomplete="off" className="margin-left-10-px form-control"></input>
                                                    <label id="error_laser_code"></label>
                                            </div>
                                        </div>            */}
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 p-0 margin-auto">
                                            <div className="col-12 pt-4">
                                                <div className="col-lg-8 col-md-12 col-sm-12 margin-auto" id="all_policy"  style={{ display: (showing_all? 'block' : 'none')}}>
                                                    <div className="card">  
                                                        <div className="card-body">
                                                            <div className="row col-12 ">
                                                                <div className="col-1 text-right">
                                                                <input onChange={this.select_all("select_all")} type="checkbox" id="all" name="all" value="all" className="margin-left-px mt-3" disabled={this.state.showing2}></input>
                                                                </div>
                                                                <div className="col-9">
                                                                    <label className='text-righ mt-2'> ทุกกรมธรรม์</label>
                                                                </div>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                                {postData} 
                                            </div>
                                        </div>
                                    </div>
                                        <div className="card-body App">
                                            <a  href="/personal" type="button" className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a>
                                            <a  onClick={() => this.next()} className="btn btn-lg btn-blue">ต่อไป</a>
                                        </div>
                                    </div>

                                <div className="card" style={{ display: (showing_step === 12 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>อัพโหลดไฟล์</strong></label>
                                            </div>
                                        </div>      
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 ">
                                            
                                            <Form onSubmit={this.submitUpload} id="form_id">
                                                <div className="padding-title">
                                                    <div className="row col-12 ">
                                                        <div className="col-lg-6 col-md-8 col-sm-8 col-xs-8 text-right mt-3">
                                                            <label htmlFor="filename" className='text-righ'><strong>รูปถ่ายหน้าตรงที่ถ่ายคู่กับสำเนาบัตรประชาชน</strong> </label>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="col-7 text-left" align="right"  style={{ margin:'10px 0px 0px 0px' }}>
                                                                <label htmlFor="file1" className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i>  อัพโหลด
                                                                </label>
                                                                <label className='ml-2'>{this.state.fileName1}</label>
                                                                <input  type="file"  id="file1" accept="image/png, image/jpeg, image/jpg, application/pdf" style={{display:'none'}}  onChange={this.handleselectedFile("fileName1")}/>
                                                            </div>
                                                        </div>
                                                    </div>      
                                                </div>

                                                {/* <div className="padding-title">
                                                    <div className="row col-12 ">
                                                        <div className="col-lg-6 col-md-8 col-sm-8 col-xs-8 text-right mt-3">
                                                            <label htmlFor="filename" className='text-righ'><strong>รูปถ่ายหน้าตรงที่ถ่ายคู่กับสำเนาบัตรประชาชน</strong> </label>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="col-7 text-left" align="right"  style={{ margin:'10px 0px 0px 0px' }}>
                                                                <label htmlFor="file2" className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i> อัพโหลด 
                                                                </label>
                                                                <label className='ml-2'>{this.state.fileName2}</label>
                                                                <input  type="file"  id="file2" accept="image/png, image/jpeg, image/jpg, application/pdf" style={{display:'none'}}  onChange={this.handleselectedFile("fileName2")}/>
                                                            </div>
                                                        </div>
                                                    </div>      
                                                </div> */}

                                            

                                                <div className="row dv-button">
                                                        
                                                    <input  type="submit" className="btn btn-primary button-right-page"  style={{display:'none'}}></input>                               
                                                </div>

                                            </Form>
                                                
                                        
                                        </div>               
                                    </div>
                                    <div className="card-body App">
                                        <a onClick={() => this.back()}  className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a> 
                                        <a onClick={() => this.next()} className="btn btn-lg btn-blue">ต่อไป</a>
                                    </div>
                                </div>

                                <div className="card" style={{ display: (showing_step === 2 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>ยืนยันข้อมูล</strong></label>
                                            </div>
                                        </div>      
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 margin-auto">
                                            <div className="col-lg-10 col-md-12 margin-auto">
                                            {postData2} 

                                            {/* <div className="card-body list-group">
                                                <div className="row col-10">
                                                    <div className="card p-0">  
                                                        <div className="card-body  header-card">
                                                        <label className='text-righ mt-2'><strong>เอกสารแนบ </strong></label>
                                                        </div> 
                                                        <ul className="list-group list-group-flush">
                                                        <li className="list-group-item">
                                                        <span className="bold">รูปถ่ายหน้าตรงที่ถ่ายคู่กับสำเนาบัตรประชาชน &nbsp;&nbsp;:</span>
                                                        <span className="light">{this.state.fileName1}</span>
                                                        </li>
                                                       
                                                    </ul>
                                                    
                                                        
                                                    </div>   
                                                </div>              
                                            </div>    */}
                                            </div>
                                        </div>
                                       
                                                
                                    </div>
                                    <div className="card-body App">
                                        <a  onClick={() => this.back()} type="button" className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a>
                                        <a  onClick={() => this.save()} className="btn btn-lg btn-blue">บันทึก</a>
                                    </div>
                                    </div>
                                
                                <div className="card" style={{ display: (showing_step === 10 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>เปลี่ยนอีเมล</strong></label>
                                            </div>
                                        </div>       
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 pt-2">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="subject" className='text-righ mt-2'><strong>ไม่มีข้อมูลกรมธรรม์</strong></label>
                                            </div>
                                           
                                        </div>  
                                        
                                    </div>
                                   
                                    <div className="card-body App">
                                        <a  href="/personal" type="button" className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a>
                                        {/* <a  onClick={() => this.next()} className="btn btn-lg btn-blue">ต่อไป</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    

                        {/* <div className='App'>
                            <a  href="/home" type="button" className="btn btn-lg btn mt-3 cust-btn"  >ย้อนกลับ</a>
                        </div> */}
                        </div> 
                </section>
                </div> 
        )
    }
        
    
}

export default EditEmail;