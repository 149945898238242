import React, { Component } from "react";
import '../../App.css';
export default class PopupDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
        item:{},
        class_lv: 'col-6',

    }
    }
    handleClick = () => {
        this.props.toggle();
    };
    async receivedData() {
         
        await fetch(process.env.REACT_APP_API_BASEURL + "/api/request/detail?id="+this.props.id+"&type="+this.props.type,
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { console.log("res",res)
            this.setState({
                item: res.payload[0]
            })
            

        })
        .catch(function(res){ console.log(res) })
        
       
    }
    async componentDidMount() {
        console.log('componentDidMount',this.props)
        await this.receivedData();
        console.log(this.state.item.card_holder_name_new?this.state.item.card_holder_name_new:"sss")
    }
    componentDidUpdate() {

        console.log('PopUp')
    }
    render() {
        const { item } = this.state;
        const { policy_no } = this.props;
        return (
        <div className="md">
            <div className="md_content_alert_pop h70">
            <span className="close" onClick={this.handleClick}>
                &times;
            </span>
            <div className="row col-11 no-margin  scroll-y">
                <div className="card-body list-group p-0" style={{ display: (this.props.type === 1 ? 'block' : 'none')}}>
                    <div className="row col-lg-9 col-md-12 p-0 margin-auto">
                        <div className="card p-0">  
                            <div className="card-body header-card">
                            <label className='text-righ mt-2'><strong>หมายเลขกรมธรรม์ : </strong> {policy_no}</label>
                            </div> 
                            <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <div>
                                <label className='text-righ mt-2'><strong>ที่อยู่เก่า </strong></label>
                                </div>
                                <div className="row col-12">
                                    <div className="col-12 p-0">
                                        <label className='text-righ mt-2'>{item.address1_old}</label>
                                    </div>
                                </div>
                                <div className="row col-12">
                                    <div className="col-12 p-0">
                                    <label className='text-righ mt-2'>{item.address2_old}</label>
                                    </div>
                                </div>

                                <div className="row col-12">
                                    <div className="col-12 p-0">
                                    <label className='text-righ mt-2'>{item.address3_old}</label>
                                    </div>
                                </div>
                                <div className="row col-12">
                                    <div className="col-12 p-0">
                                    <label className='text-righ mt-2'>{item.address4_old}</label>
                                    </div>
                                </div>
                                <div className="row col-12">
                                    <div className="col-12 p-0">
                                    <label className='text-righ mt-2'>{item.address5_old}</label>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div>
                                <label className='text-righ mt-2'><strong>ที่อยู่ใหม่ </strong></label>
                                </div>
                                <div className="row col-12">
                                    <div className="col-12 p-0">
                                        <label className='text-righ mt-2'>{item.address1_new}</label>
                                    </div>
                                </div>
                                <div className="row col-12">
                                    <div className="col-12 p-0">
                                    <label className='text-righ mt-2'>{item.address2_new}</label>
                                    </div>
                                </div>

                                <div className="row col-12">
                                    <div className="col-12 p-0">
                                    <label className='text-righ mt-2'>{item.address3_new}</label>
                                    </div>
                                </div>
                                <div className="row col-12">
                                    <div className="col-12 p-0">
                                    <label className='text-righ mt-2'>{item.address4_new}</label>
                                    </div>
                                </div>
                                <div className="row col-12">
                                    <div className="col-12 p-0">
                                    <label className='text-righ mt-2'>{item.address5_new}</label>
                                    </div>
                                </div>
                            </li>

                        
                        </ul>
                        
                        </div>   
                    </div>              
                </div> 
                <div className="card-body list-group p-0"  style={{ display: (this.props.type === 2 ? 'block' : 'none')}}>
                    <div className="row col-lg-10 col-md-10 col-sm-10 col-xs-10  margin-auto">
                        <div className="card p-0">  
                            <div className="card-body header-card">
                            <label className='text-righ mt-2'><strong>หมายเลขกรมธรรม์ :  </strong> {}</label>
                            </div> 
                            <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <div>
                                <label className='text-righ mt-2'><strong>ชื่อนามสกุล เจ้าของบัตรเครดิต เดิม : </strong> {item.card_holder_name_new}</label>
                                </div>
                                <div>
                                <label className='text-righ mt-2'><strong>หมายเลขบัตร เดิม : </strong> {item.card_number_new?item.card_number_new.substring(0,4)+"********"+item.card_number_new.substring(item.card_number_new.length-4,item.card_number_new.length):""}</label>
                                </div>
                                <div>
                                <label className='text-righ mt-2'><strong>เดือน/ปี หมดอายุของบัตร เดิม : </strong> {item.card_expire_date_new}</label>
                                </div>
                                <div>
                                <label className='text-righ mt-2'><strong>ประเภทของบัตร เดิม : </strong> {item.card_type_new?item.card_type_new.trim() == "MC"? "Mastercard" : 
                                item.card_type_new.trim() == "VI"? "Visa" : 
                                item.card_type_new.trim() == "AM"? "Amex" : 
                                item.card_type_new.trim() == "C4"? "JCB" : 
                                item.card_type_new.trim() == "DI"? "Diners" :
                                item.card_type_new.trim() == "C5"? "CUP" :
                                item.card_type_new.trim() == "99"? "Card" : "1234":""}</label>
                                </div>
                                <div>
                                <label className='text-righ mt-2'><strong>ธนาคารเจ้าของบัตรเครดิต เดิม : </strong> {item.credit_bank_name_new}</label>
                                </div>
                                <div>
                                <label className='text-righ mt-2'><strong>ความสัมพันธ์เจ้าของบัตรเครดิตผู้เอาประกันภัย เดิม : </strong> {item.card_relation_new}</label>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div>
                                <label className='text-righ mt-2'><strong>ชื่อนามสกุล เจ้าของบัตรเครดิต ใหม่ : </strong> {item.card_holder_name_old}</label>
                                </div>
                                <div>
                                <label className='text-righ mt-2'><strong>หมายเลขบัตร ใหม่ : </strong> {item.card_number_old?item.card_number_old.substring(0,4)+"********"+item.card_number_old.substring(item.card_number_old.length-4,item.card_number_old.length):""}</label>
                                </div>
                                <div>
                                <label className='text-righ mt-2'><strong>เดือน/ปี หมดอายุของบัตร ใหม่ : </strong> {item.card_expire_date_old}</label>
                                </div>
                                <div>
                                <label className='text-righ mt-2'><strong>ประเภทของบัตร ใหม่ : </strong> {item.card_type_old?item.card_type_old.trim() == "MC"? "Mastercard" : 
                                item.card_type_old.trim() == "VI"? "Visa" : 
                                item.card_type_old.trim() == "AM"? "Amex" : 
                                item.card_type_old.trim() == "C4"? "JCB" : 
                                item.card_type_old.trim() == "DI"? "Diners" :
                                item.card_type_old.trim() == "C5"? "CUP" :
                                item.card_type_old.trim() == "99"? "Card" : "1234":""}</label>
                                </div>
                                <div>
                                <label className='text-righ mt-2'><strong>ธนาคารเจ้าของบัตรเครดิต ใหม่ : </strong> {item.credit_bank_name_old}</label>
                                </div>
                                <div>
                                <label className='text-righ mt-2'><strong>ความสัมพันธ์เจ้าของบัตรเครดิตผู้เอาประกันภัย ใหม่ : </strong> {item.card_relation_old}</label>
                                </div>
                            </li>
                                              
                        </ul>                           
                        </div>   
                    </div>              
                </div>  
                <div className="card-body list-group p-0" style={{ display: (this.props.type === 3 ? 'block' : 'none')}}>
                    <div className="row col-lg-9 col-md-12 p-0 margin-auto">
                        <div className="card p-0">  
                            <div className="card-body header-card">
                            <label className='text-righ mt-2'><strong>หมายเลขกรมธรรม์ :  </strong> {policy_no}</label>
                            </div> 
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <span className="bold">อีเมลเดิม &nbsp;&nbsp;:</span>
                                    <span className="light">{item.email_old} </span>
                                    <br/>
                                </li>
                                <li className="list-group-item">
                                    <span className="bold">อีเมลใหม่ &nbsp;&nbsp;:</span>
                                    <span className="light">{item.email_new} </span>
                                    <br/>
                                </li>         
                            </ul>
                        
                            
                        </div>   
                    </div>              
                </div> 
                <div className="card-body list-group p-0" style={{ display: (this.props.type === 4 ? 'block' : 'none')}}>
                    <div className="row col-lg-9 col-md-12 p-0 margin-auto">
                        <div className="card p-0">  
                            <div className="card-body header-card">
                            <label className='text-righ mt-2'><strong>หมายเลขกรมธรรม์ :  </strong> {policy_no}</label>
                            </div> 
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <span className="bold">คำนำหน้า เดิม &nbsp;&nbsp;:</span>
                                    <span className="light">{item.title_old} </span>
                                    <br/>
                                    <span className="bold">ชื่อ - นามสกุล เดิม &nbsp;&nbsp;:</span>
                                    <span className="light">{item.first_name_old} {item.last_name_old}</span>
                                    <br/>
                                    <span className="bold">ชื่อกลาง เดิม &nbsp;&nbsp;:</span>
                                    <span className="light">{item.middle_name_old}</span>
                                </li>
                                <li className="list-group-item">
                                    <span className="bold">คำนำหน้า ใหม่ &nbsp;&nbsp;:</span>
                                    <span className="light">{item.title_new} </span>
                                    <br/>
                                    <span className="bold">ชื่อ - นามสกุล ใหม่ &nbsp;&nbsp;:</span>
                                    <span className="light">{item.first_name_new} {item.last_name_new}</span>
                                    <br/>
                                    <span className="bold">ชื่อกลาง ใหม่ &nbsp;&nbsp;:</span>
                                    <span className="light">{item.middle_name_new}</span>
                                </li>         
                            </ul>
                        
                            
                        </div>   
                    </div>              
                </div> 
                <div className="card-body list-group p-0" style={{ display: (this.props.type === 5 ? 'block' : 'none')}}> 
                    <div className="row col-lg-9 col-md-12 p-0 margin-auto">
                        <div className="card p-0">  
                            <div className="card-body header-card">
                            <label className='text-righ mt-2'><strong>หมายเลขกรมธรรม์ : </strong> {policy_no}</label>
                            </div> 
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <div>
                                    <label className='text-righ mt-2'><strong>เบอร์โทรศัพท์มือถือเดิม : </strong> {item.mobile_old}</label>
                                    </div>
                                    <div>
                                    <label className='text-righ mt-2'><strong>เบอร์โทรศัพท์บ้านเดิม : </strong> {item.phone_home_old} {item.phone_home_to_old? ' ต่อ '+ item.phone_home_to_old:''}</label>
                                    </div>
                                    <div>
                                    <label className='text-righ mt-2'><strong>เบอร์โทรศัพท์ที่ทำงานเดิม : </strong> {item.phone_office_old} {item.phone_office_to_old? ' ต่อ '+ item.phone_office_to_old:''}</label>
                                    </div>
                                    <div>
                                    <label className='text-righ mt-2'><strong>เบอร์โทรศัพท์อื่นๆเดิม : </strong> {item.phone_other_old}</label>
                                    </div>
                                </li>        
                                <li className="list-group-item">
                                    <div>
                                    <label className='text-righ mt-2'><strong>เบอร์โทรศัพท์มือถือใหม่ : </strong> {item.mobile_new}</label>
                                    </div>
                                    <div>
                                    <label className='text-righ mt-2'><strong>เบอร์โทรศัพท์บ้านใหม่ : </strong> {item.phone_home_new} {item.phone_home_to_new? ' ต่อ '+ item.phone_home_to_new:''}</label>
                                    </div>
                                    <div>
                                    <label className='text-righ mt-2'><strong>เบอร์โทรศัพท์ที่ทำงานใหม่ : </strong> {item.phone_office_new} {item.phone_office_to_new? ' ต่อ '+ item.phone_office_to_new:''}</label>
                                    </div>
                                    <div>
                                    <label className='text-righ mt-2'><strong>เบอร์โทรศัพท์อื่นๆใหม่ : </strong> {item.phone_other_new}</label>
                                    </div>
                                </li>              
                            </ul>
                        
                        </div>   
                    </div>              
                </div>    
            </div>
        </div>
        </div>
        );
    }
}
