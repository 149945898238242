import { Axios } from "../../../util";

const downloadReceiptFileAPI = async (policyNo, receiptNo) => {
  try {
    // const res = await Axios.post("/api/policy/policyInformation");
    const res = await Axios.get(
      `/api/policy/policyReceipt?policyNo=${policyNo}&receiptNo=${receiptNo}`,
    );
    // console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const getPolicyReceiptYear = async (policyNo, insureIdCard) => {
  try {
    const res = await Axios.get(
      `/api/policy/getPolicyReceiptYears?policyNo=${policyNo}&insureIdCard=${insureIdCard}`,
    );
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
}

export { downloadReceiptFileAPI,
         getPolicyReceiptYear
 };
