import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';


import { makeStyles } from '@mui/styles';
import { Col } from 'react-bootstrap';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';

function createData(
    no: number,
    name: string,
    idCard: string,
    telNo: string,

)
{
    return {
        no,
        name,
        idCard,
        telNo,
        history: [
            {
                date: '2020-01-05',
                customerId: '11091700',
                amount: 3,
            },
            {
                date: '2020-01-02',
                customerId: 'Anonymous',
                amount: 1,
            },
        ],
    };
}

function Row(props: { row: ReturnType<typeof createData> })
{
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();


    return (
        <React.Fragment>
            <TableRow className={classes.tableRow} sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell align="center" component="th" scope="row">
                    {row.no}
                </TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.idCard}</TableCell>
                <TableCell align="center">{row.telNo}</TableCell>
                <TableCell align="center">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            {/* <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography> */}
                            <li className="body-list-group font-field" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Col xl={5} md={5} xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <span className="bold">ธนาคาร : </span>
                                </Col>
                                <Col xl={8} md={8} xs={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <span className="light">กสิกรไทย</span>
                                </Col>
                            </li>
                            <li className="body-list-group font-field" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Col xl={5} md={5} xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <span className="bold">เลขบัญชี : </span>
                                </Col>
                                <Col xl={8} md={8} xs={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <span className="light">1234567890</span>
                                </Col>
                            </li>
                            <li className="body-list-group font-field" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Col  xl={5} md={5} xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingLeft: '10px' }}>
                                    <span className="bold">KYC : </span>
                                </Col>
                                <Col xl={8} md={8} xs={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <span className="light">N</span>
                                </Col>
                            </li>
                            <li className="body-list-group font-field" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Col xl={5} md={5} xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <span className="bold">ตรวจสอบ : </span>
                                </Col>
                                <Col xl={8} md={8} xs={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <span className="light"><CheckIcon style={{ color: '#4CAF50' }} /></span>

                                </Col>
                            </li>
                            <li className="body-list-group font-field" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Col xl={5} md={5} xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <span className="bold">สำเนาสูติบัตรของผู้เยาว์เซ็นสำเนาถูกต้อง : </span>
                                </Col>
                                <Col xl={8} md={8} xs={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <span className="light" style={{color:'#4CAF50'}}>filename.png</span>
                                </Col>
                            </li>
                            <li className="body-list-group font-field" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Col xl={5} md={5} xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <span className="bold">สำเนาบัตรประชาชนของผู้ปกครอง โดยชอบธรรม เซ็นสำเนาถูกต้อง : </span>
                                </Col>
                                <Col xl={8} md={8} xs={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <span className="light" style={{color:'#4CAF50'}}>filename.png</span>
                                </Col>
                            </li>
                            <li className="body-list-group font-field" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Col xl={5} md={5} xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <span className="bold">รูปถ่ายหน้าตรงคู่กับบัตรประชาชนของผู้ปกครองโดยชอบธรรม : </span>
                                </Col>
                                <Col xl={8} md={8} xs={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <span className="light" style={{color:'#4CAF50'}}>filename.png</span>
                                </Col>
                            </li>
                            <li className="body-list-group font-field" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Col xl={5} md={5} xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <span className="bold">รูปถ่ายหน้าตรงที่เป็นปัจจุบันของผู้เยาว์ : </span>
                                </Col>
                                <Col xl={8} md={8} xs={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <span className="light" style={{color:'#4CAF50'}}>filename.png</span>
                                </Col>
                            </li>
                            <li className="body-list-group font-field" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Col xl={5} md={5} xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <span className="bold">สำเนาสมุดบัญชีเงินฝากธนาคาร : </span>
                                </Col>
                                <Col xl={8} md={8} xs={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <span className="light" style={{color:'#4CAF50'}}>filename.png</span>
                                </Col>
                            </li>
                            <li className="body-list-group font-field" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Col xl={5} md={5} xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <span className="bold">สำเนาทะเบียนบ้านของผู้เยาว์ เซ็นสำเนาถูกต้อง  : </span>
                                </Col>
                                <Col xl={8} md={8} xs={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <span className="light" style={{color:'#4CAF50'}}>filename.png</span>
                                </Col>
                            </li>
                            <li className="body-list-group font-field" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Col xl={5} md={5} xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <span className="bold">สำเร็จ : </span>
                                </Col>
                                <Col xl={8} md={8} xs={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <span className="light" style={{color:'#4CAF50'}}>สำเร็จ</span>
                                </Col>
                            </li>
                            <li className="body-list-group font-field" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Col xl={5} md={5} xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <span className="bold">แก้ไข : </span>
                                </Col>
                                <Col xl={8} md={8} xs={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    {/* <span className="light" style={{ color: '#4CAF50' }}><EditIcon /></span> */}
                                    <a href="/editBenefit" type="button" className="ml-2"><EditIcon style={{ color: '#4CAF50' }} /></a>

                                </Col>
                            </li>
                            {/* <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Customer</TableCell>
                                        <TableCell align="right">Amount</TableCell>
                                        <TableCell align="right">Total price ($)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.history.map((historyRow) => (
                                        <TableRow key={historyRow.date}>
                                            <TableCell component="th" scope="row">
                                                {historyRow.date}
                                            </TableCell>
                                            <TableCell>{historyRow.customerId}</TableCell>
                                            <TableCell align="right">{historyRow.amount}</TableCell>
                                            <TableCell align="right">
                                                {Math.round(historyRow.amount * row.price * 100) / 100}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table> */}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const rows = [
    createData(1, 'สมหมาย ใจดี', '1234567890123', '0811234567'),
    createData(2, 'สม ใจดี', '3456783746578', '0811234567'),
    createData(3, 'หมาย ใจดี', '1267465890968', '0811234567'),
    createData(4, 'สมร ใจ', '0998475869095', '0811234567'),
    createData(5, 'สมหมาย ดี', '234748593009', '0811234567'),




];
const theme = createMuiTheme({
    overrides: {
        MuiTableHead: {
            root: {
                backgroundColor: '#FFA500', // กำหนดสีของ header column เป็นสีส้ม
            },
        },
    },
});
const useStyles = makeStyles({
    tableCell: {
        padding: '5px',
    },
    tableRow: {
        '& > .MuiTableCell-root': {
            padding: '5px',
            fontSize:'0.7rem'

        },
    },
});


export default function CollapsibleTable()
{
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow className={classes.tableRow} style={{ backgroundColor: '#e7e7e786' }}>
                            <TableCell align="center" style={{ width: 200, minWidth: 8 }}>No.</TableCell>
                            <TableCell align="center" style={{ width: 200, minWidth: 70 }}>ชื่อ</TableCell>
                            <TableCell align="center" style={{ width: 200, minWidth: 30 }}>เลขประจำตัวประชาชน</TableCell>
                            <TableCell align="center" style={{ width: 200, minWidth: 50 }}>เบอร์โทร</TableCell>
                            <TableCell align="center" style={{ width: 200, minWidth: 8, padding: '1px' }} />

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <Row key={row.name} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </ThemeProvider>


    );
}
