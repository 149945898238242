import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { Form } from "react-bootstrap";
import axios from 'axios';
import PopUp from '../Component/Popup';
import Header_Home from '../Component/Header_Home';

let province_id = 0;
let amphur_id = 0;
class EditAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {date: new Date() ,
        token: '',
        username:'',
        step:1,
        offset: 0,
        data: [],
        perPage: 50,
        currentPage: 0,
        pageCount:0,
        showing_step:1,
        showing_card2:false,
        showing_card3:false,
        showing_all:false,
        postcode:null,
        loading:true
    }
    }

    receivedData() {
         
        fetch(process.env.REACT_APP_API_BASEURL + "/api/personal/getaddress",
        // fetch(process.env.REACT_APP_API_BASEURL + "/api/personal/getaddress?customer="+localStorage.getItem('customer_id'),
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { 
            //console.log(res)
            if(res.success){
                const data = res.payload;
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                if(data.length > 1){
                    this.setState({showing_all : true})
                }
                const postData = slice.map(item => 
                    <div className="col-lg-8 col-md-12 col-sm-12 margin-auto">
                        <div className="card">  
                            <div className="card-body">
                                <div className="row col-12">
                                    <div className="col-1 text-right">
                                    <input   onChange={this.handleChange("policy_change")}  type="checkbox" id= {item.policy_no} name={item.policy_no} value={item.policy_no} className="margin-left-px mt-3" disabled={this.state.showing2}></input>
                                    </div>
                                    <div className="col-9">
                                        <label className='text-righ mt-2'><strong>หมายเลขกรมธรรม์ : </strong> {item.policy_no}</label>
                                        <div className="row col-12">
                                            <div className="col-12 p-0">
                                                <label className='text-righ mt-2'>{item.address1}</label>
                                            </div>
                                        </div>
                                        <div className="row col-12">
                                            <div className="col-12 p-0">
                                            <label className='text-righ mt-2'>{item.address2}</label>
                                            </div>
                                        </div>
    
                                        <div className="row col-12">
                                            <div className="col-12 p-0">
                                            <label className='text-righ mt-2'>{item.address3}</label>
                                            </div>
                                        </div>
                                        <div className="row col-12">
                                            <div className="col-12 p-0">
                                            <label className='text-righ mt-2'>{item.address4}</label>
                                            </div>
                                        </div>
                                        <div className="row col-12">
                                            <div className="col-12 p-0">
                                            <label className='text-righ mt-2'>{item.address5}</label>
                                            </div>
                                        </div>
                                        {/* <div>
                                            <label className='text-righ mt-2'><strong>ถนน : </strong> {item.street}</label>
                                        </div>
                                        <div>
                                            <label className='text-righ mt-2'><strong>แขวง/ตำบล : </strong> {item.kwang}</label>
                                        </div>
                                        <div>
                                            <label className='text-righ mt-2'><strong>เขต/อำเภอ : </strong> {item.khet}</label>
                                        </div>
                                        <div>
                                            <label className='text-righ mt-2'><strong>จังหวัด : </strong> {item.province}</label>
                                        </div>
                                        <div>
                                            <label className='text-righ mt-2'><strong>รหัสไปรษณีย์ : </strong> {item.zip}</label>
                                        </div> */}
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                    
                        
                )
                this.setState({payload : res.payload,
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    postData, loading:false})
                
            } else {
                this.setState({
                    showing_step: 10, 
                    loading:false
                });
            }
            
        })
        .catch(function(res){ console.log(res) })

    }

    getProvice() {
        fetch(process.env.REACT_APP_API_BASEURL + '/api/personal/getprovince',
        {
            headers: {
            'authorization':  localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { 
            //console.log(res)
            this.setState({province : res.payload})           
            const province = res.payload;
            var province_select = document.getElementById("province");
            var option = document.createElement("option");
            var opt = new Option('กรุณาเลือก', '-1');
                opt.disabled = true;
                opt.selected = true;
                opt.hidden = true;
                province_select.insertBefore(opt, province_select.firstChild);        
            const drop_province =  province.map(item => {
                var option = document.createElement("option");
                option.value = item.code
                option.text = item.name;
                province_select.add(option);
            })
            this.setState({drop_province})
        })
                
                
        .catch(function(res){ console.log(res) })
    }

    getAmphur(province_id) {
        fetch(process.env.REACT_APP_API_BASEURL + '/api/personal/getamphur?province_id='+province_id,
        {
            headers: {
            'authorization':  localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { 
            //console.log(res)
            this.setState({province : res.payload})           
            const khet = res.payload;
            var khet_select = document.getElementById("khet");
            var option = document.createElement("option");
            // option.value = localStorage.getItem('agent');
            // option.text = "ALL SM";
            // x.add(option);
            // khet_select.remove();
            for (var i = khet_select.length - 1; i >= 0; i--){
                // if (someCondition) {
                    khet_select.remove(i);
                // }
            }
            var opt = new Option('กรุณาเลือก', '-1');
                opt.disabled = true;
                opt.selected = true;
                opt.hidden = true;
                khet_select.insertBefore(opt, khet_select.firstChild);        
            const drop_khet =  khet.map(item => {
                var option = document.createElement("option");
                option.value = item.code
                option.text = item.name;
                khet_select.add(option);
            })
            this.setState({drop_khet,list_khet : khet})
            document.getElementById("khet").disabled = false
        })
                
                
        .catch(function(res){ console.log(res) })
    }

    getDistrict(amphur_id,province_id) {
        console.log("value",amphur_id, province_id)
        fetch(process.env.REACT_APP_API_BASEURL + '/api/personal/getdistrict?code='+amphur_id,
        {
            headers: {
            'authorization':  localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { console.log("getDistrict",res)
            this.setState({province : res.payload})           
            const kwang = res.payload;
            var kwang_select = document.getElementById("kwang");
            var option = document.createElement("option");

            console.log("kwang",kwang)
            for (var i = kwang_select.length - 1; i >= 0; i--){
                // if (someCondition) {
                    kwang_select.remove(i);
                // }
            }
            var opt = new Option('กรุณาเลือก', '-1');
                opt.disabled = true;
                opt.selected = true;
                opt.hidden = true;
                kwang_select.insertBefore(opt, kwang_select.firstChild);        
            const drop_kwang =  kwang.map(item => {
                var option = document.createElement("option");
                option.value = item.code
                option.text = item.name;
                kwang_select.add(option);
            })
            this.setState({drop_kwang})
            document.getElementById("kwang").disabled = false
        })
                
                
        .catch(function(res){ console.log(res) })
    }

    getPostal(amphur_id,kwang_id) {
        console.log("value",amphur_id, kwang_id)
        fetch(process.env.REACT_APP_API_BASEURL + '/api/form/getpostal?district_code='+amphur_id+'&subdistrict_code='+kwang_id,
        {
            headers: {
            'authorization':  localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { console.log("getPostal",res)
      
            document.getElementById("zip").value  = res.payload[0].zip
        })
                
                
        .catch(function(res){ console.log(res) })
    }

    componentDidMount() {
        fetch(process.env.REACT_APP_API_BASEURL + "/api/user/getprofile",
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { 
            console.log("profile.check" , res)
            if(res.success == 'true'){
                if(res.payload.profile == 'POS2'){
                    window.location.href ='/home';
                }
            }
        })
        // if(localStorage.getItem('profile') == 'POS2'){
        //     window.location.href ='/home';
        // }

        console.log('componentDidMount')
        this.receivedData()
        this.getProvice()
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    handleselectedFile  = input => e => {
        var file = e.target.files[0];
        if([input] == "fileName1") {
            var a = document.getElementById('showname1'); //or grab it by tagname etc
            a.href = URL.createObjectURL(file)//[reader.result]
        }
        this.setState({
            selectedFile: e.target.files[0],
            //**** added line below ****//
            file_ori: e.target.files[0].name,
            [input]: e.target.files[0].name
        });
    };
    componentDidUpdate() {
       
        console.log('componentDidUpdate')
       
        // if(document.getElementById("khet").value !== '-1' && document.getElementById("kwang").disabled && this.state.khet){
        //     this.state.list_khet.map(item => {

        //       if(item.amphur_id == document.getElementById("khet").value)  {
        //         document.getElementById("zip").value  = item.postcode
        //       }
        //     })
        //     // console.log(this.state.list_khet)
        //     this.getDistrict(document.getElementById("khet").value,document.getElementById("province").value)
        // }
    }

    componentWillUnmount() {
        console.log('componentWillUnmount')
       
    }

    con () {
        const { payload} = this.state;
        const data = payload;
        const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        const postData2 = slice.map(item => 
            this.item_change(item)
        )
        this.setState({postData2})
    }

    item_change (item) {
        let make_province = document.getElementById("province");
        let make_kwang = document.getElementById("kwang");
        let make_khet = document.getElementById("khet");
        let number = document.getElementById("number").value
        let building = document.getElementById("building").value
        let moo = document.getElementById("moo").value
        let soi = document.getElementById("soi").value
        let street = document.getElementById("street").value
        let kwang = make_kwang.options[make_kwang.selectedIndex].textContent
        let khet = make_khet.options[make_khet.selectedIndex].textContent
        let province = make_province.options[make_province.selectedIndex].textContent
        let zip = document.getElementById("zip").value
        if(document.getElementById(item.policy_no).checked) {
            return (
                <div className="card-body list-group p-0">
                    <div className="row col-lg-9 col-md-12 p-0 margin-auto">
                        <div className="card p-0">  
                            <div className="card-body header-card">
                            <label className='text-righ mt-2'><strong>หมายเลขกรมธรรม์ : </strong> {item.policy_no}</label>
                            </div> 
                            <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <div>
                                <label className='text-righ mt-2'><strong>ที่อยู่เก่า </strong></label>
                                </div>
                                <div className="row col-12">
                                    <div className="col-12 p-0">
                                        <label className='text-righ mt-2'>{item.address1}</label>
                                    </div>
                                </div>
                                <div className="row col-12">
                                    <div className="col-12 p-0">
                                    <label className='text-righ mt-2'>{item.address2}</label>
                                    </div>
                                </div>

                                <div className="row col-12">
                                    <div className="col-12 p-0">
                                    <label className='text-righ mt-2'>{item.address3}</label>
                                    </div>
                                </div>
                                <div className="row col-12">
                                    <div className="col-12 p-0">
                                    <label className='text-righ mt-2'>{item.address4}</label>
                                    </div>
                                </div>
                                <div className="row col-12">
                                    <div className="col-12 p-0">
                                    <label className='text-righ mt-2'>{item.address5}</label>
                                    </div>
                                </div>
                            </li>

                            <li className="list-group-item">
                                <div>
                                    <label className='text-righ mt-2'><strong>ที่อยู่ใหม่ </strong></label>
                                </div>
                                <div className="row col-12">
                                    <div className="col-lg-6 col-md-12 p-0">
                                        <label className='text-righ mt-2'><strong>เลขที่ : </strong> {number}</label>
                                    </div>
                                    <div className="col-lg-6 col-md-12 p-0">
                                        <label className='text-righ mt-2'><strong>อาคาร/หมู่บ้าน : </strong> {building}</label>
                                    </div>
                                </div>
                                <div className="row col-12">
                                    <div className="col-lg-6 col-md-12 p-0">
                                    <label className='text-righ mt-2'><strong>หมู่ที่ : </strong> {moo}</label>
                                    </div>
                                    <div className="col-lg-6 col-md-12 p-0">
                                    <label className='text-righ mt-2'><strong>ซอย : </strong> {soi}</label>
                                    </div>
                                </div>
                                <div>
                                    <label className='text-righ mt-2'><strong>ถนน : </strong> {street}</label>
                                </div>
                                <div>
                                    <label className='text-righ mt-2'><strong>แขวง/ตำบล : </strong> {kwang}</label>
                                </div>
                                <div>
                                    <label className='text-righ mt-2'><strong>เขต/อำเภอ : </strong> {khet}</label>
                                </div>
                                <div>
                                    <label className='text-righ mt-2'><strong>จังหวัด : </strong> {province}</label>
                                </div>
                                <div>
                                    <label className='text-righ mt-2'><strong>รหัสไปรษณีย์ : </strong> {zip}</label>
                                </div>
                            </li>                     
                        </ul>
                        
                        </div>   
                    </div>              
                </div>    
            )
        }
    }

    handleChange = input => e => {
        console.log([input],e.target.value)
        
        this.setState({ [input]: e.target.value });
        console.log({ [input]: e.target.value })

        if([input] == 'province'){
            let make = document.getElementById("province");
            this.getAmphur(document.getElementById("province").value)
            // console.log("aaa",make.options[make.selectedIndex].textContent)
        }

        if([input] == 'khet'){
            
            // console.log(this.state.list_khet)
            this.getDistrict(document.getElementById("khet").value,document.getElementById("province").value)
        }

        if([input] == 'kwang'){

            // console.log(this.state.list_khet)
            this.getPostal(document.getElementById("khet").value,document.getElementById("kwang").value)
        }
    };

    select_all = input => e =>  {
        // const {payload} = this.state;
        const {payload} = this.state;
        let policies = [];
        let body = [];
        if(document.getElementById("all").checked){
            for (let item of payload) {

                document.getElementById(item.policy_no).checked = true
            }
        } else {
            for (let item of payload) {

                document.getElementById(item.policy_no).checked = false
            }
        }
    }

    next () {
        const { showing_step} = this.state;
        let num = 1 ;
        var txt_error = 'กรุณากรอกข้อมูล';
        console.log("showing_step",showing_step)
        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        var link ='#'
        if(showing_step === 1) {
            let make_province = document.getElementById("province");
            let make_kwang = document.getElementById("kwang");
            let make_khet = document.getElementById("khet");
            let number = document.getElementById("number").value
            let building = document.getElementById("building").value
            let moo = document.getElementById("moo").value
            let soi = document.getElementById("soi").value
            let street = document.getElementById("street").value
            let kwang = make_kwang.options[make_kwang.selectedIndex].textContent
            let khet = make_khet.options[make_khet.selectedIndex].textContent
            let province = make_province.options[make_province.selectedIndex].textContent
            let zip = document.getElementById("zip").value
            // let laser_code = document.getElementById("laser_code").value
            if(!number && number !== undefined){
                document.getElementById("error_number").innerHTML = `<span name="#number" class="error">` + txt_error +  ` </span>`
                num === 1?link ='#number':link = link
                num++;
            } else {
                document.getElementById("error_number").innerHTML = ` `
            }
            if(!building && building !== undefined){
                document.getElementById("error_building").innerHTML = `<span name="#building" class="error">` + txt_error +  ` </span>`
                num === 1?link ='#building':link = link
                num++;
            } else {
                document.getElementById("error_building").innerHTML = ` `
            }
            if(!moo && moo !== undefined){
                document.getElementById("error_moo").innerHTML = `<span name="#moo" class="error">` + txt_error +  ` </span>`
                num === 1?link ='#moo':link = link
                num++;
            } else {
                document.getElementById("error_moo").innerHTML = ` `
            }
            if(!soi && soi !== undefined){
                document.getElementById("error_soi").innerHTML = `<span name="#soi" class="error">` + txt_error +  ` </span>`
                num === 1?link ='#soi':link = link
                num++;
            } else {
                document.getElementById("error_soi").innerHTML = ` `
            }
            if(!street && street !== undefined){
                document.getElementById("error_street").innerHTML = `<span name="#street" class="error">` + txt_error +  ` </span>`
                num === 1?link ='#street':link = link
                num++;
            } else {
                document.getElementById("error_street").innerHTML = ` `
            }

            if (province === -1) {
                document.getElementById("error_province").innerHTML = `<span name="#province" class="error"> กรุณาเลือกจังหวัด </span>`
                num === 1?link ='#province':link = link
                num++;
            }else {
                document.getElementById("error_province").innerHTML = ` `
            }

            if (khet === -1) {
                document.getElementById("error_khet").innerHTML = `<span name="#khet" class="error"> กรุณาเลือกเขต/อำเภอ </span>`
                num === 1?link ='#khet':link = link
                num++;
            } else {
                document.getElementById("error_khet").innerHTML = ` `
            }

            if (kwang === -1) {
                document.getElementById("error_kwang").innerHTML = `<span name="#kwang" class="error"> กรุณาเลือกแขวง/ตำบล </span>`
                num === 1?link ='#kwang':link = link
                num++;
            } else {
                document.getElementById("error_kwang").innerHTML = ` `
            }
            // if(!laser_code && laser_code !== undefined){
            //     document.getElementById("error_laser_code").innerHTML = `<span name="#laser_code" class="error">` + txt_error +  ` </span>`
            //     num++;
            // } else {
            //     document.getElementById("error_laser_code").innerHTML = ''
                
            // }

            if(num == 1) {
                const { payload} = this.state;
                let data = payload;
                let slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                let count_policy = 0;
                let loop = slice.map(item => {
                    if(document.getElementById(item.policy_no).checked){
                        count_policy++;
                    }
                })
                if(count_policy == 0) {
                    alert("กรุณาเลือกกรมธรรม์ที่ต้องการแจ้งเปลี่ยน");
                    num++;
                }
            }
        
        }
        else if (showing_step === 2) {
            if(!document.getElementById('file1').files.length>0){
                alert('เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ')
                num++;
            } else {
                let type =  document.getElementById('file1').files[0].type 
                
                if(!(type === 'image/jpeg' || type === 'image/jpg' ||type === 'image/png' ||type === 'application/pdf' )){
                    alert("กรุณาอัพโหลดเอกสารนามสกุล .jpeg, .png, .pdf เท่านั้น")
                    num++;
                }
            }
           
        }
        if(num === 1 ){
            this.setState({
                showing_step: showing_step+1
            });
            this.con();
        } else {
            window.location.href= link;
        }
        
    }

    back () {
        const {showing_step} = this.state;
        this.setState({
            showing_step: showing_step-1
        });
        this.con();
    }

    save () {
        this.setState({loading:true})
        const {payload} = this.state;
        let policies = [];
        let make_province = document.getElementById("province");
        let make_kwang = document.getElementById("kwang");
        let make_khet = document.getElementById("khet");
        let number = document.getElementById("number").value
        let building = document.getElementById("building").value
        let moo = document.getElementById("moo").value
        let soi = document.getElementById("soi").value
        let street = document.getElementById("street").value
        let kwang = make_kwang.options[make_kwang.selectedIndex].textContent
        let khet = make_khet.options[make_khet.selectedIndex].textContent
        let province = make_province.options[make_province.selectedIndex].textContent
        let zip = document.getElementById("zip").value
        for (let item of payload) {
            if(document.getElementById(item.policy_no).checked){
                let policy = {};
                policy.policy_no = item.policy_no;
                policy.policy_number = item.id;
                policy.number = number;
                policy.building = building;
                policy.moo = moo;
                policy.soi = soi;
                policy.street = street;
                policy.kwang = kwang;
                policy.khet = khet;
                policy.province = province;
                policy.zip = zip;

                policy.address1 = item.address1;
                policy.address2 = item.address2;
                policy.address3 = item.address3;
                policy.address4 = item.address4;
                policy.address5 = item.address5;

                policies.push(policy);
            }
            
            
        }
        // let laser_code = document.getElementById("laser_code").value
        // laser_code = laser_code.replaceAll("-", "");
        let save = {};
        save.policies = policies;
        // save.laser_code = laser_code;
        var self = this;
        console.log("body",save)
        fetch(process.env.REACT_APP_API_BASEURL + '/api/personal/address', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization':  localStorage.getItem('token_login')
                
            },
            body: JSON.stringify(save),
        })
        .then(res => res.json())
        
        .then( res => { 
            //console.log(res)
           console.log("body_save",res)
           if(res.success) {
                document.querySelector("input[type='submit']").click();
            } else {
                console.log(res.payload.error,res.payload)
                self.setState({loading:false}) 
                alert( res.payload.error)
            }
           
        //    document.querySelector("input[type='submit']").click();
        })
        .catch(function(res){ console.log(res) })
    }

    submitUpload = (event) => {

        const {payload} = this.state;
        let num = 0;
        var policy = '' 
        for (let item of payload) {
            if(document.getElementById(item.policy_no).checked){
                num++
                if(num == 1){
                    policy += item.policy_no
                } else {
                    policy += ','+item.policy_no
                }
            }
        }
        
        // event.preventDefault();
        let url = process.env.REACT_APP_API_BASEURL + '/api/personal/upload'
        event.preventDefault();

        
        let file_name1 = undefined;
        let file1;

        if(event.target[0].files[0]){
            file_name1 = event.target[0].files[0].name;
            file1 = event.target[0].files[0];
        }

        const formData = new FormData();        
        formData.append('image1', file1, file_name1);
        formData.append('policy', policy);
        // formData.append('customer', localStorage.getItem('customer_id'));
        formData.append('type', '1');
        var self = this;
        for (var [key, value] of formData.entries()) { 
            console.log(key, value);
        }
        let response =  axios.post(url, formData, {
            headers: {
                'authorization': localStorage.getItem('token_login'),
                'content-type': 'multipart/form-data'
            }
        
        }).then(function(result) {
            console.log("result",result)
            self.setState({loading:false}) 
            if (!result.data.success) {
                console.log('error: ' + result.data.error);
                alert( result.data.error)
                
            } else {
                alert( "บริษัทได้รับทราบข้อมูลจากท่านแล้ว ท่านจะได้รับ SMS เพื่อดาวน์โหลดเอกสารการเปลี่ยนแปลงภายใน 5 วันทำการ ขอบคุณค่ะ")
                window.location.href="/personal";
            }
         })
    };

 
    render() {
        const { showing_step, postData, postData2, showing_all,drop_province, drop_kwang, drop_khet, loading} = this.state;
        return (
            <div>
                {loading ? <PopUp /> : null}
                <Header_Home/>
                <section className="d-flex justify-content-center align-items-center h-100 my-5">
                    <div className="container-fluid  container-margin">
                        <div className='row'>
                            <div className="col-12">
                                <div className="card" style={{ display: (showing_step === 1 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>เปลี่ยนที่อยู่รับเอกสาร</strong></label>
                                            </div>
                                        </div>       
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 ">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="number" className='text-righ mt-2'><strong>เลขที่</strong></label>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-sm-6 col-xs-6">
                                                <input style={{borderWidth: 1}} type="text" id="number" name="number" maxLength="50" className="margin-left-10-px form-control" placeholder="กรณีไม่มีข้อมูล ให้ใส่เครื่องหมายขีดกลาง (-)"></input>
                                                <label id="error_number"></label>
                                            </div>
                                        </div>           

                                        <div className="row col-12 ">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="building" className='text-righ mt-2'><strong>อาคาร/หมู่บ้าน</strong></label>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-sm-6 col-xs-6">
                                                <input style={{borderWidth: 1}}  type="text" id="building" name="building" maxLength="50" className="margin-left-10-px form-control" placeholder="กรณีไม่มีข้อมูล ให้ใส่เครื่องหมายขีดกลาง (-)"></input>
                                                <label id="error_building"></label>
                                            </div>
                                        </div>           
                            
                                        <div className="row col-12 ">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="moo" className='text-righ mt-2'><strong>หมู่ที่</strong></label>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-sm-6 col-xs-6">
                                                <input style={{borderWidth: 1}}  type="text" id="moo" name="moo" maxLength="50" className="margin-left-10-px form-control" placeholder="กรณีไม่มีข้อมูล ให้ใส่เครื่องหมายขีดกลาง (-)"></input>
                                                <label id="error_moo"></label>
                                            </div>
                                        </div>           

                                        <div className="row col-12 ">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="soi" className='text-righ mt-2'><strong>ซอย</strong></label>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-sm-6 col-xs-6">
                                                <input style={{borderWidth: 1}}  type="text" id="soi" name="soi" maxLength="50" className="margin-left-10-px form-control" placeholder="กรณีไม่มีข้อมูล ให้ใส่เครื่องหมายขีดกลาง (-)"></input>
                                                <label id="error_soi"></label>
                                            </div>
                                        </div>           
                   
                                        <div className="row col-12 ">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="street" className='text-righ mt-2'><strong>ถนน</strong></label>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-sm-6 col-xs-6">
                                                <input style={{borderWidth: 1}}  type="text" id="street" name="street" maxLength="50" className="margin-left-10-px form-control" placeholder="กรณีไม่มีข้อมูล ให้ใส่เครื่องหมายขีดกลาง (-)"></input>
                                                <label id="error_street"></label>
                                            </div>
                                        </div>           
                    
                                          

                                        <div className="row col-12 ">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="province" className='text-righ mt-2'><strong>จังหวัด</strong></label>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-sm-6 col-xs-6">
                                                    <select style={{borderWidth: 1}}  className="margin-left-10-px form-control" name="province" id="province" onChange={this.handleChange("province")}   >
                                                        <option value='-1' disabled selected hidden>select</option>
                                                        {drop_province}
                                                    </select>     <label id="error_province"></label>
                                            </div>
                                        </div>           
                                          
       
                                        <div className="row col-12 ">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="khet" className='text-righ mt-2'><strong>เขต/อำเภอ</strong></label>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-sm-6 col-xs-6">
                                                <select style={{borderWidth: 1}}  className="margin-left-10-px form-control" name="khet" id="khet" onChange={this.handleChange("khet")} disabled>
                                                    {/* <option value='-1' disabled selected hidden>select</option> */}
                                                    <option value='-1' disabled selected hidden>select</option>
                                                    {drop_khet}
                                                </select> 
                                                <label id="error_khet"></label>
                                            </div>
                                        </div>      

                                        <div className="row col-12 ">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="subject" className='text-righ mt-2'><strong>แขวง/ตำบล</strong></label>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-sm-6 col-xs-6">
                                                <select style={{borderWidth: 1}}  className="margin-left-10-px form-control" name="kwang" id="kwang" onChange={this.handleChange("kwang")} disabled>
                                                    {/* <option value='-1' disabled selected hidden>select</option> */}
                                                    <option value='-1' disabled selected hidden>select</option>
                                                    {drop_kwang}
                                                </select>                                                 
                                                <label id="error_kwang"></label>
                                            </div>
                                        </div>         

                                        <div className="row col-12 ">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="subject" className='text-righ mt-2'><strong>รหัสไปรษณีย์</strong></label>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-sm-6 col-xs-6">
                                                <input style={{borderWidth: 1}}  type="text" id="zip" name="zip" maxLength="50" className="margin-left-10-px form-control" disabled></input>
                                                <label id="error_zip"></label>
                                            </div>
                                        </div>         
                                        {/* <div className="row col-12 pt-2">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="subject" className='text-righ mt-2'><strong>เบอร์หลังบัตรประชาชน</strong></label>
                                            </div>
                                             <div className="col-3">
                                                <input  type="text" id="laser_code" name="laser_code" autocomplete="off" className="margin-left-10-px form-control"></input>
                                                    <label id="error_laser_code"></label>
                                            </div> 
                                        </div>    */}
                                    </div>

                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 p-0 margin-auto">
                                            <div className="col-12 pt-4">
                                                <div className="col-lg-8 col-md-12 col-sm-12 margin-auto" id="all_policy"  style={{ display: (showing_all? 'block' : 'none')}}>
                                                    <div className="card">  
                                                        <div className="card-body">
                                                            <div className="row col-12 ">
                                                                <div className="col-1 text-right">
                                                                <input onChange={this.select_all("select_all")} type="checkbox" id="all" name="all" value="all" className="margin-left-px mt-3" disabled={this.state.showing2}></input>
                                                                </div>
                                                                <div className="col-9">
                                                                    <label className='text-righ mt-2'> ทุกกรมธรรม์</label>
                                                                </div>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                                {postData} 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body App">
                                        <a  href="/personal" type="button" className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a>
                                        <a  onClick={() => this.next()} className="btn btn-lg btn-blue">ต่อไป</a>
                                    </div>
                                </div>

                                <div className="card" style={{ display: (showing_step === 2 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>อัพโหลดไฟล์</strong></label>
                                            </div>
                                        </div>      
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 ">
                                            
                                            <Form onSubmit={this.submitUpload} id="form_id">
                                                <div className="padding-title">
                                                    <div className="row col-12 ">
                                                        <div className="col-lg-6 col-md-8 col-sm-8 col-xs-8 text-right mt-3">
                                                            <label htmlFor="filename" className='text-righ'><strong>รูปถ่ายหน้าตรงที่ถ่ายคู่กับบัตรประชาชน</strong> <br/>รองรับไฟล์ .jpeg, .png, .pdf</label>
                                                        </div>
                                                        <div className="col-6 margin-auto">
                                                            <div className="col-7 text-left" align="right"  style={{ margin:'10px 0px 0px 0px' }}>
                                                                <label htmlFor="file1" className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i>  อัพโหลด
                                                                </label>
                                                                <a className='ml-2' id="showname1" target='_blank'>{this.state.fileName1}</a>
                                                                <input  type="file"  id="file1" accept="image/png, image/jpeg, image/jpg, application/pdf" style={{display:'none'}}  onChange={this.handleselectedFile("fileName1")}/>
                                                            </div>
                                                        </div>
                                                    </div>      
                                                </div>

                                                {/* <div className="padding-title">
                                                    <div className="row col-12 ">
                                                        <div className="col-lg-6 col-md-8 col-sm-8 col-xs-8 text-right mt-3">
                                                            <label htmlFor="filename" className='text-righ'><strong>รูปถ่ายหน้าตรงที่ถ่ายคู่กับบัตรประชาชน</strong> </label>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="col-7 text-left" align="right"  style={{ margin:'10px 0px 0px 0px' }}>
                                                                <label htmlFor="file2" className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i> อัพโหลด 
                                                                </label>
                                                                <label className='ml-2'>{this.state.fileName2}</label>
                                                                <input  type="file"  id="file2" accept="image/png, image/jpeg, image/jpg, application/pdf" style={{display:'none'}}  onChange={this.handleselectedFile("fileName2")}/>
                                                            </div>
                                                        </div>
                                                    </div>      
                                                </div> */}

                                            

                                                <div className="row dv-button">
                                                        
                                                    <input  type="submit" className="btn btn-primary button-right-page"  style={{display:'none'}}></input>                               
                                                </div>

                                            </Form>
                                                
                                        
                                        </div>               
                                    </div>
                                    <div className="card-body App">
                                        <a onClick={() => this.back()}  className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a> 
                                        <a onClick={() => this.next()} className="btn btn-lg btn-blue">ต่อไป</a>
                                    </div>
                                </div>

                                <div className="card" style={{ display: (showing_step === 3 ? 'block' : 'none')}}>
                                    <div className="card-body  header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>ยืนยันข้อมูล</strong></label>
                                            </div>
                                        </div>      
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 margin-auto">
                                    
                                            <div className="col-lg-10 col-md-12 margin-auto">
                                            {postData2} 

                                            <div className="card-body list-group p-0">
                                                <div className="col-lg-10 col-md-12 margin-auto p-0">
                                                    <div className="card p-0">  
                                                        <div className="card-body  header-card">
                                                        <label className='text-righ mt-2'><strong>เอกสารแนบ </strong></label>
                                                        </div> 
                                                        <ul className="list-group list-group-flush">
                                                        <li className="list-group-item">
                                                        <span className="bold">รูปถ่ายหน้าตรงที่ถ่ายคู่กับบัตรประชาชน &nbsp;&nbsp;:</span>
                                                        <span className="light">{this.state.fileName1}</span>
                                                        </li>
                                                       
                                                    </ul>
                                                    
                                                        
                                                    </div>   
                                                </div>              
                                            </div>   
                                            </div>
                                        </div>
                                       
                                                
                                    </div>
                                    <div className="card-body App">
                                        <a  onClick={() => this.back()} type="button" className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a>
                                        <a  onClick={() => this.save()} className="btn btn-lg btn-blue">บันทึก</a>
                                    </div>
                                    </div>

                                <div className="card" style={{ display: (showing_step === 10 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>เปลี่ยนที่อยู่รับเอกสาร</strong></label>
                                            </div>
                                        </div>       
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 pt-2">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="subject" className='text-righ mt-2'><strong>ไม่มีข้อมูลกรมธรรม์</strong></label>
                                            </div>
                                           
                                        </div>  
                                        
                                    </div>
                                   
                                    <div className="card-body App">
                                        <a  href="/personal" type="button" className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a>
                                        {/* <a  onClick={() => this.next()} className="btn btn-lg btn-blue">ต่อไป</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    

                        {/* <div className='App'>
                            <a  href="/home" type="button" className="btn btn-lg btn mt-3 cust-btn"  >ย้อนกลับ</a>
                        </div> */}
                        </div> 
                </section>
                </div> 
        )
    }
    
}

export default EditAddress;