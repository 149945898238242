import React, { useState, useEffect, useContext } from "react";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./PolicyList.css";
import menu_11 from "../../images/21.svg";
import menu_12 from "../../images/22.svg";
import menu_13 from "../../images/23.svg";
import menu_14 from "../../images/Mortgage.png";
// import menu_14 from "../../images/24.svg";
import { getPolicyInformationAPI } from "./PolicyListService/policyListService";
import { getParameterAPI } from "../PolicyDetail/PolicyDetailService/policyDetailService";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { CurrencyFormater, CurrencyFormaterNonDec } from "../../util";
import moment from "moment/moment";
import CryptoJS from "crypto-js";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Modal from "react-modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import logoLine from "../../images/19201080-forNoteBookpcNBG.png";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import iconSearch from "../../images/icon-search-2.png";
import { StoreContext } from "../../context/store";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const PolicyList = () => {
  const { dataPolicyActive, updateDataPolicyActive } = useContext(StoreContext);
  const [LoadingContent, setLoadingContent] = useState(true);
  const [dataMaster, setDataMaster] = useState([]);
  const [rdConsent, setRdConsent] = useState([]);
  const [activeIndex, setActiveIndex] = useState(dataPolicyActive ?? 0);
  const [insureIdCard, setInsureIdCard] = useState(
    localStorage.getItem("customer_id")
  );
  const [receipt, setReceipt] = useState([]);
  const [option, setOption] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setSearchBasePolicy("");
  };
  const [searchBasePolicy, setSearchBasePolicy] = useState("");
  const [activeDataModal, setActiveDataModal] = useState(dataPolicyActive ?? 0);
  const [clickPre, setClickPre] = useState();
  const [clickForward, setClickForward] = useState();
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [cusTel, setCusTel] = useState("");
  let navigate = useNavigate();

  useEffect(() => {
    getPolicyInformation();
  }, [LoadingContent]);

  const handleSearchBasePolicy = (e) => {
    setSearchBasePolicy(e);
  };

  const handleConfrimSearch = () => {
    setActiveIndex(activeDataModal);
    updateDataPolicyActive(activeDataModal);
    handleCloseModal();
  };

  const handleSelectSearch = (idx) => {
    setActiveDataModal(idx);
  };

  const filteredOptions = option.filter((item) => {
    return searchBasePolicy.trim() === ""
      ? true
      : item.label.toLowerCase().includes(searchBasePolicy.toLowerCase());
  });

  const renderOptionRow = (val, idx) => {
    const isActive = activeDataModal === idx;

    return (
      <div key={idx}>
        <Row
          onClick={!isActive ? () => handleSelectSearch(idx) : undefined}
          style={isActive ? { backgroundColor: "#FF5E43" } : {}}
          className={`gap-1 border-top border-bottom border-1 rounded-1 ${
            isActive ? "text-white" : "text-danger pointer"
          }`}
        >
          <Col className="px-3 d-flex flex-row justify-content-between">
            <span>{val.label}</span>
            {isActive && (
              <ArrowCircleLeftOutlinedIcon style={{ color: "white" }} />
            )}
          </Col>
        </Row>
      </div>
    );
  };

  const getPolicyInformation = async () => {
    const result = await getPolicyInformationAPI(
      decryptCardNumber(insureIdCard, process.env.REACT_APP_ENCRYPT_DATA)
    );
    // console.log(result);
    if (result.status) {
      console.log("getPolicyInformation : ", result.data);
      let option = [];
      await result.data.policies.map((item, idx) => {
        let data = {
          label: item.policyNo,
          id: idx,
        };
        option.push(data);
      });
      await console.log("OPt : ", option);
      await setOption(option);
      await setDataMaster(result.data.policies);
      await setRdConsent(
        result.data.rdConsent.length > 0 ? result.data.rdConsent : []
      );
      await setLoadingContent(false);
    }
  };

  const goToPolicyDetail = () => {
    navigate(`/policyDetail?policyNo=${dataMaster[activeIndex].policyNo}`);
  };

  const goToPolicyRefund = () => {
    navigate(`/refundPolicy`, {
      state: {
        policyNo: dataMaster[activeIndex].policyNo,
      },
    });
  };

  const goToPayInsure = () => {
    navigate(`/payInsure`, { state: dataMaster[activeIndex] });
  };

  const goToPayment = () => {
    navigate(`/payment`, { state: dataMaster[activeIndex] });
  };

  const goToHome = () => {
    navigate(`/Home`);
  };

  const goToLoan = () => {
    navigate(`/policyLoan?policyNo=${dataMaster[activeIndex].policyNo}`);
  };

  const handleOwlChange = async (e) => {
    setActiveIndex(e);
    setActiveDataModal(e);
    updateDataPolicyActive(e);
  };

  useEffect(() => {
    setReceipt(dataMaster[activeIndex]);
  }, [dataMaster]);

  const handleAutocomplete = (val) => {
    setActiveIndex(val.id);
    updateDataPolicyActive(val.id);
    setValue(val.label);
  };

  const handleSetActiveDataModal = (val) => {
    setActiveIndex(val);
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    // { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    // { width: 850, itemsToShow: 3 },
    // { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
    // { width: 1450, itemsToShow: 5 },
    // { width: 1750, itemsToShow: 6 },
  ];

  const decryptCardNumber = (encryptedCardNumber, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedCardNumber, secretKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  };

  const [value, setValue] = React.useState(option[0]);

  const [open, setOpen] = useState(false);

  const getTel = async () => {
    const result = await getParameterAPI(12);
    const payload = result.payload;
    const cusTel = payload.find((item) => item.code === "CUS_SERV_TEL");
    const variable1 = cusTel.variable1;
    setCusTel(variable1);
  };

  const policyDueDate = dataMaster[activeIndex]?.policyDueDate;

  const validateDisablePolicyDueDate = (policyDueDate) => {
    if (!policyDueDate) {
      return false;
    }
    const [day, month, buddhistYear] = policyDueDate.split("/");
    const christianYear = parseInt(buddhistYear) - 543;
    const dueDate = new Date(`${christianYear}-${month}-${day}`);
    dueDate.setHours(0, 0, 0, 0);

    const nextMonth = new Date();
    // const nextMonth = new Date("2024-10-30");
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    nextMonth.setHours(0, 0, 0, 0);
  
    console.log("Due Date:", dueDate.toLocaleDateString());
    console.log("Next Month:", nextMonth.toLocaleDateString());
    console.log(nextMonth.getTime() === dueDate.getTime());

    return nextMonth.getTime() >= dueDate.getTime();
  };
  

  useEffect(() => {
    console.log(value);
    getTel();
  }, [value]);

  return (
    <div>
      <Header_Home />
      <Header title="ข้อมูลกรมธรรม์" />
      <div className="container">
        <Carousel
          emulateTouch={true}
          showThumbs={false}
          onChange={handleOwlChange}
          onClickItem={handleOwlChange}
          showArrows={false}
          showStatus={true}
          showIndicators={false}
          className="p-lg-4"
          breakPoints={breakPoints}
          selectedItem={activeIndex}
          statusFormatter={(currentItem, total) => {
            setCurrentItem(currentItem);
            setTotalItem(total);
          }}
          renderArrowPrev={(clickHandler, hasPrev) => {
            setClickPre(() => clickHandler); // ตั้งค่า clickHandler ใน state

            return null; // ไม่แสดงปุ่มลูกศรภายใน Carousel
          }}
          renderArrowNext={(clickHandler, hasNext) => {
            setClickForward(() => clickHandler); // ตั้งค่า clickHandler ใน state

            return null; // ไม่แสดงปุ่มลูกศรภายใน Carousel
          }}
        >
          {!LoadingContent &&
            dataMaster.map((item, idx) => {
              return (
                <div className="frame-policy-new mt-3">
                  <ul
                    className="list-group list-group-flush"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <li
                      className="body-list-group"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        fontSize: "15px",
                      }}
                    >
                      <h2
                        className="bold"
                        style={{
                          fontSize: "20px",
                          marginLeft: "20px",
                          alignItems: "start",
                          justifyItems: "start",
                          marginTop: "20px",
                        }}
                      >
                        {item.baseName}
                      </h2>
                      {!open && (
                        <div className="d-flex flex-row">
                          <AssignmentIcon
                            style={{
                              cursor: "pointer",
                              marginLeft: "20px",
                              alignItems: "start",
                              justifyItems: "start",
                              marginTop: "20px",
                            }}
                            color="warning"
                            onClick={handleOpenModal}
                          />
                        </div>
                      )}
                      {open && (
                        <Autocomplete
                          style={{
                            marginLeft: "20px",
                            alignItems: "start",
                            justifyItems: "start",
                            marginTop: "20px",
                          }}
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={option}
                          // disableCloseOnSelect={true}
                          forcePopupIcon={false}
                          onClose={() => setOpen(false)}
                          open={true}
                          key={(option) => option.id}
                          sx={{ width: 300 }}
                          value={value}
                          disableClearable={true}
                          ListboxProps={{ style: { maxHeight: 200 } }}
                          onChange={(e, val) => handleAutocomplete(val)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                      )}
                    </li>
                    <hr
                      style={{
                        margin: "0px 10px 15px 20px",
                        border: "solid 1px",
                        borderTop: 0.1,
                      }}
                    />
                    <li
                      className="body-list-group"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        fontSize: "15px",
                      }}
                    >
                      <span className="font-size-label">กรมธรรม์เลขที่</span>
                      <span className="bold">{item.policyNo}</span>
                    </li>
                    <li
                      className="body-list-group"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        fontSize: "15px",
                      }}
                    >
                      <span className="font-size-label">วันที่เริ่มสัญญา</span>
                      <span className="bold">{item.policyEffectiveDate}</span>
                    </li>
                    <li
                      className="body-list-group"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        fontSize: "15px",
                      }}
                    >
                      <span className="font-size-label">
                        วันที่ครบกำหนดสัญญา
                      </span>
                      <span className="bold">{item.policyMatureDate}</span>
                    </li>
                    <li
                      className="body-list-group"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        fontSize: "15px",
                      }}
                    >
                      <span className="font-size-label">สถานะกรมธรรม์</span>
                      <span className="bold">{item.policyStatus}</span>
                    </li>
                    <li
                      className="body-list-group"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // marginRight: "2px",
                        fontSize: "13px",
                      }}
                    >
                      <span className="font-size-label">
                        จำนวนเงินเอาประกันภัย
                      </span>
                      <span className="bold">
                        {CurrencyFormaterNonDec(item.sumAssure)}
                        <strong> บาท</strong>
                      </span>
                    </li>
                    <li
                      className="body-list-group"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        fontSize: "15px",
                      }}
                    >
                      <span className="font-size-label">วิธีการชำระเบี้ย</span>
                      <span className="bold">{item.policyPaymentMode}</span>
                    </li>
                    <li
                      className="body-list-group"
                      style={{
                        // display: "flex",
                        justifyContent: "space-between",
                        marginRight: "10px",
                      }}
                    ></li>
                  </ul>
                </div>
              );
            })}
        </Carousel>
        {LoadingContent && (
          <>
            <Row className="card-premium-loading d-flex">
              <Col className="mt-3">
                <Skeleton style={{ height: "30px" }} />
                <div className="d-flex flex-row justify-content-between">
                  <Skeleton />
                </div>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Col>
            </Row>
            <br />
          </>
        )}
        <div className="d-flex flex-row justify-content-center gap-2">
          <div
            onClick={clickPre}
            className="d-flex justify-content-center align-items-center pointer"
          >
            <ArrowBackIosIcon fontSize="small" color="warning" />
          </div>
          <div
            style={{
              backgroundColor: "rgba(255, 228, 224, 0.9)",
              height: 18,
              width: 18,
              fontSize: 10,
            }}
            className="rounded-3 text-secondary d-flex justify-content-center align-items-center"
          >
            {currentItem}
          </div>
          <div
            style={{ fontSize: 10 }}
            className="text-secondary d-flex justify-content-center align-items-center"
          >
            Of
          </div>
          <div
            style={{
              backgroundColor: "#F2F2F2",
              height: 18,
              width: 18,
              fontSize: 10,
            }}
            className="rounded-3 text-secondary d-flex justify-content-center align-items-center"
          >
            {totalItem}
          </div>
          <div
            onClick={clickForward}
            className="d-flex justify-content-center align-items-center pointer"
          >
            <ArrowForwardIosIcon fontSize="small" color="warning" />
          </div>
        </div>
        {LoadingContent ? (
          <Row className="d-flex justify-content-around px-3">
            <Col>
              <Skeleton style={{ width: "120px" }} />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col className="layoutService px-4">
              <h5 style={{ fontSize: "18px" }} className="bold">
                บริการอื่นๆ
              </h5>
            </Col>
          </Row>
        )}
        <br />
        {LoadingContent ? (
          <Row className="layoutService d-flex justify-content-around layout-mobile px-3">
            <Col className="d-flex flex-column layoutService">
              <Skeleton className="card-loading" />
            </Col>
            <Col className="d-flex flex-column layoutService">
              <Skeleton className="card-loading" />
            </Col>
            <Col className="d-flex flex-column layoutService">
              <Skeleton className="card-loading" />
            </Col>
            <Col className="d-flex flex-column layoutService">
              <Skeleton className="card-loading" />
            </Col>
          </Row>
        ) : (
          <Row className="mb-4">
            <Col className="layoutService d-flex justify-content-around layout-mobile px-3">
              <div onClick={goToPolicyDetail} className="card-service">
                <img className="img-menu-serivce" src={menu_11} alt="" />
                <p className="fontSizeCard">รายละเอียด</p>
                <p className="fontSizeCard">กรมธรรม์</p>
              </div>
              <div onClick={goToPayInsure} className="card-service">
                <img className="img-menu-serivce" src={menu_12} alt="" />
                <p className="fontSizeCard">ชำระเบี้ย</p>
                <p className="fontSizeCard">ประกันฯ</p>
              </div>
              <div onClick={goToPolicyRefund} className="card-service">
                <img className="img-menu-serivce" src={menu_13} alt="" />
                <p className="fontSizeCard">เงินคืนตาม</p>
                <p className="fontSizeCard">กรมธรรม์</p>
              </div>
              <div onClick={goToLoan} className="card-service">
                <img className="img-menu-serivce" src={menu_14} alt="" />
                <p className="fontSizeCard">ตรวจสอบ</p>
                <p className="fontSizeCard">สิทธิกู้ยืม</p>
              </div>
            </Col>
          </Row>
        )}

        {LoadingContent && (
          <>
            <Row className="card-premium d-flex">
              <Col className="mt-3">
                <Skeleton />
                <div className="d-flex flex-row justify-content-between">
                  <Skeleton />
                </div>
                <Skeleton />
                <Skeleton />
                <p className="text-danger">
                  <Skeleton />
                </p>
              </Col>
            </Row>
          </>
        )}

        {!LoadingContent && dataMaster.length > 0 && (
          <Row className="card-premium d-flex">
            <Col className="mt-3">
              <h5 style={{ fontSize: "18px" }} className="bold">
                การชำระเบี้ยประกัน
              </h5>
              <div className="d-flex flex-row justify-content-between">
                <p className="card-premium-font-size-lable">
                  วันครบกำหนดชำระเบี้ย
                </p>
                <p className="card-premium-font-size-lable bold">
                  {dataMaster[activeIndex].policyDueDate}
                </p>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <p className="card-premium-font-size-lable">
                  เบี้ยประกันที่ต้องชำระ
                </p>
                <p className="card-premium-font-size-lable bold">
                  {CurrencyFormaterNonDec(dataMaster[activeIndex].policyRemainPremiumAmount)
                  }{" "}
                  บาท
                </p>
              </div>
              <p style={{ fontSize: "10px", color: "red" }}>
                หากเกินระยะเวลาที่กำหนด จำนวนเบี้ยประกันภัยที่ต้องชำระ
                อาจมีการเปลี่ยนแปลง กรุณาติดต่อตัวแทนประกันชีวิตที่ดูแลท่าน
                หรือติดต่อฝ่ายลูกค้าสัมพันธ์ {cusTel}
              </p>
            </Col>
          </Row>
        )}
        {!LoadingContent &&
          dataMaster[activeIndex].policyPaymentAllowFlag == "Y" &&
          dataMaster[activeIndex].paymentFlagIservice == "Y" && (
            <p style={{ fontSize: "10px",color:'red' }} className="p-2 px-3">
              บริษัทฯ ได้รับเงินค่าเบี้ยประกันจากท่านเรียบร้อย
              {/* บริษัทฯ ได้รับเงินค่าเบี้ยประกันจากท่านแล้ววันที่{" "}
              {moment(dataMaster[activeIndex].paymentActualDate).format(
                "DD/MM/yyyy"
              ) ?? ""}{" "}
              และ จะนำไปชำระเบี้ยประกันให้กับท่านเมื่อถึงวันครบกำหนดชำระ{" "}
              {dataMaster[activeIndex].policyDueDate ?? ""} */}
            </p>
          )}
        {!LoadingContent &&
          dataMaster[activeIndex].policyStatus == "ขาดผลบังคับ" && (
            <p style={{ fontSize: "10px", color: "red" }} className="p-2 px-3">
              เนื่องจากบริษัทฯ ยังไม่ได้รับชำระค่าเบี้ยประกัน
              จึงทำให้กรมธรรม์ของท่านขาดผลบังคับ
              เพื่อรักษาสิทธิประโยชน์ความคุ้มครอง กรุณาติดต่อ {cusTel}{" "}
              ขออภัยหากท่านชำระแล้ว ขอบคุณค่ะ
            </p>
          )}
        <Row className="d-flex flex-column mt-3" style={{ marginBottom: 75 }}>
          {!LoadingContent &&
            dataMaster[activeIndex].policyPaymentAllowFlag === "Y" &&
            dataMaster[activeIndex].paymentFlagIservice === "N" && (
              <Col className="d-flex justify-content-end px-5 gap-2">
                <button
                  style={{ fontSize: 15 }}
                  onClick={goToPayment}
                  className="btn-checkout-premium w-100 p-2 bold"
                  disabled={!validateDisablePolicyDueDate(policyDueDate)}
                >
                  ชำระเบี้ยประกัน
                </button>
              </Col>
            )}

          <Col className="d-flex justify-content-start px-5">
            <button
              onClick={goToHome}
              className="btn-checkout-back w-100 p-2 bold"
            >
              กลับสู่หน้าหลัก
            </button>
          </Col>
        </Row>
        <Modal
          isOpen={openModal}
          onRequestClose={handleCloseModal}
          contentLabel="ModalPolicy"
          className="Modal"
          overlayClassName="Overlay"
        >
          <div className="p-lg-2 p-3">
            <h3 style={{ color: "#FA0E0E", fontSize: "18px" }}>
              กรมธรรม์เลขที่
            </h3>
            <div className="d-grid mb-4">
              <TextField
                type="text"
                size="small"
                onChange={(e) => handleSearchBasePolicy(e.target.value)}
                className=""
                value={searchBasePolicy}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img src={iconSearch} height={20} width={20} alt="" />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  backgroundColor: "#FFF",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "6px",
                    height: "35px",
                    "& fieldset": {
                      borderColor: "#FB5E3F",
                    },
                    "&:hover fieldset": {
                      borderColor: "#FB5E3F",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#FB5E3F",
                    },
                  },
                }}
              />
            </div>
            <div
              style={{
                maxHeight: "150px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {!filteredOptions.length == 0 ? (
                filteredOptions.map(renderOptionRow)
              ) : (
                <div className="text-center text-muted">
                  ไม่มีข้อมูลที่ตรงกับการค้นหา
                </div>
              )}
            </div>
            <Row className="d-flex flex-row justify-content-evenly mt-3">
              {/* <Col className="bg-danger"> */}
              <button
                style={{ fontSize: 15 }}
                onClick={handleConfrimSearch}
                className="btn-checkout-premium w-25 p-2 bold"
              >
                ตกลง
              </button>
              <button
                style={{ fontSize: 15 }}
                onClick={handleCloseModal}
                className="btn-checkout-back w-25 p-2 bold"
              >
                ยกเลิก
              </button>
            </Row>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default PolicyList;
