import { Axios } from "../../../util";


const getStausQuickTransfer = async () => {
  try {
    const res = await Axios.get(`/getStausQuickTransfer`);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

// const processQuickTransferWeb = async (import_date,userName) => {
//   try {
//     const res = await Axios.post(`/processQuickTransferWeb?import_date=${import_date}&userName=${userName}`);
//     return res.data;
//   } catch (error) {
//     console.error("There was an error fetching the API data!", error);
//     throw error;
//   }
// };
const processQuickTransferWeb = async (excelFile, excelExpireFile, import_date) => {
  const formData = new FormData();
  formData.append("excelFile", excelFile);
  formData.append("excelExpireFile", excelExpireFile);
  formData.append("import_date", import_date);

  try {
    const res = await Axios.post(`/processQuickTransferWeb`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};




export {
  getStausQuickTransfer,
  processQuickTransferWeb
};