import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import ReactPaginate from "react-paginate";
import Header_Home from '../Component/Header_Home';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {date: new Date() ,
        token: '',
        username:'',
        offset: 0,
        data: [],
        perPage: 50,
        currentPage: 0,
        pageCount:0,
        step:1,
        date1:'',
        date2:'',
        data_close:[]
    }
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDate2Change = this.handleDate2Change.bind(this);
    }

    componentDidMount() {
        console.log('componentDidMount')
        fetch(process.env.REACT_APP_API_BASEURL + "/api/user/getprofile",
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { 
            console.log("profile.check" , res)
            if(res.success == 'true'){
                if((res.payload.profile).toLowerCase() == 'customer'){
                    window.location.href ='/home';
                }
            }
        })
        
        // if(localStorage.getItem('profile') == 'customer'){
        //     window.location.href ='/home';
        // }
    }

    componentDidUpdate() {
        console.log('componentDidUpdate')
    }

    componentWillUnmount() {
        console.log('componentWillUnmount')
       
    }

    report () {
        const {  date1, date2 } = this.state; 

        let type = document.getElementById('type').value
        console.log(moment(date1).format('yyyy-MM-DD'))
        fetch(process.env.REACT_APP_API_BASEURL + "/api/report/list?start="+moment(date1).format('yyyy-MM-DD')+"&end="+moment(date2).format('yyyy-MM-DD')+"&type="+type,
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { 
            //console.log(res)
            const data = res.payload;
            const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
            console.log(slice.length, "จำนวน")
            if(data.length > 1){
                this.setState({showing_all : true})
            }
            let num = 1 ;
            const postData = slice.map((item, i)   => 
                <tr className="focus-table">
                    <td className='text-center'></td>
                    <td className='text-center'>{item.type==1?'CS001_DOCUMENT':item.type==2?'CS004_CARD':item.type==3?'CS006_EMAIL':item.type==4?'CS002_NAME':item.type==5?'CS005_PHONE':item.type==6?'CS007_DCL':
                    item.type==7?'CS008_FREELOOK':item.type==8?'CS010_RPU':item.type==9?'CS009_ETI':item.type==10?'CS011_RD_CONSENT':item.type==11?'CS012_DOCUMENT':''} </td>
                    <td className='text-center'>{i+1}</td>
                    <td className='text-center'>{item.request_date?moment(item.request_date).format('DD/MM/YYYY'):'-'}</td>
                    <td className='text-center'>{item.close_date?moment(item.close_date).format('DD/MM/YYYY'):'-'}</td>
                    <td> {item.subject}</td>
                    <td className='text-center'> {item.policy_number} </td>
                    <td> {item.insurer_name} </td>
                    <td className='text-center'> {item.close_date != null && item.request_date != null ? moment.duration(moment(item.close_date).diff(moment(item.request_date))).days():'-'} </td>
                </tr>
            )
            this.setState({payload : res.payload,
                pageCount: Math.ceil(data.length / this.state.perPage),
                postData})
            console.log("data", res.payload,res.payload.length, this.state.postData)
        })
        .catch(function(res){ console.log(res) })
    }

    handleChange = input => e => {
        const {  data_close } = this.state; 
        let close_date_array = data_close
        console.log(e.target.value)
        if(document.getElementById(e.target.value).checked== true){
            close_date_array.push(e.target.value);
        } else {
            var index = close_date_array.indexOf(e.target.value)
            if (index !== -1) {
                close_date_array.splice(index, 1);
            }
        }
        if(close_date_array.length>0){
            // document.getElementById('send_close_date').disabled = false
        }
        this.setState({ [input]: e.target.value,
            data_close: close_date_array });
        console.log({ [input]: e.target.value })
    };


    handleDateChange(date) {  
        this.setState({  
            date1: date  
        })  
    }  
    handleDate2Change(date) {  
        this.setState({  
            date2: date  
        })  
    }  

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.report()
        });

    };

    send () {
        const {  data_close } = this.state; 
        let date_close_value = document.getElementById('date_close').value
        let array_send = [];
        if(!date_close_value && date_close_value !== undefined){
            alert("กรุณากรอก close date")
            return false;
        } else {

            for (let item of data_close) {

                let close = {};
                close.id = item;
                close.close_date = date_close_value;
                array_send.push(close);                
            }

            let save = {};
            save.close_date = array_send;
            console.log(save)

            fetch(process.env.REACT_APP_API_BASEURL + '/api/request/update', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization':  localStorage.getItem('token_login')
                    
                },
                body: JSON.stringify(save),
            })
            .then(res => res.json())
            
            .then( res => { 
            //console.log(res)
                    console.log("body_save",res)
                    for (let item of data_close) {
                        document.getElementById(item).checked  = false            
                    }
                    alert( "บันทึกข้อมูลเรียบร้อย")
                    this.report();
            })
            .catch(function(res){ console.log(res) })
        }
    }
 
    render() {
        const {  postData } = this.state; 
        return (
                
            <div>
                <Header_Home/>

                <section className="d-flex justify-content-center align-items-center h-100 my-5">
                    <div className="container-fluid  container-margin">
                        <div className='row'>
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                
                                                <label htmlFor="subject" className='text-center mt-2'><strong>รายงาน</strong></label>
                                            </div>
                                        </div>       
                                    </div>
                       
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 ">
                                            <div className="row col-12 mb-4 ml-4">
                                                <div className="col-lg-4 col-md-6">
                                                    <select className="form-select menu-style  margin-left-10-px list-unstyled" name="type" id="type" >
                                                        <option value="1">รายงานการเปลี่ยนแปลงที่อยู่รับเอกสาร (CS001_DOCUMENT)</option>
                                                        <option value="4">รายงานการเปลี่ยนแปลงคำนำหน้าชื่อ ชือ นามสกุล (CS002_NAME)</option>
                                                        <option value="0">รายงานการเปลี่ยนแปลงผู้รับผลประโยชน์ (CS003_BENEFICIARY)</option>
                                                        <option value="2">รายงานการเปลี่ยนแปลงบัตรเครดิต หรือบัตรเดบิต (CS004_CARD)</option>
                                                        <option value="5">รายงานการเปลี่ยนแปลงเบอร์โทรศัพท์ (CS005_PHONE)</option>
                                                        <option value="3">รายงานการเปลี่ยนแปลง email Address (CS006_EMAIL)</option>
                                                        <option value="6">รายงานการใช้สิทธิกู้เงิน (CS007_DCL)</option>
                                                        <option value="7">รายงานการใช้สิทธิยกเลิกกรมธรรม์ (CS008_FREELOOK)</option>
                                                        <option value="8">รายงานการใช้สิทธิกรมธรรม์ใช้เงินสำเร็จ (CS010_RPU)</option>
                                                        <option value="9">รายงานการใช้สิทธิการประกันภัยแบบขยายเวลา (CS009_ETI)</option>
                                                        <option value="10">รายการการใช้สิทธิ RD Consent (CS011_RD_CONSENT)</option>
                                                    </select>   
                                                </div>
          
                                                {/* <div className="col-1 text-center pt-2">
                                                   ถึง
                                                </div> */}
                                                <div className="row col-lg-6 col-md-8">
                                                <div className="col-lg-5 col-md-6">
                                                <DatePicker
                                                    className="form-control"
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={ this.state.date1 }  
                                                    onChange={ this.handleDateChange }  
                                                />
                                                {/* <input  type="date" className="form-control" id="date1" />   */}
                                                </div>
                                                <div className="col-lg-1 col-md-3 col-sm-4 text-center p-0 pt-2">
                                                   ถึง
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                <DatePicker
                                                    className="form-control"
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={ this.state.date2 }  
                                                    onChange={ this.handleDate2Change }  
                                                />
                                                </div>
                                                     
                                                </div>
                      
                                                <div className="col-lg-1 col-md-3 col-sm-4">
                                                    <a  onClick={() => this.report()} className="btn btn-lg btn-blue">ค้นหา</a>  
                                                </div>
                                            </div>
                                          
                                            <div className="col-12">
                                                <div className="table-responsive ">
                                                    <table className="table table-class white" id="table-id">
                                                        <thead>
                                                            <tr>
  
                                                                <th className="text-center">วันที่สร้างรายงาน</th>
                                                                <th className='text-center'>รหัสรายงาน</th>
                                                                <th className='text-center'>ลำดับที่</th>
                                                                <th className='text-center'>วันที่ลูกค้าทำการยืนยันคำร้องเสร็จสมบูรณ์</th>
                                                                <th className='text-center'>วันที่ปิดงาน</th>
                                                                <th>Function ที่ ผอป ส่งคำร้อง</th>
                                                                <th className='text-center'>หมายเลขกรมธรรม์</th>
                                                                <th>ชื่อ ผอป.</th>
                                                                <th className='text-center'>จำนวนวันในการดำเนินการ </th>
                                                            </tr>
                                                        </thead>
                                                            {/* <Listcustomer list_products={this.state.product} token={this.state.token}></Listcustomer> */}
                                                            <tbody>
                                                                {postData} 
                                                            </tbody>
                                                        
                                                    </table>
                                                    <ReactPaginate
                                                            previousLabel={"prev"}
                                                            nextLabel={"next"}
                                                            breakLabel={"..."}
                                                            breakClassName={"break-me"}
                                                            pageCount={this.state.pageCount}
                                                            marginPagesDisplayed={2}
                                                            pageRangeDisplayed={5}
                                                            onPageChange={this.handlePageClick}
                                                            containerClassName={"pagination"}
                                                            subContainerClassName={"pages pagination"}
                                                            activeClassName={"active"}/>
                                                </div>
                                               
                                            </div>
                                       
                                                
                                        </div>       
                                    </div>
                                    <div className="card-body App">
                                        <a  href="/home" type="button" className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a>
                                        {/* <a  onClick={() => this.next()} className="btn btn-lg btn-blue">ต่อไป</a> */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    

                        {/* <div className='App'>
                            <a  href="/home" type="button" className="btn btn-lg btn mt-3 cust-btn"  >ย้อนกลับ</a>
                        </div> */}
                        </div> 
                </section>

               
            </div>
            )
    }
        
    
}

export default Report;