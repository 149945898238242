import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
// CSS
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BenefitReceivingHistory.css";
// component
import "moment/locale/th";
import moment from "moment";
import PopUp from "../Component/Popup";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import { TableBenefitReceiving } from "./component/TableBenefitReceiving";
import { CustomInputText } from "../Component/CustomInputText";
import { CustomInputDate } from "../Component/CustomInputDate";
// services
// import { getDataAPI } from "./services";
// icon
// import icon_ from "../../Icon/icon.png";
import { mockDataHistory, mockDataTest } from "./mockData";
import {
  searchBenefit,downloadExcel
} from "../BenefitReceivingHistory/BenefitReceivingService/benefitReceiveService";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';


export const BenefitReceivingHistory = () => {
  const [data, setData] = useState();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [name, setName] = useState('');
  const [citizenID, setCitizenID] = useState('');
  const [loading, setLoading] = useState(false);

  const onSearch = () => {
    setLoading(true);
    setTimeout(() => {
      getDataBenefit().then();
      setLoading(false);
    }, 1500);
  };

  const onClear = () => {
    setStartDate('');
    setEndDate('');
    setName('');
    setCitizenID('');
    setData([]);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      getDataBenefit().then();
      setLoading(false);
    }, 1500);
  }, []);

  const getDataBenefit = async () => {
    const formattedStartDate = startDate ? moment(startDate).format("DD/MM/YYYY") : "";
    const formattedEndDate = endDate ? moment(endDate).format("DD/MM/YYYY") : "";

    const result = await searchBenefit(formattedStartDate, formattedEndDate, name.trim(), citizenID);
    setData(result);
  };
  const downloadZip = async () => {
    const zip = new JSZip();
    const bookFolder = zip.folder("Book");
    const kycFolder = zip.folder("KYC");
  
    for (const item of data) {
      for (const file of item.file) {
        const { file_name, link_download } = file;
  
        try {
          const response = await fetch(link_download);
          if (!response.ok) {
              throw new Error(`Failed to fetch ${link_download}: ${response.statusText}`);
          }
          const blob = await response.blob();
  
          if (file_name.startsWith("Book_")) {
            bookFolder.file(file_name, blob);
          } else{
            kycFolder.file(file_name, blob);
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    saveAs(zipBlob, 'files.zip');
  };
  // const downloadZip = async () => {
  //   const zip = new JSZip();
  //   const bookFolder = zip.folder("Book");
  //   const kycFolder = zip.folder("KYC");
  
  //   for (const item of data) {
  //     for (const file of item.file) {
  //       const { file_name, link_files } = file; // Use webUrl instead of link_download
  
  //       try {
  //         const response = await fetch(link_files);
  //         if (!response.ok) {
  //           throw new Error(`Failed to fetch ${link_files}: ${response.statusText}`);
  //         }
  //         const blob = await response.blob();
  
  //         // Save file to the respective folder
  //         if (file_name.startsWith("Book_")) {
  //           bookFolder.file(file_name, blob);
  //         } else {
  //           kycFolder.file(file_name, blob);
  //         }
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     }
  //   }
  
  //   const zipBlob = await zip.generateAsync({ type: 'blob' });
  //   saveAs(zipBlob, 'files.zip');
  // };
  


  const OnDownloadExcel = () => {
    setLoading(true);
    setTimeout(() => {
      exportExcel().then();
      setLoading(false);
    }, 1500);
  };

  const exportExcel = async () => {
   await downloadExcel(startDate,endDate,name,citizenID);
   
  };

  return (
    <div className="benefit-receiving-history">
      {/* {loading ? <PopUp /> : null} */}
      <Header_Home />
      <Header title="รายชื่อลูกค้าที่ลงทะเบียนรับเงินผลประโยชน์" />
      <div className="table-page-container footer-space">
        <div className="rounded-border bg-white p-2">
          <section className="filter-section">
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} alignContent={"center"}>
                <CustomInputDate
                  id="input-start-date"
                  label="วันที่"
                  placeholder="เริ่มต้น"
                  value={startDate}
                  onChange={(value) => {
                    setStartDate(value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} alignContent={"end"}>
                <CustomInputDate
                  id="input-end-date"
                  label=" "
                  placeholder="สิ้นสุด"
                  value={endDate}
                  onChange={(value) => {
                    setEndDate(value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} alignContent={"center"}>
                <CustomInputText
                  id="input-name"
                  label="ชื่อ"
                  placeholder="ชื่อ - นามสกุล"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} alignContent={"center"}>
                <CustomInputText
                  id="input-name"
                  label="เลขประจำตัวประชาชน"
                  placeholder="เลขประจำตัวประชาชน"
                  value={citizenID}
                  onChange={(e) => {
                    setCitizenID(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </section>
          <section className="filter-action-section">
            <button
              style={{ fontSize: 18 }}
              onClick={onSearch}
              className="btn-fill-orange  p-2 bold"
            >
              ค้นหา
            </button>
            <button onClick={onClear} className="btn-fill-gray p-2 bold ml-2">
              ล้าง
            </button>
          </section>
          <section className="action-btn-section">
            <button
              style={{ fontSize: 14, minWidth: "120px" }}
              onClick={downloadZip} // Call the function here
              className="btn-bordered-orange p-2 bold"
            >
              DownLoad (PDF)
            </button>
            <button
              style={{ fontSize: 14, minWidth: "120px" }}
              onClick={OnDownloadExcel}
              className="btn-bordered-orange p-2 bold ml-2"
            >
              Export (Excel)
            </button>
          </section>
          <section className="table-section">
            <TableBenefitReceiving data={data} loading={loading} />
          </section>
        </div>
      </div>
    </div>
  );
};
