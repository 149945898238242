import React, { useContext, useEffect } from "react";
import TransferContext, {
  TransferProvider,
} from "../TranferContextProvider.js";
import icon_check from "../../../Icon/green-circle-check.png";

const Completed = () => {
  const { setHeaderTitle ,setActiveStep } = useContext(TransferContext);


  useEffect(() => {
    setHeaderTitle("เปลี่ยนแปลงบัญชีธนาคาร");
  }, []);
  const handleBack = () => {
    setActiveStep(0);
  };

  return (
    <>
      <div className="middle-item">
        <img
          src={icon_check}
          alt="check-icon"
          style={{ width: "150px", height: "150px" }}
        />
        <div style={{ fontSize: 20, marginTop: 10 }}>บันทึกข้อมูลสำเร็จ</div>
        <p className="text-label">
          บริษัทฯ
          จะดำเนินการโอนเงินตามเงื่อนไขกรมธรรม์โดยอัตโนมัติผ่านบัญชีธนาคารที่คุณได้ระบุไว้
          กรณีเปลี่ยนแปลงข้อมูลบัญชีธนาคารจะต้องแก้ไขข้อมูลก่อนจ่ายเงินคืนตามเงื่อนไขกรมธรรม์ล่วงหน้า
          15 วัน
        </p>
      </div>
      <button
        className="btn-checkout-back w-100 p-2 bold"
        onClick={handleBack}
      >
        ตกลง
      </button>
    </>
  );
};

export default Completed;
