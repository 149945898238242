import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Col } from 'react-bootstrap';
import Radio from '@mui/material/Radio';



// import Header_Home from '../Component/Header_Home';
// import Left_bar from '../Component/Left_bar';
// import ReactPaginate from 'react-paginate';
// import img_profile from '../../images/profile.svg'
import Header_Home from '../Component/Header_Home';
import Header from '../Component/Header';
import Button from 'react-bootstrap/Button';
import { BsChevronRight } from "react-icons/bs";
import menu_11 from '../../images/icon31.svg'
import menu_12 from '../../images/icon32.svg'
import menu_13 from '../../images/icon33.svg'
import menu_14 from '../../images/icon34.svg'
import bankCardImg from '../../images/BankCards.svg'
import promptpayImg from '../../images/prompt-pay-logo.svg'
import uploadImg from '../../images/upload.svg'


import '../RefundPolicy/refundPolicy-styles.css';



class ListPolicy extends Component {

    constructor(props) {
        super(props);
        this.indexselect = null;
        this.state = {
            date: new Date(),
            token: '',
            username: '',
            step: 1,
            offset: 0,
            data: [],
            perPage: 50,
            currentPage: 0,
            pageCount: 0,
            selectedCard: null,
            selectedCardIndex: null
        }

        this.cardRefs = [];

    }

    receivedData() {
        console.log(this.indexselect);

        fetch(process.env.REACT_APP_API_BASEURL + "/api/policy/list?customer=" + localStorage.getItem('customer_id'),
            {
                headers: {
                    'authorization': localStorage.getItem('token_login'),
                    'Content-Type': 'application/json'
                },
                method: "GET"
            })
            .then(res => res.json())
            .then(res => {
                const data = res.payload;
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                const postData = slice.map((item, index) =>
                    // <div key={item.id} className={`frame-policy ${this.indexselect === null ? 'frame-selected' : 'frame-policy'}`} onClick={() => this.handleCardClick(item.policy_no, index)}>

                    <div key={item.id} className={`frame-policy`} onClick={() => this.handleCardClick(item.policy_no, index)}>
                        <ul className="list-group list-group-flush" style={{ display: 'flex', flexDirection: 'column' }}>
                            <h2 className="bold" style={{ fontSize: "25px", marginLeft: "20px", marginTop: "20px" }} >{item.policy_name}</h2>
                            <hr style={{ margin: "0 20px" }} />
                            <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', marginRight: '10px' }}>
                                <span className="light">กรมธรรม์เลขที่</span>
                                <span className="bold">{item.policy_no}</span>
                            </li>
                            <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', marginRight: '10px' }}>
                                <span className="light">วันที่เริ่มสัญญา</span>
                                <span className="bold">14/01/2566</span>
                            </li>
                            <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', marginRight: '10px' }}>
                                <span className="light">วันที่ครบกำหนดสัญญา</span>
                                <span className="bold">14/01/2569</span>
                            </li>
                            <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', marginRight: '10px' }}>
                                <span className="light">สถานะกรมธรรม์</span>
                                <span className="bold">{item.policy_status}</span>
                            </li>
                            <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', marginRight: '10px' }}>
                                <span className="light">จำนวนเงินเอาประกันภัย</span>
                                <span className="bold">{(parseFloat(item.sum_insured)).toLocaleString('en-US', { maximumFractionDigits: 2 })} <strong> บาท</strong></span>
                            </li>
                            <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', marginRight: '10px' }}>
                                <span className="light">วิธีการชำระเบี้ย</span>
                                <span className="bold">รายปี</span>
                            </li>
                            <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', marginRight: '10px' }}>
                            </li>
                        </ul>
                    </div>

                )
                this.setState({
                    user: res.payload,
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    postData
                })

            })
            .catch(function (res) { console.log(res) })
    }

    load(filename, type) {
        console.log(filename, type)
        let url = 'https://dev-eapp.azurewebsites.net/api/agent/doc/download?type=' + type + '&name=' + filename;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem('token_login'),
            }
        })
            .then(
                response => response.blob()
            )

            .then(

                imageBlob => {
                    const imageObjectURL = URL.createObjectURL(imageBlob);
                    window.open(imageObjectURL, '_blank');
                }
            )

    }

    handleCardClick = (id, index) => {
        console.log(index + ":" + this.state.selectedCardIndex);
        // alert("ค่าของ card คือ " + id + ":" + index);
        this.setState({
            selectedCard: id,
            selectedCardIndex: index // เปลี่ยนจาก 1 เป็น index
        }, () => {
            // ตรวจสอบค่า this.cardRefs[index] ก่อนเรียกใช้งาน focus
            if (this.cardRefs[index]) {
                this.cardRefs[index].focus();
            }
        });
    }
    handleselectedFile = input => e => {
        var file = e.target.files[0];
        if ([input] == "fileName1") {
            var a = document.getElementById('showname1'); //or grab it by tagname etc
            a.href = URL.createObjectURL(file)//[reader.result]
        }
        this.setState({
            selectedFile: e.target.files[0],
            //**** added line below ****//
            file_ori: e.target.files[0].name,
            [input]: e.target.files[0].name
        });
    };

    componentDidMount() {

        console.log('componentDidMount')
        this.receivedData()
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };


    componentDidUpdate() {

        console.log('componentDidUpdate')
    }

    componentWillUnmount() {
        console.log('componentWillUnmount')

    }
    handleChange = input => e => {
        this.setState({ [input]: e.target.value });
        localStorage.setItem('status_cus', e.target.value);

        fetch(process.env.REACT_APP_API_BASEURL + "/api/policy/list?customer=" + localStorage.getItem('customer_id'),
            {
                headers: {
                    'authorization': localStorage.getItem('token_login'),
                    'Content-Type': 'application/json'
                },
                method: "GET"
            })
            .then(res => res.json())

            .then(res => {
                //console.log(res)
                const data = res.payload;
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                const postData = slice.map(item =>

                    <div className="card">
                        <div className="card-body">
                            <div className="title">{item.policy_no}</div>
                            <div className="subtitle">20,000</div>
                            <span className="badge bg-danger text-lg p-2 mt-2">Dective</span>
                        </div>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <span className="bold">Category</span>
                                <span className="light">Designer</span>
                            </li>
                            <li className="list-group-item">
                                <span className="bold">DOI</span>
                                <span className="light">2016-05-25</span>
                            </li>
                            <li className="list-group-item">
                                <span className="bold">DOE</span>
                                <span className="light">2016-05-30</span>
                            </li>
                        </ul>
                        <div className="card-body">
                            <button type="submit" className="btn btn-lg btn-blue">Delete</button>
                            <button type="reset" className="btn btn-lg btn-reset">Edit</button>
                        </div>
                    </div>
                )
                this.setState({
                    user: res.payload,
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    postData
                })

            })
            .catch(function (res) { console.log(res) })
    };



    render() {
        const searchParams = new URLSearchParams(window.location.search);
        const channelType = searchParams.get('channelType'); // ค่า channelType จะเป็น 'bank'
        return (
            <div>
                <Header_Home />
                <Header title="เงินคืนตามเงื่อนไขกรมธรรม์" />
                {/* <div className="card-frame margin-1"> */}
                <div className="content margin-1">
                    <div className="frame-custom">
                        <ul className="list-group list-group-flush cus-frame2" style={{ display: 'flex', flexDirection: 'column' }}>
                            <h2 className="bold" style={{ fontSize: "25px", marginLeft: "5px", marginTop: "20px" }} >ช่องทางการรับเงิน</h2>
                            <hr style={{ margin: "0px 0px 15px 0px", border: 'solid 1px' }} />
                            <>
                                {channelType === 'bank' ? (
                                    <>
                                        <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Col xl={1} md={1} xs={2} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                <Radio
                                                    checked={true}
                                                    value="a"
                                                    name="radio-buttons"
                                                    inputProps={{ 'aria-label': 'A' }}
                                                />                                    </Col>
                                            <Col xl={2} md={2} xs={3} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                <span className="bold">ธนาคาร : </span>
                                            </Col>
                                            <Col xl={9} md={9} xs={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <span className="bold">ไทยพาณิชย์</span>
                                            </Col>
                                        </li>
                                        <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Col xl={3} md={3} xs={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                <span className="bold">เลขที่บัญชี : </span>
                                            </Col>
                                            <Col xl={9} md={9} xs={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <span className="bold">123-xxxx-xxx</span>
                                            </Col>
                                        </li>
                                        <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Col xl={12} md={12} xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <span className="bold">กรณีเปลี่ยนช่องทางการรับเงินโปรด คลิก</span>
                                            </Col>
                                        </li>
                                    </>

                                ) : (
                                    <>
                                        <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Col xl={1} md={1} xs={2} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                <Radio
                                                    checked={true}
                                                    value="a"
                                                    name="radio-buttons"
                                                    inputProps={{ 'aria-label': 'A' }}
                                                />                                    </Col>
                                            <Col xl={2} md={2} xs={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                <span className="bold">พร้อมเพย์ : </span>
                                            </Col>
                                            <Col xl={9} md={9} xs={8} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <span className="bold">123-xxxx-xxx-xxx</span>
                                            </Col>
                                        </li>
                                        <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Col xl={12} md={12} xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <span className="bold">กรณีเปลี่ยนช่องทางการรับเงินโปรด คลิก</span>
                                            </Col>
                                        </li>
                                    </>

                                )}


                            </>
                            <div className="row mb-5 ml-3">
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 mt-2 custom-col">
                                    <a href='/editBank?channelType=bank'>
                                        <div className="item-menu flex-column text-center">
                                            <img className="image align-self-center" src={bankCardImg} alt="" />
                                            <h3 className="flex-shrink-1">บัญชีธนาคาร</h3>
                                        </div>
                                    </a>

                                </div>

                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 mt-2 custom-col">
                                    <a href='/editBank?channelType=promptpay'>
                                        <div className="item-menu flex-column text-center" >
                                            <img className="image2 align-self-center" src={promptpayImg} alt="" />
                                            <h3 className="flex-shrink-1">บัญชีพร้อมเพย์</h3>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className='App'>
                                <div className="d-flex justify-content-center flex-wrap">
                                    {/* <a href="/home" type="button" className="mt-2 pay-btn col-xl-6 col-lg-6 col-md-6 col-sm-12">ถัดไป</a> */}
                                    <a href="/refundPolicy" type="button" className="mt-2 btn-back col-xl-6 col-lg-6 col-md-6 col-sm-12">กลับ</a>
                                </div>
                            </div>
                            < br />
                        </ul>
                    </div>

                </div>
                {/* </div> */}


            </div>
        )
    }



}

export default ListPolicy;