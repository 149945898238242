import axios from "axios";

const sendOtp = async (citizenId) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASEURL}/api/quick-transfer/verify-citizen?citizenId=${citizenId}`
    );
    console.log(res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const verifyOtp = async (citizenId, otp) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASEURL}/api/quick-transfer/verify-otp?citizenId=${citizenId}&&otp=${otp}`
    );
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const getFileUploadList = async (docRange) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASEURL}/api/quick-transfer/getFileUploadDetails?docRange=${docRange}`
    );
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const uplaodFile = async (formData) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_BASEURL}/api/quick-transfer/uploadFile`,formData
    );
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};
const updateBenefit = async (cusPsnid, cuscfBankAccountName, cuscfBanknum, cusBank) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_BASEURL}/api/benefit/update-benefit?cusPsnid=${cusPsnid}&cuscfBankAccountName=${cuscfBankAccountName}&cuscfBanknum=${cuscfBanknum}&cusBank=${cusBank}`);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};


export { sendOtp, verifyOtp, getFileUploadList ,uplaodFile,updateBenefit};
