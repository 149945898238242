import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Col } from "react-bootstrap";
import { makeStyles } from '@mui/styles';
import {TablePagination} from '@material-ui/core';
import "../ConsentHistory/consentHistory-styles.css";

function Row(props) {

}

function createData(
)
{
    return {
        history: [
            {
                date: '2020-01-05',
                customerId: '11091700',
                amount: 3,
            },
            {
                date: '2020-01-02',
                customerId: 'Anonymous',
                amount: 1,
            },
        ],
    };
}


const theme = createMuiTheme({
    overrides: {
        MuiTableHead: {
            root: {
                backgroundColor: '#FFA500', 
            },
        },
    },
});
const useStyles = makeStyles({
    tableCell: {
        padding: '5px',
    },
    tableRow: {
        '& > .MuiTableCell-root': {
            padding: '5px',
            fontSize:'0.7rem'

        },
    },
});

  
export default function CollapsibleTable(props)
{
    const classes = useStyles();
    const {data} = props;
    function formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear() + 543;
      return `${day}/${month}/${year}`;
  }
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [refNo , setRefNo] = useState('')

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


    return (
        <ThemeProvider theme={theme}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow className={classes.tableRow} style={{ backgroundColor: '#e7e7e786' }}>
                <TableCell className="font-new" align="center" style={{ width: 200, height: '60px', minWidth: 8, fontSize: '15px' }}>Policy Number</TableCell>
                <TableCell className="font-new" align="center" style={{ width: 200, minWidth: 70, fontSize: '15px' }}>Consent</TableCell>
                <TableCell className="font-new" align="center" style={{ width: 200, minWidth: 30, fontSize: '15px' }}>Consent Flag</TableCell>
                <TableCell className="font-new" align="center" style={{ width: 200, minWidth: 50, fontSize: '15px' }}>Update By</TableCell>
                <TableCell className="font-new" align="center" style={{ width: 200, minWidth: 50, fontSize: '15px' }}>Update Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5} align="center" style={{ fontSize: '16px', color: 'black' }}>
                    ไม่พบข้อมูล
                  </TableCell>
                </TableRow>
              ) : (
                data.map((item, idx) => (
                  <TableRow key={idx}>
                    <TableCell className="font-new" align="center">{item.policyNo}</TableCell>
                    <TableCell className="font-new" align="center">{item.consents}</TableCell>
                    <TableCell className="font-new" align="center">{item.consentFlag}</TableCell>
                    <TableCell className="font-new" align="center">{item.updateBy}</TableCell>
                    <TableCell className="font-new" align="center">{formatDate(item.updateDate)}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </ThemeProvider>
      


    );
}
