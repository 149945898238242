// import "../../App.css";
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import menu_1 from "../../images/1.svg";
import gmail from "../../images/gmail.png";
import location from "../../images/location.png";
import credit from "../../images/credit.png";
import document from "../../images/document.png";
import phone from "../../images/phone.png";
import menu_7 from "../../images/7.svg";
import menu_8 from "../../images/8.svg";
import Header_Home from "../Component/Header_Home";
import { useNavigate } from "react-router-dom";
import "./requestV2-styles.css";
import Card from "react-bootstrap/Card";
import { getMenuRequest, getMenuLetter } from "./RequestV2Service/RequestV2Service";
import email from "../../images/email.png";
import find from "../../images/find.png";
import eti from "../../images/av-timer.png";
import tax from "../../images/tax.png";


const LetterMenu = () => {
  let navigate = useNavigate();
  const [menuList, setMenuList] = useState([]);

  const handleRoute = (url) => {
    navigate(url);
  };

  useEffect(() => {
    initialRequestMenu();
  }, []);

  const initialRequestMenu = async () => {
    const res = await getMenuLetter();
    setMenuList(res.data);
    console.log(res.data);
  }

  const img = (req) => {
    switch (req) {
      case "ISV04_1":
        return email;
      case "ISV04_2":
        return find;
      case "ISV03_3":
        return eti;
      case "ISV03_4":
        return tax;
      default:
        return eti;
    }
  };

  return (
    <>
      <Header_Home />
      <div className="item-container">
        <div className="row">
          <div className="col-12">
            <h2>
              <b>จดหมาย</b>
            </h2>
          </div>
          {menuList &&
            // menuList.length &&
            menuList.map(
              (post, index) => (
                <div key={index} className="col-4 mar">
                  <a onClick={() => handleRoute(post.variable3)}>
                    <div className="item-menu flex-column text-center">
                      <img
                        className="image align-self-center mt-2"
                        src={img(post.code)}
                        alt=""
                      />
                      <p className="flex-shrink-1" style={{color:" #F2654E"}}>{post.variable1}</p> 
                    </div>
                  </a>
                </div>
              )
            )}
        </div>
      </div>
      
    </>
  );
};

export default LetterMenu;
