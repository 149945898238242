import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component, useEffect, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import CollapseTable from "../ConsentHistory/collapseTable";
import { DatePicker } from "material-ui-pickers";
import "react-datepicker/dist/react-datepicker.css";
import { TextField, Grid, InputLabel } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../ConsentHistory/consentHistory-styles.css";
import "../../App.css";
import {
  searchConsentHistoryAPI,
  updateConsent,
} from "./ConsentHistoryService/consentHistoryService";
import "../ConsentHistory/consentHistory-styles.css";
import Skeleton from "react-loading-skeleton";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import OverwriteMomentBE from "../../util/OverwriteMomentBE";
import moment from "moment";
import { IconButton, InputAdornment } from "@material-ui/core";
import AlarmIcon from "@material-ui/icons/CalendarToday";
import CircularProgress from "@mui/material/CircularProgress";
import { CustomInputText } from "../Component/CustomInputText";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TablePagination } from "@material-ui/core";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Modal, Form, Col } from "react-bootstrap";
import successGif from "../../images/successGiff.gif";
import { CSVLink } from 'react-csv';

const ConsentHistory = () => {
  const useStyles = makeStyles({
    tableCell: {
      padding: "5px",
    },
    tableRow: {
      "& > .MuiTableCell-root": {
        padding: "5px",
        fontSize: "0.7rem",
      },
    },
  });
  const classes = useStyles();
  const theme = createTheme({
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            // backgroundColor: "#e7e7e786",
          },
        },
      },
    },
  });

  useEffect(() => {
    searchConsentHistory()
  }, []);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [policyNo, setPolicyNo] = useState("");
  const [rowConsentHistory, setRowConsentHistory] = useState([]);
  const [LoadingContent, setLoadingContent] = useState(false);
  const [isDisabledTaken, setIsDisabledTaken] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDisabledClose, setIsDisabledClose] = useState(true);
  const [isChecked, setIsChecked] = useState(false); 
  const [showModal, setShowModal] = useState(false);
  const [checkedCount, setCheckedCount] = useState(0); 


  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      const allIds = rowConsentHistory
        .filter((item) => item.status !== 'Y')  
        .map((item) => item.id);
      setSelectedRows(allIds);
      setCheckedCount(allIds.length);  
      console.log(allIds.length);
    } else {
      setSelectedRows([]);
      setCheckedCount(0);  
    }
    
    setIsChecked(event.target.checked);
    setIsDisabledTaken(!event.target.checked);
  };
  
  

  const handleCheckboxAllChange = (event, id) => {
    if (event.target.checked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, id]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((rowId) => rowId !== id)
      );
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear() + 543;

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeFromDate = (date) => {
    getConvertFormat(date, setFromDate);
  };

  const handleChangeToDate = (date) => {
    getConvertFormat(date, setToDate);
  };

  const getConvertFormat = (date, setDateFunction) => {
    const momentDate = moment(date);
    const formattedDate = momentDate.format("YYYY-MM-DD");
    setDateFunction(formattedDate);
  };

  const clear = () => {
    setFromDate("");
    setToDate("");
    setPolicyNo("");
    setRowConsentHistory([]);
  };

  const searchConsentHistory = async () => {
    setLoadingContent(true);
    const result = await searchConsentHistoryAPI(policyNo, fromDate, toDate);
    console.log(result);
    setTimeout(() => {
      setRowConsentHistory(result);
      setLoadingContent(false);
    }, 1000);
  };

  const handleUpdateConsent = async () => {
    if (selectedRows.length === 0) {
      alert("กรุณาเลือกรายการที่จะส่ง");
      return;
    }

    // สร้าง body ตามรูปแบบที่ต้องการ
    console.log(selectedRows);
    const body = selectedRows.map((id) => ({ id }));

    try {
      const response = await updateConsent(body); // ส่ง body ไปยังฟังก์ชัน updateConsent
      console.log("Update successful", response);
      setSelectedRows([]);
      toggleModalOpen(); // รีเฟรชข้อมูลหลังจากการอัปเดตสำเร็จ
      setIsDisabledTaken(true);
      setIsDisabledClose(true);
      searchConsentHistory();
    } catch (error) {
      console.error("Error occurred while updating", error);
      // alert("เกิดข้อผิดพลาดในการบันทึกข้อมูล");
    }
  };

  const handleCheckboxChange = (event, id) => {
    const row = rowConsentHistory.find((item) => item.id === id);
    if (row && row.status === "Y") return;
  
    let updatedSelectedRows;
  
    if (event.target.checked) {
      updatedSelectedRows = [...selectedRows, id];
    } else {
      updatedSelectedRows = selectedRows.filter((rowId) => rowId !== id);
    }
  
    setSelectedRows(updatedSelectedRows);
  
    const selectableRowCount = rowConsentHistory.filter(
      (item) => item.status !== "Y"
    ).length;
  
    setIsDisabledTaken(updatedSelectedRows.length === 0);
    setIsDisabledClose(updatedSelectedRows.length === 0);
    setIsChecked(updatedSelectedRows.length === selectableRowCount);
    setCheckedCount(updatedSelectedRows.length);
    console.log(updatedSelectedRows.length);
  };
  

  const toggleModalOpen = () => {
    setShowModal(true);
  };
  const toggleModalClose = () => {
    setShowModal(false);
    setIsChecked(false);

  };

  const generateCsvData = (data) => {
    let data_csv = [];

    data.forEach(item => {
      const data_row = {
        policyNo: `=""${item.policyNo}""`, 
        status: item.status, 
        createBy:item.createBy,
        createDate: item.createDate ? moment(item.createDate).format('DD/MM/YYYY HH:mm:ss') : '-',
        updateBy:item.updateBy,
        updateDate: item.updateDate ? moment(item.updateDate).format('DD/MM/YYYY HH:mm:ss') : '-'
      };
      data_csv.push(data_row);
    });

    return data_csv;
  };

  const data_csv = generateCsvData(rowConsentHistory);

  const headers = [
    { label: "Policy Number", key: "policyNo" },
    { label: "Status", key: "status" },
    { label: "Created By", key: "createBy" },
    { label: "Created Date", key: "createDate" },
    { label: "Update By", key: "updateBy" },
    { label: "Update Date", key: "updateDate" },
    
  ];

  return (
    <div>
      <Header_Home />
      <Header title="การขอใช้สิทธิยกเว้นภาษีเงินได้" />
      <div className="table-page-container footer-space">
        <div className="rounded-border bg-white p-2">
          <Grid
            container
            spacing={1}
            justifyContent="center"
            style={{
              marginTop: "5px",
              marginBottom: "15px",
              paddingInline: "32px",
            }}
          >
            <Grid item xs={12} md={12}>
              <CustomInputText
                id="input-name"
                label="กรมธรรม์เลขที่"
                value={policyNo}
                onChange={(event) => setPolicyNo(event.target.value)}
                InputProps={{
                  style: {
                    borderRadius: "16px",
                    height: "42px",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            justifyContent="center"
            style={{ marginTop: "5px", paddingInline: "32px" }}
          >
            <Grid item xs={12} md={6}>
              <label htmlFor="from-date" style={{ fontWeight: "bold" }}>
                วันที่
              </label>
              <MuiPickersUtilsProvider
                utils={OverwriteMomentBE}
                locale="th"
                moment={moment}
              >
                <DatePicker
                  pickerHeaderFormat="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  defaultValue={""}
                  value={fromDate || null}
                  onChange={handleChangeFromDate}
                  fullWidth
                  autoOk={true}
                  InputProps={{
                    style: {
                      borderRadius: "30px",
                      height: "38px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <AlarmIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  style={{ borderRadius: "16px", height: "42px" }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <label htmlFor="to-date" style={{ fontWeight: "bold" }}>
                ถึง
              </label>
              <MuiPickersUtilsProvider
                utils={OverwriteMomentBE}
                locale="th"
                moment={moment}
              >
                <DatePicker
                  pickerHeaderFormat="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  defaultValue={""}
                  value={toDate || null}
                  onChange={handleChangeToDate}
                  fullWidth
                  autoOk={true}
                  InputProps={{
                    style: {
                      borderRadius: "30px",
                      height: "38px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <AlarmIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  style={{ borderRadius: "16px", height: "42px" }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <br />
          <div className="App">
            <section className="filter-action-section">
              <button
                style={{ fontSize: 18 }}
                onClick={searchConsentHistory}
                className="btn-fill-orange  p-2 bold"
              >
                ค้นหา
              </button>
              <button onClick={clear} className="btn-fill-gray p-2 bold ml-2">
                ล้าง
              </button>
            </section>

            <section
              className="filter-action-section"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                paddingInline: "10px",
              }}
            >
              <button
                style={{ fontSize: 18 }}
                onClick={handleUpdateConsent}
                className={
                  isDisabledTaken
                    ? "btn-fill-gray p-2 bold"
                    : "btn-fill-orange p-2 bold"
                }
                disabled={isDisabledTaken}
              >
                บันทึก
              </button>
              <div id="export" className="margin-l-auto text-align-right">
              <CSVLink
                data={data_csv}
                headers={headers}
                filename="ConsentHistory_Report.csv"
                style={{ fontSize: 18 }}
                className="btn-fill-export  p-2 bold"
              >
                Export File
              </CSVLink>
            </div>
            </section>

            <br />
          </div>
          {LoadingContent ? (
            <div className="p-4">
              <div className="p-2">
                <Skeleton className="h-70px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
                <Skeleton className="h-30px" />
              </div>
            </div>
          ) : (
            <ThemeProvider theme={theme}>
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow
                      className={classes.tableRow}
                      style={{ backgroundColor: "#e7e7e786" }}
                    >
                      <TableCell
                        className="font-new"
                        align="center"
                        style={{ width: 200, minWidth: 50, fontSize: "15px" }}
                      >
                        <input
                          type="checkbox"
                          className="checkbox-large margin-left-px mt-1"
                          onChange={handleSelectAllChange}
                          checked={isChecked}
                        />
                      </TableCell>
                      <TableCell
                        className="font-new"
                        align="center"
                        style={{
                          width: 200,
                          height: "60px",
                          minWidth: 8,
                          fontSize: "15px",
                        }}
                      >
                        Policy Number
                      </TableCell>
                      {/* <TableCell
                        className="font-new"
                        align="center"
                        style={{ width: 200, minWidth: 70, fontSize: "15px" }}
                      >
                        Consent
                      </TableCell>
                      <TableCell
                        className="font-new"
                        align="center"
                        style={{ width: 200, minWidth: 30, fontSize: "15px" }}
                      >
                        Consent Flag
                      </TableCell> */}
                      <TableCell
                        className="font-new"
                        align="center"
                        style={{ width: 200, minWidth: 30, fontSize: "15px" }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        className="font-new"
                        align="center"
                        style={{ width: 200, minWidth: 50, fontSize: "15px" }}
                      >
                        Created By
                      </TableCell>
                      <TableCell
                        className="font-new"
                        align="center"
                        style={{ width: 200, minWidth: 50, fontSize: "15px" }}
                      >
                        Created Date
                      </TableCell>
                      <TableCell
                        className="font-new"
                        align="center"
                        style={{ width: 200, minWidth: 50, fontSize: "15px" }}
                      >
                        Update By
                      </TableCell>
                      <TableCell
                        className="font-new"
                        align="center"
                        style={{ width: 200, minWidth: 50, fontSize: "15px" }}
                      >
                        Update Date
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowConsentHistory.length === 0 ? (
                      <TableRow>
                        <TableCell
                          colSpan={9}
                          align="center"
                          style={{ fontSize: "16px", color: "black" }}
                        >
                          ไม่พบข้อมูล
                        </TableCell>
                      </TableRow>
                    ) : (
                      rowConsentHistory.map((item, idx) => (
                        <TableRow key={idx}>
                          <TableCell className="font-new" align="center">
                            {item.status !== "Y" ? (
                              <input
                                type="checkbox"
                                value={item.id}
                                id={item.id}
                                name={item.id}
                                onChange={(event) =>
                                  handleCheckboxChange(event, item.id)
                                }
                                className="checkbox-large margin-left-px mt-1"
                                checked={selectedRows.includes(item.id)}
                              />
                            ) : null}
                          </TableCell>

                          <TableCell className="font-new" align="center">
                            {item.policyNo}
                          </TableCell>
                          {/* <TableCell className="font-new" align="center">
                            {item.consents}
                          </TableCell>
                          <TableCell className="font-new" align="center">
                            {item.consentFlag}
                          </TableCell> */}
                          <TableCell className="font-new" align="center">
                            {item.status === "Y" ? (
                              <CheckRoundedIcon style={{ color: "green" }} />
                            ) : (
                              <CloseRoundedIcon style={{ color: "red" }} />
                            )}
                          </TableCell>
                          <TableCell className="font-new" align="center">
                            {item.createBy}
                          </TableCell>
                          <TableCell className="font-new" align="center">
                            {formatDate(item.createDate)}
                          </TableCell>
                          <TableCell className="font-new" align="center">
                            {item.updateBy}
                          </TableCell>
                          <TableCell className="font-new" align="center">
                            {formatDate(item.updateDate)}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={rowConsentHistory.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </ThemeProvider>
            // <CollapseTable data={rowConsentHistory} />
          )}
        </div>
      </div>
      <Modal show={showModal} onHide={toggleModalClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <hr
          style={{
            margin: "0px 10px 0px 5px",
            border: "solid 2px",
            borderTop: 0.1,
            color: "#ff5e43",
          }}
        />
        <Modal.Body>
          <img
            src={successGif}
            alt={"confirmGif"}
            style={{
              height: "auto",
              marginLeft: "35%",
              width: "30%",
            }}
          />
          <li
            className="body-list-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Col
              xl={12}
              md={12}
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <h3 className="bold">
                ระบบบันทึกข้อมูล ({checkedCount} รายการ) เรียบร้อย
              </h3>
            </Col>
          </li>
          <section className="filter-action-section">
            <button
              style={{ fontSize: 18 }}
              onClick={toggleModalClose}
              className="btn-fill-orange p-2 bold"
            >
              ตกลง
            </button>
          </section>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ConsentHistory;
