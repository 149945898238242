import React, { useEffect } from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import {
  getProfilePosAPI,
} from "./mainService/mainService";

function PrivateRoutePos() {
  const token = localStorage.getItem("token_login") == null ? false : true;

  let naviate = useNavigate();

  const handleVerifyToken = async () => {
    const result = await getProfilePosAPI();
    if (result.status) {
      console.log("getProfilePosAPI", result);
      localStorage.setItem("username", result.data.username);
      localStorage.setItem("first_name", result.data.firstname);
      localStorage.setItem("last_name", result.data.lastname);
      localStorage.setItem("email", result.data.email);
      localStorage.setItem("profile", "POS");
    } else {
      localStorage.clear();
      naviate("/");
    }
  };

  useEffect(() => {
    if (token) {
      handleVerifyToken();
    }
  }, [naviate]);

  return <>{token ? <Outlet /> : <Navigate to="/" />}</>;
}

export default PrivateRoutePos;
