import { Axios } from "../../../util";


const getMenuParameterAPI = async () => {
  try {
    const res = await Axios.get("/api/parameter/getmenu");
    console.log("data", res.data);
    return res;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error; 
  }
};

const getMenuByRoleAPI = async (role) => {
  try {
    const res = await Axios.get(`/api/parameter/getMenuByRole?role=${role}`);
    console.log("data", res.data);
    return res;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error; 
  }
};

const getAge= async (citizenId) => {
  try {
    const res = await Axios.get(`/api/policy/getAgeInitial?citizenId=${citizenId}`);
    console.log("data", res.data);
    return res;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error; 
  }
};

const getCheckIdnv = async (insureIdCard) => {
  try {
    const res = await Axios.post(`/api/policy/getCheckIdnvFlag?insureIdCard=${insureIdCard}`);
    console.log("data", res.data);
    return res;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error; 
  }
};

export { getMenuParameterAPI,
         getMenuByRoleAPI,
         getAge,
         getCheckIdnv };
