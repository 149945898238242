import * as React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Grid, IconButton, InputAdornment, InputLabel } from "@mui/material";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import icon_calendar from "../../Icon/calendar.png";
import OverwriteMomentBE from "../../util/OverwriteMomentBE";
import { useState } from "react";

moment.locale("en");

export const CustomInputDate = (props) => {
  const {
    id,
    value,
    onChange,
    label,
    placeholder,
    disabled,
    loading,
    error,
    errorText,
    ...other
  } = props;

  const valueToMoment = value ? moment.utc(new Date(value)) : null;

  return (
    <>
      <InputLabel htmlFor={id} className="label text-black">
        {label}
      </InputLabel>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="th">
        <DatePicker
          id={id}
          placeholder={"testss"}
          pickerHeaderFormat="DD/MM/YYYY"
          format="DD/MM/YYYY"
          value={valueToMoment}
          disabled={disabled}
          error={error}
          onChange={onChange}
          closeOnSelect
          // onAccept={(value) => {}}
          // onClose={(e) => {}}
          slots={{
            openPickerButton: (props) => (
              <IconButton {...props} sx={{ marginRight: "0px" }}>
                <img
                  src={icon_calendar}
                  alt="calendar-icon"
                  style={{ width: "24px" }}
                />
              </IconButton>
            ),
          }}
          slotProps={{
            textField: {
              size: "small",
              InputProps: { sx: { borderRadius: 16, marginBottom: "8px" } },
              placeholder: placeholder,
              helperText: error ? errorText || "กรุณากรอกข้อมูล" : undefined,
              fullWidth: true,
            },
          }}
          {...other}
        />
        {/* <Grid item xs={6}>
          <label htmlFor="from-date" style={{ fontWeight: "bold" }}>
            ถึง
          </label>
          <MuiPickersUtilsProvider
            utils={OverwriteMomentBE}
            locale="th"
            moment={moment}
          >
            <DatePicker
              pickerHeaderFormat="DD/MM/YYYY"
              format="DD/MM/YYYY"
              defaultValue={""}
              value={value || null}
              onChange={handleChangeToDate}
              fullWidth
              autoOk={true}
              InputProps={{
                style: {
                  borderRadius: "16px",
                  height: "42px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <img src={icon_calendar} alt="calendar-icon" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              style={{ borderRadius: "16px", height: "42px" }}
            />
          </MuiPickersUtilsProvider>
        </Grid> */}
      </LocalizationProvider>
    </>
  );
};
