import { Axios } from "../../../util";

const getListReport = async (start, end, type) => {
  try {
    // const res = await Axios.post("/api/policy/policyInformation");
    const res = await Axios.get(
      `/api/report/reportList?start=${start}&end=${end}&type=${type}`,
    );
    // console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

export { getListReport };
