import React, { useContext, useState, useEffect } from "react";
import { StoreContext } from "../../context/store";
import BankInfomationForm from "./components/BankInfomationForm";
import ShowBankInformation from "./components/ShowBankInformation";
import VerifyForm from "./components/VerifyForm";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Typography } from "@material-ui/core";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import successGif from "../../images/successGiff.gif";
import {
  verifyChangeBankAPI,
  getInfomationAPI,
  verifyChangePromptPayAPI,
} from "./changePayService/changePayService";
import { useNavigate, useSearchParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../util/Notification";
import "./changePay.css";

function getSteps() {
  return ["กรอกข้อมูล", "ยืนยันข้อมูล", "สำเร็จ"];
}

const ChangeChannel = () => {
  const {
    informationEditBank,
    updateInformationEditBank,
    informationEditPromptPayAccount,
    updateInformationEditPromptPay,
  } = useContext(StoreContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [searchParams, setSearchParams] = useSearchParams();
  const channelType = searchParams.get("channelType") || "";
  const dataParams = location.state || {};
  const [loading, setLoading] = useState(false);
  const age = dataParams.age || "";
  let navigate = useNavigate();
  const [idInput, setIdInput] = useState(""); // New state for ID input


  const handleLoading = () => {
    setLoading(!loading);
  };

  const verifyChangeBank = async () => {
    let result = {};
    if (channelType === "bank") {
      result = await verifyChangeBankAPI(informationEditBank);
    } else {
      result = await verifyChangePromptPayAPI(informationEditPromptPayAccount);
    }
    if (result.status === true) {
      notifySuccess(result.meg);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setLoading(false);
      let bank = {
        bank_account_name: "", // ชื่อ-นามสกุล
        bank_account: "", // เลขธนาคาร
        bank_name: "", // ชื่อธนาคาร
        bank_account_file_name: "", // ชื่อไฟล์
        bank_account_file: "", //ไฟล์
        bank_account_flag_information: false,
      };
      updateInformationEditBank(bank);

      let promptPay = {
        prompt_pay_account_name: "", // ชื่อเจ้าของ
        prompt_pay_account: "", // พร้อมเพย์
        prompt_pay_flag_information: false,
      };
      updateInformationEditPromptPay(promptPay);
    } else {
      notifyError(result.meg);
      setLoading(false);
    }
    console.log(result);
  };

  const handleNext = () => {
    console.log(activeStep);

    if (activeStep + 1 === 3) {
      handleLoading();
      verifyChangeBank();
    } else {
      if (activeStep == 0) {
        if (channelType == "bank") {
          if (
            informationEditBank.bank_account_name !== "" &&
            informationEditBank.bank_account !== "" &&
            informationEditBank.bank_name !== "" &&
            informationEditBank.bank_account_file !== ""
          ) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } else {
            notifyWarning("กรุณากรอกข้อมูล และแนบเอกสารให้ครบถ้วน");
          }
        } else if (channelType == "promptpay") {
          if (
            informationEditPromptPayAccount.prompt_pay_account_name !== "" &&
            informationEditPromptPayAccount.prompt_pay_account !== ""
          ) {
            if (informationEditPromptPayAccount.prompt_pay_account.length < 13) {
              notifyWarning("เลขบัตรประชาชนไม่ครบถ้วน");
            } 
            else if (informationEditPromptPayAccount.prompt_pay_account != idInput) {
              notifyWarning("เลขบัตรประชาชนไม่ถูกต้อง");
            }
            else {
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
            // if(informationEditPromptPayAccount.prompt_pay_account != ""){}
          } else {
            notifyWarning("กรุณากรอกข้อมูล และแนบเอกสารให้ครบถ้วน");
          }
        }
      } else if (activeStep + 1 == 2) {
        if (channelType == "bank") {
          if (!informationEditBank.bank_account_flag_information) {
            notifyWarning("กรุณายินยอมเงื่อนไขก่อนทำการกดยืนยัน");
          } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        }

        if (channelType == "promptpay") {
          if (!informationEditPromptPayAccount.prompt_pay_flag_information) {
            notifyWarning("กรุณายินยอมเงื่อนไขก่อนทำการกดยืนยัน");
          } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate(`/ChangePay`);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const goToHome = () => {
    navigate("/home");
  };

  const handleInfomationPolicy = async () => {
    const result = await getInfomationAPI();

    if (result.result.status === true) {
      if (channelType == "promptpay") {
        const {
          prompt_pay_account_name,
          prompt_pay_account,
          prompt_pay_flag_information,
        } = result.result.data;
        let data = {
          prompt_pay_account_name: prompt_pay_account_name, // ชื่อเจ้าของ
          prompt_pay_account: prompt_pay_account, // พร้อมเพย์
          prompt_pay_flag_information: prompt_pay_flag_information,
        };
        setIdInput(prompt_pay_account)
        updateInformationEditPromptPay(data);
      } else {
        const {
          bank_account_name,
          bank_account,
          bank_name,
          bank_account_flag_information,
        } = result.result.data;
        let data = {
          bank_account_name: bank_account_name, // ชื่อ-นามสกุล
          bank_account: bank_account, // เลขธนาคาร
          bank_name: bank_name, // ชื่อธนาคาร
          bank_account_flag_information: bank_account_flag_information,
        };
        updateInformationEditBank(data);
      }
    }
  };

  useEffect(() => {
    handleInfomationPolicy();
  }, []);

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <BankInfomationForm channelType={channelType} age={age} />;
      case 1:
        return <ShowBankInformation channelType={channelType} />;
      case 2:
        return <VerifyForm channelType={channelType} />;
      default:
        return "Unknown step";
    }
  }

  return (
    <>
      <Header_Home />
      <Header title="เปลี่ยนบัญชีธนาคาร" />
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress
            sx={{
              animationDuration: "550ms",
            }}
            color="inherit"
          />
        </Backdrop>
      )}
      <div className="container px-4 pt-5">
        <div className="card-change-pay big-frame-change-pay h-auto mb-10">
          <div className="d-flex px-3 pt-3 pb-3">
            <h2 className="font-size-title">
              {activeStep === 0
                ? "กรอกข้อมูล"
                : activeStep === 1
                ? "ยันยันข้อมูล"
                : "ยืนยัน OTP"}
            </h2>
          </div>
          <hr
            className="mx-2"
            style={{ margin: "0px 0px 15px 0px", border: "solid 1px" }}
          />
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, idx) => (
              <Step key={idx}>
                <StepLabel className="font-size-title">
                  <p className="font-label">{label}</p>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <>
              <Typography variant="h3" align="center">
                <img
                  src={successGif}
                  alt={"confirmGif"}
                  style={{
                    height: "auto",
                    marginTop: "5%",
                    marginBottom: "5%",
                    width: "20%",
                  }}
                />
                <li
                  className="body-list-group"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Col
                    xl={12}
                    md={12}
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <h3 className="bold">บันทึกข้อมูลสำเร็จ</h3>
                  </Col>
                </li>
                <li
                  className="body-list-group"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Col
                    xl={12}
                    md={12}
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      fontSize: 15,
                    }}
                  >
                    <span className="light">
                      บริษัทฯ จะดำเนินการโอนเงินตามเงื่อนไขกรมธรรม์
                      โดยอัตโนมัติผ่านบัญชีธนาคารที่คุณได้ระบุไว้
                      กรณีเปลี่ยนแปลงข้อมูลบัญชีธนาคารจะต้อง
                      แก้ไขข้อมูลก่อนจ่ายเงินคืนตามเงื่อนไขกรมธรรม์ ล่วงหน้า 15
                      วัน
                    </span>
                  </Col>
                </li>
              </Typography>
              <Row className="d-flex  flex-column mb-5">
                <Col className="d-flex justify-content-center px-5">
                  <button
                    onClick={goToHome}
                    type="button"
                    className="btn-checkout-back w-sm-100 w-lg-25 p-2 bold"
                  >
                    กลับสู่หน้าหลัก
                  </button>
                </Col>
              </Row>
            </>
          ) : (
            <div>
              <div className="p-3">{getStepContent(activeStep)}</div>
            </div>
          )}
        </div>
        {activeStep !== 3 && (
          <Row className="d-flex flex-column mt-2" style={{ marginBottom: 75 }}>
            <Col className="d-flex justify-content-end px-5 gap-2">
              <input
                type="submit"
                style={{ fontSize: 15 }}
                onClick={handleNext}
                value="ยืนยัน"
                className="btn-checkout-premium w-100 p-2 bold"
                disabled={activeStep === 1 && !informationEditBank.bank_account_flag_information && !informationEditPromptPayAccount.prompt_pay_flag_information}
              ></input>
            </Col>
            <Col className="d-flex justify-content-start px-5">
              <button
                onClick={handleBack}
                type="button"
                className="btn-checkout-back w-100 p-2 bold"
              >
                ย้อนกลับ
              </button>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default ChangeChannel;
