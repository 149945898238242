import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { Form } from "react-bootstrap";
import axios from 'axios';
// import Header_Home from '../Component/Header_Home';
// import Left_bar from '../Component/Left_bar';
// import ReactPaginate from 'react-paginate';
// import img_profile from '../../images/profile.svg'
import Header_Home from '../Component/Header_Home';
import PopUp from '../Component/Popup';
class EditPhone extends Component {
    constructor(props) {
        super(props);
        this.state = {date: new Date() ,
        token: '',
        username:'',
        step:1,
        offset: 0,
        data: [],
        perPage: 50,
        currentPage: 0,
        pageCount:0,
        showing_step:1,
        showing_all:false,
        loading:true
    }
    }

    receivedData() {
         
        fetch(process.env.REACT_APP_API_BASEURL + "/api/personal/getphone",
        //fetch(process.env.REACT_APP_API_BASEURL + "/api/personal/getphone?customer="+localStorage.getItem('customer_id'),
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { 
            //console.log(res)
            if(res.success){
                const data = res.payload;
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                
                if(data.length > 1){
                    this.setState({showing_all : true})
                }
                const postData = slice.map(item => 
                    <div className="col-lg-8 col-md-12 col-sm-12 margin-auto">
                        <div className="card">  
                            <div className="card-body">
                                <div className="row col-12">
                                    <div className="col-1 text-right">
                                    <input  onChange={this.handleChange("policy_change")}  type="checkbox" id= {item.policy_no} name={item.policy_no} value={item.policy_no} className="margin-left-px mt-3" disabled={this.state.showing2}></input>
                                    </div>
                                    <div className="col-9">
                                        <label className='text-righ mt-2'><strong>หมายเลขกรมธรรม์ : </strong> {item.policy_no}</label>
                                        <div>
                                        <label className='text-righ mt-2'><strong>เบอร์โทรศัพท์มือถือ : </strong> {item.mobile}</label>
                                        </div>
                                        <div>
                                        <label className='text-righ mt-2'><strong>เบอร์โทรศัพท์บ้าน : </strong>  {item.phone_home} {item.phone_home_to? ' ต่อ '+ item.phone_home_to:''}</label>
                                        </div>
                                        <div>
                                        <label className='text-righ mt-2'><strong>เบอร์โทรศัพท์ที่ทำงาน : </strong> {item.phone_office} {item.phone_office_to? ' ต่อ '+ item.phone_office_to:''}</label>
                                        </div>
                                        <div>
                                        <label className='text-righ mt-2'><strong>เบอร์โทรศัพท์อื่นๆ : </strong> {item.phone_other}</label>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
              
                )
                this.setState({payload : res.payload,
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    postData, loading:false})
                
            } else {
                this.setState({
                    showing_step: 10, 
                    loading:false
                });
            }
           
        })
        .catch(function(res){ console.log(res) })
    }

    componentDidMount() {
        fetch(process.env.REACT_APP_API_BASEURL + "/api/user/getprofile",
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { 
            console.log("profile.check" , res)
            if(res.success == 'true'){
                if(res.payload.profile == 'POS2'){
                    window.location.href ='/home';
                }
            }
        })
        // if(localStorage.getItem('profile') == 'POS2'){
        //     window.location.href ='/home';
        // }

        console.log('componentDidMount')
        this.receivedData()
    }
    
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    handleselectedFile  = input => e => {
        this.setState({
            selectedFile: e.target.files[0],
            //**** added line below ****//
            file_ori: e.target.files[0].name,
            [input]: e.target.files[0].name
        });
    };
    componentDidUpdate() {
       
        console.log('componentDidUpdate')
    }

    componentWillUnmount() {
        console.log('componentWillUnmount')
       
    }

    con () {
        const { payload} = this.state;
        const data = payload;
        const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        const postData2 = slice.map(item => 
            this.item_change(item)
        )
        this.setState({postData2})
    }

    item_change (item) {
        if(document.getElementById(item.policy_no).checked) {
            return (
                <div className="card-body list-group p-0">
                    <div className="row col-lg-9 col-md-12 p-0 margin-auto">
                        <div className="card p-0">  
                            <div className="card-body header-card">
                            <label className='text-righ mt-2'><strong>หมายเลขกรมธรรม์ : </strong> {item.policy_no}</label>
                            </div> 
                            <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <div>
                                <label className='text-righ mt-2'><strong>เบอร์โทรศัพท์มือถือเดิม : </strong> {item.mobile}</label>
                                </div>
                                <div>
                                <label className='text-righ mt-2'><strong>เบอร์โทรศัพท์บ้านเดิม : </strong> {item.phone_home} {item.phone_home_to? ' ต่อ '+ item.phone_home_to:''}</label>
                                </div>
                                <div>
                                <label className='text-righ mt-2'><strong>เบอร์โทรศัพท์ที่ทำงานเดิม : </strong> {item.phone_office} {item.phone_office_to? ' ต่อ '+ item.phone_office_to:''}</label>
                                </div>
                                <div>
                                <label className='text-righ mt-2'><strong>เบอร์โทรศัพท์อื่นๆเดิม : </strong> {item.phone_other}</label>
                                </div>
                            </li>

                            <li className="list-group-item">
                            <div>
                            <label className='text-righ mt-2'><strong>เบอร์โทรศัพท์มือถือใหม่ : </strong> {document.getElementById(item.policy_no).checked?document.getElementById("ch_mobile").checked?document.getElementById("mobile").value:item.mobile:item.mobile}</label>
                            </div>
                            <div>
                                <label className='text-righ mt-2'><strong>เบอร์โทรศัพท์บ้านใหม่ : </strong> 
                                {document.getElementById(item.policy_no).checked?document.getElementById("ch_phone_home").checked?document.getElementById("phone_home").value:item.phone_home:item.phone_home} 
                                {document.getElementById(item.policy_no).checked?document.getElementById("ch_phone_home").checked?document.getElementById("phone_home_to").value ? ' ต่อ '+ document.getElementById("phone_home_to").value:'':item.phone_home_to? ' ต่อ '+ item.phone_home_to:'':item.phone_home_to? ' ต่อ '+ item.phone_home_to:''}
                                </label>
                            </div>
                            <div>
                                <label className='text-righ mt-2'><strong>เบอร์โทรศัพท์ที่ทำงานใหม่ : </strong> 
                                {document.getElementById(item.policy_no).checked?document.getElementById("ch_phone_office").checked?document.getElementById("phone_office").value:item.phone_office:item.phone_office} 
                                {document.getElementById(item.policy_no).checked?document.getElementById("ch_phone_office").checked?document.getElementById("phone_office_to").value ? ' ต่อ '+ document.getElementById("phone_office_to").value:'':item.phone_office_to? ' ต่อ '+ item.phone_office_to:'':item.phone_office_to? ' ต่อ '+ item.phone_office_to:''}
                                </label>
                            </div>
                            <div>
                                <label className='text-righ mt-2'><strong>เบอร์โทรศัพท์อื่นๆใหม่ : </strong>{document.getElementById(item.policy_no).checked?document.getElementById("ch_phone_other").checked?document.getElementById("phone_other").value:item.phone_other:item.phone_other}</label>
                            </div>
                            </li>                     
                        </ul>
                        
                        </div>   
                    </div>              
                </div>    
            )
        }
    }

    handleChange = input => e => {
        console.log(e.target.value)
        if(document.getElementById(e.target.value).checked== false){
            document.getElementById("all").checked =false
        }
        this.setState({ [input]: e.target.value });
        console.log({ [input]: e.target.value })
    };

    next () {
        const { showing_step} = this.state;
        let num = 1 ;
        var txt_error = 'กรุณากรอกข้อมูล';
        console.log("showing_step",showing_step)
        let num_ch = 0;
        if(showing_step === 1) {
            let mobile = document.getElementById("mobile").value
            let phone_office = document.getElementById("phone_office").value
            let phone_home = document.getElementById("phone_home").value
            let phone_other = document.getElementById("phone_other").value

            let ch_mobile = document.getElementById("ch_mobile").checked
            let ch_phone_office = document.getElementById("ch_phone_office").checked
            let ch_phone_home = document.getElementById("ch_phone_home").checked
            let ch_phone_other = document.getElementById("ch_phone_other").checked
           
            if(ch_mobile){
                if(!mobile && mobile !== undefined){
                    document.getElementById("error_mobile").innerHTML = `<span name="#mobile" class="error">` + txt_error +  ` </span>`
                    num++;
                } else {
                    if(mobile.length < 10){
                        document.getElementById("error_mobile").innerHTML = `<span name="#phone_other" class="error"> เบอร์โทรศัพท์มือถือต้องมี 10 หลัก </span>`
                        num++;
                    } else {
                        document.getElementById("error_mobile").innerHTML = ''
                        num_ch++;
                    }
                    
                }
            } else {
                console.log("sss",ch_mobile)
            }

            if(ch_phone_home){
               if(phone_home && phone_home.length < 9){
                    document.getElementById("error_phone_home").innerHTML = `<span name="#phone_other" class="error"> เบอร์โทรศัพท์บ้านต้องมี 9 หลัก </span>`
                    num++;
                } else {
                    document.getElementById("error_phone_home").innerHTML = ''
                    num_ch++;
                }
            }

            if(ch_phone_office){
                if(phone_office && phone_office.length < 9){
                    document.getElementById("error_phone_office").innerHTML = `<span name="#phone_other" class="error"> เบอร์โทรศัพท์ที่ทำงานต้องมี 9 หลัก </span>`
                    num++;
                } else {
                    document.getElementById("error_phone_office").innerHTML = ''
                    num_ch++;
                }
            }


            if(ch_phone_other){
                if(phone_other && phone_other.length < 10 ){
                    document.getElementById("error_phone_other").innerHTML = `<span name="#phone_other" class="error"> เบอร์โทรศัพท์อื่นๆต้องมี 10 หลัก </span>`
                    num++;
                }
                num_ch++;
            }
            // let laser_code = document.getElementById("laser_code").value
            

            // if(!laser_code && laser_code !== undefined){
            //     document.getElementById("error_laser_code").innerHTML = `<span name="#laser_code" class="error">` + txt_error +  ` </span>`
            //     num++;
            // } else {
            //     document.getElementById("error_laser_code").innerHTML = ''
            // }

            // if(!phone_office && phone_office !== undefined){
            //     document.getElementById("error_phone_office").innerHTML = `<span name="#phone_office" class="error">` + txt_error +  ` </span>`
            //     num++;
            // } else {
   
            // }
            // if(!phone_home && phone_home !== undefined){
            //     document.getElementById("error_phone_home").innerHTML = `<span name="#phone_home" class="error">` + txt_error +  ` </span>`
            //     num++;
            // } else {
                
            // }
            
            console.log("num_ch :"+num_ch)
            if(num_ch == 0) {
                alert("กรุณาเลือกข้อมูลที่ต้องการแจ้งเปลี่ยน");
                num++;
            }
            if(num == 1) {
                const { payload} = this.state;
                let data = payload;
                let slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                let count_policy = 0;
                let loop = slice.map(item => {
                    if(document.getElementById(item.policy_no).checked){
                        count_policy++;
                    }
                })
                if(count_policy == 0) {
                    alert("กรุณาเลือกกรมธรรม์ที่ต้องการแจ้งเปลี่ยน");
                    num++;
                }
            }
        }
        // else if (showing_step === 2) {
        //     if(!document.getElementById('file1').files.length>0){
        //         alert('เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ')
        //         num++;
        //     }
            
        // }
        if(num === 1 ){
            this.setState({
                showing_step: showing_step+1
            });
            this.con();
        }
        
    }
    
    back () {
        const {showing_step} = this.state;
        this.setState({
            showing_step: showing_step-1
        });
        this.con();
    }

    select_all = input => e =>  {
        // const {payload} = this.state;
        const {payload} = this.state;
        let policies = [];
        let body = [];
        if(document.getElementById("all").checked){
            for (let item of payload) {

                document.getElementById(item.policy_no).checked = true
            }
        } else {
            for (let item of payload) {

                document.getElementById(item.policy_no).checked = false
            }
        }
    }
    save () {
        const {payload, policy_change} = this.state;
        console.log("policy_change : "+policy_change)
        let policies = [];
        let body = [];
        this.setState({loading:true})
        for (let item of payload) {
            if(document.getElementById(item.policy_no).checked) {
                let policy = {};
                policy.policy_no = item.policy_no;
                policy.policy_no = item.policy_no;
                policy.mobile = document.getElementById(item.policy_no).checked?document.getElementById("ch_mobile").checked?document.getElementById("mobile").value:item.mobile?item.mobile:" ":item.mobile;
                policy.phone_office = document.getElementById(item.policy_no).checked?document.getElementById("ch_phone_office").checked?document.getElementById("phone_office").value:item.phone_office?item.phone_office:" ":item.phone_office;
                policy.phone_home = document.getElementById(item.policy_no).checked?document.getElementById("ch_phone_home").checked?document.getElementById("phone_home").value:item.phone_home?item.phone_home:" ":item.phone_home;
                policy.phone_office_to = document.getElementById(item.policy_no).checked?document.getElementById("ch_phone_office").checked?document.getElementById("phone_office_to").value:item.phone_office_to?item.phone_office_to:" ":item.phone_office_to;
                policy.phone_home_to = document.getElementById(item.policy_no).checked?document.getElementById("ch_phone_home").checked?document.getElementById("phone_home_to").value:item.phone_home_to?item.phone_home_to:" ":item.phone_home_to;
                policy.phone_other = document.getElementById(item.policy_no).checked?document.getElementById("ch_phone_other").checked?document.getElementById("phone_other").value:item.phone_other?item.phone_other:" ":item.phone_other;
                
                policy.old_mobile = item.mobile?item.mobile:" ";
                policy.old_phone_office = item.phone_office?item.phone_office:" ";
                policy.old_phone_home = item.phone_home?item.phone_home:" ";
                policy.old_phone_office_to = item.phone_office_to?item.phone_office_to:" ";
                policy.old_phone_home_to = item.phone_home_to?item.phone_home_to:" ";
                policy.old_phone_other = item.phone_other?item.phone_other:" ";
    
                policies.push(policy);
            }
        }
        // let laser_code = document.getElementById("laser_code").value
        // laser_code = laser_code.replaceAll("-", " ");
        let save = {};
        save.policies = policies;
        // save.laser_code = laser_code;

        console.log("body",save)
        fetch(process.env.REACT_APP_API_BASEURL + '/api/personal/phone', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization':  localStorage.getItem('token_login')
                
            },
            body: JSON.stringify(save),
        })
        .then(res => res.json())
        
        .then( res => { 
            //console.log(res)
           console.log("body_save",res)
           this.setState({loading:false})
           if(res.success) {
                alert( "บริษัทได้รับทราบข้อมูลจากท่านแล้ว ขอบคุณค่ะ")
                window.location.href="/personal";
            } else {
                alert( res.payload.error)
            }
        //    document.querySelector("input[type='submit']").click();
        })
        .catch(function(res){ console.log(res) })
    }

    submitUpload = (event) => {

        // const FormData = require('form-data');
        
        // event.preventDefault();
        let url = process.env.REACT_APP_API_BASEURL + '/api/personal/upload'
        event.preventDefault();

        
        let file_name1 = undefined;
        let file1;
        if(event.target[0].files[0]){
            file_name1 = event.target[0].files[0].name;
            file1 = event.target[0].files[0];
        }

        const formData = new FormData();        
        formData.append('image1', file1, file_name1);
        // formData.append('customer', localStorage.getItem('customer_id'));
        formData.append('type', '5');
        for (var [key, value] of formData.entries()) { 
            console.log(key, value);
        }
        let response =  axios.post(url, formData, {
            headers: {
                'authorization': localStorage.getItem('token_login'),
                'content-type': 'multipart/form-data'
            }
        
        });

        console.log(response);
        response.then(function(result) {
            console.log("result",result) 
            if (!result.data.success) {
                console.log('error: ' + result.data.payload.error);
                alert( result.data.payload.error)
            } else {
                alert( "บริษัทได้รับทราบข้อมูลจากท่านแล้ว ขอบคุณค่ะ")
                window.location.href="/personal";
            }
         })
        
        
        
    };

 
    render() {
        const { showing_step, postData, postData2, showing_all, loading} = this.state;
        return (
            <div >
                {loading ? <PopUp /> : null}
                <Header_Home/>
                <section className="d-flex justify-content-center align-items-center h-100 my-5">
                    <div className="container-fluid container-margin">
                        <div className='row'>
                            <div className="col-12">
                                <div className="card" style={{ display: (showing_step === 1 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>เปลี่ยนเบอร์โทรศัพท์</strong></label>
                                            </div>
                                        </div>       
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 pt-2">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="subject" className='text-righ mt-2'><strong>เบอร์โทรศัพท์มือถือ</strong></label>
                                                <input style={{borderWidth: 1}} type="checkbox" id="ch_mobile" name="ch_mobile" class="margin-left-px mt-3 ml-3" />
                                            </div>
                                            
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                               
                                                <input style={{borderWidth: 1}}  type="text" id="mobile" onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                    }} 
                                                    maxlength="10" name="mobile" className="margin-left-10-px form-control"></input>
                                                <label id="error_mobile"></label>
                                            </div>
                                        </div>  
                                        <div className="row col-12 pt-2">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="subject" className='text-righ mt-2'><strong>เบอร์โทรศัพท์บ้าน</strong></label>
                                                <input style={{borderWidth: 1}} type="checkbox" id="ch_phone_home" name="ch_phone_home" class="margin-left-px mt-3 ml-3" />
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <inpu style={{borderWidth: 1}}t  type="text" id="phone_home" name="phone_home" onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                    }} 
                                                    maxlength="9" className="margin-left-10-px form-control"></inpu>
                                                <label id="error_phone_home"></label>
                                            </div>
                                            <div className="col-1">
                                                <label  className='text-center mt-2'>ต่อ</label>
                                            </div>
                                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                <input style={{borderWidth: 1}}  type="text" id="phone_home_to" name="phone_home_to" onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                    }} 
                                                    maxlength="9" className="margin-left-10-px form-control"></input>
                                            </div>
                                        </div>  
                                        <div className="row col-12 pt-2">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="subject" className='text-righ mt-2'><strong>เบอร์โทรศัพท์ที่ทำงาน</strong></label>
                                                <input style={{borderWidth: 1}} type="checkbox" id="ch_phone_office" name="ch_phone_office" class="margin-left-px mt-3 ml-3" />
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <inpu style={{borderWidth: 1}}t  type="text" id="phone_office" name="phone_office" onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                    }} 
                                                    maxlength="9" className="margin-left-10-px form-control"></inpu>
                                                    <label id="error_phone_office"></label>
                                            </div>
                                            <div className="col-1">
                                                <label  className='text-center mt-2'>ต่อ</label>
                                            </div>
                                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                <input style={{borderWidth: 1}} type="text" id="phone_office_to" name="phone_office_to" onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                    }} 
                                                    maxlength="9" className="margin-left-10-px form-control"></input>
                                                    <label id="error_phone_office"></label>
                                            </div>
                                        </div>       
                                        <div className="row col-12 pt-2">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="subject" className='text-righ mt-2'><strong>เบอร์โทรศัพท์อื่นๆ</strong></label>
                                                <input style={{borderWidth: 1}} type="checkbox" id="ch_phone_other" name="ch_phone_other" class="margin-left-px mt-3 ml-3" />
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                                <input style={{borderWidth: 1}} type="text" id="phone_other" name="phone_other" onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                    }} 
                                                    maxlength="10" className="margin-left-10-px form-control"></input>
                                                    <label id="error_phone_other"></label>
                                            </div>
                                        </div>       
                                        {/* <div className="row col-12 pt-2">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="subject" className='text-righ mt-2'><strong>เบอร์หลังบัตรประชาชน</strong></label>
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                                <input  type="text" id="laser_code" name="laser_code" autocomplete="off" className="margin-left-10-px form-control"></input>
                                                    <label id="error_laser_code"></label>
                                            </div>
                                        </div>        */}
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 p-0 margin-auto">
                                            <div className="col-12 pt-4">
                                                <div className="col-lg-8 col-md-12 col-sm-12 margin-auto" id="all_policy"  style={{ display: (showing_all? 'block' : 'none')}}>
                                                    <div className="card">  
                                                        <div className="card-body">
                                                            <div className="row col-12 ">
                                                                <div className="col-1 text-right">
                                                                <input onChange={this.select_all("select_all")} type="checkbox" id="all" name="all" value="all" className="margin-left-px mt-3" disabled={this.state.showing2}></input>
                                                                </div>
                                                                <div className="col-9">
                                                                    <label className='text-righ mt-2'> ทุกกรมธรรม์</label>
                                                                </div>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                                {postData} 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body App">
                                        <a  href="/personal" type="button" className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a>
                                        <a  onClick={() => this.next()} className="btn btn-lg btn-blue">ต่อไป</a>
                                    </div>
                                </div>

                                <div className="card" style={{ display: (showing_step === 12 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>อัพโหลดไฟล์</strong></label>
                                            </div>
                                        </div>      
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 ">
                                            
                                            <Form onSubmit={this.submitUpload} id="form_id">
                                                <div className="padding-title">
                                                    <div className="row col-12 ">
                                                        <div className="col-lg-6 col-md-8 col-sm-8 col-xs-8 text-right mt-3">
                                                            <label htmlFor="filename" className='text-righ'><strong>รูปถ่ายหน้าตรงที่ถ่ายคู่กับสำเนาบัตรประชาชน</strong> </label>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="col-7 text-left" align="right"  style={{ margin:'10px 0px 0px 0px' }}>
                                                                <label htmlFor="file1" className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i> อัพโหลด 
                                                                </label>
                                                                <label className='ml-2'>{this.state.fileName1}</label>
                                                                <input  type="file"  id="file1" accept="image/png, image/jpeg, image/jpg, application/pdf" style={{display:'none'}}  onChange={this.handleselectedFile("fileName1")}/>
                                                            </div>
                                                        </div>
                                                    </div>      
                                                </div>


                                            

                                                <div className="row dv-button">
                                                        
                                                    <input  type="submit" className="btn btn-primary button-right-page"  style={{display:'none'}}></input>                               
                                                </div>

                                            </Form>
                                                
                                        
                                        </div>               
                                    </div>
                                    <div className="card-body App">
                                        <a onClick={() => this.back()}  className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a> 
                                        <a onClick={() => this.next()} className="btn btn-lg btn-blue">ต่อไป</a>
                                    </div>
                                </div>

                                <div className="card" style={{ display: (showing_step === 2 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>ยืนยันข้อมูล</strong></label>
                                            </div>
                                        </div>      
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 margin-auto">
                                            <div className="col-lg-10 col-md-12 margin-auto">
                                                {postData2} 

                                                {/* <div className="card-body list-group">
                                                    <div className="row col-10 ">
                                                        <div className="card p-0">  
                                                            <div className="card-body header-card">
                                                            <label className='text-righ mt-2'><strong>เอกสารแนบ </strong></label>
                                                            </div> 
                                                            <ul className="list-group list-group-flush">
                                                            <li className="list-group-item">
                                                            <span className="bold">รูปถ่ายหน้าตรงที่ถ่ายคู่กับสำเนาบัตรประชาชน &nbsp;&nbsp;:</span>
                                                            <span className="light">{this.state.fileName1}</span>
                                                            </li>
                                                                           
                                                        </ul>
                                                        
                                                            
                                                        </div>   
                                                    </div>              
                                                </div>   */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body App">
                                        <a onClick={() => this.back()} type="button" className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a>
                                        <a  onClick={() => this.save()} className="btn btn-lg btn-blue">บันทึก</a>
                                    </div>
                                </div>

                                <div className="card" style={{ display: (showing_step === 10 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>เปลี่ยนเบอร์โทรศัพท์</strong></label>
                                            </div>
                                        </div>       
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 pt-2">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="subject" className='text-righ mt-2'><strong>ไม่มีข้อมูลกรมธรรม์</strong></label>
                                            </div>
                                           
                                        </div>  
                                        
                                    </div>
                                   
                                    <div className="card-body App">
                                        <a  href="/personal" type="button" className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a>
                                        {/* <a  onClick={() => this.next()} className="btn btn-lg btn-blue">ต่อไป</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    

                        {/* <div className='App'>
                            <a  href="/home" type="button" className="btn btn-lg btn mt-3 cust-btn"  >ย้อนกลับ</a>
                        </div> */}
                        </div> 
                </section>
            </div> 
        )
    }
        
    
}

export default EditPhone;