import { Axios } from "../../../util";


const getPolicyDetailAPI = async (policyNo, insureIdCard) => {
  try {
    const res = await Axios.post(`/api/policy/policyDetail?policyNo=${policyNo}&insureIdCard=${insureIdCard}`);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const payment = async (json) => {
  try {
    const res = await Axios.post(`/api/payment`, json);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const getDataByReferenceID = async (reference_number) => {
  try {
    const res = await Axios.post(`/api/payment/searchDataByReferenceNumber?referenceNumber=${reference_number}`);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const getDataPlanCode = async (planCode) => {
  try {
    const res = await Axios.post(`/api/payment/getDataConfigPlan?planCode=${planCode}`);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};


export { getPolicyDetailAPI, payment,getDataByReferenceID,getDataPlanCode };