import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import "../../App.css";
import "react-datepicker/dist/react-datepicker.css";
import { TextField, Grid, InputLabel, TableFooter } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "../LetterDigiCom/letterDigiCom-styles.css";
import { getListReport } from "../ReportRequest/reportRequestService/reportRequestService";
import { getParameterAPI } from "../PolicyDetail/PolicyDetailService/policyDetailService";
import Skeleton from "react-loading-skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TablePagination } from "@material-ui/core";
import "moment/locale/th";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { Select, OutlinedInput, MenuItem } from "@mui/material";
import imgTrash from "../../images/Trash-Icon.png";
import imgEdit from "../../images/mode-edit.png";
import { useNavigate } from "react-router-dom";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import OverwriteMomentBE from "../../util/OverwriteMomentBE";
import { DatePicker } from "material-ui-pickers";
import { IconButton, InputAdornment } from "@material-ui/core";
import AlarmIcon from "@material-ui/icons/CalendarToday";

const ReportRequest = () => {
  const theme = createTheme({
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            // backgroundColor: "#FFA500",
          },
        },
      },
    },
  });

  useEffect((date) => {
    // getConvertFormat(date)
  }, []);

  const style = {
    py: 0,
    width: "100%",
    maxWidth: 360,
    borderRadius: 2,
    border: "1px solid",
    borderColor: "divider",
    backgroundColor: "background.paper",
  };


  const [rowDataReport, setRowDataReport] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [LoadingContent, setLoadingContent] = useState(false);
  const [spinner, setSpinner] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedStDate, setSelectedStDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [currentDate, setCurrentDate] = useState(moment());
  const [openTooltip, setOpenTooltip] = useState(false);
  const [dataSearchBy, setDataSearchBy] = useState([]);
  const [selectedReportList, setSelectedReportList] = useState("");
  const [paramSearchBy, setParamSearchBy] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [type, setType] = useState("");
  const [dataReportList, setDataReportList] = useState([]);
  const navigate = useNavigate();


  const handleChangeReportList = (event) => {
    setSelectedReportList(event.target.value);
  };

  const handleStDateChange = (date) => {
    getConvertStDateFormat(date);
  };
  const handleEndDateChange = (date) => {
    getConvertEndDateFormat(date);
  };
  const handleTypeChange = (date) => {
    getConvertEndDateFormat(date);
  };

  const clear = () => {
    setSelectedReportList([])
    setSelectedStDate('')
    setSelectedEndDate('')
    setRowDataReport([])
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchListReport = async (start, end, type) => {
    setLoadingContent(true);
    const result = await getListReport(start, end, selectedReportList);
    console.log(result);

    setTimeout(() => {
      setPage(0);
      setRowDataReport(result.data);
      setLoadingContent(false);
    }, 1000);
  };

  const handleEditClick = (rowData) => {
    navigate("/letterDigiComDetail", { state: { rowData } });
  };

  useEffect(() => {
    getDataReportList();
  }, []);

  const handleSearchClick = () => {
    fetchListReport(selectedStDate, selectedEndDate, selectedReportList);
  };

  const getConvertStDateFormat = (date) => {
    const momentDate = moment(date);
    const formattedDate = momentDate.format("YYYY-MM-DD");
    setSelectedStDate(formattedDate);
  };

  const getConvertEndDateFormat = (date) => {
    const momentDate = moment(date);
    const formattedDate = momentDate.format("YYYY-MM-DD");
    setSelectedEndDate(formattedDate);
  };

  const getDataReportList = async () => {
    const result = await getParameterAPI(20);
    if (result.success) {
      const payload = result.payload;
      setDataReportList(payload);
      console.log(result.payload.map((item) => item.name));
    }
  };

  return (
    <div>
      <Header_Home />
      <Header title="รายงาน" />
      <div className="table-page-container footer-space">
        <div className="rounded-border bg-white p-2">
          <Grid container spacing={1} justifyContent="center" style={{ marginTop: '5px', paddingInline: '32px' }}>
            <Grid item xs={12} md={12}>
              <InputLabel className="label">
                <label style={{ color: "black" }} htmlFor="policyNo">
                  ชื่อรายงาน
                </label>
              </InputLabel>
              <Select
                input={<OutlinedInput />}
                style={{ borderRadius: "16px", height: "42px" }}
                id="bank_name"
                variant="outlined"
                fullWidth
                displayEmpty
                className="select-input mb-2 label"
                value={selectedReportList}
                onChange={handleChangeReportList}
              >
                {dataReportList &&
                  dataReportList.length > 0 &&
                  dataReportList.map((value) => (
                    <MenuItem key={value.pmdId} value={value.variable1}>
                      {value.name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="center" style={{ marginTop: '5px', paddingInline: '32px' }}>
            <Grid item xs={12} md={6}>
              <label htmlFor="from-date" style={{ fontWeight: "bold" }}>
                วันที่เริ่มต้น
              </label>
              <MuiPickersUtilsProvider
                utils={OverwriteMomentBE}
                locale="th"
                moment={moment}
              >
                <DatePicker
                  pickerHeaderFormat="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  defaultValue={""}
                  value={selectedStDate || null}
                  onChange={handleStDateChange}
                  fullWidth
                  autoOk={true}
                  InputProps={{
                    style: {
                      borderRadius: "16px",
                      height: "42px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <AlarmIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  style={{ borderRadius: "16px", height: "42px" }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <label htmlFor="from-date" style={{ fontWeight: "bold" }}>
                วันที่สิ้นสุด
              </label>
              <MuiPickersUtilsProvider
                utils={OverwriteMomentBE}
                locale="th"
                moment={moment}
              >
                <DatePicker
                  pickerHeaderFormat="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  defaultValue={""}
                  value={selectedEndDate || null}
                  onChange={handleEndDateChange}
                  fullWidth
                  autoOk={true}
                  InputProps={{
                    style: {
                      borderRadius: "16px",
                      height: "42px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <AlarmIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  style={{ borderRadius: "16px", height: "42px" }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <div className="App">
            <br />
            <section className="filter-action-section">
              <button
                style={{ fontSize: 18 }}
                onClick={() => handleSearchClick()}
                className="btn-fill-orange  p-2 bold"
              >
                ค้นหา
              </button>
              <button
                onClick={() => clear()}
                className="btn-fill-gray p-2 bold ml-2">
                ล้าง
              </button>
            </section>
            <br />
            <hr
              style={{
                margin: "10px 10px 15px 5px",
                border: "solid 1px",
                borderTop: 0.1,
              }}
            />

            <br />
            <div style={{ position: 'relative' }}>
              {LoadingContent && (
                <div className="loading-content">
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                </div>
              )}
              <ThemeProvider theme={theme}>
                <TableContainer
                  style={{ height: "auto" }}
                  component={Paper}
                >
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow style={{ backgroundColor: "#e7e7e786" }}>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 200, minWidth: 8 }}
                        >
                          วันที่สร้างรายงาน
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 200, minWidth: 8 }}
                        >
                          รหัสรายงาน
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 200, minWidth: 70 }}
                        >
                          ลำดับที่
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 400, minWidth: 30 }}
                        >
                          วันที่ลูกค้าทำการยืนยันคำร้องเสร็จสมบูรณ์
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 300, minWidth: 50 }}
                        >
                          วันที่ปิดงาน
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 200, minWidth: 50 }}
                        >
                          Function ที่ ผอป. ส่งคำร้อง
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 200, minWidth: 50 }}
                        >
                          หมายเลขกรมธรรม์
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 200, minWidth: 50 }}
                        >
                          ID
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 200, minWidth: 50 }}
                        >
                          ชื่อ ผอป.
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 200, minWidth: 50 }}
                        >
                          จำนวนวันในการดำเนินการ
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!(rowDataReport.length === 0) ? (
                        rowDataReport
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item, idx) => {
                            const seq = idx + 1 + page * rowsPerPage;
                            return (
                              <TableRow key={idx}>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 200, minWidth: 8 }}
                                >
                                  { }
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 200, minWidth: 70 }}
                                >
                                  {{
                                    1: "CS001_DOCUMENT",
                                    2: "CS004_CARD",
                                    3: "CS006_EMAIL",
                                    4: "CS002_NAME",
                                    5: "CS005_PHONE",
                                    6: "CS007_DCL",
                                    7: "CS008_FREELOOK",
                                    8: "CS010_RPU",
                                    9: "CS009_ETI",
                                    10: "CS011_RD_CONSENT",
                                    11: "CS012_DOCUMENT",
                                  }[item.type] || ""}
                                </TableCell>

                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 200, minWidth: 30 }}
                                >
                                  {idx + 1}
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 200, minWidth: 50 }}
                                >
                                  {item.request_date ? moment(item.request_date).format('DD/MM/YYYY') : '-'}
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 200, minWidth: 50 }}
                                >
                                  {item.close_date ? moment(item.close_date).format('DD/MM/YYYY') : '-'}
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 200, minWidth: 50 }}
                                >
                                  {item.subject}
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 200, minWidth: 50 }}
                                >
                                  {item.policy_number}
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 200, minWidth: 50 }}
                                >
                                  {item.customer_id}
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 200, minWidth: 50 }}
                                >
                                  {item.insurer_name}
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 200, minWidth: 50 }}
                                >
                                  {item.close_date != null && item.request_date != null ? moment.duration(moment(item.close_date).diff(moment(item.request_date))).days() : '-'}
                                </TableCell>
                              </TableRow>
                            );
                          })
                      ) : (
                        <TableCell align="center" colSpan={12}>
                          <span className="font-new">ไม่พบข้อมูล</span>
                        </TableCell>
                      )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rowDataReport.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </ThemeProvider>
            </div>

          </div>
        </div>

      </div>
    </div>

  );
};

export default ReportRequest;
