import React from "react";
import { Select, InputLabel, OutlinedInput, MenuItem } from "@mui/material";

export const CustomInputDropdown = (props) => {
  const {
    id,
    value,
    onChange,
    label,
    placeholder,
    disabled,
    options,
    ...other
  } = props;

  return (
    <>
      <InputLabel htmlFor={id} className="label text-black">
        {label}
      </InputLabel>
      <Select
        input={<OutlinedInput />}
        style={{ borderRadius: "16px", height: "42px" }}
        variant="outlined"
        fullWidth
        displayEmpty
        className="select-input mb-2 label"
        sx={{
          "& .MuiInputBase-input": {
            color: value ? "black" : "gray",
            fontSize: "15px",
            fontWeight: "400",
          },
        }}
        id={id}
        placeholder={placeholder}
        value={value || ""}
        onChange={onChange}
        disabled={disabled}
        {...other}
      >
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
        {options &&
          options.map((item) => (
            <MenuItem key={item.name} value={item.code}>
            {item.name}
            </MenuItem>
          ))}
      </Select>
    </>
  );
};
