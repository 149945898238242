import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { Form } from "react-bootstrap";
import axios from 'axios';
// import Header_Home from '../Component/Header_Home';
// import Left_bar from '../Component/Left_bar';
// import ReactPaginate from 'react-paginate';
// import img_profile from '../../images/profile.svg'

import Header_Home from '../Component/Header_Home';
import PopUp from '../Component/Popup'
class EditName extends Component {
    constructor(props) {
        super(props);
        this.state = {date: new Date() ,
        token: '',
        username:'',
        step:1,
        offset: 0,
        data: [],
        perPage: 50,
        currentPage: 0,
        pageCount:0,
        showing_step:1,
        showing_card2:false,
        showing_card3:false,
        showing_all:false,
        loading:true
    }
    }

    receivedData() {
         
        fetch(process.env.REACT_APP_API_BASEURL + "/api/personal/getname",
        //fetch(process.env.REACT_APP_API_BASEURL + "/api/personal/getname?customer="+localStorage.getItem('customer_id'),
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { 
            //console.log(res)
            if(res.success) {
                const data = res.payload;
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                if(data.length > 1){
                    this.setState({showing_all : true})
                }
                const postData = slice.map(item => 

                    <div className="col-lg-8 col-md-12 col-sm-12 margin-auto">
                    <div className="card">  
                        <div className="card-body">
                            <div className="row col-12">
                                    {/* <div className="col-1 text-right">
                                    <input  onChange={this.handleChange("policy_change")}  type="checkbox" id= {item.policy_no} name={item.policy_no} value={item.policy_no} className="margin-left-px mt-3" disabled={this.state.showing2}></input>
                                    </div> */}
                                    <div className="col-9">
                                        <label className='text-righ mt-2'><strong>หมายเลขกรมธรรม์ : </strong> {item.policy_no}</label>
                                        <div>
                                            <label className='text-righ mt-2'><strong>คำนำหน้าชื่อ : </strong>{item.title} </label>
                                        </div>
                                        <div>
                                            <label className='text-righ mt-2'><strong>ชื่อ - นามสกุล : </strong> {item.first_name} {item.last_name}</label>
                                        </div>
                                        <div>
                                            <label className='text-righ mt-2'><strong>ชื่อกลาง : </strong> {item.middle_name}</label>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                    
                )
                this.setState({payload : res.payload,
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    postData, loading:false
                })
                
            } else {
                this.setState({
                    showing_step: 10, 
                    loading:false
                });
            }
            
        })
        .catch(function(res){ console.log(res) })
    }

    getPrefix () {
        fetch(process.env.REACT_APP_API_BASEURL + "/api/personal/getprefix",
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { console.log("prefix",res)
            const prefix = res.payload;
            var prefix_select = document.getElementById("title");
            var option = document.createElement("option");
            // option.value = localStorage.getItem('agent');
            // option.text = "ALL SM";
            // x.add(option);
            option.value = '-1';
            option.disabled = true
            option.selected = true
            option.text = "Select";
            option.hidden = true
            prefix_select.add(option);
            const drop_prefix =  prefix.map(item => {
                var option = document.createElement("option");
                option.value = item.description
                option.text = item.description;
                prefix_select.add(option);
            })
            this.setState({drop_prefix})
        })
        .catch(function(res){ console.log(res) })
    }

    componentDidMount() {
        fetch(process.env.REACT_APP_API_BASEURL + "/api/user/getprofile",
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { 
            console.log("profile.check" , res)
            if(res.success == 'true'){
                if(res.payload.profile == 'POS2'){
                    window.location.href ='/home';
                }
            }
        })
        // if(localStorage.getItem('profile') == 'POS2'){
        //     window.location.href ='/home';
        // }

        console.log('componentDidMount')
        this.receivedData();
        this.getPrefix();
    }
    
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    handleselectedFile  = input => e => {
        var file = e.target.files[0];

        if([input] == "fileName1") {
            var a = document.getElementById('showname1'); //or grab it by tagname etc
            a.href = URL.createObjectURL(file)//[reader.result]
        } else if([input] == "fileName2") {
            var a = document.getElementById('showname2'); //or grab it by tagname etc
            a.href = URL.createObjectURL(file)//[reader.result]
        } else if([input] == "fileName3") {
            var a = document.getElementById('showname3'); //or grab it by tagname etc
            a.href = URL.createObjectURL(file)//[reader.result]
        } 

        this.setState({
            selectedFile: e.target.files[0],
            //**** added line below ****//
            file_ori: e.target.files[0].name,
            [input]: e.target.files[0].name
        });
    };

    select_all = input => e =>  {
        // const {payload} = this.state;
        const {payload} = this.state;
        if(document.getElementById("all").checked){
            for (let item of payload) {
                document.getElementById(item.policy_no).checked = true
            }
        } else {
            for (let item of payload) {

                document.getElementById(item.policy_no).checked = false
            }
        }
    }
    componentDidUpdate() {
       
        console.log('componentDidUpdate')
    }

    componentWillUnmount() {
        console.log('componentWillUnmount')
       
    }

    con () {
        const { payload} = this.state;
        const data = payload;
        const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        const postData2 = 
            this.item_change(data[0])
        
        this.setState({postData2})
    }

    item_change (item) {
        // if(document.getElementById(item.policy_no).checked) {
            return (
                <div className="card-body list-group p-0">
                    <div className="row col-lg-9 col-md-12 p-0 margin-auto">
                        <div className="card p-0">  
                            <div className="card-body header-card">
                            {/* <label className='text-righ mt-2'><strong>หมายเลขกรมธรรม์ :  </strong> {item.policy_no}</label> */}
                            </div> 
                            <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                            <span className="bold">คำนำหน้า เดิม &nbsp;&nbsp;:</span>
                            <span className="light">{item.title} </span>
                            <br/>
                            <span className="bold">ชื่อ - นามสกุล เดิม &nbsp;&nbsp;:</span>
                            <span className="light">{item.first_name} {item.last_name}</span>
                            <br/>
                            {/* </li> */}
                            {/* <li className="list-group-item"> */}
                            <span className="bold">ชื่อกลาง เดิม &nbsp;&nbsp;:</span>
                            <span className="light">{item.middle_name}</span>
                            </li>
                            <li className="list-group-item">
                            <span className="bold">คำนำหน้า ใหม่ &nbsp;&nbsp;:</span>
                            <span className="light">{document.getElementById("title").value} </span>
                            <br/>
                            <span className="bold">ชื่อ - นามสกุล ใหม่ :</span>
                            <span className="light">{document.getElementById("first_name").value + ' '+ document.getElementById("last_name").value} </span>
                            <br/>
                            <span className="bold">ชื่อกลาง ใหม่ &nbsp;&nbsp;:</span>
                            <span className="light">{document.getElementById("middle_name").value}</span>
                            </li>                  
                        </ul>
                        
                            
                        </div>   
                    </div>              
                </div>   
            )
        // }
    }

    handleChange = input => e => {
        console.log(e.target.value)
        if(document.getElementById(e.target.value).checked== false){
            document.getElementById("all").checked =false
        }
        this.setState({ [input]: e.target.value });
        console.log({ [input]: e.target.value })
    };

    next () {
        const { showing_step} = this.state;
        let num = 1 ;
        var txt_error = 'กรุณากรอกข้อมูล';
        console.log("showing_step",showing_step)
        if(showing_step === 1) {
            let first_name = document.getElementById("first_name").value
            let last_name = document.getElementById("last_name").value
            // let laser_code = document.getElementById("laser_code").value
            if(!first_name && first_name !== undefined){
                document.getElementById("error_fname").innerHTML = `<span name="#mobile" class="error">` + txt_error +  ` </span>`
                num++;
            }
            if(!last_name && last_name !== undefined){
                document.getElementById("error_lname").innerHTML = `<span name="#phone_office" class="error">` + txt_error +  ` </span>`
                num++;
            }
            // if(!laser_code && laser_code !== undefined){
            //     document.getElementById("error_laser_code").innerHTML = `<span name="#laser_code" class="error">` + txt_error +  ` </span>`
            //     num++;
            // } else {
            //     document.getElementById("error_laser_code").innerHTML = ''
            // }
        } else if (showing_step === 2) {
            // if(!document.getElementById('file1').files.length>0){
            //     alert('เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ')
            //     num++;
            // } else 
            if(document.getElementById('file1').files.length>0 && num === 1) {
                let type =  document.getElementById('file1').files[0].type 
                if(!(type === 'image/jpeg' || type === 'image/jpg' ||type === 'image/png' ||type === 'application/pdf' )){
                    alert("กรุณาอัพโหลดเอกสารนามสกุล .jpeg, .png, .pdf เท่านั้น")
                    num++;
                }
            }
            // if(!document.getElementById('file2').files.length>0 && num === 1){
            //     alert('เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ')
            //     num++;
            // } else 
            if(document.getElementById('file2').files.length>0 && num === 1) {
                let type =  document.getElementById('file2').files[0].type 
                
                if(!(type === 'image/jpeg' || type === 'image/jpg' ||type === 'image/png' ||type === 'application/pdf' )){
                    alert("กรุณาอัพโหลดเอกสารนามสกุล .jpeg, .png, .pdf เท่านั้น")
                    num++;
                }
            }
            // if(!document.getElementById('file3').files.length>0 && num === 1){
            //     alert('เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ')
            //     num++;
            // } else 
            if(document.getElementById('file3').files.length>0 && num === 1) {
                let type =  document.getElementById('file3').files[0].type 
                
                if(!(type === 'image/jpeg' || type === 'image/jpg' ||type === 'image/png' ||type === 'application/pdf' )){
                    alert("กรุณาอัพโหลดเอกสารนามสกุล .jpeg, .png, .pdf เท่านั้น")
                    num++;
                }
            }
            
        }
        if(num === 1 ){
            this.setState({
                showing_step: showing_step+1
            });
            this.con();
        }
        
    }

    back () {
        const {showing_step} = this.state;
        this.setState({
            showing_step: showing_step-1
        });
        this.con();
    }

    save () {
        const {payload} = this.state;
        let policies = [];
        let body = [];
        this.setState({loading:true})
        for (let item of payload) {

            let policy = {};
            policy.policy_no = item.policy_no;
            policy.title = document.getElementById("title").value;
            policy.first_name = document.getElementById("first_name").value;
            policy.last_name = document.getElementById("last_name").value;
            policy.middle_name = document.getElementById("middle_name").value;

            policy.old_title = item.title;
            policy.old_first_name = item.first_name;
            policy.old_last_name = item.last_name;
            policy.old_middle_name = item.middle_name;
            policies.push(policy);
            
        }
        // let laser_code = document.getElementById("laser_code").value
        // laser_code = laser_code.replaceAll("-", "");
        let save = {};
        save.policies = policies;
        // save.laser_code = laser_code;
        console.log("body",save)
        var self = this;
        fetch(process.env.REACT_APP_API_BASEURL + '/api/personal/name', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization':  localStorage.getItem('token_login')
                
            },
            body: JSON.stringify(save),
        })
        .then(res => res.json())
        
        .then( res => { 
            //console.log(res)
           console.log("body_save",res)
            if(res.success) {
                document.querySelector("input[type='submit']").click();
            } else {
                alert( res.payload.error)
                self.setState({loading:false}) 
            }
           
        })
        .catch(function(res){ console.log(res) })
    }

    submitUpload = (event) => {

        const {payload} = this.state;
        let num = 0;
        var policy = '' 
        for (let item of payload) {
            num++
            if(num == 1){
                policy += item.policy_no
            } else {
                policy += ','+item.policy_no
            }
        }
        
        // event.preventDefault();
        let url = process.env.REACT_APP_API_BASEURL + '/api/personal/upload'
        event.preventDefault();

        
        let file_name1 = undefined;
        let file1;
        let file_name2 = undefined;
        let file2;
        let file_name3 = undefined;
        let file3;
        if(event.target[0].files[0]){
            file_name1 = event.target[0].files[0].name;
            file1 = event.target[0].files[0];
        }
        if(event.target[1].files[0]){
            file_name2 = event.target[1].files[0].name;
            file2 = event.target[1].files[0];
        }
        if(event.target[2].files[0]){
            file_name3 = event.target[2].files[0].name;
            file3 = event.target[2].files[0];
        }

        console.log('file_name',event.target[1].files[0]);
        console.log('file_name2',file2);
        const formData = new FormData();   
        if(file_name1 !== undefined){
            formData.append('image1', file1, file_name1);
        }
        if(file_name2 !== undefined){
            formData.append('image2', file2, file_name2);
        }
        if(file_name3 !== undefined){
            formData.append('image3', file3, file_name3);
        }     
        
        
        
        formData.append('type', '4');
        formData.append('policy', policy);
        // formData.append('customer', localStorage.getItem('customer_id'));
        for (var [key, value] of formData.entries()) { 
            console.log(key, value);
        }
        var self = this;

        if(file_name1 !== undefined || file_name2 !== undefined || file_name3 !== undefined) {
            let response =  axios.post(url, formData, {
                headers: {
                    'authorization': localStorage.getItem('token_login'),
                    'content-type': 'multipart/form-data'
                }
            
            }).then(function(result) {
                console.log("result",result) 
                self.setState({loading:false})
                if (!result.data.success) {
                    console.log('error: ' + result.data.error);
                    alert( result.data.error)
                } else {
                    alert( "บริษัทได้รับทราบข้อมูลจากท่านแล้ว ท่านจะได้รับ SMS เพื่อดาวน์โหลดเอกสารการเปลี่ยนแปลงภายใน 5 วันทำการ ขอบคุณค่ะ")
                    window.location.href="/personal";
                }
             })
        } else {
            alert( "บริษัทได้รับทราบข้อมูลจากท่านแล้ว ท่านจะได้รับ SMS เพื่อดาวน์โหลดเอกสารการเปลี่ยนแปลงภายใน 5 วันทำการ ขอบคุณค่ะ")
            window.location.href="/personal";
        }
      
        
        
    };

 
    render() {
        const { showing_step, postData, postData2, showing_all, drop_prefix, loading} = this.state;
        return (
            <div>
                 {this.state.loading ? <PopUp /> : null}
                <Header_Home/>
               
                <section className="d-flex justify-content-center align-items-center h-100 my-5" style={{ display: (loading === false ? 'block' : 'none')}}>
                    <div className="container-fluid  container-margin">
                        <div className='row'>
                            <div className="col-12">
                                <div className="card" style={{ display: (showing_step === 1 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>เปลี่ยน ชื่อ - นามสกุล</strong></label>
                                            </div>
                                        </div>       
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 ">
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="title" className='text-righ mt-2'><strong>คำนำหน้าชื่อ</strong></label>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                            <select style={{borderWidth: 1}}  className="margin-left-10-px form-control" name="title" id="title" onChange={this.handleChange("title")}   >
                                                {/* <option value='-1' disabled selected hidden>select</option> */}
                                                {drop_prefix}
                                            </select>     <label id="error_title"></label>
                                            </div>
                                        </div>   
                                        <div className="row col-12 ">
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="subject" className='text-righ mt-2'><strong>ชื่อ - นามสกุล</strong></label>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                                <input style={{borderWidth: 1}}  type="text" id="first_name" name="first_name" className="margin-left-10-px form-control"></input><label id="error_fname"></label>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                                <input style={{borderWidth: 1}}  type="text" id="last_name" name="last_name" className="margin-left-10-px form-control"></input><label id="error_lname"></label>
                                            </div>
                                            
                                        </div>     
                                        <div className="row col-12 ">
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="subject" className='text-righ mt-2'><strong>ชื่อกลาง</strong></label>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                                <input style={{borderWidth: 1}}  type="text" id="middle_name" name="middle_name" className="margin-left-10-px form-control"></input><label id="error_fname"></label>
                                            </div>
                                            
                                        </div>        
                                        {/* <div className="row col-12 pt-2">
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="subject" className='text-righ mt-2'><strong>เบอร์หลังบัตรประชาชน</strong></label>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                                <input  type="text" id="laser_code" name="laser_code" autocomplete="off" className="margin-left-10-px form-control"></input>
                                                    <label id="error_laser_code"></label>
                                            </div>
                                        </div>  */}
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 p-0 margin-auto">
                                            <div className="col-12 pt-4">
                                                    {postData} 
                                            </div>
                                        </div>
                                    
                                            
                                    </div>
                                    <div className="card-body App">
                                        <a  href="/personal" type="button" className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a>
                                        <a  onClick={() => this.next()} className="btn btn-lg btn-blue">ต่อไป</a>
                                    </div>
                                </div>

                                <div className="card" style={{ display: (showing_step === 2 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>อัพโหลดไฟล์</strong></label>
                                            </div>
                                            {/* <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                                <input  type="email" id="subject" name="subject" className="margin-left-10-px form-control form-control-lg"></input>
                                            </div> */}
                                        </div>      
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 ">
                                            
                                            <Form onSubmit={this.submitUpload} id="form_id">
                                                <div className="padding-title">
                                                    <div className="row col-12 p-0 pt-4">
                                                        <div className="col-lg-6 col-md-8 col-sm-8 col-xs-8 text-right mt-3">
                                                            <label htmlFor="filename" className='text-righ'><strong>รูปถ่ายหน้าตรงพร้อมถือบัตรประชาชนตัวจริง</strong> <br/>รองรับไฟล์ .jpeg, .png, .pdf</label>
                                                        </div>
                                                        <div className="col-6 margin-auto">
                                                            <div className="col-7 text-left" align="right"  style={{ margin:'10px 0px 0px 0px' }}>
                                                                <label htmlFor="file1" className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i> อัพโหลด 
                                                                </label>
                                                                <a className='ml-2' id="showname1" target='_blank'>{this.state.fileName1}</a>
                                                                <input  type="file"  id="file1"  accept="image/png, image/jpeg, image/jpg, application/pdf" style={{display:'none'}}  onChange={this.handleselectedFile("fileName1")}/>
                                                            </div>
                                                        </div>
                                                    </div>      
                                                </div>

                                                <div className="padding-title">
                                                    <div className="row col-12 p-0 pt-4">
                                                        <div className="col-lg-6 col-md-8 col-sm-8 col-xs-8 text-right mt-3">
                                                            <label htmlFor="filename" className='text-righ'><strong>สำเนาบัตรประชาชนด้านหน้า (เซ็นรับรองสำเนาถูกต้อง)</strong> <br/>รองรับไฟล์ .jpeg, .png, .pdf</label>
                                                        </div>
                                                        <div className="col-6 margin-auto">
                                                            <div className="col-7 text-left" align="right"  style={{ margin:'10px 0px 0px 0px' }}>
                                                                <label htmlFor="file3" className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i> อัพโหลด 
                                                                </label>
                                                                <a className='ml-2' id="showname3" target='_blank'>{this.state.fileName3}</a>
                                                                <input  type="file"  id="file3" accept="image/png, image/jpeg, image/jpg, application/pdf" style={{display:'none'}}  onChange={this.handleselectedFile("fileName3")}/>
                                                            </div>
                                                        </div>
                                                    </div>      
                                                </div>

                                                <div className="padding-title">
                                                    <div className="row col-12 p-0 pt-4">
                                                        <div className="col-lg-6 col-md-8 col-sm-8 col-xs-8 text-right mt-3">
                                                            <label htmlFor="filename" className='text-righ'><strong> สำเนาการเปลี่ยนแปลงชื่อสกุลจากทางราชการ (ถ้ามี)</strong> <br/>รองรับไฟล์ .jpeg, .png, .pdf</label>
                                                        </div>
                                                        <div className="col-6 margin-auto">
                                                            <div className="col-7 text-left" align="right"  style={{ margin:'10px 0px 0px 0px' }}>
                                                                <label htmlFor="file2" className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i> อัพโหลด 
                                                                </label>
                                                                <a className='ml-2' id="showname2" target='_blank'>{this.state.fileName2}</a>
                                                                <input  type="file"  id="file2" accept="image/png, image/jpeg, image/jpg, application/pdf" style={{display:'none'}}  onChange={this.handleselectedFile("fileName2")}/>
                                                            </div>
                                                        </div>
                                                    </div>      
                                                </div>

                                                <div className="row dv-button">
                                                    <input  type="submit" className="btn btn-primary button-right-page"  style={{display:'none'}}></input>                               
                                                </div>

                                            </Form>
                                                
                                        
                                        </div>               
                                    </div>
                                    <div className="card-body App">
                                        <a onClick={() => this.back()}  className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a> 
                                        <a onClick={() => this.next()} className="btn btn-lg btn-blue">ต่อไป</a>
                                    </div>
                                </div>

                                <div className="card" style={{ display: (showing_step === 3 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>ยืนยันข้อมูล</strong></label>
                                            </div>
                                        </div>      
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 margin-auto">
                                    
                                            <div className="col-lg-10 col-md-12 margin-auto">
                                                {postData2} 
                                                <div className="card-body list-group p-0">
                                                    <div className="col-lg-10 col-md-12 margin-auto p-0">
                                                        <div className="card p-0">  
                                                            <div className="card-body  header-card">
                                                            <label className='text-righ mt-2'><strong>เอกสารแนบ </strong></label>
                                                            </div> 
                                                            <ul className="list-group list-group-flush">
                                                                <li className="list-group-item">
                                                                <span className="bold">รูปถ่ายหน้าตรงที่ถ่ายคู่กับสำเนาบัตรประชาชน &nbsp;&nbsp;:</span>
                                                                <span className="light">{this.state.fileName1}</span>
                                                                </li>
                                                                <li className="list-group-item">
                                                                <span className="bold">สำเนาบัตรประชาชนด้านหน้า (เซ็นรับรองสำเนาถูกต้อง) :</span>
                                                                <span className="light">{this.state.fileName3} </span>
                                                                </li>       
                                                                <li className="list-group-item">
                                                                <span className="bold"> สำเนาการเปลี่ยนแปลงชื่อสกุลจากทางราชการ (ถ้ามี) :</span>
                                                                <span className="light">{this.state.fileName2} </span>
                                                                </li>                 
                                                            </ul>
                                                        
                                                            
                                                        </div>   
                                                    </div>              
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body App">
                                        <a onClick={() => this.back()} type="button" className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a>
                                        <a  onClick={() => this.save()} className="btn btn-lg btn-blue">บันทึก</a>
                                    </div>
                                </div>

                                <div className="card" style={{ display: (showing_step === 10 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>เปลี่ยน ชื่อ - นามสกุล</strong></label>
                                            </div>
                                        </div>       
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 pt-2">
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="subject" className='text-righ mt-2'><strong>ไม่มีข้อมูลกรมธรรม์</strong></label>
                                            </div>
                                           
                                        </div>  
                                        
                                    </div>
                                   
                                    <div className="card-body App">
                                        <a  href="/personal" type="button" className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a>
                                        {/* <a  onClick={() => this.next()} className="btn btn-lg btn-blue">ต่อไป</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    

                        {/* <div className='App'>
                            <a  href="/home" type="button" className="btn btn-lg btn mt-3 cust-btn"  >ย้อนกลับ</a>
                        </div> */}
                        </div> 
                </section>
                
            </div> 
        )
    }
        
    
}

export default EditName;