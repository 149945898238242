import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component, useState, useEffect } from "react";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import { Modal, Form, Col } from "react-bootstrap";
import successGif from "../../images/successGiff.gif";
import { CustomInputText } from "../Component/CustomInputText";
import { CustomInputFile } from "../Component/CustomInputFile";
import { useSwipeable } from "react-swipeable";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import {
  getPolicyInformationAPI,
  uploadFileOnedrive,
} from "./RequestManageService/requestManageService";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import "./requestManage-styles.css";
import CircularProgress from "@mui/material/CircularProgress";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../util/Notification";
import FileUploadWithPreviewRpu from "./FileUploadWithPreviewRpu";

const requestRpu = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState();

  const [policyno, setPolicyno] = useState("");
  const [showingStep, setShowingStep] = useState(1);
  const [file1, setFile1] = useState();
  const [fileName1, setFileName1] = useState();
  const [file2, setFile2] = useState();
  const [fileName2, setFileName2] = useState();
  const [file3, setFile3] = useState();
  const [fileName3, setFileName3] = useState();
  const [insureIdCard, setInsureIdCard] = useState(
    localStorage.getItem("customer_id")
  );
  const [payload, setPayload] = useState([]);
  const [idnvFlag, setIdnvFlag] = useState(
    localStorage.getItem("idnvFlag") || "N"
  );
  const fileDescriptions = [
    "รูปถ่ายหน้าตรงที่เป็นปัจจุบันคู่บัตรประชาชน",
    "สำเนาบัตรประชาชนด้านหน้า (เซ็นรับรองสำเนาถูกต้อง)",
    "สำเนาสมุดบัญชีธนาคาร (ถ้ามี) (เซ็นรับรองสำเนาถูกต้อง)",
  ];

  const [files, setFiles] = useState(Array(fileDescriptions.length).fill(null));
  const [toggleImg, setToggleImg] = useState(false);
  const [textModal, setTextModal] = useState("");
  const [completedModal, setCompletedModal] = useState(false);

  const handleUploadFiles = async () => {
    setSpinner(true);
    // validateImage()

    const formData = new FormData();
    console.log(files);

    files.forEach((f, index) => {
      console.log(f);
      console.log(index);
      
      if (f != null) {
        formData.append("files", f, f.name);
        formData.append("codeFile", index);
        console.log(f);
        
      }
     
    });
    console.log(formData);

    const type = 8; // Example type
    formData.append("type", type);
    formData.append("policyNo", policyno);

    try {
      const result = await uploadFileOnedrive(formData);
      setSpinner(false);
      toggleModalOpen();
      // setTimeout(() => {
      //     navigate('/request'); // หลังจากอัพโหลดเสร็จ ไปยังหน้า /request
      // }, 1500);
      console.log("Upload Result:", result);
    } catch (error) {
      // alert("Error uploading files");
      notifyWarning("Error uploading files");

      console.error("Error uploading files:", error);
    }
  };

  const handleStepUploadFiles = () => {
    files.forEach((f, index) => {
      console.log(f);
      console.log(index);
      // console.log(f.name);
      
      if (f != null) {
        console.log(f);
        if (index == 0){
          setFileName1(f.name)
        }
        else if (index == 1){
          setFileName2(f.name)
        }
        else {
          setFileName3(f.name)
        }
        
      }
    });
    
  };

  const decryptCardNumber = (encryptedCardNumber, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedCardNumber, secretKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  };

  useEffect(() => {
    getPolicyInformation().then();
  }, []);

  const getPolicyInformation = async () => {
    const result = await getPolicyInformationAPI(
      decryptCardNumber(insureIdCard, process.env.REACT_APP_ENCRYPT_DATA)
    );
    if (result.status) {
      setPayload(result.data.policies);
      console.log(result.data.policies);
    }
  };

  const onNext = () => {
    console.log("showing_step", showingStep);

    let num = 1;
    // if (showingStep === 1 && idnvFlag === 'Y') {
    //     setShowingStep(3);
    //     handleUploadFiles()
    //     return;
    // }

    if (showingStep === 1) {
      if (!policyno && policyno !== undefined) {
        notifyWarning("กรุณากรอกเลขกรมธรรม์");
        num++;
      } else {
        if (policyno.length != 8) {
          notifyWarning("เลขที่กรมธรรม์ให้ครบ 8 หลัก");
          num++;
        } else {
          let chk_no = 0;
          let chk = payload.map((item) => {
            console.log("'" + item.policyNo + "'" + ":'" + policyno + "'");
            if (item.policyNo == policyno) {
              chk_no++;
            }
          });
          if (chk_no == 0) {
            notifyWarning("เลขที่กรมธรรม์ไม่ถูกต้อง");
            num++;
          }
        }
      }
    } else if (showingStep === 2) {
      if (!file1 || file1.length === 0 || !file2 || file2.length === 0) {
        notifyWarning(
          "เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ"
        );
        num++;
      } else if (file2.length > 0) {
        let type = file2[0].type;
        if (
          !(
            type === "image/jpeg" ||
            type === "image/jpg" ||
            type === "image/png" ||
            type === "application/pdf"
          )
        ) {
          notifyWarning("กรุณาอัพโหลดเอกสารนามสกุล .jpeg, .png, .pdf เท่านั้น");
          num++;
        }
      } else if (file3 != undefined) {
        let type = file3[0].type;
        if (
          !(
            type === "image/jpeg" ||
            type === "image/jpg" ||
            type === "image/png" ||
            type === "application/pdf"
          )
        ) {
          notifyWarning("กรุณาอัพโหลดเอกสารนามสกุล .jpeg, .png, .pdf เท่านั้น");
          num++;
        }
      }
    }
    if (num === 1) {
      setShowingStep(showingStep + 1);
    }
  };
  const onBackRequest = () => {
    navigate("/request"); // หลังจากอัพโหลดเสร็จ ไปยังหน้า /request
  };

  const onBack = () => {
    setShowingStep(showingStep - 1);
  };

  const toggleModalOpen = () => {
    setShowModal(true);
  };
  const toggleModalClose = () => {
    setShowModal(false);
    navigate("/home"); // หลังจากอัพโหลดเสร็จ ไปยังหน้า /request
  };

  const validateImage = () => {
    let error = false;
    let errorMessage = "";
  
    // ตรวจสอบว่าไม่มีไฟล์ใดเป็น null หรือ undefined
    // for (const f of files) {
    //   if (f == null) {
    //     error = true;
    //     errorMessage = "เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ";
    //     break;
    //   }
    // }
  
    for (const [index, f] of files.entries()) {
      console.log(f);
      console.log(index);
      if (index == 0 || index == 1) {
        console.log("");
        if (f == null) {
          error = true;
          notifyWarning("เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ")
          // errorMessage = "เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ";
          break;
        }
      }
    }
  
    // ถ้าไม่มี error ในขั้นต้น ให้ตรวจสอบประเภทไฟล์ต่อไป
    // if (!error) {
    //   for (const file of files) {
    //     if (file && file.type) { // เพิ่มการตรวจสอบ file เป็น null/undefined
    //       const type = file.type;
    //       if (!["image/jpeg", "image/jpg", "image/png", "application/pdf"].includes(type)) {
    //         error = true;
    //         errorMessage = "กรุณาอัพโหลดเอกสารนามสกุล .jpeg, .png, .pdf เท่านั้น";
    //         break;
    //       }
    //     } else {
    //       error = true;
    //       errorMessage = "ไฟล์บางไฟล์ไม่มีข้อมูลประเภท กรุณาอัพโหลดใหม่";
    //       break;
    //     }
    //   }
    // }
  
    if (error) {
      setToggleImg(false);
      setTextModal(errorMessage);
      setCompletedModal(true);
    }
  
    return error;
  };

  const ScrollableCardInformation = () => {
    const cardRef = React.useRef(null);

    const handlers = useSwipeable({
      onSwiped: (eventData) => {
        if (cardRef.current) {
          cardRef.current.scrollTop += -eventData.deltaY; // เลื่อนการ์ดตามการสไวป์ (ขึ้นหรือลง)
        }
      },
      delta: 10, // กำหนดความไวต่อการสไวป์
      trackMouse: true, // ทำให้ใช้เมาส์ในการลากได้
    });

    return (
      <Card
        sx={{
          maxHeight: "490px",
          overflowY: "hidden",
          border: "1px solid rgba(255, 94, 67, 0.3)", // สีของขอบ #FF5E43 ที่โปร่งใส 30%
          borderRadius: "12px", // ความโค้งของขอบ
          borderWidth: "2px",
          display: "flex",
          flexDirection: "column",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        }}
        ref={cardRef}
        {...handlers} // ผูกฟังก์ชันสไวป์ให้กับการ์ด
      >
        <CardContent
          sx={{
            cursor: "pointer",
            overflowY: "auto", // เปิดการเลื่อนในแนวตั้ง
            boxSizing: "border-box",
          }}
        >
          <Box
            key={0}
            sx={{
              marginBottom: "15px",
              padding: "10px",

              transition: "background-color 0.3s ease, border 0.3s ease",
              cursor: "pointer",
              alignItems: "center", // จัดให้ทุกอย่างอยู่กึ่งกลางในแนวตั้ง
              justifyContent: "space-between", // แยกข้อความทางซ้ายกับไอคอนทางขวา
            }}
          >
            <Card
              style={{ zIndex: 1000 }}
              sx={{
                backgroundColor: "transparent", // พื้นหลังปกติ
                transition: "background-color 0.3s ease, border 0.3s ease",
              }}
            >
              <CardHeader
                title={"กรมธรรม์เลขที่ : " + policyno}
                sx={{ backgroundColor: "rgb(255, 94, 67,0.3)" }}
              />
              <CardContent>
                <Typography>
                  <Grid
                    container
                    spacing={1}
                    justifyContent="left"
                    style={{ marginTop: "15px", paddingInline: "32px" }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={6}
                        style={{ textAlign: "left", fontSize: "15px" }}
                      >
                        <span className="bold">
                          {"รูปถ่ายหน้าตรงที่เป็นปัจจุบันคู่กับบัตรประชาชน : "}
                        </span>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        <span style={{ fontSize: "15px" }}>{fileName1}</span>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{ textAlign: "left", fontSize: "15px" }}
                      >
                        <span className="bold">
                          {
                            "สำเนาบัตรประชาชน (ด้านหน้า) เซ็นรับรองสำเนาถูกต้อง : "
                          }
                        </span>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        <span style={{ fontSize: "15px" }}>{fileName2}</span>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{ textAlign: "left", fontSize: "15px" }}
                      >
                        <span className="bold">
                          {
                            "สำเนาสมุดบัญชีธนาคาร (ถ้ามี) เซ็นรับรองสำเนาถูกต้อง : "
                          }
                        </span>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        <span style={{ fontSize: "15px" }}>
                          {fileName3 ? fileName3 : "-"}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Typography>
              </CardContent>
            </Card>
            <Box></Box>
          </Box>

          {/* ))} */}
        </CardContent>
      </Card>
    );
  };

  return (
    <div>
      <Header_Home />
      <Header title="ขอใช้สิทธิ กรมธรรม์ใช้เงินสำเร็จ (RPU)" />
      {spinner && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
          }}
        >
          <CircularProgress
            sx={{
              animationDuration: "550ms",
            }}
            color="inherit"
          />
        </div>
      )}
      <div
        className="table-page-container footer-space"
        style={{ display: showingStep === 1 ? "block" : "none" }}
      >
        <div className="rounded-border bg-white p-2">
          <Grid
            container
            spacing={1}
            justifyContent="center"
            style={{ marginTop: "5px", paddingInline: "32px" }}
          >
            <Grid item xs={12} md={6} alignContent={"center"}>
              <CustomInputText
                id="input-name"
                label="เลขที่กรมธรรม์"
                placeholder="เลขที่กรมธรรม์ 8 หลัก"
                value={policyno}
                onChange={(e) => {
                  setPolicyno(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <br />
        </div>
        <div className="App">
          <button
            style={{ fontSize: 15 }}
            onClick={onBackRequest}
            className="btn-back-step p-2 mr-2 bold"
          >
            ย้อนกลับ
          </button>
          <button
            style={{ fontSize: 15 }}
            onClick={onNext}
            className="btn-next-step  p-2 bold"
          >
            ต่อไป
          </button>
          <br />
        </div>
      </div>

      <div
        className="table-page-container footer-space"
        style={{ display: showingStep === 2 ? "block" : "none" }}
      >
        <div className="rounded-border bg-white p-2">
          <Grid
            container
            spacing={1}
            justifyContent="left"
            style={{ marginTop: "15px", paddingInline: "32px" }}
          >
            <h5 className="bold">อัพโหลดไฟล์</h5>
          </Grid>
          <hr
            className="mx-2"
            style={{ margin: "0px 0px 15px 0px", border: "solid 1px" }}
          />
          <FileUploadWithPreviewRpu
            setFiles={setFiles}
            fileDescriptions={fileDescriptions}
            files={files}
          />
          <hr
            className="mx-2"
            style={{ margin: "0px 0px 15px 0px", border: "solid 1px" }}
          />
          <Grid container>
            
            <Grid item xs={12}>
              <Typography
                variant="body2"
                sx={{ color: "red", marginTop: "5px", marginLeft: "5px" }} // ข้อความสีแดงพร้อม margin ด้านบน
              >
                กรณีกรมธรรม์มีเงินจ่ายคืนทันที บริษัทฯ
                จะโอนเข้าบัญชีธนาคารตามที่ท่านได้ให้ไว้กับบริษัทฯ
              </Typography>
            </Grid>
            
          </Grid>
          <br />
        </div>
        <br/>

        <section className="action-address">
          <Grid
            container
            spacing={2}
            justifyContent="center" // จัดตำแหน่งตรงกลางแนวนอน
            alignItems="center" // จัดตรงกลางแนวตั้ง
            sx={{ minHeight: "10vh" }} // ให้ Grid เต็มความสูงหน้าจอ
          >
            <Grid item xs={12} sm={6} md={2}>
              <button
                onClick={() => {
                  if (showingStep === 1) {
                    // exit page or back step
                    navigate(-1);
                  } else if (showingStep === 2) {
                    setShowingStep(1);
                  } else {
                    setShowingStep(2);
                  }
                }}
                className="btn-address-back w-100 p-2 bold"
              >
                ย้อนกลับ
              </button>
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <button
                style={{ fontSize: 18 }}
                onClick={() => {
                  if (showingStep === 1) {
                    let errorsName = validateName();
                    if (errorsName.length === 0) {
                      if (listData.length === 0) {
                        // setOpenErrorMissingName(true);
                        setToggleImg(false);
                        setTextModal(
                          "กรุณาเลือกกรมธรรม์ที่ต้องการขอใช้สิทธิยกเลิกกรมธรรม์"
                        );
                        setCompletedModal(true);
                        // refCarousel.current.scrollIntoView();
                      } else {
                        handleCheckAll();
                        setShowingStep(2);
                      }
                    }
                  } else if (showingStep === 2) {
                    const errorImage = validateImage();
                    if (!errorImage) {
                      handleStepUploadFiles();
                      setShowingStep(3);
                    }
                  } else {
                    handleUploadFiles();
                  }
                }}
                className="btn-address-next w-100 p-2 bold"
              >
                {showingStep <= 2 ? "ต่อไป" : "บันทึก"}
              </button>
            </Grid>
          </Grid>
        </section>
      </div>

      <br/>
      <div
        className="table-page-container footer-space"
        style={{ display: showingStep === 3 ? "block" : "none" }}
      >
        <ScrollableCardInformation />
        <div className="App">
          <button
            style={{ fontSize: 15 }}
            onClick={onBack}
            className="btn-back-step p-2 mr-2 bold"
          >
            ย้อนกลับ
          </button>
          <button
            style={{ fontSize: 15 }}
            onClick={handleUploadFiles}
            className="btn-next-step  p-2 bold"
          >
            บันทึก
          </button>
          <br />
        </div>
      </div>

      <Modal show={showModal} onHide={toggleModalClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img
            src={successGif}
            alt={"successGif"}
            style={{
              height: "auto",
              marginLeft: "35%",
              width: "30%",
            }}
          />
          <li
            className="body-list-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Col
              xl={12}
              md={12}
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <h5
                className="bold"
                style={{ fontSize: "16px", textAlign: "center" }}
              >
                บริษัทได้รับทราบข้อมูลจากท่านแล้ว ท่านจะได้รับ SMS
                เพื่อดาวน์โหลดจดหมายแจ้งการใช้สิทธิภายใน 30 วันทำการ ขอบคุณค่ะ
              </h5>
            </Col>
          </li>
          <section className="filter-action-section">
            <button
              style={{ fontSize: 18 }}
              onClick={toggleModalClose}
              className="btn-fill-orange  p-2 bold"
            >
              กลับสู่หน้าหลัก
            </button>
          </section>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default requestRpu;
