import * as React from "react";
import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import { getdetail } from "../RequestService/requestService";

export const TableDetail = ({ id_detail, type, policy_number }) => {
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getdetail(id_detail, type);
                console.log(result);
                setData(result.payload); // คาดว่า result.payload เป็น array
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [id_detail, type]);

    return (
        <TableContainer component={Paper}>
            <Table aria-label="detail table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">หมายเลขกรมธรรม์</TableCell>
                        <TableCell align="center">ข้อมูลเก่า</TableCell>
                        <TableCell align="center">ข้อมูลใหม่</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <TableRow>
                            <TableCell colSpan={3} align="center">
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    ) : (
                        data.map((item, index) => (
                            <TableRow key={item.id || index}>
                                <TableCell align="center">{policy_number}</TableCell>
                                <TableCell style={{ verticalAlign: 'top' }}>
                                    {/* แสดงข้อมูลเก่า */}
                                    {type === 1 && (
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <div>{item.address1_old}</div>
                                                <div>{item.address2_old}</div>
                                                <div>{item.address3_old}</div>
                                                <div>{item.address4_old}</div>
                                                <div>{item.address5_old}</div>
                                            </li>
                                        </ul>
                                    )}
                                    {type === 2 && (
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <div>
                                                    <label className='text-righ'><strong>ชื่อนามสกุล : </strong> {item.card_holder_name_old}</label>
                                                </div>
                                                <div>
                                                    <label className='text-righ'><strong>หมายเลขบัตร : </strong> {item.card_number_old ? item.card_number_old.substring(0, 4) + "********" + item.card_number_old.substring(item.card_number_old.length - 4, item.card_number_old.length) : ""}</label>
                                                </div>
                                                <div>
                                                    <label className='text-righ'><strong>เดือน/ปี หมดอายุของบัตร : </strong> {item.card_expire_date_old}</label>
                                                </div>
                                                <div>
                                                    <label className='text-righ'><strong>ประเภทของบัตร : </strong> {item.card_type_old ? item.card_type_old.trim() == "MC" ? "Mastercard" :
                                                        item.card_type_new.trim() == "VI" ? "Visa" :
                                                            item.card_type_new.trim() == "AM" ? "Amex" :
                                                                item.card_type_new.trim() == "C4" ? "JCB" :
                                                                    item.card_type_new.trim() == "DI" ? "Diners" :
                                                                        item.card_type_new.trim() == "C5" ? "CUP" :
                                                                            item.card_type_new.trim() == "99" ? "Card" : "1234" : ""}</label>
                                                </div>
                                                <div>
                                                    <label className='text-righ'><strong>ธนาคารเจ้าของบัตรเครดิต : </strong> {item.credit_bank_name_old}</label>
                                                </div>
                                                <div>
                                                    <label className='text-righ'><strong>ความสัมพันธ์เจ้าของบัตรเครดิตผู้เอาประกันภัย : </strong> {item.card_relation_old}</label>
                                                </div>
                                            </li>
                                        </ul>
                                    )}
                                    {type === 3 && (
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <div>
                                                    <label className='text-righ'><strong>อีเมล : </strong> {item.email_old}</label>
                                                </div>
                                            </li>
                                        </ul>
                                    )}
                                    {type === 4 && (
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <div>
                                                    <label className='text-righ'><strong>คำนำหน้า : </strong> {item.title_old}</label>
                                                </div>
                                                <div>
                                                    <label className='text-righ'><strong>ชื่อ - นามสกุล : </strong> {item.first_name_old} {item.last_name_old}</label>
                                                </div>
                                                <div>
                                                    <label className='text-righ'><strong>ชื่อกลาง : </strong>   {item.middle_name_old ? item.middle_name_old : '-'}
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    )}
                                    {type === 5 && (
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <div>
                                                    <label className='text-righ'><strong>เบอร์โทรศัพท์มือถือ : </strong>   {item.mobile_old ? item.mobile_old : '-'}</label>
                                                </div>
                                                <div>
                                                    <label className='text-righ'><strong>เบอร์โทรศัพท์บ้าน : </strong>   {item.phone_home_old ? item.phone_home_old : '-'}</label>
                                                </div>
                                                <div>
                                                    <label className='text-righ'><strong>เบอร์โทรศัพท์ที่ทำงาน : </strong>   {item.phone_office_old ? item.phone_office_old : '-'}</label>
                                                </div>
                                                <div>
                                                    <label className='text-righ'><strong>เบอร์โทรศัพท์อื่นๆ : </strong>   {item.phone_other_old ? item.phone_other_old : '-'}</label>
                                                </div>
                                            </li>
                                        </ul>
                                    )}
                                </TableCell>
                                <TableCell style={{ verticalAlign: 'top' }}>
                                    {/* แสดงข้อมูลใหม่ */}
                                    {type === 1 && (
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <div>{item.address1_new}</div>
                                                <div>{item.address2_new}</div>
                                                <div>{item.address3_new}</div>
                                                <div>{item.address4_new}</div>
                                                <div>{item.address5_new}</div>
                                            </li>
                                        </ul>
                                    )}
                                    {type === 2 && (
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <div>
                                                    <label className='text-righ'><strong>ชื่อนามสกุล : </strong> {item.card_holder_name_new}</label>
                                                </div>
                                                <div>
                                                    <label className='text-righ'><strong>หมายเลขบัตร : </strong> {item.card_number_new ? item.card_number_new.substring(0, 4) + "********" + item.card_number_new.substring(item.card_number_new.length - 4, item.card_number_new.length) : ""}</label>
                                                </div>
                                                <div>
                                                    <label className='text-righ'><strong>เดือน/ปี หมดอายุของบัตร : </strong> {item.card_expire_date_new}</label>
                                                </div>
                                                <div>
                                                    <label className='text-righ'><strong>ประเภทของบัตร : </strong> {item.card_type_new ? item.card_type_new.trim() == "MC" ? "Mastercard" :
                                                        item.card_type_new.trim() == "VI" ? "Visa" :
                                                            item.card_type_new.trim() == "AM" ? "Amex" :
                                                                item.card_type_new.trim() == "C4" ? "JCB" :
                                                                    item.card_type_new.trim() == "DI" ? "Diners" :
                                                                        item.card_type_new.trim() == "C5" ? "CUP" :
                                                                            item.card_type_new.trim() == "99" ? "Card" : "1234" : ""}</label>
                                                </div>
                                                <div>
                                                    <label className='text-righ'><strong>ธนาคารเจ้าของบัตรเครดิต : </strong> {item.credit_bank_name_new}</label>
                                                </div>
                                                <div>
                                                    <label className='text-righ'><strong>ความสัมพันธ์เจ้าของบัตรเครดิตผู้เอาประกันภัย : </strong> {item.card_relation_new}</label>
                                                </div>
                                            </li>
                                        </ul>
                                    )}
                                    {type === 3 && (
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <div>
                                                    <label className='text-righ'><strong>อีเมล : </strong> {item.email_new}</label>
                                                </div>
                                            </li>
                                        </ul>
                                    )}
                                    {type === 4 && (
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <div>
                                                    <label className='text-righ'><strong>คำนำหน้า : </strong> {item.title_new}</label>
                                                </div>
                                                <div>
                                                    <label className='text-righ'><strong>ชื่อ - นามสกุล : </strong> {item.first_name_new} {item.last_name_new}</label>
                                                </div>
                                                <div>
                                                    <label className='text-righ'><strong>ชื่อกลาง : </strong>   {item.middle_name_new ? item.middle_name_new : '-'}</label>
                                                </div>
                                            </li>
                                        </ul>
                                    )}
                                    {type === 5 && (
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <div>
                                                    <label className='text-righ'><strong>เบอร์โทรศัพท์มือถือ : </strong>   {item.mobile_new ? item.mobile_new : '-'}</label>
                                                </div>
                                                <div>
                                                    <label className='text-righ'><strong>เบอร์โทรศัพท์บ้าน : </strong>   {item.phone_home_new ? item.phone_home_new : '-'}</label>
                                                </div>
                                                <div>
                                                    <label className='text-righ'><strong>เบอร์โทรศัพท์ที่ทำงาน : </strong>   {item.phone_office_new ? item.phone_office_new : '-'}</label>
                                                </div>
                                                <div>
                                                    <label className='text-righ'><strong>เบอร์โทรศัพท์อื่นๆ : </strong>   {item.phone_other_new ? item.phone_other_new : '-'}</label>
                                                </div>
                                            </li>
                                        </ul>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
