import React, { useEffect, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, Typography, Button, Divider, Dialog, DialogContent, IconButton } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloseIcon from '@mui/icons-material/Close';


const FileUploadWithPreviewName = ({ setFile,fileDescriptions,file}) => {
    const [open, setOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);


    useEffect(() => {
        
    }, [setFile])
    const onDrop = useCallback((acceptedFiles, index) => {
        if (acceptedFiles.length > 0) {
            setFile((prevFiles) => {
                const updatedFiles = [...prevFiles];
                updatedFiles[index] = Object.assign(acceptedFiles[0], {
                    preview: URL.createObjectURL(acceptedFiles[0]),
                });
                return updatedFiles;
            });
        }
    }, [setFile]);
    

    const createDropzoneProps = (index) => {
        return useDropzone({
            onDrop: (acceptedFiles) => onDrop(acceptedFiles, index),
            accept: {
                'image/*': [],
                'application/pdf': [],
            },
            multiple: false,
        });
    };

    const handleClickOpen = (file) => {
        setSelectedFile(file);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedFile(null);
    };

    return (
        <div style={{ padding: 20 }}>
           
                {/* // Component ที่แสดงเมื่อ flagRelation เป็น true */}
                <Grid container spacing={2}>
                    {fileDescriptions.map((description, index) => {
                        const { getRootProps, getInputProps } = createDropzoneProps(index);
                        return (
                            <Grid container spacing={1} key={index} style={{ marginBottom: '20px' }}>
                                {/* Description Section */}
                                <Grid item xs={12} md={10}>
                                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                        {description}
                                        {index != 2 && ( <span style={{ color: 'red', marginLeft: '4px' }}> *</span>)}
                                       
                                    </Typography>
                                    {index === 2 && (
                                        <Typography
                                            component="a"
                                            href="https://rabbitlife-my.sharepoint.com/:i:/g/personal/saijai_s_rabbitlife_co_th/Ef_D2yj-LP9DtzcbhUNP_70BzGqtkwNUtdUve5O2HgSpBw" // ใส่ URL ที่ต้องการเปิด
                                            target="_blank" // เปิดลิงก์ในแท็บใหม่
                                            rel="noopener noreferrer" // ป้องกันการโจมตีจากแท็บอื่น
                                            variant="body2"
                                            style={{ fontWeight: 'bold', color: 'red', cursor: 'pointer' }}
                                        >
                                            (ตัวอย่างเอกสาร)
                                        </Typography>
                                    )}
                                    <Typography variant="body2" color="textSecondary">
                                        รองรับไฟล์ .jpeg, .png, .pdf
                                    </Typography>
                                </Grid>

                                {/* File Upload Button Section */}
                                <Grid item xs={12} md={2} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <div {...getRootProps()} style={{ cursor: 'pointer' }}>
                                        <input {...getInputProps()} />
                                        <Button
                                            variant="outlined"
                                            style={{
                                                borderRadius: '15px',
                                                padding: '8px 20px',
                                                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
                                                textTransform: 'none',
                                                backgroundColor: '#f7f7f7',
                                                borderColor: '#cccccc',
                                                color: '#333333',
                                                fontSize: '16px',
                                            }}
                                        >
                                            อัปโหลด
                                        </Button>
                                    </div>
                                </Grid>

                                {/* File Name Preview */}
                                <Grid item xs={12} md={10}>
                                    {file[index] && (
                                        <Typography
                                            variant="subtitle1"
                                            style={{ cursor: 'pointer', color: '#ff5e43', textDecoration: 'underline' }}
                                            onClick={() => handleClickOpen(file[index])}
                                        >
                                            {file[index].name}
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            
        


            {/* Dialog for File Preview */}
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogContent>
                    <IconButton
                        style={{ position: 'absolute', right: 8, top: 8 }}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                    {selectedFile && selectedFile.type.startsWith('image/') && (
                        <img
                            src={selectedFile.preview}
                            alt={selectedFile.name}
                            style={{ width: '100%', height: 'auto' }}
                        />
                    )}
                    {selectedFile && selectedFile.type === 'application/pdf' && (
                        <object
                            data={selectedFile.preview}
                            type="application/pdf"
                            width="100%"
                            height="500px"
                        >
                            <p>PDF preview not available.</p>
                        </object>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default FileUploadWithPreviewName;
