import React, { useState, useEffect } from "react";
import Header_Home from "../../Component/Header_Home";
import Header from "../../Component/Header";
import "./paymentCallBack-styles.css";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Col } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Box, Typography, Grid, Paper, Button, Card, CardContent, Divider } from '@mui/material';
import { getDataByReferenceID } from "../PaymentService/paymentService";
import { Await, useLocation } from 'react-router-dom';
import { CurrencyFormater } from "../../../util";

const PaymentCallBack = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const theme = useTheme();
  const referenceNumber = query.get('referenceNumber');
  const [activeTab, setActiveTab] = useState();
  const [policyNo, setPolicyNo] = useState('');
  const [paymentDuedate, setPaymentDuedate] = useState('');
  const [paymentChannel, setPaymentChannel] = useState('');
  const [paymentCurentDate, setPaymentCurentDate] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [amount, setAmount] =  useState(0)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleSelect = (key) => {
    setActiveTab(key);
  };

  const goBack = () => {
    window.location.href = "/home";
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // เดือนเริ่มต้นที่ 0
    const year = d.getFullYear() + 543; // เพิ่ม 543 ปีเพื่อให้เป็น พ.ศ.
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await getDataByReferenceID(referenceNumber);
      if (result.message =="Success") {
      setPolicyNo(result.payload.policyNo)
      setPaymentDuedate(result.payload.paymentDueDate)
      if(result.payload.paymentMethod =="card"){
        setPaymentChannel('บัตรเครดิต')
      }else if(result.payload.paymentMethod =="qr"){
        setPaymentChannel('โอนเงิน')
      }else{
        setPaymentChannel('อื่นๆ')
      }
      
      setPaymentCurentDate(result.payload.createdDate)
      if(result.payload.paymentStatus=="PAID"){
        setPaymentStatus("ชำระเงินสำเร็จ")
      }else{
        setPaymentStatus("ชำระเงินไม่สำเร็จ")
      }
      
        // Update state with the result data as needed
      }
      setAmount(result.payload.amount)

    };
    fetchData();
  }, [referenceNumber]);

  return (
    <>
      <Header_Home />
      <Header title="ชำระเบี้ยประกัน" />
      <div className="container-md">
        <Box className="container">
          <Paper elevation={3} className="paper" style={{ marginTop: '20px', marginLeft: '20px', marginRight: '20px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className={`label ${isSmallScreen ? 'small' : ''}`}>กรมธรรม์เลขที่</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={`value ${isSmallScreen ? 'small' : ''}`}>{policyNo}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={`label ${isSmallScreen ? 'small' : ''}`}>วันที่ครบกำหนดชำระ</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={`value ${isSmallScreen ? 'small' : ''}`}>{formatDate(paymentDuedate)}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={`label ${isSmallScreen ? 'small' : ''}`}>จำนวนเงิน</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={`value ${isSmallScreen ? 'small' : ''}`}>{CurrencyFormater(amount)}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={`label ${isSmallScreen ? 'small' : ''}`}>ช่องทางการชำระ</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={`value ${isSmallScreen ? 'small' : ''}`}>{paymentChannel}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={`label ${isSmallScreen ? 'small' : ''}`}>สถานะ:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={`value ${isSmallScreen ? 'small' : ''}`} style={{color:paymentStatus == 'ชำระเงินสำเร็จ' ?  'green': 'red'}}>{paymentStatus}</Typography>
                <Typography className={`value ${isSmallScreen ? 'small' : ''}`}>{formatDate(paymentCurentDate)}</Typography>
              </Grid>
            </Grid>
            <br/>
            
            <Typography variant="small" className={`value ${isSmallScreen ? 'small' : ''}`} color="red">
          ท่านสามารถดาวน์โหลดใบเสร็จรับเงินได้ ภายใน 7 วัน ที่เมนู ดาวน์โหลดเอกสาร
        </Typography>
          </Paper>

          

          {/* <Box className="button-container">
            <Button variant="contained" className="button" onClick={goBack}>กลับสู่หน้าหลัก</Button>
          </Box> */}
          <Row className="d-flex flex-lg-row flex-column mt-2 mb-5">
              <Col className="d-flex justify-content-start px-5">
                <button
                  onClick={goBack}
                  className="btn-checkout-back w-100 p-2 bold"
                >
                  กลับสู่หน้าหลัก
                </button>
              </Col>
            </Row>
        </Box>
      </div>
    </>
  );
};
export default PaymentCallBack;
