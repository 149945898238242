import React, { useState, useEffect } from "react";
import "../../App.css";
import menu_1 from "../../images/1.svg";
import menu_2 from "../../images/2.svg";
import menu_3 from "../../images/3.svg";
import menu_4 from "../../images/4.svg";
import menu_5 from "../../images/5.svg";
import menu_6 from "../../images/6.svg";
import menu_7 from "../../images/7.svg";
import menu_8 from "../../images/8.svg";
import menu_9 from "../../images/จดหมาย.png";
import menu_10 from "../../images/12. Consent.png";
import menu_11 from "../../images/11.svg";
import menu_12 from "../../images/13. ปรับปรุงสภานะ.png";
import menu_13 from "../../images/14. Payment Inquiry.png";
import menu_14 from "../../images/14.svg";
import menu_15 from "../../images/15.svg";
import menu_16 from "../../images/15. รายงาน.png";
import menu_17 from "../../images/16. รายชื่อลูกค้าที่ลงทะเบียน.png";
import menu_18 from "../../images/18.svg";
import menu_22 from "../../images/14. Payment Inquiry.png";
import menu_mail from "../../images/Mail.svg";
import menu_linking from "../../images/Linking.svg";
import menu_tall from "../../images/Tall Person.svg";
import Header_Home from "../Component/Header_Home";
import { getMenuParameterAPI, getMenuByRoleAPI,getAge,getCheckIdnv } from "./homeService/homeService";
import "./Home-styles.css";
import { useNavigate } from "react-router-dom";
import Footer from "../Component/Footer";
import CryptoJS from 'crypto-js';

const Home = () => {
  const [role, setRole] = useState("");
  const [menu, setMenu] = useState([]);
  let navigate = useNavigate();
  const [profileRole, setProfileRole] = useState(localStorage.getItem("profile"));
  const [age,setAge] = useState(0)
  const [idnvFlag,setIdnvFlag] = useState(0)


  const handleGetMenu = async () => {
    // const profile = await localStorage.getItem("profile");
    const profile =
      (await localStorage.getItem("profile")) !== null
        ? localStorage.getItem("profile")
        : null;
    const token =
      (await localStorage.getItem("token_login")) !== null
        ? localStorage.getItem("token_login")
        : null;
    const perRole =
      (await localStorage.getItem("role")) !== null
        ? localStorage.getItem("role")
        : null;
    console.log("have Use", profile, token)
    console.log(perRole);
    if (profile !== null && token !== null) {
      console.log("have Use");
      setRole(profile);
      console.log(profile);
      let menu;

      if (profile === 'Customer') {
        const resultMenu = await getMenuParameterAPI();
        menu = resultMenu.data.payload;
        console.log(menu);
      } else if (profile === 'POS') {
        const resultByRole = await getMenuByRoleAPI(perRole);
        menu = resultByRole.data.payload;
        console.log(menu);
      }
      setMenu(menu);

    } else {
      localStorage.clear();
      navigate("/");
    }

  };

  const decryptCardNumber = (encryptedCardNumber, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedCardNumber, secretKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  };

  const getAgeAPI = async () => {
    const result = await getAge(decryptCardNumber(localStorage.getItem("customer_id"),process.env.REACT_APP_ENCRYPT_DATA));
    console.log(result);
    setAge(result);
    localStorage.setItem("age",result.data);

  };

  const getCheckIdnvAPI = async () => {
    const result = await getCheckIdnv(decryptCardNumber(localStorage.getItem("customer_id"),process.env.REACT_APP_ENCRYPT_DATA));
    console.log(result);
    console.log(result.data.data.idnvFlag);
    setIdnvFlag(result);
    localStorage.setItem("idnvFlag",result.data.data.idnvFlag);

  };

  useEffect(() => {
    handleGetMenu();
    getAgeAPI();
    getCheckIdnvAPI();
  }, []);

  const handleGoToRoute = (url) => {
    navigate(url);
  };

  const img = (req) => {
    switch (req) {
      case "ISV001":
        return menu_1;
      case "ISV002":
        return menu_2;
      case "ISV003":
        return menu_3;
      case "ISV004":
        return menu_4;
      case "ISV005":
        return menu_5;
      case "ISV006":
        return menu_6;
      case "ISV007":
        return menu_7;
      case "ISV008":
        return menu_8;
      case "ISV009":
        return menu_9;
      case "ISV010":
        return menu_10;
      case "ISV011":
        return menu_11;
      case "ISV012":
        return menu_12;
      case "ISV013":
        return menu_22;
      case "ISV014":
        return menu_14;
      case "ISV015":
        return menu_15;
      case "ISV016":
        return menu_16;
      case "ISV017":
        return menu_17;
      default:
        return menu_13;
    }
  };
  return (
    <>
      <Header_Home menu={menu} role={role} />
      <div className="item-container">
        <div className="row">
          <div className="col-12">
            <h2>
              <b>บริการทั้งหมด</b>
            </h2>
          </div>
          {menu &&
            // menu.length &&
            menu.map(
              (post, index) =>
                post.role == role && (
                  <div key={index} className="col-4 mar">
                    <a onClick={() => handleGoToRoute(post.urlLink)}>
                      <div className="item-menu flex-column text-center">
                        <img
                          className="image align-self-center mt-2"
                          src={img(post.code)}
                          alt=""
                        />
                        {/* <h3 className="flex-shrink-1">{post.menuName}</h3> */}
                        <p className="flex-shrink-1" style={{color:" #F2654E"}}>{post.menuName}</p>
                      </div>
                    </a>
                  </div>
                )
            )}
        </div>
      </div>
    </>
  );
  // return (
  //   <>
  //     <Header_Home />
  //     <section className="d-flex justify-content-center align-items-center">
  //       <div className="my-4 px-4">
  //         <div className="row justify-content-center">
  //           <div className="col-12 text-start px-lg-2">
  //             <h2>
  //               <span className="bold">บริการทั้งหมด</span>
  //             </h2>
  //           </div>
  //           <div className="card-menu-grid">
  //             {menu.map((item) => (
  //               <div
  //                 className="card-menu d-flex flex-column align-items-center"
  //                 onClick={() => {
  //                   handleGoToRoute(item.urlLink);
  //                 }}
  //               >
  //                 <img
  //                   className="image align-self-center"
  //                   style={profileRole === "POS" ? { width: '100px' } : { maxWidth: '120px' }}
  //                   src={img(item.code)}
  //                   alt=""
  //                 />
  //                 <p
  //                   style={profileRole === "POS" ? { textAlign: 'center', width: '160px' } : { textAlign: 'center', width: '180px' }}
  //                 >{item.menuName}</p>
  //               </div>
  //             ))}
  //           </div>
  //         </div>
  //       </div>
  //     </section>

  //   </>
  // );
};

export default Home;
