import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { Form } from "react-bootstrap";
import axios from 'axios';
import Header_Home from '../Component/Header_Home';
import PopUp from '../Component/Popup';
class Request_RD extends Component {
    constructor(props) {
        super(props);
        this.state = {date: new Date() ,
        token: '',
        username:'',
        step:1,
        offset: 0,
        data: [],
        perPage: 50,
        currentPage: 0,
        pageCount:0,
        showing_step:1,
        showing_all:false,
        loading:true
    }
    }

    receivedData() {
         
        fetch(process.env.REACT_APP_API_BASEURL + "/api/policy/list",
        //fetch(process.env.REACT_APP_API_BASEURL + "/api/policy/list?customer="+localStorage.getItem('customer_id'),
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { 
            //console.log(res)
            const data = res.payload;
            const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
            
            if(data.length > 1){
                this.setState({showing_all : true})
            }
            const postData = slice.map(item => 
                <div className="col-lg-8 col-md-12 col-sm-12 margin-auto">
                    <div className="card">  
                        <div className="card-body">
                            <div className="row col-12">
                                <div className="col-1 text-right pt-2">
                                    <input onChange={this.handleChange("policy_change_ans")} type="checkbox" id={item.policy_no} name={item.policy_no}  className="margin-left-px"  disabled={this.state.showing3} ></input>
                                </div>
                                <div className="col-9 pr-0">
                                    <label className='text-righ mt-2'><strong>หมายเลขกรมธรรม์ : </strong> {item.policy_no}</label>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
          
            )
            this.setState({payload : res.payload,
                pageCount: Math.ceil(data.length / this.state.perPage),
                postData, loading:false})
            console.log("user", res.payload,res.payload.length, this.state.postData )
        })
        .catch(function(res){ console.log(res) })
    }

    componentDidMount() {
        fetch(process.env.REACT_APP_API_BASEURL + "/api/user/getprofile",
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { 
            console.log("profile.check" , res)
            if(res.success == 'true'){
                if(res.payload.profile == 'POS1'){
                    window.location.href ='/home';
                }
            }
        })
      
        // if(localStorage.getItem('profile') == 'POS1'){
        //     window.location.href ='/home';
        // }
        console.log('componentDidMount')
        this.receivedData()
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    handleselectedFile  = input => e => {
        this.setState({
            selectedFile: e.target.files[0],
            //**** added line below ****//
            file_ori: e.target.files[0].name,
            [input]: e.target.files[0].name
        });
    };

    componentDidUpdate() {
       
        console.log('componentDidUpdate')
    }

    componentWillUnmount() {
        console.log('componentWillUnmount')
       
    }

    con () {
        const { payload} = this.state;
        const data = payload;
        const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        const postData2 = slice.map(item => 
            this.item_change(item)
        )
        this.setState({postData2})
    }

    item_change (item) {
        // const { policy_change_ans} = this.state;
        if(document.getElementById(item.policy_no).checked) {
            return (
                <div className="card-body list-group p-0">
                    <div className="row col-lg-9 col-md-12 p-0 margin-auto">
                        <div className="card p-0">  
                            <div className="card-body header-card">
                            <label className='text-righ mt-2'><strong>หมายเลขกรมธรรม์ที่ใช้สิทธิลดหย่อนภาษี </strong> </label>
                            </div> 
                            <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <div>
                                <label className='text-righ mt-2'><strong>หมายเลขกรมธรรม์ : </strong> {item.policy_no}</label>
                                </div>
                            </li>                  
                        </ul>
                        
                        </div>   
                    </div>              
                </div>    
            )
        }
    }

    handleChange = input => e => {
        console.log("handleChange",e.target.value)

        this.setState({ [input]: e.target.value });
        console.log({ [input]: e.target.value })
    };
    next () {
        const { showing_step, payload, policy_change_ans} = this.state;
        let num = 1 ;
        var txt_error = 'กรุณากรอกข้อมูล';
        console.log("showing_step",showing_step)
        
        if(showing_step === 1) {
            console.log("policy_change")
            let count_change = 0;
            payload.map(item => {
                if(document.getElementById(item.policy_no).checked){
                    count_change++;
                }
            }
                
            )
            if(count_change === 0){
                alert("กรุณาเลือกกรมธรรม์")
                num++;
            } 
        } 
        else if (showing_step === 2) {
            if(!document.getElementById('file1').files.length>0){
                alert('เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ')
                num++;
            } else {
                let type =  document.getElementById('file1').files[0].type 
                
                if(!(type === 'image/jpeg' || type === 'image/jpg' ||type === 'image/png' ||type === 'application/pdf' )){
                    alert("กรุณาอัพโหลดเอกสารนามสกุล .jpeg, .png, .pdf เท่านั้น")
                    num++;
                }
            }
            
        }
        if(num === 1 ){
            this.setState({
                showing_step: showing_step+1
            });
            this.con();
        }
        
    }
    back () {
        const {showing_step} = this.state;
        this.setState({
            showing_step: showing_step-1
        });
        this.con();
    }

    select_all = input => e =>  {
        // const {payload} = this.state;
        const {payload} = this.state;
        let policies = [];
        let body = [];
        if(document.getElementById("all").checked){
            for (let item of payload) {

                document.getElementById(item.policy_no).checked = true
            }
        } else {
            for (let item of payload) {

                document.getElementById(item.policy_no).checked = false
            }
        }
    }
    save () {
        this.setState({loading:true})
        document.querySelector("input[type='submit']").click();
    }

    submitUpload = (event) => {
        const { policy_change_ans, showing_step} = this.state;
        // const FormData = require('form-data');
        
        // event.preventDefault();
 
            let url = process.env.REACT_APP_API_BASEURL + '/api/personal/upload'
            event.preventDefault();

            
            let file_name1 = undefined;
            let file1;
            if(event.target[0].files[0]){
                file_name1 = event.target[0].files[0].name;
                file1 = event.target[0].files[0];
            }


            const formData = new FormData();        
            formData.append('image1', file1, file_name1);
            formData.append('policy', policy_change_ans);
            
            // formData.append('customer', localStorage.getItem('customer_id'));
            formData.append('type', '10');
            for (var [key, value] of formData.entries()) { 
                console.log(key, value);
            }
            var self = this;
            let response =  axios.post(url, formData, {
                headers: {
                    'authorization': localStorage.getItem('token_login'),
                    'content-type': 'multipart/form-data'
                }
            
            }).then(function(result) {
                console.log("result",result) 
                self.setState({loading:false})
                if (!result.data.success) {
                    console.log('error: ' + result.data.payload.error);
                    alert( result.data.payload.error)
                } else {
                    alert( "บริษัทได้รับทราบข้อมูลจากท่านแล้ว ขอบคุณค่ะ")
                    window.location.href="/request";
                }
            })


        
        
    };



 
    render() {
        const { showing_step, postData, postData2, showing_all,policy_change_ans, loading} = this.state;
        return (
            <div >
                {loading ? <PopUp /> : null}
                <Header_Home/>
                <section className="d-flex justify-content-center align-items-center h-100 my-5">
                    <div className="container-fluid  container-margin">
                        <div className='row'>
                            <div className="col-12">
                                <div className="card" style={{ display: (showing_step === 1 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>ขอใช้สิทธิ ลดหย่อนภาษี </strong></label>
                                            </div>
                                        </div>       
                                    </div>
                       
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 p-0 margin-auto">
                                            <div className="col-12 pt-4">
                                                <div className="col-lg-8 col-md-12 col-sm-12 margin-auto" id="all_policy"  style={{ display: (showing_all? 'block' : 'none')}}>
                                                    <div className="card">  
                                                        <div className="card-body">
                                                            <div className="row col-12 ">
                                                                <div className="col-1 text-right">
                                                                <input onChange={this.select_all("select_all")} type="checkbox" id="all" name="all" value="all" className="margin-left-px mt-3" disabled={this.state.showing2}></input>
                                                                </div>
                                                                <div className="col-9">
                                                                    <label className='text-righ mt-2'> ทุกกรมธรรม์</label>
                                                                </div>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                                {postData} 
                                                </div>
                                       
                                                
                                        </div>       
                                        <div className="row col-lg-8 col-md-12 margin-auto">
                                            {/* <label><span className="bold">กรณีผู้เอาประกันชำระเบี้ยด้วยการโอนเงินเข้าบัญชีบริษัท :</span>  บริษัทฯ จะโอนเงินเข้าบัญชีธนาคารตามที่ท่านได้ให้ไว้กับบริษัทฯ</label>
                                            <br/><br/>
                                            <label><span className="bold">กรณีที่ผู้เอาประกันชำระเบี้ยงวดแรกด้วยบัตรเครดิต :</span> บริษัทฯ จะคืนเงินเข้าบัตรเครดิตใบเดิม</label>
                                            <label>เมื่อบริษัทฯ ได้รับเอกสารการยกเลิกกรมธรรม์เรียบร้อยแล้ว บริษัทฯ จะดำเนินการคืนเงินภายใน 30 วัน</label><br/><br/> */}
                                            {/* <label><span className="bold">ที่อยู่ในการส่งเล่มกรมธรรม์</span><br/></label> */}
                                            <label> 
                                            <span className="bold"> หมายเหตุ : </span>
                                             เบี้ยประกันชีวิตสำหรับอ้างอิงการใช้สิทธิหักลดหย่อนภาษีเงินได้บุคคลธรรมดาตามกฎหมาย (กรมธรรม์ประกันชีวิตฉบับนี้ทำหลังปี พ.ศ. 2551) แบบสิบปีขึ้นไป 
                                            </label>
                                                                
                                        </div>
                                    </div>
                                    <div className="card-body App">
                                        <a  href="/request" type="button" className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a>
                                        <a  onClick={() => this.next()} className="btn btn-lg btn-blue">ต่อไป</a>
                                    </div>
                                </div>

                                <div className="card" style={{ display: (showing_step === 2 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>อัพโหลดไฟล์</strong></label>
                                            </div>
                                        </div>      
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 ">
                                            
                                            <Form onSubmit={this.submitUpload} id="form_id">
                                                <div className="padding-title">
                                                    <div className="row col-12 ">
                                                        <div className="col-lg-6 col-md-8 col-sm-8 col-xs-8 text-right mt-3">
                                                            <label htmlFor="filename" className='text-righ'><strong>หนังสือให้ความยินยอมในการเปิดเผยข้อมูลส่วนบุคคล</strong> <br/>รองรับไฟล์ .jpeg, .png, .pdf</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="col-12 text-left" align="right"  style={{ margin:'10px 0px 0px 0px' }}>
                                                                <label htmlFor="file1" className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i> อัพโหลด 
                                                                </label>
                                                                <label className='ml-2'>{this.state.fileName1}</label>
                                                                <input  type="file"  id="file1" accept="image/png, image/jpeg, image/jpg, application/pdf" style={{display:'none'}}  onChange={this.handleselectedFile("fileName1")}/>
                                                            </div>
                                                        </div>
                                                    </div>      
                                                </div>

                                                <div className="row dv-button">
                                                        
                                                    <input  type="submit" className="btn btn-primary button-right-page"  style={{display:'none'}}></input>                               
                                                </div>

                                            </Form>
                                                
                                        
                                        </div>               
                                    </div>
                                    <div className="card-body App">
                                        <a onClick={() => this.back()}  className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a> 
                                        <a onClick={() => this.next()} className="btn btn-lg btn-blue">ต่อไป</a>
                                    </div>
                                </div>

                                <div className="card" style={{ display: (showing_step === 3 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>ยืนยันข้อมูล</strong></label>
                                            </div>
                                        </div>      
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 margin-auto">
                                            <div className="col-lg-10 col-md-12 margin-auto">
                                                {postData2} 

                                                <div className="card-body list-group p-0">
                                                    <div className="col-lg-10 col-md-12 margin-auto p-0">
                                                        <div className="card p-0">  
                                                            <div className="card-body  header-card">
                                                            <label className='text-righ mt-2'><strong>เอกสารแนบ </strong></label>
                                                            </div> 
                                                            <ul className="list-group list-group-flush">
                                                            <li className="list-group-item">
                                                            <span className="bold">หนังสือให้ความยินยอมในการเปิดเผยข้อมูลส่วนบุคคล &nbsp;&nbsp;:</span>
                                                            <span className="light">{this.state.fileName1}</span>
                                                            </li>
                                                                           
                                                        </ul>
                                                        
                                                            
                                                        </div>   
                                                    </div>              
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 p-0 margin-auto">
                                            <div className="col-lg-10 col-md-12 margin-auto">
                                               

                                                <div className="card-body list-group p-0">
                                                    <div className="col-lg-10 col-md-12 margin-auto">
                                                        <div className="card p-0">  
                                                            {/* <div className="card-body header-card">
                                                            <label className='text-righ mt-2'><strong>เอกสารแนบ </strong></label>
                                                            </div>  */}
                                                            <ul className="list-group list-group-flush p-0">
                                                            <li className="list-group-item">
                                          
                                                            <span className="bold">แจ้งความประสงค์ลดหย่อนภาษี</span><br/><br/>
                                                 
                                                            ตามประกาศอธิบดีกรมสรรพากรเกี่ยวกับภาษีเงินได้(ฉบับที่ 383) กำหนดให้ผู้มีเงินได้ที่ต้องการนำเบี้ยประกันไปใช้สิทธิลดหย่อนภาษีเงินได้สำหรับปีภาษี 2561 เป็นต้นไป 
                                                            ต้องแจ้งความประสงค์ที่จะใช้สิทธิ และยินยอมให้บริษัทฯ เปิดเผยข้อมูลเกี่ยวกับเบี้ยประกันต่อสรรพากร
                                                            {/* ท่านสามารถแจ้งความประสงค์ที่จะใช้สิทธิลดหย่อยภาษีเงินได้ 	 */}
                                                         
                                                            </li>
                                                                           
                                                        </ul>
                                                        
                                                            
                                                        </div>   
                                                    </div>              
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body App">
                                        <a onClick={() => this.back()} type="button" className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a>
                                        <a  onClick={() => this.save()} className="btn btn-lg btn-blue">บันทึก</a>
                                    </div>
                                </div>


                                <div className="card" style={{ display: (showing_step === 4 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>ส่งคำร้องขอใช้สิทธิ Freelook : {policy_change_ans} เรียบร้อย</strong></label>
                                            </div>
                                        </div>      
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 ">
                                            <div className="col-2 ">
                                                    {/* <label htmlFor="subject" className='text-righ mt-2'><strong>Email</strong></label> */}
                                            </div>
                                            <div className="col-10">
                                               

                                                <div className="card-body list-group">
                                                    <div className="row col-10 ">
                                                        <div className="card p-0">  
                                                            {/* <div className="card-body header-card">
                                                            <label className='text-righ mt-2'><strong>เอกสารแนบ </strong></label>
                                                            </div>  */}
                                                            <ul className="list-group list-group-flush">
                                                            <li className="list-group-item">
                                          
                                                            <span className="bold">แจ้งความประสงค์ลดหย่อนภาษี</span><br/><br/>
                                                 
                                                            ตามประกาศอธิบดีกรมสรรพากรเกี่ยวกับภาษีเงินได้(ฉบับที่ 383) กำหนดให้ผู้มีเงินได้ที่ต้องการนำเบี้ยประกันไปใช้สิทธิลดหย่อนภาษีเงินได้สำหรับปีภาษี 2561 เป็นต้นไป 
                                                            ต้องแจ้งความประสงค์ที่จะใช้สิทธิ และยินยอมให้บริษัทฯ เปิดเผยข้อมูลเกี่ยวกับเบี้ยประกันต่อสรรพากร
                                                            ท่านสามารถแจ้งความประสงค์ที่จะใช้สิทธิลดหย่อยภาษีเงินได้ 	
                                                         
                                                            </li>
                                                                           
                                                        </ul>
                                                        
                                                            
                                                        </div>   
                                                    </div>              
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body App">
                                        <a onClick={() => this.next()} id="next_1" className="btn btn-lg btn-blue" style={{display:'none'}}>ต่อไป</a>
                                        <a onClick={() => this.back()} type="button" className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a>
                                        <a  onClick={() => this.save()} className="btn btn-lg btn-blue">บันทึก</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    

                        {/* <div className='App'>
                            <a  href="/home" type="button" className="btn btn-lg btn mt-3 cust-btn"  >ย้อนกลับ</a>
                        </div> */}
                        </div> 
                </section>
            </div> 
        )
    }
        
    
}

export default Request_RD;