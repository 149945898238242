import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { Form } from "react-bootstrap";
import axios from 'axios';
import moment from 'moment/moment';
import Header_Home from '../Component/Header_Home';
import PopUp from '../Component/Popup';

let due_date = ''
class EditCard extends Component {
    constructor(props) {
        super(props);
        this.state = {date: new Date() ,
        token: '',
        username:'',
        step:1,
        offset: 0,
        data: [],
        perPage: 50,
        currentPage: 0,
        pageCount:0,
        showing_step:1,
        showing_card2:false,
        showing_card3:false,
        expiry:'',
        showing_all:false,
        loading:true,
        class_for_owner:"padding-title",
        class_owner_cf:"list-group-item"
    }
    }

    receivedData() {
         
        fetch(process.env.REACT_APP_API_BASEURL + "/api/personal/getcredit",
        // fetch(process.env.REACT_APP_API_BASEURL + "/api/personal/getcredit?customer="+localStorage.getItem('customer_id'),
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { 
            //console.log(res)
            console.log("crad", res)

            if(res.success) {
                const data = res.payload;
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                if(data.length > 1){
                    this.setState({showing_all : true})
                }
                const postData = slice.map(item => 
                    <div className="col-lg-8 col-md-12 col-sm-12 margin-auto">
                        <div className="card">  
                            <div className="card-body">
                                <div className="row col-12 m-0">
                                    <div className="col-1 text-right mt-1 pt-2">
                                    <input onChange={this.handleChange("policy_change_ans")} type="radio" id="policy_change" name="policy_change" value={item.policy_no} className="margin-left-px"  disabled={this.state.showing2} ></input>

                                    {/* <input  onChange={this.handleChange("policy_change")}  type="checkbox" id= {item.policy_no} name={item.policy_no} value={item.policy_no} className="margin-left-px mt-3" disabled={this.state.showing2}></input> */}
                                    </div>
                                    <div className="col-9 pr-0">
                                        <label className='text-righ mt-2'><strong>หมายเลขกรมธรรม์ : </strong> {item.policy_no}</label>
                                        <div>
                                        <label className='text-righ mt-2'><strong>ชื่อ นามสกุลบนบัตร : </strong> {item.card_holder_name}</label>
                                        </div>
                                        <div>
                                        <label className='text-righ mt-2'><strong>หมายเลขบัตร : </strong> {item.card_number?item.card_number.substring(0,4)+"********"+item.card_number.substring(item.card_number.length-4,item.card_number.length):""}</label>
                                        </div>
                                        <div>
                                        <label className='text-righ mt-2'><strong>เดือน/ปี หมดอายุของบัตร : </strong> {item.card_expire_date}</label>
                                        </div>
                                        <div>
                                        <label className='text-righ mt-2'><strong>ประเภทของบัตร : </strong> {item.card_type.trim() == "MC"? "Mastercard" : 
                                            item.card_type.trim() == "VI"? "Visa" : 
                                            item.card_type.trim() == "AM"? "Amex" : 
                                            item.card_type.trim() == "C4"? "JCB" : 
                                            item.card_type.trim() == "DI"? "Diners" :
                                            item.card_type.trim() == "C5"? "CUP" :
                                            item.card_type.trim() == "99"? "Card" : item.card_type}</label>
                                        </div>
                                        <div>
                                        <label className='text-righ mt-2'><strong>ธนาคารเจ้าของบัตรเครดิต : </strong> {item.credit_bank_name}</label>
                                        </div>
                                        <div>
                                        <label className='text-righ mt-2'><strong>ความสัมพันธ์เจ้าของบัตรเครดิตผู้เอาประกันภัย : </strong> {item.card_relation}</label>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                )
                this.setState({payload : res.payload,
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    postData, loading:false})
                
            } else {
                this.setState({
                    showing_step: 10, 
                    loading:false
                });
            }
            
        })
        .catch(function(res){ console.log(res) })
    }

    componentDidMount() {
        fetch(process.env.REACT_APP_API_BASEURL + "/api/user/getprofile",
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { 
            console.log("profile.check" , res)
            if(res.success == 'true'){
                if(res.payload.profile == 'POS2'){
                    window.location.href ='/home';
                }
            }
        })
        // if(localStorage.getItem('profile') == 'POS2'){
        //     window.location.href ='/home';
        // }

        console.log('componentDidMount')
        this.receivedData()

        let dateDropdown = document.getElementById('expireYY');

        let currentYear = new Date().getFullYear();
        let earliestYear = new Date().getFullYear()+8;

        while (currentYear <= earliestYear) {
        let dateOption = document.createElement('option');
        dateOption.text = currentYear.toString().substr(-2);
        dateOption.value = currentYear.toString().substr(-2);
        dateDropdown.add(dateOption);
        currentYear += 1;
        }
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    onChange(evt) {
        // console.log("onChange fired with event info: ", evt);
        // var html = evt.editor.getData();
        console.log("this", document.getElementById("expireMM").value+'/'+document.getElementById("expireYY").value);
        document.getElementById("expiry").value =document.getElementById("expireMM").value+'/'+document.getElementById("expireYY").value
        // this.setState({ expiry});
    }


    handleselectedFile  = input => e => {
        var file = e.target.files[0];

        if([input] == "fileName1") {
            var a = document.getElementById('showname1'); //or grab it by tagname etc
            a.href = URL.createObjectURL(file)//[reader.result]
        } else if([input] == "fileName2") {
            var a = document.getElementById('showname2'); //or grab it by tagname etc
            a.href = URL.createObjectURL(file)//[reader.result]
        } else if([input] == "fileName3") {
            var a = document.getElementById('showname3'); //or grab it by tagname etc
            a.href = URL.createObjectURL(file)//[reader.result]
        } else if([input] == "fileName4") {
            var a = document.getElementById('showname4'); //or grab it by tagname etc
            a.href = URL.createObjectURL(file)//[reader.result]
        } else if([input] == "fileName5") {
            var a = document.getElementById('showname5'); //or grab it by tagname etc
            a.href = URL.createObjectURL(file)//[reader.result]
        }
       
        console.log("[input]",[input])
        this.setState({
            selectedFile: e.target.files[0],
            //**** added line below ****//
            file_ori: e.target.files[0].name,
            [input]: e.target.files[0].name
        });
    };
    componentDidUpdate() {
       
        console.log('componentDidUpdate')
    }

    componentWillUnmount() {
        console.log('componentWillUnmount')
       
    }

    con () {
        const { payload} = this.state;
        const data = payload;
        const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        const postData2 = slice.map(item => 
            this.item_change(item)
        )
        this.setState({postData2})
    }

    item_change (item) {
        const { relation_card_change_ans} = this.state;
        if(item.policy_no ==  this.state.policy_change_ans) {
            return (
                <div className="card-body list-group p-0">
                    <div className="row col-lg-6 col-md-8 col-sm-8 col-xs-8  margin-auto">
                        <div className="card p-0">  
                            <div className="card-body header-card">
                            <label className='text-righ mt-2'><strong>หมายเลขกรมธรรม์ :  </strong> {item.policy_no}</label>
                            </div> 
                            <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <div>
                                <label className='text-righ mt-2'><strong>ชื่อนามสกุล เจ้าของบัตรเครดิต เดิม : </strong> {item.card_holder_name}</label>
                                </div>
                                <div>
                                <label className='text-righ mt-2'><strong>หมายเลขบัตร เดิม : </strong> {item.card_number?item.card_number.substring(0,4)+"********"+item.card_number.substring(item.card_number.length-4,item.card_number.length):""}</label>
                                </div>
                                <div>
                                <label className='text-righ mt-2'><strong>เดือน/ปี หมดอายุของบัตร เดิม : </strong> {item.card_expire_date}</label>
                                </div>
                                <div>
                                <label className='text-righ mt-2'><strong>ประเภทของบัตร เดิม : </strong> {item.card_type.trim() == "MC"? "Mastercard" : 
                                item.card_type.trim() == "VI"? "Visa" : 
                                item.card_type.trim() == "AM"? "Amex" : 
                                item.card_type.trim() == "C4"? "JCB" : 
                                item.card_type.trim() == "DI"? "Diners" :
                                item.card_type.trim() == "C5"? "CUP" :
                                item.card_type.trim() == "99"? "Card" : "1234"}</label>
                                </div>
                                <div>
                                <label className='text-righ mt-2'><strong>ธนาคารเจ้าของบัตรเครดิต เดิม : </strong> {item.credit_bank_name}</label>
                                </div>
                                <div>
                                <label className='text-righ mt-2'><strong>ความสัมพันธ์เจ้าของบัตรเครดิตผู้เอาประกันภัย เดิม : </strong> {item.card_relation}</label>
                                </div>
                            </li>

                            <li className="list-group-item">
                                <div>
                                <label className='text-righ mt-2'><strong>ชื่อนามสกุล เจ้าของบัตรเครดิต ใหม่ : </strong> {(item.policy_no ==  this.state.policy_change_ans)?document.getElementById("card_holder_name").value:item.card_holder_name}</label>
                                </div>
                                <div>
                                <label className='text-righ mt-2'><strong>หมายเลขบัตร ใหม่ : </strong>  {(item.policy_no ==  this.state.policy_change_ans)?document.getElementById("card_number").value:item.card_number}</label>
                                </div>
                                <div>
                                <label className='text-righ mt-2'><strong>เดือน/ปี หมดอายุของบัตร ใหม่ : </strong>{(item.policy_no ==  this.state.policy_change_ans)?document.getElementById("expiry").value:item.card_expire_date}</label>
                                </div>
                                <div>
                                <label className='text-righ mt-2'><strong>ประเภทของบัตร ใหม่ : </strong>{(item.policy_no ==  this.state.policy_change_ans)?(document.getElementById("card_type").value == "MC"? "Mastercard" : 
                                document.getElementById("card_type").value == "VI"? "Visa" : 
                                document.getElementById("card_type").value == "AM"? "Amex" : 
                                document.getElementById("card_type").value == "C4"? "JCB" : 
                                document.getElementById("card_type").value == "DI"? "Diners" :
                                document.getElementById("card_type").value == "C5"? "CUP" :
                                document.getElementById("card_type").value == "99"? "Card" : ""):item.card_type}</label>
                                </div>
                                <div>
                                    <label className='text-righ mt-2'><strong>ธนาคารเจ้าของบัตรเครดิต ใหม่ : </strong> {(item.policy_no ==  this.state.policy_change_ans)?document.getElementById("credit_bank_name").value:item.credit_bank_name}</label>
                                </div>
                                <div>
                                <label className='text-righ mt-2'><strong>ความสัมพันธ์เจ้าของบัตรเครดิตผู้เอาประกันภัย ใหม่ : </strong> {relation_card_change_ans}</label>
                                </div>
                            </li>                     
                        </ul>                           
                        </div>   
                    </div>              
                </div>  
            )
        }
        
        
       
    }

    handleChange = input => e => {
        console.log(e.target.value,input)
        // if(input!= 'relation_card_change_ans' && document.getElementById(e.target.value).checked== false){
        //     document.getElementById("all").checked =false
        // }
        this.setState({ [input]: e.target.value });
        console.log({ [input]: e.target.value })
    };

    select_all = input => e =>  {
        // const {payload} = this.state;
        const {payload} = this.state;
        let policies = [];
        let body = [];
        if(document.getElementById("all").checked){
            for (let item of payload) {

                document.getElementById(item.policy_no).checked = true
            }
        } else {
            for (let item of payload) {

                document.getElementById(item.policy_no).checked = false
            }
        }
    }

    next () {
        const { showing_step} = this.state;
        let num = 1 ;
        var txt_error = 'กรุณากรอกข้อมูล';
        console.log("showing_step",showing_step)
        let relation_card = this.state.relation_card_change_ans
        if(showing_step === 1) {
            let card_holder_name = document.getElementById("card_holder_name").value
            let card_number = document.getElementById("card_number").value
            let expireMM = document.getElementById("expireMM").value
            let expireYY = document.getElementById("expireYY").value
            let card_type = document.getElementById("card_type").value
            let credit_bank_name  = document.getElementById("credit_bank_name").value
            // let relation_card = this.state.relation_card_change_ans//document.getElementById("relation_card_change_ans").value
            let relation_card = this.state.relation_card_change_ans//document.getElementById("relation_card_change_ans").value

            console.log("relation_card: "+relation_card)
            if(!card_holder_name && card_holder_name !== undefined){
                document.getElementById("error_card_holder_name").innerHTML = `<span name="#card_holder_name" class="error">` + txt_error +  ` </span>`
                num++;
            }
            if(!credit_bank_name && credit_bank_name !== undefined){
                document.getElementById("error_credit_bank_name").innerHTML = `<span name="#credit_bank_name" class="error">` + txt_error +  ` </span>`
                num++;
            }
            if(!card_number && card_number !== undefined){
                document.getElementById("error_card_number").innerHTML = `<span name="#card_number" class="error">` + txt_error +  ` </span>`
                num++;
            }
            if(!card_type && card_type !== undefined){
                document.getElementById("error_card_type").innerHTML = `<span name="#card_type" class="error">` + txt_error +  ` </span>`
                num++;
            }
            console.log("expireMM :"+expireMM)
            if (expireMM === '-1' || expireYY === '-1') {
                console.log("expireMM :"+expireMM)
                // errorTitle.push('กรุณากรอกข้อมูล ชื่อ')
                document.getElementById("error_card_expire_date").innerHTML = `<span name="#card_expire_date" class="error">` + txt_error +  ` </span>`
                num++;
            } else {
                console.log("expireMM else :"+expireMM)
                document.getElementById("error_card_expire_date").innerHTML = ' '
            }
            // if (expireYY === -1) {
            //     // errorTitle.push('กรุณากรอกข้อมูล ชื่อ')
            //     document.getElementById("error_card_expire_date").innerHTML = `<span name="#card_expire_date" class="error">` + txt_error +  ` </span>`
            //     num++;
            // } else {
            //     document.getElementById("error_card_expire_date").innerHTML = ' '
            // }
            // console.log("new Date()",expireMM , (new Date()).getMonth())
            if(expireYY == (new Date()).getFullYear().toString().substr(-2)) {
                if(parseInt(expireMM) < parseInt((new Date()).getMonth())+1) {
                    alert("เดือน/ปี  ของบัตรเครดิตหมดอายุ");
                    document.getElementById("error_card_expire_date").innerHTML =`<span name="#card_expire_date" class="error"> กรุณาตรวจสอบวันหมดอายุของบัตรเครดิต </span>`
                    num++;
                }
            }

            if (card_type === '-1') {
                // errorTitle.push('กรุณากรอกข้อมูล ชื่อ')
                document.getElementById("error_card_type").innerHTML = `<span name="#card_type" class="error">` + txt_error +  ` </span>`
                num++;
            } else {
                document.getElementById("error_card_type").innerHTML = ' '
            }
            if((card_type === 'Visa' || card_type === 'Master') && card_number) {
                if(card_number.length>16|| card_number.length<16){
                    document.getElementById("error_card_number").innerHTML = `<span name="#card_number" class="error"> กรุณากรอกเลขบัตรให้ถูกต้อง </span>`
                    num++;
                }
            }
            if(card_type === 'Amex' && card_number) {
                if(card_number.length>15|| card_number.length<15){
                    document.getElementById("error_card_number").innerHTML = `<span name="#card_number" class="error"> กรุณากรอกเลขบัตรให้ถูกต้อง </span>`
                    num++;
                }
            }
            if(!relation_card || relation_card == undefined){
                console.log("Hi")
                document.getElementById("error_relation_card").innerHTML = `<span name="#relation_card" class="error">` + txt_error +  ` </span>`
                num++;
            } else {
                if(relation_card =="ตนเอง"){
                    this.setState({
                        class_for_owner: "padding-title none-dis",
                        class_owner_cf: "list-group-item none-dis"
                    });
                } else {
                    this.setState({
                        class_for_owner: "padding-title",
                        class_owner_cf: "list-group-item"
                    });
                }
                console.log("Hi2")
                document.getElementById("error_relation_card").innerHTML = ''
            }

            if(num == 1) {
                const { policy_change_ans} = this.state;
                if(policy_change_ans === undefined){
                    alert("กรุณาเลือกกรมธรรม์ที่ต้องการแจ้งเปลี่ยน")
                    num++;
                } 
            }

        } else if (showing_step === 2) {
            if(!document.getElementById('file1').files.length>0){
                alert('เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ')
                num++;
            } else {
                let type =  document.getElementById('file1').files[0].type 
                
                if(!(type === 'image/jpeg' || type === 'image/jpg' ||type === 'image/png' ||type === 'application/pdf' )){
                    alert("กรุณาอัพโหลดเอกสารนามสกุล .jpeg, .png, .pdf เท่านั้น")
                    num++;
                }
            }
            if(!document.getElementById('file2').files.length>0 && num === 1){
                alert('เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ')
                num++;
                // alert('เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ')
                // num++;
            } else {
                let type =  document.getElementById('file2').files[0].type 
                if(!(type === 'image/jpeg' || type === 'image/jpg' ||type === 'image/png' ||type === 'application/pdf' )){
                    alert("กรุณาอัพโหลดเอกสารนามสกุล .jpeg, .png, .pdf เท่านั้น")
                    num++;
                }
            }

            if(!document.getElementById('file3').files.length>0 && num === 1){
                alert('เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ')
                num++;
            } else if(document.getElementById('file3').files.length>0 && num === 1) {
                let type =  document.getElementById('file3').files[0].type 
                
                if(!(type === 'image/jpeg' || type === 'image/jpg' ||type === 'image/png' ||type === 'application/pdf' )){
                    alert("กรุณาอัพโหลดเอกสารนามสกุล .jpeg, .png, .pdf เท่านั้น")
                    num++;
                }
            }

            if(relation_card !="ตนเอง") {
                if(!document.getElementById('file4').files.length>0 && num === 1){
                    alert('เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ')
                    num++;
                }else if(document.getElementById('file4').files.length>0 && num === 1){
                    let type =  document.getElementById('file4').files[0].type 
                    
                    if(!(type === 'image/jpeg' || type === 'image/jpg' ||type === 'image/png' ||type === 'application/pdf' )){
                        alert("กรุณาอัพโหลดเอกสารนามสกุล .jpeg, .png, .pdf เท่านั้น")
                        num++;
                    }
                }
    
                if(!document.getElementById('file5').files.length>0 && num === 1){
                    alert('เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ')
                    num++;
                } else if(document.getElementById('file5').files.length>0 && num === 1){
                    let type =  document.getElementById('file5').files[0].type 
                    
                    if(!(type === 'image/jpeg' || type === 'image/jpg' ||type === 'image/png' ||type === 'application/pdf' )){
                        alert("กรุณาอัพโหลดเอกสารนามสกุล .jpeg, .png, .pdf เท่านั้น")
                        num++;
                    }
                }
            }

            
            // if(document.getElementById('file4').files.length>0 && num === 1){
            //     let type =  document.getElementById('file4').files[0].type 
                
            //     if(!(type === 'image/jpeg' || type === 'image/jpg' ||type === 'image/png' ||type === 'application/pdf' )){
            //         alert("กรุณาอัพโหลดเอกสารนามสกุล .jpeg, .png, .pdf เท่านั้น")
            //         num++;
            //     }
            // }

            // if(document.getElementById('file5').files.length>0 && num === 1){
            //     let type =  document.getElementById('file5').files[0].type 
                
            //     if(!(type === 'image/jpeg' || type === 'image/jpg' ||type === 'image/png' ||type === 'application/pdf' )){
            //         alert("กรุณาอัพโหลดเอกสารนามสกุล .jpeg, .png, .pdf เท่านั้น")
            //         num++;
            //     }
            // }
            
        }
        if(num === 1 ){
            this.setState({
                showing_step: showing_step+1
            });
            this.con();
        }
        
    }

    back () {
        const {showing_step} = this.state;
        this.setState({
            showing_step: showing_step-1
        });
        this.con();
    }

    save () {
        const {payload,relation_card_change_ans} = this.state;
        let policies = [];
        let body = [];
        this.setState({loading:true})
        for (let item of payload) {

            if(item.policy_no == this.state.policy_change_ans){
                let policy = {};
                policy.policy_no = item.policy_no;
                policy.card_relation = relation_card_change_ans;
                policy.card_holder_name = (item.policy_no ==  this.state.policy_change_ans)?document.getElementById("card_holder_name").value:item.card_holder_name;
                policy.card_number = (item.policy_no ==  this.state.policy_change_ans)?document.getElementById("card_number").value:item.card_number;
                policy.card_expire_date = (item.policy_no ==  this.state.policy_change_ans)?document.getElementById("expiry").value:item.card_expire_date;
                policy.card_type = (item.policy_no ==  this.state.policy_change_ans)?document.getElementById("card_type").value:item.card_type;
                policy.next_premium_due = item.next_premium_due;
                policy.premium_amount= item.premium_amount;
                policy.credit_bank_name = (item.policy_no ==  this.state.policy_change_ans)?document.getElementById("credit_bank_name").value:item.credit_bank_name;
               
                policy.old_card_holder_name = item.card_holder_name;
                policy.old_card_number =  item.card_number;
                policy.old_card_expire_date = item.card_expire_date;
                policy.old_card_type = item.card_type;
                policy.old_credit_bank_name = item.credit_bank_name;

                policies.push(policy);
                due_date = item.next_premium_due;
            }
           
            
        }
        // let laser_code = document.getElementById("laser_code").value
        // laser_code = laser_code.replaceAll("-", "");
        let save = {};
        save.policies = policies;
        // save.laser_code = laser_code;
        var self = this;
        console.log("body",save)
        fetch(process.env.REACT_APP_API_BASEURL + '/api/personal/credit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization':  localStorage.getItem('token_login')
                
            },
            body: JSON.stringify(save),
        })
        .then(res => res.json())
        
        .then( res => { 
            //console.log(res)
           console.log("body_save",res)
           if(res.success) {
                document.querySelector("input[type='submit']").click();
            } else {
                self.setState({loading:false}) 
                alert( res.payload.error)
            }
        })
        .catch(function(res){ console.log(res) })
    }

    submitUpload = (event) => {

        const {payload, relation_card_change_ans} = this.state;
        // const {payload} = this.state;
        let num = 0;
        var policy = '' 
        for (let item of payload) {
            if(item.policy_no == this.state.policy_change_ans){
                num++
                if(num == 1){
                    policy += item.policy_no
                } else {
                    policy += ','+item.policy_no
                }
            }
        }
        
        // event.preventDefault();
        let url = process.env.REACT_APP_API_BASEURL + '/api/personal/upload'
        event.preventDefault();

        
        let file_name1 = undefined;
        let file1;
        let file_name2 = undefined;
        let file2;
        let file_name3 = undefined;
        let file3;
        let file_name4 = undefined;
        let file4;
        let file_name5 = undefined;
        let file5;
        if(event.target[0].files[0]){
            file_name1 = event.target[0].files[0].name;
            file1 = event.target[0].files[0];
        }
        if(event.target[1].files[0]){
            file_name2 = event.target[1].files[0].name;
            file2 = event.target[1].files[0];
        }
        if(event.target[2].files[0]){
            file_name3 = event.target[2].files[0].name;
            file3 = event.target[2].files[0];
        }

        if( relation_card_change_ans  != "ตนเอง"){
            if(event.target[3].files[0]){
                file_name4 = event.target[3].files[0].name;
                file4 = event.target[3].files[0];
            }
    
            if(event.target[4].files[0]){
                file_name5 = event.target[4].files[0].name;
                file5 = event.target[4].files[0];
            }
        }
        
        // if(event.target[3].files[0]){
        //     file_name4 = event.target[3].files[0].name;
        //     file4 = event.target[3].files[0];
        // }

        // if(event.target[4].files[0]){
        //     file_name5 = event.target[4].files[0].name;
        //     file5 = event.target[4].files[0];
        // }

        console.log('file_name',event.target[1].files[0]);
        console.log('file_name2',file2);
        const formData = new FormData();  
        if(file_name1 != undefined){
            formData.append('image1', file1, file_name1);
        }
        if(file_name2 != undefined){
            formData.append('image2', file2, file_name2);
        }
        if(file_name3 != undefined){
            formData.append('image3', file3, file_name3);
        }
        if(file_name4 != undefined){
            formData.append('image4', file4, file_name4);
        }
        if(file_name5 != undefined){
            formData.append('image5', file5, file_name5);
        }      
        
        
       
       
       
        // formData.append('customer', localStorage.getItem('customer_id'));
        formData.append('type', '2');
        formData.append('policy', policy);
        for (var [key, value] of formData.entries()) { 
            console.log(key, value);
        }
        var self = this;
        let response =  axios.post(url, formData, {
            headers: {
                'authorization': localStorage.getItem('token_login'),
                'content-type': 'multipart/form-data'
            }
        
        }).then(function(result) {
            console.log("result",result) 
            self.setState({loading:false})
            if (!result.data.success) {
                console.log('error: ' + result.data.error);
                alert( result.data.error)
            } else {
                if(result.data.success){
                    alert( "การเปลี่ยนแปลงดังกล่าว จะมีผลการตัดชำระเบี้ยในงวดชำระครั้งถัดไป วันที่  "+moment(due_date).format('DD/MM/YYYY')+"\n\nหากท่านต้องการให้ตัดชำระเบี้ยในงวดปัจจุบัน โปรดติดต่อเจ้าหน้าที่ฝ่ายลูกค้าสัมพันธ์ 026483600 ")
                    window.location.href="/personal";
                }
                
            }
         })
        
        //  this.reload()
        
        
    };

 
    render() {
        const { showing_step, postData, postData2, showing_all} = this.state;
        return (
            <div>
                {this.state.loading ? <PopUp /> : null}
                <Header_Home/>
                <section className="d-flex justify-content-center align-items-center h-100 my-5">
                    <div className="container-fluid  container-margin">
                        <div className='row'>
                            <div className="col-12">
                                <div className="card" style={{ display: (showing_step === 1 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>เปลี่ยน บัตรเครดิต</strong></label>
                                            </div>
                                        </div>       
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 ">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="subject" className='text-right mt-2'><strong>ชื่อนามสกุล เจ้าของบัตรเครดิต</strong></label>
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                                <input style={{borderWidth: 1}}  type="text" id="card_holder_name" name="card_holder_name" 
                                                // onKeyPress={(event) => {
                                                //         if (!/[A-Z a-z]/.test(event.key)) {
                                                //         event.preventDefault();
                                                //         }
                                                //     }}  
                                                    className="margin-left-10-px form-control"></input><label id="error_card_holder_name"></label>
                                            </div>
                                        </div>   
                                        <div className="row col-12 ">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="subject" className='text-righ mt-2'><strong>หมายเลขบัตร</strong></label>
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                                <input style={{borderWidth: 1}}  type="text" id="card_number" name="card_number" onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                    }} maxlength="16"  className="margin-left-10-px form-control"></input><label id="error_card_number"></label>
                                            </div>
                                        </div>   
                                        <div className="row col-12 ">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="subject" className='text-righ mt-2'><strong>เดือน/ปี หมดอายุของบัตร</strong></label>
                                            </div>
                                            <div className="row col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                                <div className='col-6'>
                                                <select style={{borderWidth: 1}} className="margin-left-10-px form-control" name='expireMM' id='expireMM' onChange={this.onChange}>
                                                    <option value='-1' disabled selected hidden>Month</option>
                                                    <option value='01'>01</option>
                                                    <option value='02'>02</option>
                                                    <option value='03'>03</option>
                                                    <option value='04'>04</option>
                                                    <option value='05'>05</option>
                                                    <option value='06'>06</option>
                                                    <option value='07'>07</option>
                                                    <option value='08'>08</option>
                                                    <option value='09'>09</option>
                                                    <option value='10'>10</option>
                                                    <option value='11'>11</option>
                                                    <option value='12'>12</option>
                                                </select> 
                                                
                                                </div>/
                                                <div className='col-5'>
                                                    <select style={{borderWidth: 1}} className="margin-left-10-px form-control" name='expireYY' id='expireYY' onChange={this.onChange}>
                                                        <option value='-1' disabled selected hidden>Year</option>
                         
                                                    </select>  
                                                </div>
                                                <div className='col-12'>
                                                <label id="error_card_expire_date"> </label>
                                                </div>
                                               
                                            <input className="inputCard" type="hidden" name="expiry" id="expiry" maxlength="5"/>
                                            </div>
                                        </div>   
                                        <div className="row col-12 ">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="subject" className='text-righ mt-2'><strong>ประเภทของบัตร</strong></label>
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                            <select style={{borderWidth: 1}} className="margin-left-10-px form-control" name="card_type" id="card_type" onChange={this.handleChange("card_type")}   >
                                                <option value='-1' disabled selected hidden>select</option>
                                                <option value="99">Card</option>
                                                <option value="AM">Amex</option>
                                                <option value="DI">Dinners</option>
                                                <option value="CUP">CUP</option>
                                                <option value="C4">JCB</option>
                                                <option value="MC">Mastercard</option>
                                                <option value="VI">Visa</option>
                                      
                                            </select>     <label id="error_card_type"></label>
                                            </div>
                                        </div>   
                                        <div className="row col-12 ">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="credit_bank_name" className='text-righ mt-2'><strong>ธนาคารเจ้าของบัตรเครดิต</strong></label>
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                                <input style={{borderWidth: 1}} type="text" id="credit_bank_name" name="credit_bank_name"  className="margin-left-10-px form-control"></input><label id="error_credit_bank_name"></label>
                                            </div>
                                        </div>  
                                        <div className="row col-12 ">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="credit_bank_name" className='text-righ mt-2'><strong>ความสัมพันธ์เจ้าของบัตรเครดิตผู้เอาประกันภัย *</strong></label>
                                                <label id="error_relation_card"></label>
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                                <div className="row col-12 ">
                                                    <div className="col-1 text-right pt-2">
                                                        <input style={{borderWidth: 1}} onChange={this.handleChange("relation_card_change_ans")} type="radio" id="relation_card_change_ans" name="relation_card_change_ans" value='ตนเอง' className="margin-left-px"></input>
                                                    </div>
                                                    <div className="col-9">
                                                        <label className='text-righ mt-2'>ตนเอง</label>
                                                    </div>
                                                </div>  
                                                <div className="row col-12 ">
                                                    <div className="col-1 text-right pt-2">
                                                        <input style={{borderWidth: 1}} onChange={this.handleChange("relation_card_change_ans")} type="radio" id="relation_card_change_ans" name="relation_card_change_ans" value='บิดา / มารดา' className="margin-left-px"></input>
                                                    </div>
                                                    <div className="col-9">
                                                        <label className='text-righ mt-2'>บิดา / มารดา</label>
                                                    </div>
                                                </div>  
                                                <div className="row col-12 ">
                                                    <div className="col-1 text-right pt-2">
                                                        <input style={{borderWidth: 1}} onChange={this.handleChange("relation_card_change_ans")} type="radio" id="relation_card_change_ans" name="relation_card_change_ans" value='สามี / ภรรยา' className="margin-left-px"></input>
                                                    </div>
                                                    <div className="col-9">
                                                        <label className='text-righ mt-2'> สามี / ภรรยา</label>
                                                    </div>
                                                </div>  
                                                <div className="row col-12 ">
                                                    <div className="col-1 text-right pt-2">
                                                        <input style={{borderWidth: 1}} onChange={this.handleChange("relation_card_change_ans")} type="radio" id="relation_card_change_ans" name="relation_card_change_ans" value='บุตร' className="margin-left-px"></input>
                                                    </div>
                                                    <div className="col-9">
                                                        <label className='text-righ mt-2'>บุตร</label>
                                                    </div>
                                                </div>  
                                                <div className="row col-12 ">
                                                    <div className="col-1 text-right pt-2">
                                                        <input style={{borderWidth: 1}} onChange={this.handleChange("relation_card_change_ans")} type="radio" id="relation_card_change_ans" name="relation_card_change_ans" value='พี่น้องร่วมสายโลหิต' className="margin-left-px"></input>
                                                    </div>
                                                    <div className="col-9">
                                                        <label className='text-righ mt-2'>พี่น้องร่วมสายโลหิต</label>
                                                    </div>
                                                </div>  

                                                {/* <input  type="radio" id="credit_bank_name" name="credit_bank_name"  className="margin-left-10-px form-control"></input><label id="error_credit_bank_name"></label> */}
                                            </div>
                                            {/* <div className="row col-12 pt-2">
                                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 text-right">
                                                    <label htmlFor="subject" className='text-righ mt-2'><strong>เบอร์หลังบัตรประชาชน</strong></label>
                                                </div>
                                                 <div className="col-3">
                                                    <input  type="text" id="laser_code" name="laser_code" autocomplete="off" className="margin-left-10-px form-control"></input>
                                                        <label id="error_laser_code"></label>
                                                </div> 
                                            </div>  */}
                                        </div>   
                                        <div className="row col-12 ">
                                            <div className="col-lg-3  col-md-1 col-sm-1 col-xs-0  text-right">
                                                {/* <label htmlFor="credit_bank_name" className='text-righ mt-2'><strong>ธนาคารเจ้าของบัตรเครดิต</strong></label> */}
                                            </div>
                                            <div className="col-lg-9 col-md-11 col-sm-11 col-xs-12  text-right">
                                                <label className='text-left mt-2'>***บริษัทจะเก็บรวบรวม ใช้ หรือ เปิดเผยข้อมูลส่วนบุคคลที่ได้รับจากท่าน (เจ้าของบัตรเครดิต) เพื่อเรียกเก็บค่าเบี้ยประกันภัยหรือคืนเงินค่าเบี้ยประกันภัย ตามนโยบายความเป็นส่วนตัวสำหรับลูกค้าของบริษัทที่ปรากฏบน website www.rabbitlife.co.th***</label>
                                            </div>
                                            
                                        </div>   
                                        <div className="row col-12 ">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                {/* <label htmlFor="credit_bank_name" className='text-righ mt-2'><strong>ธนาคารเจ้าของบัตรเครดิต</strong></label> */}
                                            </div>
                                            <div className="col-9 text-left">
                                                {/* <label className='text-left mt-2'>ท่านสามารถศึกษารายละเอียดนโยบายส่วนบุคคลได้ที่</label> */}
                                            </div>
                                        </div>   
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 p-0 margin-auto">
                                            <div className="col-12 pt-4">
                                                {/* <div className="col-lg-8 col-md-12 col-sm-12 margin-auto" id="all_policy"  style={{ display: (showing_all? 'block' : 'none')}}>
                                                    <div className="card">  
                                                        <div className="card-body">
                                                            <div className="row col-12 ">
                                                                <div className="col-1 text-right">
                                                                <input onChange={this.select_all("select_all")} type="checkbox" id="all" name="all" value="all" className="margin-left-px mt-3" disabled={this.state.showing2}></input>
                                                                </div>
                                                                <div className="col-9">
                                                                    <label className='text-righ mt-2'> ทุกกรมธรรม์</label>
                                                                </div>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {postData} 
                                            </div>
                                        </div>
                                    
                                            
                                    </div>
                                    <div className="card-body App">
                                        <a  href="/personal" type="button" className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a>
                                        <a  onClick={() => this.next()} className="btn btn-lg btn-blue">ต่อไป</a>
                                    </div>
                                </div>

                                <div className="card" style={{ display: (showing_step === 2 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>อัพโหลดไฟล์</strong></label>
                                            </div>
                                        </div>      
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 ">
                                            
                                            <Form onSubmit={this.submitUpload} id="form_id">
                                               

                                                <div className="padding-title">
                                                    <div className="row col-12 ">
                                                        <div className="col-lg-6 col-md-8 col-sm-8 col-xs-8 text-right mt-3">
                                                            <label htmlFor="filename" className='text-righ'><strong>รูปถ่ายหน้าบัตรเครดิต</strong> รองรับไฟล์ .jpeg, .png, .pdf</label>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-4 col-xs-4">
                                                            <div className="col-7 text-left" align="right"  style={{ margin:'10px 0px 0px 0px' }}>
                                                                <label htmlFor="file1" className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i> อัพโหลด
                                                                </label>
                                                                <a className='ml-2' id="showname1" target='_blank'>{this.state.fileName1}</a>
                                                                <input  type="file"  id="file1" accept="image/png, image/jpeg, image/jpg, application/pdf" style={{display:'none'}}  onChange={this.handleselectedFile("fileName1")}/>
                                                            </div>
                                                        </div>
                                                    </div>      
                                                </div>

                                                <div className="padding-title">
                                                    <div className="row col-12 ">
                                                        <div className="col-lg-6 col-md-8 col-sm-8 col-xs-8 text-right mt-3">
                                                            <label htmlFor="filename" className='text-righ'><strong>รูปถ่ายหน้าตรงพร้อมบัตรประชาชนตัวจริง (ผู้เอาประกันภัย) </strong> <br/>รองรับไฟล์ .jpeg, .png, .pdf</label>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-4 col-xs-4">
                                                            <div className="col-7 text-left" align="right"  style={{ margin:'10px 0px 0px 0px' }}>
                                                                <label htmlFor="file2" className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i> อัพโหลด 
                                                                </label>
                                                                <a className='ml-2' id="showname2" target='_blank'>{this.state.fileName2}</a>
                                                                <input  type="file"  id="file2" accept="image/png, image/jpeg, image/jpg, application/pdf" style={{display:'none'}}  onChange={this.handleselectedFile("fileName2")}/>
                                                            </div>
                                                        </div>
                                                    </div>      
                                                </div>

                                                <div className="padding-title">
                                                    <div className="row col-12 ">
                                                        <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12 text-right mt-3">
                                                            <label htmlFor="filename" className='text-righ'><strong>สำเนาบัตรประชาชนด้านหน้าเซ็นรับรองสำเนาถูกต้อง (ผู้เอาประกันภัย)</strong> <br/>รองรับไฟล์ .jpeg, .png, .pdf </label>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-4 col-xs-4">
                                                            <div className="col-7 text-left" align="right"  style={{ margin:'10px 0px 0px 0px' }}>
                                                                <label htmlFor="file3" className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i> อัพโหลด 
                                                                </label>
                                                                <a className='ml-2' id="showname3" target='_blank'>{this.state.fileName3}</a>
                                                                <input  type="file"  id="file3" accept="image/png, image/jpeg, image/jpg, application/pdf" style={{display:'none'}}  onChange={this.handleselectedFile("fileName3")}/>
                                                            </div>
                                                        </div>
                                                    </div>      
                                                </div>

                                                {/* <div className={this.state.class_for_owner}> */}
                                                <div className="padding-title">
                                                    <div className="row col-12 ">
                                                        <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12 text-right mt-3">
                                                            <label htmlFor="filename" className='text-righ'><strong>รูปถ่ายหน้าตรงพร้อมบัตรประชาชนตัวจริง (เจ้าของบัตรเครดิต) *</strong> <br/>รองรับไฟล์ .jpeg, .png, .pdf</label>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-4 col-xs-4">
                                                            <div className="col-7 text-left" align="right"  style={{ margin:'10px 0px 0px 0px' }}>
                                                                <label htmlFor="file4" className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i> อัพโหลด 
                                                                </label>
                                                                <a className='ml-2' id="showname4" target='_blank'>{this.state.fileName4}</a>
                                                                <input  type="file"  id="file4" accept="image/png, image/jpeg, image/jpg, application/pdf" style={{display:'none'}}  onChange={this.handleselectedFile("fileName4")}/>
                                                            </div>
                                                        </div>
                                                    </div>      
                                                </div>

                                                {/* <div className={this.state.class_for_owner}> */}
                                                <div className="padding-title">
                                                    <div className="row col-12 ">
                                                        <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12 text-right mt-3">
                                                            <label htmlFor="filename" className='text-righ'><strong>สำเนาบัตรประชาชนด้านหน้าเซ็นรับรองสำเนาถูกต้อง(เจ้าของบัตรเครดิต) *</strong> <br/>รองรับไฟล์ .jpeg, .png, .pdf</label>
                                                        </div>
                                                        <div className="col-lg-6 col-md-4 col-sm-4 col-xs-4">
                                                            <div className="col-7 text-left" align="right"  style={{ margin:'10px 0px 0px 0px' }}>
                                                                <label htmlFor="file5" className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i> อัพโหลด 
                                                                </label>
                                                                <a className='ml-2' id="showname5" target='_blank'>{this.state.fileName5}</a>
                                                                <input  type="file"  id="file5" accept="image/png, image/jpeg, image/jpg, application/pdf" style={{display:'none'}}  onChange={this.handleselectedFile("fileName5")}/>
                                                            </div>
                                                        </div>
                                                    </div>      
                                                </div>

                                                {/* <div className={this.state.class_for_owner}> */}
                                                <div className="padding-title">
                                                    <div className="row col-12 ">
                                                        <div className="col-lg-6 col-md-8 col-sm-8 col-xs-8 text-right mt-3">
                                                            <label htmlFor="ex" className='text-righ color-text-rbl pointer'>*กรณีผู้เอาประกันภัย และ เจ้าของบัตรเครดิต เป็นบุคคลคนเดียวกัน ไม่ต้องนำส่งเอกสาร </label>
                                                        </div>
                                                        
                                                    </div>      
                                                </div>

                                                <div className="row dv-button">
                                                        
                                                    <input  type="submit" className="btn btn-primary button-right-page"  style={{display:'none'}}></input>                               
                                                </div>

                                            </Form>
                                                
                                        
                                        </div>               
                                    </div>
                                    <div className="card-body App">
                                        <a onClick={() => this.back()}  className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a> 
                                        <a onClick={() => this.next()} className="btn btn-lg btn-blue">ต่อไป</a>
                                    </div>
                                </div>

                                <div className="card" style={{ display: (showing_step === 3 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>ยืนยันข้อมูล</strong></label>
                                            </div>
                                        </div>      
                                    </div>
                                    <div className="my-4">
                                        <div className="row col-12 px-0 mx-0">
                                            <div className="col-12">
                                                {postData2} 
                                                <div className="row col-12 mx-0">
                                                    <div className="card p-0">  
                                                        <div className="card-body header-card">
                                                        <label className='text-righ mt-2'><strong>เอกสารแนบ </strong></label>
                                                        </div> 
                                                        <ul className="list-group list-group-flush">
                                                        <li className="list-group-item">
                                                        <span className="bold">รูปถ่ายหน้าบัตรเครดิต &nbsp;&nbsp;:</span>
                                                        <span className="light">{this.state.fileName1}</span>
                                                        </li>
                                                        <li className="list-group-item">
                                                        <span className="bold"> รูปถ่ายหน้าตรงพร้อมบัตรประชาชนตัวจริง (ผู้เอาประกันภัย)  :</span>
                                                        <span className="light">{this.state.fileName2} </span>
                                                        </li>
                                                        <li className="list-group-item">
                                                        <span className="bold">สำเนาบัตรประชาชนด้านหน้าเซ็นรับรองสำเนาถูกต้อง (ผู้เอาประกันภัย) :</span>
                                                        <span className="light">{this.state.fileName3} </span>
                                                        </li>           
                                                        <li className="list-group-item">
                                                        <span className="bold">รูปถ่ายหน้าตรงพร้อมบัตรประชาชนตัวจริง (เจ้าของบัตรเครดิต) &nbsp;&nbsp;:</span>
                                                        <span className="light">{this.state.fileName4}</span>
                                                        </li>
                                                        <li className="list-group-item">
                                                        <span className="bold">สำเนาบัตรประชาชนด้านหน้าเซ็นรับรองสำเนาถูกต้อง(เจ้าของบัตรเครดิต) &nbsp;&nbsp;:</span>
                                                        <span className="light">{this.state.fileName5}</span>
                                                        </li>            
                                                    </ul>
                                                    
                                                        
                                                    </div>   
                                                </div>              
                                          
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body App">
                                        <a onClick={() => this.back()} type="button" className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a>
                                        <a  onClick={() => this.save()} className="btn btn-lg btn-blue">บันทึก</a>
                                    </div>
                                </div>

                                <div className="card" style={{ display: (showing_step === 10 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>เปลี่ยน บัตรเครดิต</strong></label>
                                            </div>
                                        </div>       
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 pt-2">
                                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 text-right">
                                                <label htmlFor="subject" className='text-righ mt-2'><strong>ไม่มีข้อมูลกรมธรรม์</strong></label>
                                            </div>
                                           
                                        </div>  
                                        
                                    </div>
                                   
                                    <div className="card-body App">
                                        <a  href="/personal" type="button" className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a>
                                        {/* <a  onClick={() => this.next()} className="btn btn-lg btn-blue">ต่อไป</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    

                        {/* <div className='App'>
                            <a  href="/home" type="button" className="btn btn-lg btn mt-3 cust-btn"  >ย้อนกลับ</a>
                        </div> */}
                        </div> 
                </section>
                </div> 
        )
    }
        
    
}

export default EditCard;