import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Backdrop,
  CircularProgress,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import moment from "moment/moment";
import { useSwipeable } from "react-swipeable";
import { Checkbox, FormControlLabel, Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
// CSS
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./edit_card_v2.css";
import { CardHeader } from "@mui/material";
import { styled } from "@mui/system";
import ScrollableCreditCard from "./ScrollableCreditCard";
import FileUploadWithPreview from './FileUploadWithPreview';

// component
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import PopUp from "../Component/Popup";
import { CustomCarousel } from "../Component/CustomCarousel";
import { CustomInputText } from "../Component/CustomInputText";
import { CustomInputAutocomplete } from "../Component/CustomInputAutocomplete";
import { CustomInputFile } from "../Component/CustomInputFile";
// services
import {
  getPersonalCardAPI,
  getUserProfileAPI,
  updatePersonalImageAPI,
  updatePersonalCardAPI,
} from "./PersonalService/personalService";
// icon
import icon_bank_card from "../../Icon/bank-card.png";
import { mockDataBankName } from "../BenefitReceivingHistory/mockData";
import { Carousel } from "react-responsive-carousel";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Modal from "react-modal";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import iconSearch from "../../images/icon-search-2.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { Modal as BootstrapModal } from "react-bootstrap";
import greenCheck from "../../Icon/green-circle-check.png";
import remove from "../../images/remove.png";

const EditCard2 = (props) => {
  let navigate = useNavigate();

  const refCarousel = useRef(null);

  const [cardStep, setCardStep] = useState(1);
  const [cardData, setCardData] = useState([]);
  const [selectedCard, setSelectedCard] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [relationOptions, setRelationOptions] = useState();
  const [bankOptions, setBankOptions] = useState();
  const [loading, setLoading] = useState(true); // for get profile, get card, save edit, save image
  const [loadingType, setLoadingType] = useState(true); // for get Type
  const [loadingRelation, setLoadingRelation] = useState(true); // for get relation
  const [loadingBank, setLoadingBank] = useState(true); // for get relation
  const [toggleImg, setToggleImg] = useState(false);


  const [fullName, setFullName] = useState();
  const [cardNumber, setCardNumber] = useState();
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [type, setType] = useState();
  const [bank, setBank] = useState();
  const [relation, setRelation] = useState();
  const [inputErrors, setInputErrors] = useState([]);

  const [dueDate, setDueDate] = useState();

  const [flagRelation, setFlagRelation] = useState(true);


  const [file1, setFile1] = useState();
  const [fileName1, setFileName1] = useState();
  const [file2, setFile2] = useState();
  const [fileName2, setFileName2] = useState();
  const [file3, setFile3] = useState();
  const [fileName3, setFileName3] = useState();
  const [file4, setFile4] = useState();
  const [fileName4, setFileName4] = useState();
  const [file5, setFile5] = useState();
  const [fileName5, setFileName5] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [clickPre, setClickPre] = useState();
  const [clickForward, setClickForward] = useState();
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [searchBasePolicy, setSearchBasePolicy] = useState("");
  const [option, setOption] = useState([]);
  const handleOpenModal = () => setOpenModal(true);
  const [activeDataModal, setActiveDataModal] = useState(0);
  const [completedModal, setCompletedModal] = useState(false);
  const [textModal, setTextModal] = useState("");

  const [selectedIndexes, setSelectedIndexes] = useState([]); // เก็บ index ของ Box ที่ถูกเลือกเป็น array
  const [isChecked, setIsChecked] = useState(false); // สถานะของ CheckAll
  const [listData, setListData] = useState([]); // เก็บข้อมูลที่ถูกเลือก
  const [idnvFlag, setIdnvFlag] = useState(localStorage.getItem("idnvFlag") || 'N');

  const pdfUrl = 'path_to_your_pdf_file.pdf';

  const fileDescriptions = [
    "รูปถ่ายหน้าบัตรเครดิต",
    "รูปถ่ายหน้าตรงที่เป็นปัจจุบันคู่บัตรประชาชน (ผู้เอาประกันภัย)",
    "สำเนาบัตรประชาชนด้านหน้า (เซ็นรับรองสำเนาถูกต้อง)"
];

const fileDescriptionsOption2 = [
  "รูปถ่ายหน้าบัตรเครดิต",
  "รูปถ่ายหน้าตรงที่เป็นปัจจุบันคู่บัตรประชาชน (ผู้เอาประกันภัย)",
  "สำเนาบัตรประชาชนด้านหน้า (เซ็นรับรองสำเนาถูกต้อง)",
  "รูปถ่ายหน้าตรงที่เป็นปัจจุบันคู่บัตรประชาชน (เจ้าของบัตรเครดิต)",
  "สำเนาบัตรประชาชนด้านหน้า (เซ็นรับรองสำเนาถูกต้อง)"
];


const [files, setFiles] = useState(Array(fileDescriptions.length).fill(null));


  // ----- function for Step 1 update card -----

  const formatDate = (dateString) => {
    const date = new Date(dateString); // แปลงสตริงเป็นวันที่

    // ตรวจสอบว่ามีวันที่ที่ถูกต้องหรือไม่
    if (isNaN(date)) {
      return "-"; // ถ้าวันที่ไม่ถูกต้อง ส่งกลับ "-"
    }

    const month = String(date.getMonth() + 1).padStart(2, "0"); // เดือนเริ่มจาก 0
    const year = String(date.getFullYear()).slice(-2); // ปีสองหลัก

    return `${month}/${year}`; // ฟอร์แมตเป็น mm/yy
  };
  const styleDrive = {
    py: 0,
    width: "100%",
    maxWidth: "auto",
    // borderRadius: 2,
    // border: '1px solid',
    borderColor: "divider",
    backgroundColor: "background.paper",
  };
  const getPersonalCard = () => {
    setLoading(true);
    // setErrorCardData(false);
    getPersonalCardAPI()
      .then((res) => {
        const mockResponseGetPersonalCardAPI = {
          success: true,
          payload: [
            {
              policy_no: "00356040  ",
              next_premium_due: "2567-04-26T00:00:00",
              premium_amount: 29120.0,
              card_holder_name: "",
              card_number: "",
              card_expire_date: "",
              card_type: "",
              credit_bank_name: null,
              card_relation: null,
            },
          ],
        };
        setLoading(false);
        if (res.success) {
          // TODO: ask why API added space
          // NOTE: trim space out of payload
          const trimPayload = res.payload.map((item) => {
            let newItem = {
              policy_no: item.policy_no ? item.policy_no.trim() : null,
              next_premium_due: "2567-04-26T00:00:00",
              premium_amount: 29120.0,
              card_holder_name: item.card_holder_name
                ? item.card_holder_name.trim()
                : null,
              card_number: item.card_number ? item.card_number.trim() : null,
              card_expire_date: item.card_expire_date
                ? item.card_expire_date.trim()
                : null,
              card_type: item.card_type ? item.card_type.trim() : null,
              credit_bank_name: item.credit_bank_name
                ? item.credit_bank_name.trim()
                : null,
              card_relation: item.card_relation
                ? item.card_relation.trim()
                : null,
            };
            return newItem;
          });
          let option = [];
          const data = [
            {
              policy_no: "4213423",
            },
            {
              policy_no: "43523534",
            },
            {
              policy_no: "234234",
            },
            {
              policy_no: "5353",
            },
          ];
          trimPayload.map((item, idx) => {
            let dataTmp = {
              label: item.policy_no,
              id: idx,
            };
            option.push(dataTmp);
          });
          setOption(option);
          setCardData(trimPayload);
          // setCardData(trimPayload);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        // setErrorCardData(true);
        // setOpenErrorWarning(true);
        setToggleImg(false);
        setTextModal("ไม่สามารถเข้าถึงข้อมูลได้");
        setCompletedModal(true);
      });
  };

  const cardTypes = [
    { value: "AM", label: "Amex" },
    { value: "C4", label: "JCB" },
    { value: "MC", label: "Mastercard" },
    { value: "VI", label: "Visa" },
  ];

  const getType = () => {
    setLoadingType(true);
    setTimeout(() => {
      setLoadingType(false);
      setTypeOptions(cardTypes);
    }, 500);
  };

  const getCardTypeNameByCode = (code) => {
    const findCardType = cardTypes.find((item) => item.value === code);
    return findCardType ? findCardType.label : code;
  };

  const partialHide = (cardNumber) => {
    const hiddenCardNumber = cardNumber.split("");
    hiddenCardNumber.splice(4, 8, "********");
    return hiddenCardNumber.join("");
  };

  const getRelation = () => {
    setLoadingRelation(true);
    setTimeout(() => {
      setLoadingRelation(false);
      setRelationOptions([
        { value: "ตนเอง" },
        { value: "บิดา / มารดา" },
        { value: "สามี / ภรรยา" },
        { value: "บุตร" },
        { value: "พี่น้องร่วมสายโลหิต" },
      ]);
    }, 500);
  };

  const getBank = () => {
    setLoadingBank(true);
    setTimeout(() => {
      setLoadingBank(false);
      setBankOptions(mockDataBankName);
    }, 500);
  };

  const getYear = () => {
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 8; i++) {
      let yearTemp = currentYear + i;
      years.push(yearTemp.toString().substring(2));
    }
    setYearOptions(years);
  };

  // ----- function for Step 2 upload file -----
  const validateCard = () => {
    const errors = [];
    if (!fullName) errors.push("input-full-name");
    if (!cardNumber) errors.push("input-card-number");
    if (!month) errors.push("input-month");
    if (!year) errors.push("input-year");
    if (!type) errors.push("input-type");
    if (!bank) errors.push("input-bank");
    if (!relation) errors.push("input-relation");
    setInputErrors(errors);
    if (errors.length > 0) {
      setToggleImg(false);
      setTextModal("กรุณากรอกข้อมูลให้ครบถ้วน");
      setCompletedModal(true);
    } else {
      // more validation about month/year must not past yet (card expired)
      let currentDate = new Date();
      let cardExpiredDate = new Date();
      cardExpiredDate.setMonth(Number(month) - 1);
      cardExpiredDate.setFullYear(
        Number(cardExpiredDate.getFullYear().toString().substring(0, 2) + year)
      );

      if (currentDate > cardExpiredDate) {
        setToggleImg(false);
        setTextModal("เดือน/ปี ของบัตรเครดิตหมดอายุ");
        setCompletedModal(true);
        errors.push("input-month-year");
        setInputErrors(["input-month-year"]);
      }
    }
    return errors;
  };

  const validateImage = () => {
    let error = false;
    let errorMessage = "";
  
    // ตรวจสอบว่าไม่มีไฟล์ใดเป็น null หรือ undefined
    for (const f of files) {
      if (f == null) {
        error = true;
        errorMessage = "เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ";
        break;
      }
    }
  
    // ถ้าไม่มี error ในขั้นต้น ให้ตรวจสอบประเภทไฟล์ต่อไป
    if (!error) {
      for (const file of files) {
        if (file && file.type) { // เพิ่มการตรวจสอบ file เป็น null/undefined
          const type = file.type;
          if (!["image/jpeg", "image/jpg", "image/png", "application/pdf"].includes(type)) {
            error = true;
            errorMessage = "กรุณาอัพโหลดเอกสารนามสกุล .jpeg, .png, .pdf เท่านั้น";
            break;
          }
        } else {
          error = true;
          errorMessage = "ไฟล์บางไฟล์ไม่มีข้อมูลประเภท กรุณาอัพโหลดใหม่";
          break;
        }
      }
    }
  
    if (error) {
      setToggleImg(false);
      setTextModal(errorMessage);
      setCompletedModal(true);
    }
  
    return error;
  };
  
  

  const removeInputError = (inputId) => {
    setInputErrors((errors) => errors.filter((err) => err !== inputId));
  };

  const saveCardAndImage = async () => {
    try {
      setLoading(true);

      // ตรวจสอบความถูกต้องของบัตรและรูปภาพ
      const errorsCard = validateCard();
      console.log(errorsCard);
      const errorImage = validateImage();

      console.log(errorImage);
      if (
        listData.length > 0 &&
        errorsCard.length === 0
        // && errorImage === false
      ) {
        // สร้าง Payload สำหรับบันทึกบัตร
        const cardPayload = { policies: [] };
        listData.forEach((item) => {
          cardPayload.policies.push({
            policy_no: item.policy_no,
            card_holder_name: fullName,
            card_number: cardNumber,
            card_expire_date: `${month}/${year}`,
            card_type: type.value,
            credit_bank_name: bank,
            card_relation: relation.value,

            premium_amount: item.premium_amount,
            next_premium_due: item.next_premium_due,

            old_card_holder_name: item.card_holder_name || "",
            old_card_number: item.card_number || "",
            old_card_expire_date: item.card_expire_date || "",
            old_card_type: item.card_type.value || "",
            old_credit_bank_name: item.credit_bank_name || "",
          });
          setDueDate(item.next_premium_due);
        });

        // เรียก API บันทึกบัตร
        const cardResponse = await updatePersonalCardAPI(cardPayload);

        if (!cardResponse.success) {
          throw new Error("ไม่สามารถบันทึกข้อมูลบัตรได้");
        }

        // สร้าง Payload สำหรับบันทึกรูปภาพ
        if (idnvFlag === 'N') {
          let policy = selectedCard.map((item) => item.policy_no).join(",");
          const imagePayload = new FormData();
        
          files.forEach(f=>{
            imagePayload.append("files", f, f.name);
        
          })
          // imagePayload.append("files", file1[0], fileName1);
          // imagePayload.append("files", file2[0], fileName2);
          // imagePayload.append("files", file3[0], fileName3);
          // if (file4 && file4.length !== 0 && fileName4) {
          //   imagePayload.append("files", file4[0], fileName4);
          // }
          // if (file5 && file5.length !== 0 && fileName5) {
          //   imagePayload.append("files", file5[0], fileName5);
          // }
          imagePayload.append("listID", cardResponse.result);
          imagePayload.append("type", "2");

          // เรียก API บันทึกรูปภาพ
          const imageResponse = await updatePersonalImageAPI(imagePayload);

          if (!imageResponse.success) {
            throw new Error("ไม่สามารถบันทึกรูปภาพได้");
          }

        }


        // ถ้าบันทึกสำเร็จทั้งหมด
        const dueDateFormat = moment(dueDate).format("DD/MM/YYYY");
        setToggleImg(true);
        setTextModal(
          "บริษัทได้รับทราบข้อมูลจากท่านแล้ว ท่านจะได้รับ SMS เพื่อดาวน์โหลดเอกสารการเปลี่ยนแปลงภายใน 5 วันทำการ ขอบคุณค่ะ"
        );
        setCompletedModal(true);
      } else {
        throw new Error("ข้อมูลไม่ถูกต้อง");
      }
    } catch (error) {
      console.error(error);
      setToggleImg(false);
      setTextModal(error.message || "ไม่สามารถบันทึกข้อมูลได้");
      setCompletedModal(true);
    } finally {
      setLoading(false);
    }
  };

  // const saveCard = () => {
  //   setLoading(true);
  //   // setErrorSaveCardData(false);
  //   const errorsCard = validateCard();
  //   const errorImage = validateImage();
  //   if (
  //     selectedCard.length > 0 &&
  //     errorsCard.length === 0 &&
  //     errorImage === false
  //   ) {
  //     const payload = { policies: [] };
  //     selectedCard.forEach((item) => {
  //       payload.policies.push({
  //         policy_no: item.policy_no,
  //         card_holder_name: fullName,
  //         card_number: cardNumber,
  //         card_expire_date: month + "/" + year,
  //         card_type: type.value,
  //         credit_bank_name: bank,
  //         card_relation: relation.value,

  //         premium_amount: item.premium_amount,
  //         next_premium_due: item.next_premium_due,

  //         old_card_holder_name: item.card_holder_name || "",
  //         old_card_number: item.card_number || "",
  //         old_card_expire_date: item.card_expire_date || "",
  //         old_card_type: item.card_type || "",
  //         old_credit_bank_name: item.credit_bank_name || "",
  //       });
  //       setDueDate(item.next_premium_due);
  //     });
  //     // console.log("payload to submit >>>", payload);
  //     const mockPayload = {
  //       policies: [
  //         {
  //           policy_no: "00356040  ",
  //           card_relation: "ตนเอง",
  //           card_holder_name: "-",
  //           card_number: "0000000000000000",
  //           card_expire_date: "12/24",
  //           card_type: "99",
  //           next_premium_due: "2567-04-26T00:00:00",
  //           premium_amount: 29120,
  //           credit_bank_name: "Test Bank",
  //           old_card_holder_name: " ",
  //           old_card_number: " ",
  //           old_card_expire_date: " ",
  //           old_card_type: " ",
  //           old_credit_bank_name: null,
  //         },
  //       ],
  //     };
  //     updatePersonalCardAPI(payload)
  //       .then((res) => {
  //         setLoading(false);
  //         if (res.success) {
  //           saveImage();
  //         } else {
  //           console.error("fail Save Card Data", res);
  //           // setErrorSaveCardData(true);
  //           // setOpenErrorSaveCard(true);
  //           // alert("ไม่สามารถบันทึกข้อมูลได้");
  //           setToggleImg(false);
  //           setTextModal("ไม่สามารถบันทึกข้อมูลได้");
  //           setCompletedModal(true);
  //         }
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //         setLoading(false);
  //         // setErrorSaveCardData(true);
  //         // setOpenErrorSaveCard(true);
  //         setToggleImg(false);
  //         setTextModal("ไม่สามารถบันทึกข้อมูลได้");
  //         setCompletedModal(true);
  //       });
  //   } else {
  //     setLoading(false);
  //   }
  // };
  // const saveImage = () => {
  //   setLoading(true);
  //   // setErrorSaveImageData(false);

  //   const errorImage = validateImage();
  //   if (selectedCard.length > 0 && errorImage === false) {
  //     let policy = "";
  //     selectedCard.forEach((item, index) => {
  //       if (index === 0) policy = item.policy_no;
  //       else policy = policy + "," + item.policy_no;
  //     });
  //     const payload = new FormData();
  //     payload.append("files", file1[0], fileName1);
  //     payload.append("files", file2[0], fileName2);
  //     payload.append("files", file3[0], fileName3);
  //     if (file4 && file4.length !== 0 && fileName4) {
  //       payload.append("files", file4[0], fileName4);
  //     }
  //     if (file5 && file5.length !== 0 && fileName5) {
  //       payload.append("files", file5[0], fileName5);
  //     }
  //     payload.append("policyNo", policy);
  //     payload.append("type", "2");

  //     updatePersonalImageAPI(payload)
  //       .then((res) => {
  //         setLoading(false);
  //         if (res.success) {
  //           const dueDateFormat = moment(dueDate).format("DD/MM/YYYY");
  //           setToggleImg(true);
  //           setCompletedModal(true);
  //           setTextModal(
  //             "บริษัทได้รับทราบข้อมูลจากท่านแล้ว ท่านจะได้รับ SMS เพื่อดาวน์โหลดเอกสารการเปลี่ยนแปลงภายใน 5 วันทำการ ขอบคุณค่ะ"
  //           );
  //         } else {
  //           console.error("fail Save Image Data", res);
  //           setToggleImg(false);
  //           setTextModal("ไม่สามารถบันทึกข้อมูลได้");
  //           setCompletedModal(true);
  //         }
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //         setLoading(false);
  //         // setErrorSaveImageData(true);
  //         // setOpenErrorSaveImage(true);
  //         setToggleImg(false);
  //         setTextModal("ไม่สามารถบันทึกข้อมูลได้");
  //         setCompletedModal(true);
  //       });
  //   } else {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    // check user profile
    setLoading(true);
    getUserProfileAPI()
      .then((res) => {
        const mockResponseGetUserProfileAPI = {
          payload: {
            error: "",
            type: "",
            profile: "Customer",
          },
          success: "true",
          act: false,
        };
        setLoading(false);
        if (res.success == "true") {
          if (res.payload.profile == "POS2") {
            // window.location.href = "/home";
            navigate("/home");
          }
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        // setErrorProfileData(true);
        // setOpenErrorWarning(true);
        setToggleImg(false);
        setTextModal("ไม่สามารถเข้าถึงข้อมูลได้");
        setCompletedModal(true);
      });

    // get Personal credit card
    getPersonalCard();
    // get type and relation data for dropdown
    getType();
    getRelation();
    getBank();

    // get year from current year for dropdown
    getYear();

    return () => {
      console.log("useEffect return unmount >>>");
    };

    if (flagRelation) {
      setFiles(Array(fileDescriptions.length).fill(null));
    } else {
      setFiles(Array(fileDescriptionsOption2.length).fill(null));
    }
  }, [flagRelation]);

  const breakPoints = [{ width: 1, itemsToShow: 1 }];

  const handleOwlChange = async (e) => {
    setActiveIndex(e);
    setActiveDataModal(e);
    setUpSelectCard(e);
    // updateDataPolicyActive(e);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSearchBasePolicy("");
  };

  const filteredOptions = option.filter((item) => {
    return searchBasePolicy.trim() === ""
      ? true
      : item.label.toLowerCase().includes(searchBasePolicy.toLowerCase());
  });

  const handleConfrimSearch = () => {
    setActiveIndex(activeDataModal);
    // updateDataPolicyActive(activeDataModal);
    handleCloseModal();
    setUpSelectCard(activeDataModal);
    // setUpSelectName(activeDataModal);
  };

  const renderOptionRow = (val, idx) => {
    const isActive = activeDataModal === idx;

    return (
      <div key={idx}>
        <Row
          onClick={!isActive ? () => handleSelectSearch(idx) : undefined}
          style={isActive ? { backgroundColor: "#FF5E43" } : {}}
          className={`gap-1 border-top border-bottom border-1 rounded-1 ${isActive ? "text-white" : "text-danger pointer"
            }`}
        >
          <Col className="px-3 d-flex flex-row justify-content-between">
            <span>{val.label}</span>
            {isActive && (
              <ArrowCircleLeftOutlinedIcon style={{ color: "white" }} />
            )}
          </Col>
        </Row>
      </div>
    );
  };
  const CustomCheckbox = styled(Checkbox)({
    color: "rgba(255, 94, 67, 0.5)",
    "&.Mui-checked": {
      color: "#FF5E43",
    },
    marginRight: "15px",
  });

  const handleSelectSearch = (idx) => {
    setActiveDataModal(idx);
  };

  const setUpSelectCard = (index) => {
    setSelectedCard((prev) => {
      let findName = prev.find(
        (i) => i.policy_no === cardData[index].policy_no
      );
      if (findName) {
        return prev.filter((ii) => ii.policy_no !== findName.policy_no);
      } else {
        return [...prev, cardData[index]];
      }
    });
  };

  useEffect(() => {
    if (cardData.length > 0) {
      setUpSelectCard(0);
    }
  }, [cardData]);

  const ScrollableCard = () => {
    const cardRef = React.useRef(null);

    const handlers = useSwipeable({
      onSwiped: (eventData) => {
        if (cardRef.current) {
          cardRef.current.scrollTop += -eventData.deltaY; // เลื่อนการ์ดตามการสไวป์ (ขึ้นหรือลง)
        }
      },
      delta: 10, // กำหนดความไวต่อการสไวป์
      trackMouse: true, // ทำให้ใช้เมาส์ในการลากได้
    });

    if (listData.length > 0) {
      console.log(listData);
      // setFlgCanNext(true)
    }

    const handleBoxClick = (
      index,
      policy_no,
      old_card_holder_name,
      old_card_number,
      old_card_expire_date,
      old_card_type,
      old_credit_bank_name,
      premium_amount,
      next_premium_due,
      card_relation
    ) => {
      setSelectedIndexes(() => {
        if (selectedIndexes.includes(index)) {
          // ถ้าถูกเลือกแล้ว ให้ลบออกจาก array (ทำให้ไม่มีการเลือก)
          removeItemByPolicyNo(policy_no);
          return []; // ล้าง selectedIndexes
        } else {
          // ถ้ายังไม่ถูกเลือก ให้เพิ่มเข้าไปใน array (และล้างการเลือกก่อนหน้านี้)
          const updatedList = {
            policy_no: policy_no,
            card_holder_name: fullName,
            card_number: cardNumber,
            card_expire_date: month + "/" + year,
            card_type: type,
            credit_bank_name: bank,
            card_relation: relation,
            premium_amount: premium_amount,
            next_premium_due: next_premium_due,
            old_card_holder_name: old_card_holder_name || "",
            old_card_number: old_card_number || "",
            old_card_expire_date: old_card_expire_date || "",
            old_card_type: old_card_type || "",
            old_credit_bank_name: old_credit_bank_name || "",
            old_card_relation: card_relation || "",
          };

          addItem(updatedList);
          return [index]; // เก็บ index ของรายการที่เลือกใหม่ (ล้าง index ก่อนหน้านี้)
        }
      });
    };

    const addItem = (newItem) => {
      setListData((prevListData) => [...prevListData, newItem]);
    };

    const removeItemByPolicyNo = (policyNo) => {
      setListData((prevListData) =>
        prevListData.filter((item) => item.policy_no !== policyNo)
      );
    };

    return (
      <Card
        sx={{
          maxHeight: "680px",
          overflowY: "hidden",
          border: "1px solid rgba(255, 94, 67, 0.3)", // สีของขอบ #FF5E43 ที่โปร่งใส 30%
          borderRadius: "12px", // ความโค้งของขอบ
          borderWidth: "2px",
          display: "flex",
          flexDirection: "column",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        }}
        ref={cardRef}
        {...handlers} // ผูกฟังก์ชันสไวป์ให้กับการ์ด
      >
        <CardContent
          sx={{
            cursor: "pointer",
            overflowY: "auto", // เปิดการเลื่อนในแนวตั้ง
            boxSizing: "border-box",
          }}
        >
          {/* <Box>
            <FormControlLabel
              control={<CustomCheckbox onClick={handleCheckAll} checked={isChecked} />}
              label={<Typography style={{ fontSize: '20px' }}>ทุกกรมธรรม์</Typography>}
            />
          </Box> */}
          <Typography
            variant="body2"
            sx={{ color: "red", marginTop: "5px", marginLeft: "5px" }} // ข้อความสีแดงพร้อม margin ด้านบน
          >
            *กรุณาเลือกกรมธรรม์
          </Typography>

          {cardData.map((_, index) => (
            <Box
              key={index}
              onClick={() =>
                handleBoxClick(
                  index,
                  _.policy_no,
                  _.card_holder_name,
                  _.card_number,
                  _.card_expire_date,
                  _.card_type,
                  _.credit_bank_name,
                  _.premium_amount,
                  _.next_premium_due,
                  _.card_relation
                )
              }
              sx={{
                marginBottom: "15px",
                padding: "10px",
                transition: "background-color 0.3s ease, border 0.3s ease",
                cursor: "pointer",
                alignItems: "center", // จัดให้ทุกอย่างอยู่กึ่งกลางในแนวตั้ง
                justifyContent: "space-between", // แยกข้อความทางซ้ายกับไอคอนทางขวา
                border: selectedIndexes.includes(index),
              }}
            >
              <Card
                style={{ zIndex: 1000 }}
                sx={{
                  backgroundColor: selectedIndexes.includes(index)
                    ? "rgba(255, 94, 67, 0.1)" // สีพื้นหลังเข้มเมื่อถูกเลือก
                    : "transparent", // พื้นหลังปกติ
                  transition: "background-color 0.3s ease, border 0.3s ease",
                }}
              >
                {/* <CardHeader title={"กรมธรรม์เลขที่ : " + _.policy_no} sx={{ backgroundColor: 'rgb(255, 94, 67,0.3)' }} /> */}
                <CardHeader
                  title={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <CustomCheckbox
                        checked={selectedIndexes.includes(index)} // ทำการเช็คตาม selectedIndexes
                        onChange={(e) => {
                          console.log("");
                          e.stopPropagation(); // หยุดไม่ให้เกิด event ที่ parent
                          // handleBoxClick(index, _.policy_no, _.address1, _.address2, _.address3, _.address4, _.address5, _.id); // ทำการคลิกเมื่อ checkbox ถูกเลือก
                        }}
                        sx={{ color: "rgba(255, 94, 67, 0.8)" }} // ปรับสี
                      />
                      <Box sx={{ marginLeft: "10px" }}>
                        {" "}
                        {/* เพิ่มระยะห่างระหว่าง checkbox และข้อความ */}
                        {"กรมธรรม์เลขที่ : " + _.policy_no}
                      </Box>
                    </Box>
                  }
                  sx={{
                    backgroundColor: "rgba(255, 94, 67, 0.3)", // ปรับให้ใช้งาน rgba ถูกต้อง
                  }}
                  titleTypographyProps={{
                    sx: {
                      fontFamily: '"Prompt-Thin", sans-serif !important',
                      fontSize: "15px",
                      color: "black",
                      marginLeft: "15px",
                    },
                  }}
                />

                <CardContent>
                  <Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">
                          ชื่อ นามสกุล หน้าบัตรเครดิต :
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={8} md={9} lg={9} xl={9}>
                        <div
                          style={{
                            // display: 'flex',
                            flexDirection: "column", // ให้จัดเรียงข้อความในแนวตั้ง
                            whiteSpace: "normal", // ให้ตัดบรรทัดตามความยาวของเนื้อหา
                            wordBreak: "break-word", // ตัดคำเมื่อคำยาวเกินไป
                          }}
                        >
                          <span className="font-size-label-pol">
                            {`${_.card_holder_name}`}
                          </span>
                        </div>
                      </Grid>
                    </Grid>

                    {/* <Grid container spacing={1}>
                      <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">
                          หมายเลขบัตร :
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={8} md={9} lg={9} xl={9}>
                        <span className="font-size-label-pol">
                          {`${_.card_number}`}
                        </span>
                      </Grid>
                    </Grid> */}

                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">เดือน/ปี :</span>
                      </Grid>
                      <Grid item xs={12} sm={8} md={9} lg={9} xl={9}>
                        <span className="font-size-label-pol">
                          {/* {`${_.card_expire_date}`} */}
                          {formatDate(_.card_expire_date || "-")}
                        </span>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">
                          หมายเลขบัตร :
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <span className="font-size-label-pol">
                          {/* ******** */}
                          {`${_.card_number ? partialHide(_.card_number) : "-"
                            }`}
                        </span>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item xs={11} sm={4} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">
                          เดือน/ปี หมดอายุของบัตร :
                        </span>
                      </Grid>
                      <Grid item xs={11} sm={7} md={8} lg={8} xl={8}>
                        <span className="font-size-label-pol">
                          {/* ******** */}
                          {/* {`${_.card_expire_date || "-"}`} */}
                          {formatDate(_.card_expire_date || "-")}
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        sm={1}
                        md={1}
                        lg={1}
                        xl={1}
                        sx={{
                          display: "flex",
                          justifyContent: "center", // จัดให้อยู่กึ่งกลางแนวนอน
                          alignItems: "center", // จัดให้อยู่กึ่งกลางแนวตั้ง
                        }}
                      >
                        {/* {selectedIndexes.includes(index) && (
                          <CheckCircleIcon
                            sx={{
                              color: 'rgba(255, 94, 67, 0.8)',
                            }}
                          />
                        )} */}
                      </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">
                          ประเภทของบัตร :
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <span className="font-size-label-pol">
                          {/* ******** */}
                          {`${_.card_type
                            ? getCardTypeNameByCode(_.card_type)
                            : "-"
                            }`}
                        </span>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">
                          ธนาคารเจ้าของบัตรเครดิต :
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <span className="font-size-label-pol">
                          {/* ******** */}
                          {`${_.credit_bank_name === null
                            ? "-"
                            : _.credit_bank_name
                            }`}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={5} md={3} lg={4} xl={4}>
                        <span className="font-size-label-pol">
                          ความสัมพันธ์เจ้าของบัตรเครดิตผู้เอาประกันภัย :
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={7} md={8} lg={8} xl={8}>
                        <span className="font-size-label-pol">
                          {/* ******** */}
                          {`${_.card_relation == null ? "-" : _.card_relation}`}
                        </span>
                      </Grid>

                    </Grid>
                  </Typography>
                </CardContent>
              </Card>
              <Box></Box>
            </Box>
          ))}
        </CardContent>
      </Card>
    );
  };

  const ScrollableCardInformation = () => {
    const cardRef = React.useRef(null);

    const handlers = useSwipeable({
      onSwiped: (eventData) => {
        if (cardRef.current) {
          cardRef.current.scrollTop += -eventData.deltaY; // เลื่อนการ์ดตามการสไวป์ (ขึ้นหรือลง)
        }
      },
      delta: 10, // กำหนดความไวต่อการสไวป์
      trackMouse: true, // ทำให้ใช้เมาส์ในการลากได้
    });

    return (
      <Card
        sx={{
          maxHeight: "590px",
          overflowY: "hidden",
          border: "1px solid rgba(255, 94, 67, 0.3)", // สีของขอบ #FF5E43 ที่โปร่งใส 30%
          borderRadius: "12px", // ความโค้งของขอบ
          borderWidth: "2px",
          display: "flex",
          flexDirection: "column",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        }}
        ref={cardRef}
        {...handlers} // ผูกฟังก์ชันสไวป์ให้กับการ์ด
      >
        <CardContent
          sx={{
            cursor: "pointer",
            overflowY: "auto", // เปิดการเลื่อนในแนวตั้ง
            boxSizing: "border-box",
          }}
        >
          {listData.map((_, index) => (
            <Box
              key={index}
              // onClick={() => handleBoxClick(index, _.policy_no, _.old_card_holder_name, _.old_card_number, _.old_card_expire_date, _.old_card_type, _.old_credit_bank_name, _.premium_amount, _.next_premium_due)}
              sx={{
                marginBottom: "15px",
                padding: "10px",
                transition: "background-color 0.3s ease, border 0.3s ease",
                cursor: "pointer",
                alignItems: "center", // จัดให้ทุกอย่างอยู่กึ่งกลางในแนวตั้ง
                justifyContent: "space-between", // แยกข้อความทางซ้ายกับไอคอนทางขวา
                border: selectedIndexes.includes(index),
              }}
            >
              <Card
                style={{ zIndex: 1000 }}
                sx={{
                  // backgroundColor: selectedIndexes.includes(index)
                  //   ? 'rgba(255, 94, 67, 0.1)' // สีพื้นหลังเข้มเมื่อถูกเลือก
                  //   : 'transparent', // พื้นหลังปกติ
                  transition: "background-color 0.3s ease, border 0.3s ease",
                }}
              >
                {/* <CardHeader title={"กรมธรรม์เลขที่ : " + _.policy_no} sx={{ backgroundColor: 'rgb(255, 94, 67,0.3)' }} /> */}

                <CardHeader
                  title={"กรมธรรม์เลขที่ : " + _.policy_no}
                  sx={{
                    backgroundColor: "rgba(255, 94, 67, 0.3)", // ปรับให้ใช้งาน rgba ถูกต้อง
                  }}
                  titleTypographyProps={{
                    sx: {
                      fontFamily: '"Prompt-Thin", sans-serif !important',
                      fontSize: "15px",
                      color: "black",
                      marginLeft: "15px",
                      // width: '50px !important',
                    },
                  }}
                />
                <List sx={styleDrive}>
                  <ListItem>
                    <ListItemText
                      primary="ข้อมูลใหม่"
                      style={{ marginLeft: "10px" }}
                    />
                  </ListItem>
                  <Divider variant="middle" component="li" />
                </List>
                <CardContent>
                  <Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">
                          ชื่อ นามสกุล หน้าบัตรเครดิต :
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={8} md={9} lg={9} xl={9}>
                        <div
                          style={{
                            // display: 'flex',
                            flexDirection: "column", // ให้จัดเรียงข้อความในแนวตั้ง
                            whiteSpace: "normal", // ให้ตัดบรรทัดตามความยาวของเนื้อหา
                            wordBreak: "break-word", // ตัดคำเมื่อคำยาวเกินไป
                          }}
                        >
                          <span className="font-size-label-pol">
                            {`${_.card_holder_name}`}
                          </span>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">
                          หมายเลขบัตร :
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <span className="font-size-label-pol">
                          {/* ******** */}
                          {`${_.card_number ? partialHide(_.card_number) : "-"
                            }`}
                        </span>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item xs={11} sm={4} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">
                          เดือน/ปี หมดอายุของบัตร :
                        </span>
                      </Grid>
                      <Grid item xs={11} sm={7} md={8} lg={8} xl={8}>
                        <span className="font-size-label-pol">
                          {/* ******** */}
                          {/* {`${_.card_expire_date || "-"}`} */}
                          {formatDate(_.card_expire_date || "-")}
                        </span>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">
                          ประเภทของบัตร :
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <span className="font-size-label-pol">
                          {/* ******** */}
                          {`${_.card_type
                            ? getCardTypeNameByCode(_.card_type.value)
                            : "-"
                            }`}
                        </span>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">
                          ธนาคารเจ้าของบัตรเครดิต :
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <span className="font-size-label-pol">
                          {/* ******** */}
                          {`${_.credit_bank_name === null
                            ? "-"
                            : _.credit_bank_name
                            }`}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={5} md={3} lg={4} xl={4}>
                        <span className="font-size-label-pol">
                          ความสัมพันธ์เจ้าของบัตรเครดิตผู้เอาประกันภัย :
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={7} md={8} lg={8} xl={8}>
                        <span className="font-size-label-pol">
                          {/* ******** */}
                          {`${_.card_relation == null
                            ? "-"
                            : _.card_relation.value
                            }`}
                        </span>
                      </Grid>
                    </Grid>
                  </Typography>
                </CardContent>

                <List sx={styleDrive}>
                  <ListItem>
                    <ListItemText
                      primary="ข้อมูลเก่า"
                      style={{ marginLeft: "10px" }}
                    />
                  </ListItem>
                  <Divider variant="middle" component="li" />
                </List>
                <CardContent>
                  <Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">
                          ชื่อ นามสกุล หน้าบัตรเครดิต :
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={8} md={9} lg={9} xl={9}>
                        <div
                          style={{
                            // display: 'flex',
                            flexDirection: "column", // ให้จัดเรียงข้อความในแนวตั้ง
                            whiteSpace: "normal", // ให้ตัดบรรทัดตามความยาวของเนื้อหา
                            wordBreak: "break-word", // ตัดคำเมื่อคำยาวเกินไป
                          }}
                        >
                          <span className="font-size-label-pol">
                            {`${_.old_card_holder_name}`}
                          </span>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">
                          หมายเลขบัตร :
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <span className="font-size-label-pol">
                          {/* ******** */}
                          {`${_.old_card_number
                            ? partialHide(_.old_card_number)
                            : "-"
                            }`}
                        </span>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item xs={11} sm={4} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">
                          เดือน/ปี หมดอายุของบัตร :
                        </span>
                      </Grid>
                      <Grid item xs={11} sm={7} md={8} lg={8} xl={8}>
                        <span className="font-size-label-pol">
                          {/* ******** */}
                          {/* {`${_.old_card_expire_date || "-"}`} */}
                          {formatDate(_.old_card_expire_date || "-")}
                        </span>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">
                          ประเภทของบัตร :
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <span className="font-size-label-pol">
                          {/* ******** */}
                          {`${_.old_card_type
                            ? getCardTypeNameByCode(_.old_card_type)
                            : "-"
                            }`}
                        </span>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">
                          ธนาคารเจ้าของบัตรเครดิต :
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <span className="font-size-label-pol">
                          {/* ******** */}
                          {`${_.old_credit_bank_name === null
                            ? "-"
                            : _.old_credit_bank_name
                            }`}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={5} md={3} lg={4} xl={4}>
                        <span className="font-size-label-pol">
                          ความสัมพันธ์เจ้าของบัตรเครดิตผู้เอาประกันภัย :
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={7} md={8} lg={8} xl={8}>
                        <span className="font-size-label-pol">
                          {/* ******** */}
                          {`${_.old_card_relation == "" ? "" : _.old_card_relation.value
                            }`}
                        </span>
                      </Grid>
                    </Grid>
                  </Typography>
                </CardContent>
              </Card>
              <Box></Box>
            </Box>
          ))}
        </CardContent>
      </Card>
    );
  };

  return (
    <div className="edit-name">
      {loading ? (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress
            sx={{
              animationDuration: "550ms",
            }}
            color="inherit"
          />
        </Backdrop>
      ) : null}
      <Header_Home />
      <Header title="เปลี่ยนบัตรเครดิต" />
      <div className="container-footer-space">
        {/* <section
          className="policy-address"
          style={{ display: cardStep === 1 ? "block" : "none" }}
          ref={refCarousel}
        >
          {cardData && cardData.length > 0 ? (
            <div>
              <Carousel
                emulateTouch={true}
                showThumbs={false}
                onChange={handleOwlChange}
                onClickItem={handleOwlChange}
                showArrows={false}
                showStatus={true}
                showIndicators={false}
                // className="p-lg-4"
                breakPoints={breakPoints}
                selectedItem={activeIndex}
                statusFormatter={(currentItem, total) => {
                  setCurrentItem(currentItem);
                  setTotalItem(total);
                }}
                renderArrowPrev={(clickHandler, hasPrev) => {
                  setClickPre(() => clickHandler); // ตั้งค่า clickHandler ใน state

                  return null; // ไม่แสดงปุ่มลูกศรภายใน Carousel
                }}
                renderArrowNext={(clickHandler, hasNext) => {
                  setClickForward(() => clickHandler); // ตั้งค่า clickHandler ใน state

                  return null; // ไม่แสดงปุ่มลูกศรภายใน Carousel
                }}
              >
                {cardData.map((item, index) => (
                  <div className="frame-policy-new mt-3">
                    <ul
                      className="list-group list-group-flush"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <li
                        className="body-list-group"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginRight: "10px",
                          fontSize: "15px",
                        }}
                      >
                        <h2
                          className="bold"
                          style={{
                            fontSize: "20px",
                            marginLeft: "20px",
                            alignItems: "start",
                            justifyItems: "start",
                            marginTop: "20px",
                          }}
                        >
                          {"กรมธรรม์เลขที่ : " + item.policy_no}
                        </h2>
                        <div className="d-flex flex-row">
                          <AssignmentIcon
                            style={{
                              cursor: "pointer",
                              marginLeft: "20px",
                              alignItems: "start",
                              justifyItems: "start",
                              marginTop: "20px",
                            }}
                            color="warning"
                            onClick={handleOpenModal}
                          />
                        </div>
                      </li>
                      <hr
                        style={{
                          margin: "0px 10px 15px 20px",
                          border: "solid 1px",
                          borderTop: 0.1,
                        }}
                      />
                      <li
                        className="body-list-group"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginRight: "10px",
                          fontSize: "15px",
                          marginBottom: 15,
                        }}
                      >
                        <span className="font-size-label">
                          ชื่อ นามสกุล หน้าบัตรเครดิต
                        </span>
                        <span className="bold">{`${
                          item.card_holder_name || "-"
                        }`}</span>
                      </li>
                      <li
                        className="body-list-group"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginRight: "10px",
                          fontSize: "15px",
                          marginBottom: 15,
                        }}
                      >
                        <span className="font-size-label">หมายเลขบัตร</span>
                        <span className="bold">{`${item.card_number ? partialHide(item.card_number) : "-"}`}</span>
                      </li>
                      <li
                        className="body-list-group"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginRight: "10px",
                          fontSize: "15px",
                          marginBottom: 15,
                        }}
                      >
                        <span className="font-size-label">
                          เดือน/ปี หมดอายุของบัตร
                        </span>
                        <span className="bold">
                          {" "}
                          {`${item.card_expire_date || "-"}`}
                        </span>
                      </li>
                      <li
                        className="body-list-group"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginRight: "10px",
                          fontSize: "15px",
                          marginBottom: 15,
                        }}
                      >
                        <span className="font-size-label">ประเภทของบัตร</span>
                        <span className="bold">{`${
                          item.card_type
                            ? getCardTypeNameByCode(item.card_type)
                            : "-"
                        }`}</span>
                      </li>
                      <li
                        className="body-list-group"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginRight: "10px",
                          fontSize: "15px",
                          marginBottom: 15,
                        }}
                      >
                        <span className="font-size-label">
                          ธนาคารเจ้าของบัตรเครดิต
                        </span>
                        <span className="bold">
                          {`${item.credit_bank_name || "-"}`}{" "}
                        </span>
                      </li>
                      <li
                        className="body-list-group"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginRight: "10px",
                          fontSize: "15px",
                          marginBottom: 15,
                        }}
                      >
                        <span className="font-size-label">
                          ความสัมพันธ์เจ้าของบัตรเครดิตผู้เอาประกันภัย
                        </span>
                        <span className="bold">
                          {`${item.card_relation || "-"}`}{" "}
                        </span>
                      </li>
                    </ul>
                  </div>
                ))}
              </Carousel>
            </div>
          ) : (
            <div className="address-policy-card">
              {loading ? "กำลังโหลดข้อมูล..." : "ไม่พบข้อมูลกรมธรรม์"}
            </div>
          )}
        </section> */}
        {/* {cardStep == 1 ? (
          <div
            className="d-flex flex-row justify-content-center gap-2"
            style={{ marginTop: 20 }}
          >
            <div
              onClick={clickPre}
              className="d-flex justify-content-center align-items-center pointer"
            >
              <ArrowBackIosIcon fontSize="small" color="warning" />
            </div>
            <div
              style={{
                backgroundColor: "rgba(255, 228, 224, 0.9)",
                height: 18,
                width: 18,
                fontSize: 10,
              }}
              className="rounded-3 text-secondary d-flex justify-content-center align-items-center"
            >
              {currentItem}
            </div>
            <div
              style={{ fontSize: 10 }}
              className="text-secondary d-flex justify-content-center align-items-center"
            >
              Of
            </div>
            <div
              style={{
                backgroundColor: "#F2F2F2",
                height: 18,
                width: 18,
                fontSize: 10,
              }}
              className="rounded-3 text-secondary d-flex justify-content-center align-items-center"
            >
              {totalItem}
            </div>
            <div
              onClick={clickForward}
              className="d-flex justify-content-center align-items-center pointer"
            >
              <ArrowForwardIosIcon fontSize="small" color="warning" />
            </div>
          </div>
        ) : (
          ""
        )} */}
        <section
          className="update-receiving-address"
          style={{
            display: cardStep === 1 ? "block" : "none",
            marginBottom: "10px",
          }}
        >
          <div className="pb-2">
            <img
              className="name-document-icon"
              src={icon_bank_card}
              alt="name-document-icon"
            />
            <span className="bold">{"บัตรเครดิต"}</span>
          </div>
          <div className="px-4">
            <CustomInputText
              id="input-full-name"
              label="ชื่อ นามสกุล หน้าบัตรเครดิต"
              placeholder="ชื่อ นามสกุล หน้าบัตรเครดิต"
              value={fullName}
              onChange={(e) => {
                setFullName(e.target.value);
                removeInputError("input-full-name");
              }}
              error={inputErrors.includes("input-full-name")}
            />
            <CustomInputText
              id="input-card-number"
              label="หมายเลขบัตร"
              placeholder="หมายเลขบัตร"
              value={cardNumber}
              onChange={(e) => {
                let formatValue = e.target.value
                  ? e.target.value.replace(/[^0-9]/g, "")
                  : "";
                if (formatValue.length <= 16) setCardNumber(formatValue);
                removeInputError("input-card-number");
              }}
              // onKeyDown={(e) => {
              //   if (!/[0-9]/.test(e.key)) {
              //     e.preventDefault();
              //   }
              // }}
              error={inputErrors.includes("input-card-number")}
            />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label style={{ marginTop: "11px", fontWeight: "bold", marginTop: "10px" }}>
                  เดือน/ปี หมดอายุของบัตร
                </label>
              </Grid>

              <Grid item xs={5}>
                <CustomInputAutocomplete
                  id="input-month"
                  // label=""
                  placeholder="เดือน"
                  value={month}
                  style={{ marginTop: "-10px" }}
                  onChange={(value) => {
                    setMonth(value);
                    removeInputError("input-month");
                  }}
                  options={[
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "10",
                    "11",
                    "12",
                  ]}
                  error={
                    inputErrors.includes("input-month") ||
                    inputErrors.includes("input-month-year")
                  }
                  errorText={
                    inputErrors.includes("input-month-year")
                      ? "บัตรหมดอายุ"
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={1}>
                <div style={{ paddingTop: "40px", marginTop: "-30px" }}>/</div>
              </Grid>
              <Grid item xs={5}>
                <CustomInputAutocomplete
                  id="input-year"
                  label=" "
                  placeholder="ปี"
                  value={year}
                  onChange={(value) => {
                    setYear(value);
                    removeInputError("input-year");
                  }}
                  options={yearOptions}
                  error={
                    inputErrors.includes("input-year") ||
                    inputErrors.includes("input-month-year")
                  }
                  style={{ marginTop: "-10px" }}
                  errorText={
                    inputErrors.includes("input-month-year") ? " " : undefined
                  }
                />
              </Grid>
            </Grid>
            <CustomInputAutocomplete
              id="input-type"
              label="ประเภทของบัตร"
              placeholder="กรุณาเลือก"
              value={type}
              onChange={(value) => {
                setType(value);
                removeInputError("input-type");
              }}
              options={typeOptions || []}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              loading={loadingType}
              error={inputErrors.includes("input-type")}
            />

            <CustomInputAutocomplete
              id="input-bank"
              label="ธนาคารเจ้าของบัตรเครดิต"
              placeholder="กรุณาเลือก"
              value={bank}
              onChange={(value) => {
                setBank(value);
                removeInputError("input-bank");
              }}
              options={bankOptions || []}
              loading={loadingBank}
              error={inputErrors.includes("input-bank")}
            />
            {/* <CustomInputText
              id="input-bank"
              label="ธนาคารเจ้าของบัตรเครดิต"
              placeholder="ธนาคารเจ้าของบัตรเครดิต"
              value={bank}
              onChange={(e) => {
                setBank(e.target.value);
                removeInputError("input-bank");
              }}
            /> */}
            <CustomInputAutocomplete
              id="input-relation"
              label={<span className="multiline-label">ความสัมพันธ์เจ้าของบัตรเครดิตผู้เอาประกันภัย</span>}
              placeholder="กรุณาเลือก"
              value={relation}
              onChange={(value) => {
                console.log(value.value);
                if (value.value == "ตนเอง") {
                  setFlagRelation(false);
                } else {
                  setFlagRelation(true);
                }
                setRelation(value);
                removeInputError("input-relation");
              }}
              options={relationOptions || []}
              getOptionLabel={(option) => option.value}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              loading={loadingRelation}
              error={inputErrors.includes("input-relation")}
            />
            <Grid item xs={12} sm={5} md={3} lg={4} xl={4}>
              <Typography
                variant="body2"
                sx={{ color: "red", marginTop: "5px", marginLeft: "5px" }} // ข้อความสีแดงพร้อม margin ด้านบน
              >
                *บริษัทฯ จะเก็บรวบรวม ใช้ หรือ เปิดเผยข้อมูลส่วนบุคคลที่ได้รับจากท่าน (เจ้าของบัตรเครดิต) เพื่อเรียกเก็บค่าเบี้ยประกันภัย หรือคืนเงินค่าเบี้ยประกันภัย
ตามนโยบายความเป็นส่วนตัวสำหรับลูกค้าของบริษัทฯ ที่ปรากฏบน website www.rabbitlife.co.th
              </Typography>
            </Grid>

          </div>
        </section>



        <Box
          sx={{
            display: cardStep === 3 ? "flex" : "none",
            alignItems: "center",
          }}
          style={{ marginBottom: "10px" }}
        >
          <AssignmentTurnedInRoundedIcon
            fontSize={"large"}
            style={{ color: "rgb(255, 94, 67)" }}
          />
          <Typography style={{ fontSize: "20px", marginLeft: "10px" }}>
            ยืนยันข้อมูล
          </Typography>
        </Box>

        {cardStep == 1 ? (
          <ScrollableCreditCard listData={listData} cardData={cardData} setListData={setListData} fullName={fullName}
            cardNumber={cardNumber}
            month={month}
            year={year}
            type={type}
            bank={bank}
            relation={relation}
            inputErrors={inputErrors} />
        ) : cardStep == 3 ? (
          <ScrollableCardInformation />
        ) : (
          <section
            className="upload-image-address"
            style={{ display: cardStep === 2 ? "block" : "none" }}
          >
            <div className={`address-image-card`}>
              <ul className="list-group list-group-flush">
                <div className="address-card-title">{"อัพโหลดไฟล์"}</div>
                <hr className="line" />
                <li className="address-card-body">
                  <div className="d-flex flex-row justify-content-between align-content-center align-items-center ">
                    {/* <div>
                      <div className="bold">{"รูปถ่ายหน้าบัตรเครดิต"}</div>
                      <div>{"รองรับไฟล์ .jpeg, .png, .pdf"}</div>
                    </div> */}

                    <FileUploadWithPreview  flagRelation={flagRelation} setFiles={setFiles} fileDescriptions={fileDescriptions} files={files} fileDescriptionsOption2={fileDescriptionsOption2} />
                    {/* <CustomInputFile
                      id={"file1"}
                      filename={fileName1}
                      onChange={(e) => {
                        const inputFile = e.target.files;
                        setFile1(inputFile);
                        setFileName1(inputFile[0].name);
                      }}
                    /> */}
                  </div>
                </li>
                {/* <li className="address-card-body">
                  <div className="d-flex flex-row justify-content-between align-content-center align-items-center ">
                    <div>
                      <div className="bold">
                        {"รูปถ่ายหน้าตรงที่เป็นปัจจุบันคู่บัตรประชาชน"}
                      </div>
                      <div className="bold">{"(ผู้เอาประกันภัย) "}</div>
                      <div>{"รองรับไฟล์ .jpeg, .png, .pdf"}</div>
                    </div>
                    <CustomInputFile
                      id={"file2"}
                      filename={fileName2}
                      onChange={(e) => {
                        const inputFile = e.target.files;
                        setFile2(inputFile);
                        setFileName2(inputFile[0].name);
                      }}
                    />
                  </div>
                </li> */}
                {/* <li className="address-card-body">
                  <div className="d-flex flex-row justify-content-between align-content-center align-items-center ">
                    <div>
                      <div className="bold">
                        {"สำเนาบัตรประชาชนด้านหน้า (เซ็นรับรองสำเนาถูกต้อง)"}
                      </div>
                      <div className="bold">{"(ผู้เอาประกันภัย) "}</div>
                      <a
                        href="https://rabbitlife-my.sharepoint.com/:i:/g/personal/saijai_s_rabbitlife_co_th/Ef_D2yj-LP9DtzcbhUNP_70BzGqtkwNUtdUve5O2HgSpBw"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: "red",
                          textDecoration: "underline",
                          // marginLeft: "5px",
                        }} // ใช้ขีดเส้นใต้และระยะห่างเล็กน้อย
                      >
                        (ตัวอย่างเอกสาร)
                      </a>
                      <div>{"รองรับไฟล์ .jpeg, .png, .pdf"}</div>
                    </div>
                    <CustomInputFile
                      id={"file3"}
                      filename={fileName3}
                      onChange={(e) => {
                        const inputFile = e.target.files;
                        setFile3(inputFile);
                        setFileName3(inputFile[0].name);
                      }}
                    />
                  </div>
                </li> */}
                {/* แสดงไฟล์เพิ่มเติมเฉพาะเมื่อ flagRelation เป็น true */}
                {/* {flagRelation && (
                  <>
                    <li className="address-card-body">
                      <div className="d-flex flex-row justify-content-between align-content-center align-items-center ">
                        <div>
                          <div className="bold">
                            {"รูปถ่ายหน้าตรงที่เป็นปัจจุบันคู่บัตรประชาชน"}
                          </div>
                          <div className="bold">
                            {"(เจ้าของบัตรเครดิต) "}
                            <span style={{ color: "red" }}>*</span>
                          </div>
                          <div>{"รองรับไฟล์ .jpeg, .png, .pdf"}</div>
                        </div>
                        <CustomInputFile
                          id={"file4"}
                          filename={fileName4}
                          onChange={(e) => {
                            const inputFile = e.target.files;
                            setFile4(inputFile);
                            setFileName4(inputFile[0].name);
                          }}
                        />
                      </div>
                    </li>
                    <li className="address-card-body">
                      <div className="d-flex flex-row justify-content-between align-content-center align-items-center ">
                        <div>
                          <div className="bold">
                            {"สำเนาบัตรประชาชนด้านหน้า (เซ็นรับรองสำเนาถูกต้อง)"}
                          </div>
                          <div className="bold">
                            {"(เจ้าของบัตรเครดิต) "}
                            <span style={{ color: "red" }}>*</span>
                          </div>
                          <div>{"รองรับไฟล์ .jpeg, .png, .pdf"}</div>
                        </div>
                        <CustomInputFile
                          id={"file5"}
                          filename={fileName5}
                          onChange={(e) => {
                            const inputFile = e.target.files;
                            setFile5(inputFile);
                            setFileName5(inputFile[0].name);
                          }}
                        />
                      </div>
                    </li>
                  </>
                )} */}

                {/* <li className="address-card-body">
                  <div className="d-flex flex-row justify-content-between align-content-center align-items-center ">
                    <span style={{ color: "red" }}>
                      *กรณีผู้เอาประกันภัย และ เจ้าของบัตรเครดิต
                      เป็นบุคคลคนเดียวกัน ไม่ต้องนำส่งเอกสาร
                    </span>
                  </div>
                </li> */}
              </ul>
            </div>
          </section>
        )}

        <br />
        <section className="action-address">
          <Grid
            container
            spacing={2}
            justifyContent="center" // จัดตำแหน่งตรงกลางแนวนอน
            alignItems="center" // จัดตรงกลางแนวตั้ง
            sx={{ minHeight: "10vh" }} // ให้ Grid เต็มความสูงหน้าจอ
          >

            <Grid item xs={12} sm={6} md={2}>
              <button
                onClick={() => {
                  if (cardStep === 1) {
                    // exit page or back step
                    navigate(-1);
                  } else if (cardStep === 3 && idnvFlag == 'Y') {
                    setCardStep(cardStep - 2)
                  } else {
                    setCardStep(cardStep - 1);
                  }
                }}
                className="btn-address-back w-100 p-2 bold"
              >
                ย้อนกลับ
              </button>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <button
                style={{ fontSize: 18 }}
                onClick={() => {
                  if (cardStep === 1) {
                    if (idnvFlag === 'Y') {
                      let errorsCard = validateCard();
                      if (errorsCard.length === 0) {
                        if (listData.length === 0) {
                          // setOpenErrorMissingCard(true);
                          setToggleImg(false);
                          setTextModal(
                            "กรุณาเลือกกรมธรรม์ที่ต้องการแจ้งเปลี่ยนบัตรเครดิต"
                          );
                          setCompletedModal(true);
                          // refCarousel.current.scrollIntoView();
                        } else {
                          setCardStep(3);
                        }
                      }
                    }
                    else {
                      let errorsCard = validateCard();
                      if (errorsCard.length === 0) {
                        if (listData.length === 0) {
                          // setOpenErrorMissingCard(true);
                          setToggleImg(false);
                          setTextModal(
                            "กรุณาเลือกกรมธรรม์ที่ต้องการแจ้งเปลี่ยนบัตรเครดิต"
                          );
                          setCompletedModal(true);
                          // refCarousel.current.scrollIntoView();
                        } else {
                          setCardStep(2);
                        }
                      }
                    }
                  } else if (cardStep === 2) {
                    const errorImage = validateImage();

                    if (!errorImage) {
                      setCardStep(3);

                    }
                  } else {
                    saveCardAndImage();
                  }

                }}
                className="btn-address-next w-100 p-2 bold"
              >
                {cardStep <= 2 ? "ต่อไป" : "บันทึก"}
              </button>
            </Grid>
          </Grid>
        </section>
      </div>
      <Modal
        isOpen={openModal}
        onRequestClose={handleCloseModal}
        contentLabel="ModalPolicy"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="p-lg-2 p-3">
          <h3 style={{ color: "#FA0E0E", fontSize: "18px" }}>กรมธรรม์เลขที่</h3>
          <div className="d-grid mb-4">
            <TextField
              type="text"
              size="small"
              onChange={(e) => handleSearchBasePolicy(e.target.value)}
              className=""
              value={searchBasePolicy}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={iconSearch} height={20} width={20} alt="" />
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "#FFF",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "6px",
                  height: "35px",
                  "& fieldset": {
                    borderColor: "#FB5E3F",
                  },
                  "&:hover fieldset": {
                    borderColor: "#FB5E3F",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#FB5E3F",
                  },
                },
              }}
            />
          </div>
          <div
            style={{
              maxHeight: "150px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {!filteredOptions.length == 0 ? (
              filteredOptions.map(renderOptionRow)
            ) : (
              <div className="text-center text-muted">
                ไม่มีข้อมูลที่ตรงกับการค้นหา
              </div>
            )}
          </div>
          <Row className="d-flex flex-row justify-content-evenly mt-3">
            {/* <Col className="bg-danger"> */}
            <button
              style={{ fontSize: 15 }}
              onClick={handleConfrimSearch}
              className="btn-checkout-premium w-25 p-2 bold"
            >
              ตกลง
            </button>
            <button
              style={{ fontSize: 15 }}
              onClick={handleCloseModal}
              className="btn-checkout-back w-25 p-2 bold"
            >
              ยกเลิก
            </button>
          </Row>
        </div>
      </Modal>
      <BootstrapModal
        show={completedModal}
        backdrop="static"
        // size="md"
        // style={{}}
        className="backdrop-alert"
        contentClassName="modal-alert"
      >
        <BootstrapModal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={toggleImg ? greenCheck : remove}
              alt="check-icon"
              className="modal-image-size"
            />
          </div>

          <li
            className="body-list-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Col
              xl={12}
              md={12}
              xs={12}
              style={{
                display: "flex",
                marginTop: 5,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div className="font-modal">{textModal}</div>
            </Col>
          </li>
          <div className="d-flex justify-content-center flex-wrap">
            <button
              className="btn-checkout-modal w-20 p-2 bold"
              onClick={() => {
                if (toggleImg) {
                  navigate("/personal");
                } else {
                  setCompletedModal(false);
                }
              }}
            >
              กลับ
            </button>
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>
    </div>
  );
};

export default EditCard2;
