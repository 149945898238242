import React from "react";
import uploadImg from "../../images/upload.svg";
import CircularProgress from "@mui/material/CircularProgress";
import "./CustomInputFile.css";

// ----- EXAMPLE Usage -----
{
  /* <CustomInputFile
  // required props
  id={"file1"}
  onChange={(e) => {
    const inputFile = e.target.files;
    setFile1(inputFile);
    setFileName1(inputFile[0].name);
  }}
  // optional props
  filename={fileName1}
  accept={"application/pdf"} // default as "image/png, image/jpeg, image/jpg, application/pdf"
  label={"เลือกไฟล์"} // default as "อัพโหลด"
  disabled={true || false}
  loading={true || false}
  error={true || false}
  errorText={"กรุณาเลือกไฟล์" || "ไฟล์มีขนาดใหญ่เกินไป"}
/>; */
}

export const CustomInputFileExcel = (props) => {
  const {
    id,
    accept,
    onChange,
    label,
    disabled,
    loading,
    filename,
    error,
    errorText,
    ...other
  } = props;

  return (
    <div className="flex ml-2">
      <label
        htmlFor={id}
        style={{ fontSize: 14 }}
        className={`btn-upload-image d-flex flex-row justify-content-center align-items-center
           ${
             disabled || loading ? " btn-disabled " : error ? " btn-error " : ""
           }`}
      >
        {loading ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          <>
            {filename ? filename : label ? label : "อัพโหลด"}
            <img
              className="icon-image-content-upload"
              src={uploadImg}
              alt="icon-image-content-upload"
            />
          </>
        )}
      </label>
      <input
        type="file"
        id={id}
        accept={
          accept ? accept : ".xlsx, .xls"
        }
        style={{ display: "none" }}
        onChange={onChange}
        disabled={disabled || loading}
        {...other}
      />
      <div className="error">{error && errorText ? errorText : null}</div>
    </div>
  );
};
