import React, { useRef, useEffect, useState } from 'react';
import { Card, CardContent, Box, FormControlLabel, Checkbox, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { CardHeader } from '@mui/material';



const ScrollableEmail = ({ listData, emailData, setListData,email }) => {
  const cardRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedIndexes, setSelectedIndexes] = useState([]); // เก็บ index ของ Box ที่ถูกเลือกเป็น array
  const [inputErrors, setInputErrors] = useState([]);
  // const [listData, setListData] = useState([]); // เก็บข้อมูลที่ถูกเลือก

  useEffect(() => {
    console.log(listData)
    const selectedIndexList = listData.map(item => item.index); // ดึง index จาก listData
    setSelectedIndexes(selectedIndexList);
    
    // console.log("cardRef.current:", cardRef.current);


  }, []); // เรียกครั้งเดียวหลัง 

  useEffect(() => {

    const updatedList = listData.map((item) => ({
      index:item.index,
      policyNo: item.policyNo,
      oldEmail: item.email,
      email: email
    }));
    
    setListData(updatedList);
  }, [email]);

  // useEffect(() => {
  //   console.log("Updated listData:", listData);
  // }, [listData]);
  


  const CustomCheckbox = styled(Checkbox)({
    color: 'rgba(255, 94, 67, 0.5)',
    '&.Mui-checked': {
      color: '#FF5E43',
    },
    marginRight: '15px'
  });

  const handleCheckAll = () => {
    if (isChecked) {
      removeAllItems(); // ลบข้อมูลทั้งหมด
    } else {
      const allIndexes = emailData.map((_, index) => index); // สร้าง array ของทุก index
      setSelectedIndexes(allIndexes);
  
      const updatedList = emailData.map((item, index) => ({
        index: index,
        policyNo: item.policy_no, // ใช้ข้อมูลจาก emailData แทน listData
        oldEmail: item.email,
        email: email // ใช้ค่า email ปัจจุบัน
      }));
  
      setListData(updatedList); // อัปเดต listData ด้วยข้อมูลใหม่ทั้งหมด
    }
    setIsChecked(!isChecked); // เปลี่ยนสถานะ CheckAll
  };
  

  const handleBoxClick = (index, policyNoVal, emailVal) => {
    setSelectedIndexes((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        // ถ้าถูกเลือกแล้ว ให้ลบออกจาก array ทั้งใน selectedIndexes และ listData
        console.log("old list : ",listData)
        console.log("policy : ",policyNoVal.trim())
        setListData((prevList) =>
          prevList.filter((item) => item.policyNo !== policyNoVal.trim())
        );
        
        // ลบ index ออกจาก selectedIndexes
        return prevIndexes.filter((i) => i !== index);
      } else {
        // ถ้ายังไม่ถูกเลือก ให้เพิ่มเข้าไปใน selectedIndexes
        const updatedList = {
          index: index,
          policyNo: policyNoVal.trim(),
          oldEmail: emailVal,
          email: email,
        };
        
        // อัปเดต listData โดยตรวจสอบว่า policyNoVal ยังไม่มีอยู่ใน listData ก่อนเพิ่ม
        setListData((prevList) => {
          const alreadyExists = prevList.some(item => item.policyNo === policyNoVal);
          
          // ถ้าไม่มีใน listData ถึงจะเพิ่ม updatedList เข้าไป
          if (!alreadyExists) {
            return [...prevList, updatedList];
          }
          return prevList;
        });
  
        // เพิ่ม index เข้าไปใน selectedIndexes
        return [...prevIndexes, index];
      }
    });
  };
  
  
  


  const addItem = (newItem) => {
    
    setListData((prevListData) => [...prevListData, newItem]);
  };

  // const removeItemByPolicyNo = (policyNo) => {
  //   console.log(prevListData)
  //   console.log(policyNo)
  //   setListData((prevList) =>
  //     prevList.filter((item) => item.policyNo !== policyNoVal)
  //   );

  //   setListData((prevListData) => prevListData.filter(item => item.policyNo !== policyNo));
  // };

  const removeAllItems = () => {
    setListData([]); // ตั้งค่า listData ให้เป็น array ว่าง
    setSelectedIndexes([]); // เคลียร์ selectedIndexes
  };

  return (
    <Card ref={cardRef} sx={{ maxHeight: '490px', overflowY: 'auto' }}>
      <CardContent
        sx={{
          maxHeight: '490px',
          overflowY: 'auto',
          border: '1px solid rgba(255, 94, 67, 0.3)', // สีของขอบ #FF5E43 ที่โปร่งใส 30%
          borderRadius: '12px', // ความโค้งของขอบ
          borderWidth: '2px',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box>
          <FormControlLabel
            control={<CustomCheckbox onClick={handleCheckAll} checked={isChecked} />}
            label={<Typography style={{ fontSize: '20px' }}>ทุกกรมธรรม์</Typography>}
          />
        </Box>

        <Typography
          variant="body2"
          sx={{ color: 'red', marginTop: '5px', marginLeft: '5px' }} // ข้อความสีแดงพร้อม margin ด้านบน
        >
          *กรุณาเลือกกรมธรรม์
        </Typography>

        {emailData.map((_, index) => (


          <Box
            key={index}
            onClick={() => handleBoxClick(index, _.policy_no, _.email)} // เมื่อ Box ถูกคลิก
            sx={{
              marginBottom: '15px',
              padding: '10px',

             
              transition: 'background-color 0.3s ease, border 0.3s ease',
              cursor: 'pointer',
              // display: 'flex', // จัดการแสดงผลให้เป็นแนวนอน (flexbox)
              alignItems: 'center', // จัดให้ทุกอย่างอยู่กึ่งกลางในแนวตั้ง
              justifyContent: 'space-between', // แยกข้อความทางซ้ายกับไอคอนทางขวา
              border: selectedIndexes.includes(index)
            
            }}
          >
            <Card style={{ zIndex: 1000 }} sx={{

              backgroundColor: selectedIndexes.includes(index)
                ? 'rgba(255, 94, 67, 0.1)' // สีพื้นหลังเข้มเมื่อถูกเลือก
                : 'transparent', // พื้นหลังปกติ
              transition: 'background-color 0.3s ease, border 0.3s ease',
            }}>
              {/* <CardHeader title={"กรมธรรม์เลขที่ : " + _.policy_no} sx={{ backgroundColor: 'rgb(255, 94, 67,0.3)' }} /> */}
              <CardHeader
                title={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CustomCheckbox
                      checked={selectedIndexes.includes(index)} // ทำการเช็คตาม selectedIndexes
                      onChange={(e) => {
                        console.log("");
                        e.stopPropagation(); // หยุดไม่ให้เกิด event ที่ parent
                        // handleBoxClick(index, _.policy_no, _.address1, _.address2, _.address3, _.address4, _.address5, _.id); // ทำการคลิกเมื่อ checkbox ถูกเลือก
                      }}
                      sx={{ color: 'rgba(255, 94, 67, 0.8)' }} // ปรับสี
                    />
                    <Box sx={{ marginLeft: '10px' }}> {/* เพิ่มระยะห่างระหว่าง checkbox และข้อความ */}
                      {"กรมธรรม์เลขที่ : " + _.policy_no}
                    </Box>
                  </Box>
                }
                sx={{
                  backgroundColor: 'rgba(255, 94, 67, 0.3)', // ปรับให้ใช้งาน rgba ถูกต้อง
                }}
                titleTypographyProps={{
                  sx: {
                    fontFamily: '"Prompt-Thin", sans-serif !important',
                    fontSize: '15px',
                    color: 'black',
                    marginLeft: '15px',
                  },
                }}
              />

              <CardContent>
                <Typography  >
                  <Grid container spacing={1} >
                    <Grid item xs={3} sm={3} md={1} lg={1} xl={1}>
                      <span className="font-size-label-pol">อีเมล :</span>
                    </Grid>
                    <Grid item xs={8} sm={8} md={10} lg={10} xl={10}>
                      <span className="font-size-label-pol">
                        {`${_.email}`}
                      </span>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      xl={1}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center', // จัดให้อยู่กึ่งกลางแนวนอน
                        alignItems: 'center',     // จัดให้อยู่กึ่งกลางแนวตั้ง
                      }}
                    >
            
                    </Grid>


                  </Grid>





                </Typography>
              </CardContent>
            </Card >
            <Box >
            </Box>
          </Box>
        ))}

      </CardContent>

    </Card>
  );
};

export default ScrollableEmail;
