import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import Axios from "../../../util/Axios";

// ----- use in many page -----
export const getUserProfileAPI = async () => {
  try {
    const res = await Axios.get(`/api/user/getprofile`);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

export const updatePersonalImageAPI = async (payload) => {
  try {
    const res = await Axios.post(`/api/personal/upload`, payload, {
      headers: { "content-type": "multipart/form-data" },
    });
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

// ----- use in edit address page -----
export const getPersonalAddressAPI = async () => {
  try {
    const res = await Axios.get(`/api/personal/getaddress`);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

export const getPersonalProvinceAPI = async () => {
  try {
    const res = await Axios.get(`/api/personal/getprovince`);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

export const getPersonalAmphurAPI = async (provinceCode) => {
  try {
    const res = await Axios.get(
      `/api/personal/getamphur?province_id=${provinceCode}`
    );
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

export const getPersonalDistrictAPI = async (amphurCode) => {
  try {
    const res = await Axios.get(`/api/personal/getdistrict?code=${amphurCode}`);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

export const getPersonalPostalAPI = async (amphurCode, districtCode) => {
  try {
    const res = await Axios.get(
      `/api/form/getpostal?district_code=${amphurCode}&subdistrict_code=${districtCode}`
    );
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

export const updatePersonalAddressAPI = async (payload) => {
  try {
    const res = await Axios.post(`/api/personal/address`, payload);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

// ----- use in edit phone page -----
export const getPersonalPhoneAPI = async () => {
  try {
    const res = await Axios.get(`/api/personal/getphone`);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

export const updatePersonalPhoneAPI = async (payload) => {
  try {
    const res = await Axios.post(`/api/personal/phone`, payload);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

// ----- use in edit email page -----
export const getPersonalEmailAPI = async () => {
  try {
    const res = await Axios.get(`/api/personal/getemail`);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

export const updatePersonalEmailAPI = async (payload) => {
  try {
    const res = await Axios.post(`/api/personal/email`, payload);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

// ----- use in edit name page -----
export const getPersonalPrefixAPI = async () => {
  try {
    const res = await Axios.get(`/api/personal/getprefix`);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

export const getPersonalNameAPI = async () => {
  try {
    const res = await Axios.get(`/api/personal/getname`);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

export const updatePersonalNameAPI = async (payload) => {+
  console.log("PayLoad : ",payload)
  try {
    const res = await Axios.post(`/api/personal/name`, payload);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

// ----- use in edit card page -----
export const getPersonalCardAPI = async () => {
  try {
    const res = await Axios.get(`/api/personal/getcredit`);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

export const updatePersonalCardAPI = async (payload) => {
  try {
    const res = await Axios.post(`/api/personal/credit`, payload);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

export const getPersonalMenu = async () => {
  try {
    const res = await Axios.get(`/api/getManuPersonal`);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};
