import React from "react";

export default function Header(props) {
  const { title } = props;
  return (
    <div className="top">
      <section className="sec-navbar">
        <nav
          style={{
            backgroundColor: "#FF5E43",
            color: "white",
            minHeight: "50px",
          }}
        >
          <div style={{ padding: "10px" }}>
            <h5 className="flex-shrink-1 text-center bold mb-0">{title}</h5>
          </div>
        </nav>
      </section>
    </div>
  );
}
