import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { StoreContextProvider } from "./context/store";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import "moment/locale/th";
import Footer from "./views/Component/Footer";
import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer } from "react-toastify";

const theme = createMuiTheme({
  overrides: {
    typography: {
      fontFamily: "Kanit",
    },
    MuiStepIcon: {
      root: {
        "&$active": {
          color: "#FF5E43", // กำหนดสีที่นี่
        },
        "&$completed": {
          color: "#FF5E43",
        },
      },
      active: {}, // ไม่ต้องทำอะไรเพิ่ม
      completed: {},
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <StoreContextProvider>
      <App />
      <ToastContainer />
    </StoreContextProvider>
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
