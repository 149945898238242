import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";

const Footer = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <footer style={{ position: "fixed", width: "100%", bottom: 0 }}>
      <section className="sec-footer">
        <nav className="navbar navbar-expand-sm navbar-light bg-light ">
          <div className="navbar-collapse">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <span className="pl-2">
                  ©2022 RABBITLIFE Inc. All Rights Reserved
                </span>
              </li>
            </ul>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a
                  className="text-black"
                  href="https://www.rabbitlife.co.th/site/ติดต่อสอบถาม"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ติดต่อสอบถาม
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </section>
    </footer>
  );
};

export default Footer;
