import React, { useState } from "react";
import { Grid } from "@mui/material";
// CSS
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./MailManagement.css";
// component
import PopUp from "../Component/Popup";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import { CustomInputDate } from "../Component/CustomInputDate";
import { mockData } from "./mockData";
import { ModalAlert } from "../Component/ModalAlert";

export const MailManagement = () => {
  const currentDate = new Date();
  const [selectDate, setSelectDate] = useState(currentDate);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const onClickProcess = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpenModal(true);
    }, 1500);
  };
  return (
    <div className="mail-management-history">
      {loading ? <PopUp /> : null}
      <Header_Home />
      <Header title="จัดการจดหมาย" />
      <ModalAlert
        title={"ระบบกำลังดำเนินการ"}
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
      <div className="mail-page-container footer-space">
        <div className="rounded-border bg-white p-4">
          <section className="input-section">
            <CustomInputDate
              id="input-start-date"
              label="วันที่"
              value={selectDate}
              onChange={(value) => {
                setSelectDate(value);
              }}
            />
          </section>
          <section className="info-section">
            <Grid container spacing={2}>
              <Grid item xs={6} textAlign={"right"}>
                ไฟล์ Web :
              </Grid>
              <Grid item xs={6}>
                {mockData.fileWebName || "ไม่มีไฟล์"}
              </Grid>
              <Grid item xs={6} textAlign={"right"}>
                ไฟล์ Web Expired :
              </Grid>
              <Grid item xs={6}>
                {mockData.fileWebExpiredName || "ไม่มีไฟล์"}
              </Grid>
            </Grid>
          </section>
          <section className="action-btn-section">
            <button
              style={{ fontSize: 18, width: "200px" }}
              onClick={() => onClickProcess()}
              className="btn-fill-orange p-2 bold"
            >
              Process
            </button>
          </section>
        </div>
      </div>
    </div>
  );
};
