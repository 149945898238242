import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import menu_1 from '../../images/1.svg'
import gmail from '../../images/gmail.png'
import loand from '../../images/dashboard.png'
import freelook from '../../images/Group 6435.png'
import RD from '../../images/bxs-discount.png'
import RPU from '../../images/case-check.png'
import ETI from '../../images/av-timer.png'
import menu_8 from '../../images/8.svg'
import Header_Home from '../Component/Header_Home';
import PopUp from '../Component/Popup';
// const CONFIG_1 = process.env.REACT_APP_CONFIG_1;
class Request extends Component {
    constructor(props) {
        super(props);
        this.state = {date: new Date() ,
        token: '',
        username:'',
        step:1,
        text_loan:'col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-4',
        text_freelook:'col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-4',
        text_rd:'col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-4',
        text_rpu:'col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-4',
        text_eti:'col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-4',
        loading:true
    }
        // setInterval(() => this.tick() ,1000)
        // setInterval(() => console.log('con') ,1000)
        // console.log('con'+ props.username)
    }

    tick() {
        this.setState({date: new Date()});
    }
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };
    
    prevStep = () => {
        this.props.prevStep();
    };

    componentDidMount() {
        console.log('componentDidMount')
        if(localStorage.getItem('profile') == 'POS1'){
            this.setState({
                text_loan:'col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-4',
                text_freelook:'col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-4',
                text_rd:'col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-4  none-dis',
                text_rpu:'col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-4',
                text_eti:'col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-4',
            })
        } else if(localStorage.getItem('profile') == 'POS2') {
            this.setState({
                text_loan:'col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-4 none-dis',
                text_freelook:'col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-4 none-dis',
                text_rd:'col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-4',
                text_rpu:'col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-4 none-dis',
                text_eti:'col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-4 none-dis',
            })

        } else if(localStorage.getItem('profile') == 'customer') {
            this.setState({
                text_loan:'col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-4',
                text_freelook:'col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-4',
                text_rd:'col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-4',
                text_rpu:'col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-4',
                text_eti:'col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-4',
            })

        }
        this.setState({loading:false})
    }

    componentDidUpdate() {
        console.log('componentDidUpdate')
    }

    componentWillUnmount() {
        console.log('componentWillUnmount')
       
    }


 
    render() {
        return (
                
            <div>
                <Header_Home/>
                {this.state.loading ? <PopUp /> : null}
                <section className="d-flex justify-content-center align-items-center h-100 my-4">
                    <div className="container-fluid  container-margin">
                        <div className="row">
                            <div className="col-12">
                                <h2><b>การใช้สิทธิ</b></h2>
                            </div>
                            <div className="row mb-3">
                            


                            {/* <div className={this.state.text_loan}>
                                <a href='/request/loan'>
                                    <div className="bx h-100 d-flex px-3 py-4 flex-column text-center">
                                        <img className="align-self-center" src={loand} alt=""/>
                                        <h4 className="flex-shrink-1 pt-3"> การใช้สิทธิกู้เงิน </h4>
                                    </div>
                                </a>
                                
                            </div> */}

                            <div className={this.state.text_loan}>
                                <a href='/request/freelook'>
                                    <div className="bx h-100 d-flex px-3 py-4 flex-column text-center">
                                        <img className="align-self-center" src={freelook} alt=""/>
                                        <h4 className="flex-shrink-1 pt-3"> การใช้สิทธิ Freelook</h4>
                                    </div>
                                </a>
                                
                            </div>    
                            
                            

                            {/* <div className={this.state.text_rd}>
                                <a href='/request/rd'>
                                    <div className="bx h-100 d-flex px-3 py-4 flex-column text-center">
                                        <img className="align-self-center" src={RD}  alt=""/>
                                        <h4 className="flex-shrink-1  pt-4"> การใช้สิทธิลดหย่อนภาษี</h4>
                                    </div>
                                </a>
                            </div>  */}

                            <div className={this.state.text_rpu}>
                                <a href='/request/rpu'>
                                    <div className="bx h-100 d-flex px-3 py-4 flex-column text-center">
                                        <img className="align-self-center" src={RPU}  alt=""/>
                                        <h4 className="flex-shrink-1 pt-4">กรมธรรม์ใช้เงินสำเร็จ</h4>
                                    </div>
                                </a>
                            </div> 
                            <div className={this.state.text_eti}>
                                <a href='/request/eti'>
                                    <div className="bx h-100 d-flex px-3 py-4 flex-column text-center">
                                        <img className="align-self-center" src={ETI}  alt=""/>
                                        <h4 className="flex-shrink-1 pt-4">การประกันภัยแบบขยายเวลา</h4>
                                    </div>
                                </a>
                            </div> 
                            </div>

                           

                           
                        </div>

                        <div className='App'>
                            <a  href="/home" type="button" className="btn btn-lg btn-blue"  >ย้อนกลับ</a>
                        </div>

                    </div>
                </section>

                {/* <Footer/> */}


            </div>
            )
    }
        
    
}

export default Request;