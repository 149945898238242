import React, { Component } from "react";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import Box from "@mui/material/Box";
import withNavigation from "./withNavigation";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Switch,
  FormControlLabel,
  DialogContentText,
  Grid,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { styled } from "@mui/material/styles";
import "../../App.css";
import "./Add_Parameter.css";
import "bootstrap/dist/css/bootstrap.min.css";

const RedSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked ": {
    color: "coral",
    "&:hover": {
      backgroundColor: "rgba(225, 0, 0, 0.08)",
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "coral",
  },
  "& .MuiFormControlLabel-label": {
    color: "coral",
  },
}));

const columns = [
  {
    field: "pmtId",
    headerName: "No.",
    flex: 0.5,
    minWidth: 80,
    align: "center",
    headerAlign: "center",
    editable: false,
    resizable: false,
    filterable: false,
    headerClassName: "super-app-theme--header",
    renderHeader: () => <strong>No.</strong>,
  },
  {
    field: "code",
    headerName: "Code",
    flex: 1,
    align: "center",
    headerAlign: "center",
    editable: false,
    resizable: false,
    filterable: false,
    minWidth: 120,
    headerClassName: "super-app-theme--header",
    renderHeader: () => <strong>Code</strong>,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    minWidth: 200,
    align: "center",
    headerAlign: "center",
    editable: false,
    resizable: false,
    filterable: false,
    headerClassName: "super-app-theme--header",
    renderHeader: () => <strong>Description</strong>,
  },
  {
    field: "flagActive",
    headerName: "Flag active",
    type: "boolean",
    flex: 1,
    minWidth: 160,
    align: "center",
    headerAlign: "center",
    editable: false,
    sortable: false,
    resizable: false,
    filterable: false,
    headerClassName: "super-app-theme--header",
    renderHeader: () => <strong>Flag active</strong>,
    renderCell: (params) =>
      params.value ? (
        <div className="actionBlock">Active</div>
      ) : (
        <div className="inActionBlock">Inactive</div>
      ),
  },
  {
    field: "action",
    headerName: "Action",
    description: "This column has a value getter and is not sortable.",
    align: "center",
    headerAlign: "center",
    editable: false,
    sortable: false,
    resizable: false,
    filterable: false,
    flex: 1,
    minWidth: 170,
    headerClassName: "super-app-theme--header",
    renderHeader: () => <strong>Action</strong>,
    renderCell: (params) => (
      <div className="detail_action">
        <Button
          variant="contained"
          color="primary"
          className="detail_button btn"
          onClick={() => params.api.current.handleButtonClick(params.row)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            className="bi bi-info-circle"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
          </svg>
          <span> Detail</span>
        </Button>
      </div>
    ),
  },
];

class Add_Parameter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      token: "",
      username: "",
      step: 1,
      offset: 0,
      data: [],
      perPage: 50,
      currentPage: 0,
      pageCount: 0,
      open: false,
      code: "",
      description: "",
      checked: false,
      apiData: [],
    };
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  componentDidMount() {
    axios
      .get("https://localhost:7044/api/parameter/getparameter")
      .then((response) => {
        this.setState({
          apiData: response.data.payload,
        });
      })
      .catch((error) => {
        console.error("There was an error fetching the API data", error);
      });
  }

  handleButtonClick(row) {
    this.props.navigate("/addParameteredit", { state: { row } });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  isDuplicateCode = (code) => {
    return this.state.apiData.some((item) => item.code === code);
  };
  handleSave = () => {
    const { code, description, checked } = this.state;
    if (code != "" && description != "" && !this.isDuplicateCode(code)) {
      const dataupdate = {
        code: code,
        name: description,
        description: description,
        flag_active: "Y",
      };
      axios
        .post("https://localhost:7044/api/parameter/postparameter", dataupdate)
        .then((response) => {
          this.setState((prevState) => {
            const newId =
              prevState.apiData.length > 0
                ? this.state.apiData.slice(-1)[0].pmtId + 1
                : 1;
            const dataupdate_table = {
              pmtId: newId,
              code: code,
              name: description,
              description: description,
              flagActive: "Y",
            };
            const newRow = [...prevState.apiData];
            newRow[newId - 1] = dataupdate_table;
            this.setState({ apiData: newRow });
            alert("Add Row successfully");
            console.log(response);
            this.handleClose();
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      alert("code already exists");
    }
  };

  handleChange = (e) => {
    if (e.target.name === "checked") {
      this.setState({ checked: e.target.checked });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  render() {
    const { open, code, description, checked, apiData } = this.state;
    return (
      <div>
        <Header_Home />
        <Header title="Parameter : บันทึกข้อมูล" />
        <div className="content margin-3">
          <div className="content margin-auto">
            <h3 className="head_parameter">Parameter : บันทึกข้อมูล</h3>
            <section className="section rounded border border-2 p-3">
              <div>
                <button
                  className="add_para_button"
                  variant="contained"
                  onClick={this.handleClickOpen}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="26"
                    fill="currentColor"
                    className="bi bi-plus-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                    />
                  </svg>
                  <span> Add parameter</span>
                </button>
                <Dialog
                  open={open}
                  onClose={this.handleClose}
                  fullWidth
                  PaperProps={{
                    style: { minWidth: "500px" },
                  }}
                >
                  <DialogTitle>เพิ่มข้อมูล</DialogTitle>
                  <DialogContentText></DialogContentText>
                  <DialogContent>
                    <Grid container spacing={2}>
                      <TextField
                        autoFocus
                        margin="dense"
                        label="Code"
                        type="text"
                        variant="standard"
                        name="code"
                        value={code}
                        onChange={this.handleChange}
                        fullWidth
                      />
                      <TextField
                        autoFocus
                        margin="dense"
                        type="text"
                        variant="standard"
                        label="Descirption"
                        name="description"
                        value={description}
                        onChange={this.handleChange}
                        fullWidth
                      />
                      <FormControlLabel
                        control={
                          <RedSwitch
                            checked={checked}
                            onChange={this.handleChange}
                            name="checked"
                            defaultChecked
                          />
                        }
                        label={
                          <Box sx={{ color: checked ? "coral" : "#000000" }}>
                            {checked ? "Active" : "Inactive"}
                          </Box>
                        }
                      />
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <div className="popButton">
                      <div className="popButton_in" onClick={this.handleSave}>
                        <button className="btn popButton_in_button">
                          Save
                        </button>
                      </div>
                      <div className="popButton_in" onClick={this.handleClose}>
                        <button className="btn popButton_in_button">
                          Close
                        </button>
                      </div>
                    </div>
                  </DialogActions>
                </Dialog>
              </div>
              <div className="row">
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    "& .super-app-theme--header": {
                      backgroundColor: "rgba(189,229,254,255)",
                    },
                  }}
                >
                  <DataGrid
                    rows={apiData}
                    columns={columns.map((col) => {
                      if (col.field === "action") {
                        return {
                          ...col,
                          renderCell: (params) => (
                            <button
                              className="btn detail_button"
                              variant="contained"
                              color="primary"
                              onClick={() => this.handleButtonClick(params.row)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="18"
                                fill="currentColor"
                                className="bi bi-info-circle"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                              </svg>
                              <span> Detail</span>
                            </button>
                          ),
                        };
                      }
                      return col;
                    })}
                    getRowId={(row) => row.pmtId}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[10, 20, 50, 100]}
                    disableColumnMenu
                    disableRowSelectionOnClick
                  />
                </Box>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default withNavigation(Add_Parameter);
